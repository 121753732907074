import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Popup, Grid, Input } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyFilesListURL, companyFileDownloadURL } from "../../constants";


class CompanyFiles extends React.Component {
    state = {
        files: null,
        error: null,
        loading: false,
        search_start_date: null,
        search_end_date: null,
        search: ""
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchFileList();
    }


    handleFetchFileList = () => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(companyFilesListURL(activeCompanyId))
            .then(res => {
                this.setState({
                    files: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    fileDetails = (id) => {
        this.props.history.push(`/company/file-details/${id}/`)
    };

    downloadFile = (file) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(companyFileDownloadURL(activeCompanyId, file.id), { responseType: 'blob' })
            .then(res => {
                //Create a Blob from the data stream
                const res_file = new Blob(
                    [res.data]);
                //Build a URL from the file
                const fileURL = URL.createObjectURL(res_file);
                // Get the file name from the URL
                const filename = file.file.substring(file.file.lastIndexOf("/") + 1);
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();

                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date")
        });
    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date")
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        const { error, loading, files, search, search_end_date, search_start_date } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        let filtered_items = [];

        // Filter data for search
        if (files !== null) {
            filtered_items = files.filter((file) => {
                return file.group.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    file.category.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    file.name.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    file.author.first_name.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    file.author.last_name.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    file.date_created.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
            if (search_start_date !== null) {
                filtered_items = filtered_items.filter((file) => {
                    return (new Date(file.date_created) >= new Date(search_start_date))
                })
            }
            if (search_end_date !== null) {
                filtered_items = filtered_items.filter((file) => {
                    return (new Date(file.date_created) <= new Date(search_end_date).setDate(new Date(search_end_date).getDate() + 1))
                })
            }
        }
        return (
            <Shell>
                <Header as="h3">{t("Company files")}</Header>
                <Grid columns="2">
                    <Grid.Column width={9}>
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            size="mini"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleStartDateChange(selected)}
                            format='DD.MM.YYYY' />
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            size="mini"
                            disabled={loading}
                            label="->"
                            locale={fiLocale}
                            placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleEndDateChange(selected)}
                            format='DD.MM.YYYY' />
                    </Grid.Column>
                    <Grid.Column floated="right" width={6}>
                        <Input
                            size="mini"
                            icon='search'
                            value={search}
                            name="search"
                            onChange={this.handleChange} />
                        <Button positive size='tiny' content={t("+ File")} onClick={() => this.props.history.push(`/company/file-details/upload/`)} />
                    </Grid.Column>
                </Grid>

                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {files && (
                    <Table striped selectable celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("File")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("File group")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("File category")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("File name")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("File added")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("File author")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Modify")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {filtered_items.map((file) => {
                                return (
                                    <Table.Row key={file.id} >
                                        <Table.Cell>
                                            <Popup content={file.file.substring(file.file.lastIndexOf("/") + 1)} trigger={
                                                <Button primary size='mini' icon='file alternate outline' onClick={() => this.downloadFile(file)} />}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>{file.group}</Table.Cell>
                                        <Table.Cell>{file.category}</Table.Cell>
                                        <Table.Cell>{file.name}</Table.Cell>
                                        <Table.Cell>{new Date(file.date_created).toLocaleString('fi-FI')}</Table.Cell>
                                        <Table.Cell>{file.author.first_name} {file.author.last_name}</Table.Cell>
                                        <Table.Cell children={<Button positive size='mini' onClick={() => this.fileDetails(file.id)} icon='edit' />} />
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companyfiles')(CompanyFiles))
);