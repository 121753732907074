// Copyright Caproc Oy
// System name: IoT
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version
// 6.5.2020 TS Added roles selection
// 26.6.2020 TS Added sensor group selection for alarms to limit alarms only from selected groups

import React from "react";
import { Form, Input, Message, Header, Button, Checkbox, Confirm, Modal, Popup, Accordion, Icon } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import axios from "axios";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyUserDetailsURL, companyUserDetailsUpdateURL, signupURL, companyUserRoleOptionsURL, userExistsURL, fetchSensorGroupsURL } from "../../constants";


class CompanyUserDetails extends React.Component {
    state = {
        first_name: "",
        last_name: "",
        title: "",
        email: "",
        phone_number: "",
        email_alarms: false,
        sms_alarms: false,
        push_notifications: true,
        is_active: true,
        error: null,
        success_mgs: null,
        loading: false,
        create: null,
        role_options: null,
        userroles: null,
        confirm_open: false,
        confirm_text: "",
        confirm_param: null,
        user_id: null,
        modal_open: false,
        modal_text: "",
        sensor_alarm_group_options: null,
        sensor_alarm_groups_email: [],
        sensor_alarm_groups_sms: [],
        sensor_alarm_groups_push: [],
        sensor_user_groups: [],
        activeIndexAlarms: 0,
        activeIndexUserGroups: 0,
        access_group_visible: false
    };

    componentDidMount() {
        this.props.setActivity();
        if (this.props.match.params.user_id === "create") {
            this.setState({
                create: true,
                is_active: true
            })
            this.fetchRoleOptions();
            this.fetchSensorGroupOptions();
        } else {
            this.handleDetails();
        }
    }

    fetchSensorGroupOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchSensorGroupsURL(activeCompanyId))
            .then(res => {
                
                this.setState({
                    sensor_alarm_group_options: this.handleFormatSensorGroupOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatSensorGroupOptions = options => {
        const { t } = this.props;
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: options[k].name,
                value: options[k].id
            };
        });
    };

    fetchRoleOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(companyUserRoleOptionsURL(activeCompanyId))
            .then(res => {
                
                this.setState({
                    role_options: this.handleFormatRoleOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    checkIfUserAlreadyExists = e => {
        this.setState({
            loading: true
        });
        const { activeCompanyId, t } = this.props;
        const { email } = this.state;

        authAxios
            .post(userExistsURL(activeCompanyId), {
                user_name: email
            })
            .then(res => {
                this.setState({
                    error: null,
                    success_mgs: null,
                    loading: false
                });
                // Returns 0 if user does not exist
                if (parseInt(res.data) === 0) {
                    this.handleSubmit(e);
                } else if (parseInt(res.data) === -1) { // Returns -1 if user already exists, but is not active
                    this.setState({
                        modal_open: true,
                        modal_text: t("User already exists, but it is NOT active. Please ask support (info@caproc.fi) to activate user and then add roles?", {user: email}),
                    })
                } else { // Returns user id if user already exists and is active
                    this.setState({
                        confirm_open: true,
                        confirm_param: e,
                        confirm_text: t("User already exists. Do you want to give selected roles to existing user?", {user: email}),
                        create: false,
                        user_id: res.data
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatRoleOptions = options => {
        const { t } = this.props;
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: t(options[k].name),
                value: this.checkRoleStatus(options[k])
            };
        });
    };

    checkRoleStatus = role => {
        const { userroles } = this.state;
        const { activeCompanyId } = this.props;
        if (userroles !== null) {
            let i = 0;
            let return_value = false;
            for (i in userroles) {
                if (userroles[i].company === parseInt(activeCompanyId) && userroles[i].role === role.value && userroles[i].is_active) {
                    return_value = true;
                    if (role.name === "Client" || role.name === "Asiakas") {
                        this.setState({
                            access_group_visible: true
                        });
                    }
                }
            }
            return return_value;
        } else {
            return false;
        }
    };


    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { user_id } = this.props.match.params;
        authAxios
            .get(companyUserDetailsURL(activeCompanyId, user_id))
            .then(res => {
                this.setState({
                    user_id: user_id,
                    first_name: res.data.first_name,
                    last_name: res.data.last_name,
                    title: res.data.title,
                    email: res.data.email,
                    phone_number: res.data.phone_number,
                    email_alarms: res.data.email_alarms,
                    sms_alarms: res.data.sms_alarms,
                    push_notifications: res.data.push_notifications,
                    is_active: res.data.is_active,
                    userroles: res.data.userroles,
                    sensor_alarm_groups_sms: this.handleExistingAlarmGroups(res.data.sensoralarmgroups, "S"),
                    sensor_alarm_groups_email: this.handleExistingAlarmGroups(res.data.sensoralarmgroups, "E"),
                    sensor_alarm_groups_push: this.handleExistingAlarmGroups(res.data.sensoralarmgroups, "P"),
                    sensor_user_groups: this.handleExistingUserGroups(res.data.sensorusergroups),
                    error: null,
                    success_mgs: null,
                    loading: false
                });
                this.fetchRoleOptions();
                this.fetchSensorGroupOptions();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleExistingAlarmGroups = (groups, type) => {
        let sorted_group = [];
        let i = 0;
        for (i in groups) {
            if (groups[i].alarm_type === type) {
                sorted_group.push(groups[i].sensor_group);
            }
        }
        return sorted_group;
    };

    handleExistingUserGroups = (groups) => {
        let sorted_group = [];
        let i = 0;
        for (i in groups) {
            sorted_group.push(groups[i].sensor_group);
        }
        return sorted_group;
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleCheckboxChange = (e, { name, value }) => {
        this.setState({
            [name]: !value
        });
    };

    handleRolesCheckboxChange = (e, { name, value }) => {
        let { role_options, access_group_visible } = this.state;
        let i = 0;

        for (i in role_options) {
            if (name === role_options[i].text) {
                role_options[i].value = !value;
                if (name === "Asiakas" || name === "Client") {
                    access_group_visible = !value;
                }
                this.setState({
                    role_options: role_options,
                    access_group_visible: access_group_visible
                });
            }
        }    
    };

    checkRoleChoices = () => {
        const { role_options } = this.state;
        let i = 0;
        let return_value = false;
        for (i in role_options) {
            if (role_options[i].value) {
                return_value = true;
            }
        }
        return return_value;
    };

    handlePreCheck = e => {
        if (this.state.create) {
            this.checkIfUserAlreadyExists(e);
        } else {
            this.handleSubmit(e);
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            first_name,
            last_name,
            title,
            email,
            phone_number,
            email_alarms,
            sms_alarms,
            push_notifications,
            is_active,
            role_options,
            create,
            user_id,
            sensor_alarm_groups_sms,
            sensor_alarm_groups_email,
            sensor_alarm_groups_push,
            sensor_user_groups
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (first_name === "") {
            this.setState({
                error: t("Please fill in first name"),
                success_mgs: null,
                loading: false
            });
        } else if (last_name === "") {
            this.setState({
                error: t("Please fill in last name"),
                success_mgs: null,
                loading: false
            });
        } else if (first_name.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for First name"),
                success_mgs: null,
                loading: false
            });
        } else if (last_name.length > 30) {
            this.setState({
                error: t("Max amount of characters is 30 for Last name"),
                success_mgs: null,
                loading: false
            });
        } else if (title.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for Title"),
                success_mgs: null,
                loading: false
            });
        } else if (phone_number.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for Phone"),
                success_mgs: null,
                loading: false
            });
        } else {
            // Join email and alarm groups in to one group for the interface
            let i = 0;
            let sensor_alarm_groups = [];
            for (i in sensor_alarm_groups_email) {
                sensor_alarm_groups.push({
                    "sensor_group": sensor_alarm_groups_email[i],
                    "alarm_type": "E"
                });
            }
            i = 0;
            for (i in sensor_alarm_groups_sms) {
                sensor_alarm_groups.push({
                    "sensor_group": sensor_alarm_groups_sms[i],
                    "alarm_type": "S"
                });
            }
            i = 0;
            for (i in sensor_alarm_groups_push) {
                sensor_alarm_groups.push({
                    "sensor_group": sensor_alarm_groups_push[i],
                    "alarm_type": "P"
                });
            }
            i = 0;
            let sensor_user_access_groups = [];
            for (i in sensor_user_groups) {
                sensor_user_access_groups.push({
                    "sensor_group": sensor_user_groups[i],
                    "group_type": "N"
                });
            }
            if (create) {
                const pswd = 'kjdfs7fds!a';
                axios
                    .post(signupURL, {
                        username: email,
                        email,
                        password1: pswd,
                        password2: pswd
                    })
                    .then(res => {
                        authAxios
                            .post(companyUserDetailsUpdateURL(activeCompanyId, null), {
                                token: res.data.key,
                                username: email,
                                first_name,
                                last_name,
                                title,
                                email,
                                phone_number,
                                email_alarms,
                                sms_alarms,
                                push_notifications,
                                is_active,
                                is_created: true,
                                role_options,
                                sensor_alarm_groups,
                                sensor_user_access_groups
                            })
                            .then(res => {
                                this.props.history.push("/company/users/");
                            })
                            .catch(err => {
                                this.setState({
                                    loading: false,
                                    success_mgs: null,
                                    error: err
                                });
                            });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            } else {
                
                authAxios
                    .post(companyUserDetailsUpdateURL(activeCompanyId, user_id), {
                        username: email,
                        first_name,
                        last_name,
                        title,
                        email,
                        phone_number,
                        email_alarms,
                        sms_alarms,
                        push_notifications,
                        is_active,
                        is_created: false,
                        role_options,
                        sensor_alarm_groups,
                        sensor_user_access_groups
                    })
                    .then(res => {
                        this.props.history.push("/company/users/");
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    closeConfirm = () => {
        this.setState({
            confirm_open: false,
            confirm_param: "",
            confirm_text: "",
            create: true,
            user_id: null
        });
    };

    closeModal = () => {
        this.setState({
            modal_open: false,
            modal_text: ""
        });
    };

    handleCheckIfSelectedAlarmGroup = (id, type) => {
        const { sensor_alarm_groups_email, sensor_alarm_groups_sms, sensor_alarm_groups_push } = this.state;
        let i = 0;
        let return_value = false;
        let list = null;
        if (type === "S") {
            list = sensor_alarm_groups_sms;
        } else if (type === "E") {
            list = sensor_alarm_groups_email;
        } else if (type === "P") {
            list = sensor_alarm_groups_push;
        }
        for (i in list) {
            if (id === list[i]) {
                return_value = true;
            }
        }
        return return_value;
    };

    handleCheckIfSelectedUserGroup = (id) => {
        const { sensor_user_groups } = this.state;
        let i = 0;
        let return_value = false;
        for (i in sensor_user_groups) {
            if (id === sensor_user_groups[i]) {
                return_value = true;
            }
        }
        return return_value;
    };


    handleAlarmGroupsEmailCheckboxChange = (e, { value, checked }) => {
        let { sensor_alarm_groups_email } = this.state;
        if (checked) {
            if (!sensor_alarm_groups_email.includes(value)) {
                sensor_alarm_groups_email.push(value);
            }
        } else {
            if (sensor_alarm_groups_email.includes(value)) {
                sensor_alarm_groups_email.splice(sensor_alarm_groups_email.indexOf(value), 1);
            }
        }
        this.setState({ sensor_alarm_groups_email });
    };

    handleAlarmGroupsSmsCheckboxChange = (e, { value, checked }) => {
        let { sensor_alarm_groups_sms } = this.state;
        if (checked) {
            if (!sensor_alarm_groups_sms.includes(value)) {
                sensor_alarm_groups_sms.push(value);
            }
        } else {
            if (sensor_alarm_groups_sms.includes(value)) {
                sensor_alarm_groups_sms.splice(sensor_alarm_groups_sms.indexOf(value), 1);
            }
        }
        this.setState({ sensor_alarm_groups_sms });
    };

    handleAlarmGroupsPushCheckboxChange = (e, { value, checked }) => {
        let { sensor_alarm_groups_push } = this.state;
        if (checked) {
            if (!sensor_alarm_groups_push.includes(value)) {
                sensor_alarm_groups_push.push(value);
            }
        } else {
            if (sensor_alarm_groups_push.includes(value)) {
                sensor_alarm_groups_push.splice(sensor_alarm_groups_push.indexOf(value), 1);
            }
        }
        this.setState({ sensor_alarm_groups_push });
    };

    handleUserGroupsCheckboxChange = (e, { value, checked }) => {
        let { sensor_user_groups } = this.state;
        if (checked) {
            if (!sensor_user_groups.includes(value)) {
                sensor_user_groups.push(value);
            }
        } else {
            if (sensor_user_groups.includes(value)) {
                sensor_user_groups.splice(sensor_user_groups.indexOf(value), 1);
            }
        }
        this.setState({ sensor_user_groups });
    };


    handleClickUserGroups = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndexUserGroups } = this.state;
        const newIndex = activeIndexUserGroups === index ? -1 : index

        this.setState({ activeIndexUserGroups: newIndex })
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndexAlarms } = this.state;
        const newIndex = activeIndexAlarms === index ? -1 : index

        this.setState({ activeIndexAlarms: newIndex })
    };

    render() {
        const {
            first_name,
            last_name,
            title,
            email,
            phone_number,
            email_alarms,
            sms_alarms,
            push_notifications,
            error,
            success_mgs,
            loading,
            create,
            role_options,
            confirm_open,
            confirm_param,
            confirm_text,
            modal_open,
            modal_text,
            sensor_alarm_group_options,
            activeIndexAlarms,
            activeIndexUserGroups,
            access_group_visible
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        const logo = localStorage.getItem("Logo");
        return (
            <Shell >
                {logo && (
                    <>
                        <Header as="h4">{t("Company user details")}</Header>
                        <Form error={error !== null}>
                            <Form.Field required>
                                <label>{t("First name")}</label>
                                <Input
                                    required
                                    value={first_name}
                                    name="first_name"
                                    onChange={create && (this.handleChange)} />
                            </Form.Field>
                            <Form.Field required>
                                <label>{t("Last name")}</label>
                                <Input
                                    required
                                    value={last_name}
                                    name="last_name"
                                    onChange={create && (this.handleChange)} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t("Title")}</label>
                                <Input
                                    value={title}
                                    name="title"
                                    onChange={create && (this.handleChange)} />
                            </Form.Field>
                            <Form.Field required>
                                <label>{t("Email")}</label>
                                <Input
                                    required
                                    value={email}
                                    name="email"
                                    onChange={create && (this.handleChange)} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t("Phone")}</label>
                                <Input
                                    value={phone_number}
                                    name="phone_number"
                                    onChange={create && (this.handleChange)} />
                            </Form.Field>
                            <Accordion>
                                <Accordion.Title
                                    active={activeIndexAlarms === 1}
                                    index={1}
                                    onClick={this.handleClick}>
                                    <Icon name='dropdown' />
                                    <b>{t("Alarm settings")}</b> 
                                </Accordion.Title>
                                <Accordion.Content active={activeIndexAlarms === 1}>   
                                    <hr/>
                                    <Form.Field>
                                        <Checkbox
                                            label={create ? <label>{t("Receive email alarms")}</label> : <Popup content={t("Only user can activate/deactivate alarms")} trigger={<label>{t("Receive email alarms")}</label>} />}
                                            disabled={!create}
                                            name={"email_alarms"}
                                            onChange={create && (this.handleCheckboxChange)}
                                            checked={email_alarms}
                                            value={email_alarms}
                                        />
                                    </Form.Field>
                                    {(sensor_alarm_group_options) && (
                                        <React.Fragment>
                                            <br/>
                                            <label><strong>{t("Select sensor groups for email alarms")}</strong></label>
                                            <br/>
                                            <br/>
                                            {sensor_alarm_group_options.map((group) => {
                                                return (
                                                    <Form.Field>
                                                        <Checkbox
                                                            label={<label>{t(group.text)}</label>}
                                                            onChange={this.handleAlarmGroupsEmailCheckboxChange}
                                                            name="alarm_group_email"
                                                            checked={this.handleCheckIfSelectedAlarmGroup(group.value, "E") === true}
                                                            value={group.value}
                                                        />
                                                    </Form.Field>
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                    <hr/>
                                    <Form.Field>
                                        <Checkbox
                                            label={create ? <label>{t("Receive sms alarms")}</label> : <Popup content={t("Only user can activate/deactivate alarms")} trigger={<label>{t("Receive sms alarms")}</label>} />}
                                            disabled={!create}
                                            name={"sms_alarms"}
                                            onChange={create && (this.handleCheckboxChange)}
                                            checked={sms_alarms}
                                            value={sms_alarms}
                                        />
                                    </Form.Field>
                                    {(sensor_alarm_group_options) && (
                                        <React.Fragment>
                                            <br/>
                                            <label><strong>{t("Select sensor groups for sms alarms")}</strong></label>
                                            <br/>
                                            <br/>
                                            {sensor_alarm_group_options.map((group) => {
                                                return (
                                                    <Form.Field>
                                                        <Checkbox
                                                            label={<label>{t(group.text)}</label>}
                                                            onChange={this.handleAlarmGroupsSmsCheckboxChange}
                                                            name="alarm_group_sms"
                                                            checked={this.handleCheckIfSelectedAlarmGroup(group.value, "S") === true}
                                                            value={group.value}
                                                        />
                                                    </Form.Field>
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                    <hr/>
                                    <Form.Field>
                                        <Checkbox
                                            label={create ? <label>{t("Receive push alarms")}</label> : <Popup content={t("Only user can activate/deactivate alarms")} trigger={<label>{t("Receive push alarms")}</label>} />}
                                            disabled={!create}
                                            name={"push_notifications"}
                                            onChange={create && (this.handleCheckboxChange)}
                                            checked={push_notifications}
                                            value={push_notifications}
                                        />
                                    </Form.Field>
                                    {(sensor_alarm_group_options) && (
                                        <React.Fragment>
                                            <br/>
                                            <label><strong>{t("Select sensor groups for push alarms")}</strong></label>
                                            <br/>
                                            <br/>
                                            {sensor_alarm_group_options.map((group) => {
                                                return (
                                                    <Form.Field>
                                                        <Checkbox
                                                            label={<label>{t(group.text)}</label>}
                                                            onChange={this.handleAlarmGroupsPushCheckboxChange}
                                                            name="alarm_group_push"
                                                            checked={this.handleCheckIfSelectedAlarmGroup(group.value, "P") === true}
                                                            value={group.value}
                                                        />
                                                    </Form.Field>
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </Accordion.Content>
                            </Accordion>
                            <hr/>
                            {/* <Form.Field>
                                <Checkbox
                                    label={<label>{t("Is active")}</label>}
                                    name="is_active"
                                    onChange={this.handleCheckboxChange}
                                    checked={is_active}
                                    value={is_active}

                                />
                            </Form.Field> */}
                            {role_options && (
                                <React.Fragment>
                                    <br/>
                                    <label><strong>{t("User roles")}</strong></label>
                                    <br/>
                                    <br/>
                                    {role_options.map((role) => {
                                        return (
                                            <Form.Field>
                                                <Checkbox
                                                    disabled={role.key === "A"}
                                                    label={<label>{t(role.text)}</label>}
                                                    name={role.text}
                                                    onChange={this.handleRolesCheckboxChange}
                                                    checked={role.value}
                                                    value={role.value}
                                                />
                                            </Form.Field>
                                        );
                                    })}
                                </React.Fragment>
                            )}
                            {access_group_visible && (
                                <Accordion>
                                    <Accordion.Title
                                        active={activeIndexUserGroups === 1}
                                        index={1}
                                        onClick={this.handleClickUserGroups}>
                                        <Icon name='dropdown' />
                                        {logo === "Polygon" ? (
                                            <b>{t("Access to projects")}</b> 
                                        ) : (
                                            <b>{t("Access groups")}</b> 
                                        )}
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndexUserGroups === 1}> 
                                        {(sensor_alarm_group_options) && (
                                            <React.Fragment>
                                                <br/>
                                                {logo === "Polygon" ? (
                                                    <label><strong>{t("Choose projects for the role")}</strong></label>
                                                ) : (
                                                    <label><strong>{t("Choose access groups for the role")}</strong></label>
                                                )}
                                                <br/>
                                                <hr/>
                                                {sensor_alarm_group_options.map((group) => {
                                                    return (
                                                        <Form.Field>
                                                            <Checkbox
                                                                label={<label>{t(group.text)}</label>}
                                                                onChange={this.handleUserGroupsCheckboxChange}
                                                                name="user_access_group"
                                                                checked={this.handleCheckIfSelectedUserGroup(group.value) === true}
                                                                value={group.value}
                                                            />
                                                        </Form.Field>
                                                    );
                                                })}
                                            </React.Fragment>
                                        )}
                                    </Accordion.Content>
                                </Accordion>
                            )}
                            <br/>
                            {error && <Message error header={t("Error")} content={error} />}
                            {success_mgs && <Message positive header={success_mgs} />}
                            <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handlePreCheck(e)}>
                                {create ? (t("Create")) : (t("Save"))}
                            </Button>
                        </Form>
                        <Confirm
                            cancelButton={t("Cancel")}
                            confirmButton={t("OK")}
                            content={confirm_text}
                            open={confirm_open}
                            onCancel={() => this.closeConfirm()}
                            onConfirm={() => this.handleSubmit(confirm_param)}
                        />
                        <Modal basic size='tiny' open={modal_open} >
                            
                            <Modal.Content>
                                {modal_text}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button type="button" primary content={t("OK")} onClick={() => this.closeModal()} />
                            </Modal.Actions>
                        </Modal>
                    </>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companyuserdetails')(CompanyUserDetails))
);