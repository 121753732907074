import React from "react";
import { Grid, Container, Segment, Header, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import { logout } from "../../store/actions/auth";

const Shell = props => (
    <Segment vertical>
        <Container>
            <Grid container stackable verticalAlign="top" divided columns={2}>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Header as="h3">{props.t("Account")}</Header>
                        <Menu vertical fluid>
                            <Menu.Item
                                active={props.location.pathname === "/account/account-details"}
                                onClick={() => props.history.push("/account/account-details")}
                                name="account-details"
                            >
                                {props.t("Account details")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/account/change-password"}
                                onClick={() => props.history.push("/account/change-password")}
                                name="change-password"
                            >
                                {props.t("Change password")}
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={12}>{props.children}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment>
);

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout())
    };
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(withTranslation('accountshell')(Shell))
);