import React from "react";
import { Form, Input, Message, Header, Button, TextArea, Accordion, Icon, Popup, Confirm } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import Shell from "./Shell";
import { authAxios, authFileAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyTaskDetailsURL, companyTaskUpdateURL, companyTaskCreateURL, companyTaskFileUploadURL, companyTaskFileDownloadURL, companyTaskFileDeleteURL } from "../../constants";


class CompanyTaskDetails extends React.Component {
    state = {
        task: {
            company: null,
            customer: null,
            task_type: "",
            location: "",
            category: "",
            target: "",
            name: "",
            description: "",
            operator: "",
            task_date: null,
            date_created: null,
            last_modified: null,
            author: null,
            task_files: null
        },
        files: [],
        file_names: [],
        error: null,
        loading: false,
        create: null,
        sendingOK: false,
        activeIndex: 0,
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: ""

    };

    componentDidMount() {
        this.props.setActivity();
        if (this.props.match.params.task_id === "create") {
            this.handleDateChange(new Date());
            this.setState({
                create: true
            })
        } else {
            this.handleDetails();
        }
    }

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { task_id } = this.props.match.params;
        authAxios
            .get(companyTaskDetailsURL(activeCompanyId, task_id))
            .then(res => {
                this.setState({
                    task: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleChange = e => {
        const { task } = this.state;
        const updatedTask = {
            ...task,
            [e.target.name]: e.target.value
        }
        this.setState({
            task: updatedTask,
            error: null
        });
    };

    handleFileSelect = (e) => {
        let { file_names } = this.state;
        let i = 0;
        for (i in e.target.files) {
            file_names[i] = e.target.files[i];
        }

        this.setState({
            files: e.target.files,
            file_names: file_names
        });
    };

    handleDateChange = value => {
        const { task } = this.state;
        const updatedTask = {
            ...task,
            task_date: getISOFormattedDate(value, "Date")
        };
        this.setState({
            task: updatedTask
        });
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    };

    handleSaveFiles = (task_id) => {
        const { files } = this.state;
        const { activeCompanyId } = this.props;
        let i = 0;
        for (i = 0; i < files.length; i++) {
            let form_data = new FormData();
            form_data.append('file_name', files[i], files[i].name);
            form_data.append('task', task_id);
            form_data.append('company', activeCompanyId);

            authFileAxios
                .post(companyTaskFileUploadURL(activeCompanyId, task_id), form_data)
                .then(res => {
                    if (parseInt(files.length) === parseInt(i)) {
                        this.setState({
                            sendingOK: true
                        });
                    }
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        sendingOK: false,
                        error: err
                    });
                    return;
                });
        }

    };

    handleDownloadFile = (file) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(companyTaskFileDownloadURL(activeCompanyId, file.id), { responseType: 'blob' })
            .then(res => {
                //Create a Blob from the data stream
                const res_file = new Blob(
                    [res.data]);
                //Build a URL from the file
                const fileURL = URL.createObjectURL(res_file);
                // Get the file name from the URL
                const filename = file.file_name.substring(file.file_name.lastIndexOf("/") + 1);
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();

                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleDeleteFile = id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(companyTaskFileDeleteURL(activeCompanyId, id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleDetails();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });

    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            task,
            files,
            create
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (task.name === "") {
            this.setState({
                error: t("Please fill in Name"),
                loading: false
            });
        } else if (task.operator === "") {
            this.setState({
                error: t("Please fill in Operator"),
                loading: false
            });
        } else if (task.operator.length > 50) {
            this.setState({
                error: t("Max amount of characters for Operator is 50"),
                loading: false
            });
        } else {
            if (create) {
                const updatedTask = {
                    ...task,
                    task_type: "COMPANY"
                }
                authAxios
                    .post(companyTaskCreateURL(activeCompanyId), {
                        ...updatedTask
                    })
                    .then(res => {
                        const task_id = res.data.task_id;
                        if (files.length > 0) {
                            this.handleSaveFiles(task_id);
                        } else {
                            this.setState({
                                sendingOK: true
                            });
                        }
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            error: err
                        });
                    });

            } else {
                const { task_id } = this.props.match.params;
                authAxios
                    .put(companyTaskUpdateURL(activeCompanyId, task_id), {
                        ...task
                    })
                    .then(res => {
                        if (files.length > 0) {
                            this.handleSaveFiles(task_id);
                        } else {
                            this.setState({
                                sendingOK: true
                            });
                        }
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            error: err
                        });
                    });
            }
        }
    };

    render() {
        const {
            task,
            file_names,
            activeIndex,
            error,
            loading,
            create,
            delete_confirm_open,
            delete_confirm_text,
            delete_item
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (this.state.sendingOK) {
            this.props.history.push(`/company/tasks/`);
        }
        // console.log(this.state);
        return (
            <Shell>
                <Header as="h4">{t("Company task")}</Header>
                <Form onSubmit={this.handleSubmit} error={error !== null}>
                    <Form.Field >
                        <label>{t("Location")}</label>
                        <Input
                            value={task.location}
                            name="location"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field >
                        <label>{t("Category")}</label>
                        <Input
                            value={task.category}
                            name="category"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Target")}</label>
                        <Input
                            value={task.target}
                            name="target"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Task name")}</label>
                        <Input
                            required
                            value={task.name}
                            name="name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field
                        label={t("Description")}
                        control={TextArea}
                        rows={5}
                        value={task.description}
                        name="description"
                        onChange={this.handleChange} />
                    <Form.Field required>
                        <label>{t("Operator")}</label>
                        <Input
                            required
                            value={task.operator}
                            name="operator"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Task date")}</label>
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={task.task_date !== null ? (new Date(task.task_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleDateChange(selected)}
                            format='DD.MM.YYYY' />
                    </Form.Field>
                    {(task.company && task.task_files.length > 0) && (
                        <React.Fragment>
                            <b>{t("Task files")}</b> <br />
                        </React.Fragment>
                    )}
                    {(task.company && task.task_files.length > 0) && (
                        task.task_files.map((file, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <Popup content={t("Download file")} trigger={
                                        <Link onClick={() => this.handleDownloadFile(file)}>
                                            <Icon name='file outline' />
                                            <b>{file.file_name.substring(file.file_name.lastIndexOf("/") + 1)} </b>
                                        </Link>} />
                                    <Popup content={t("Delete file")} trigger={
                                        <Link onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { name: file.file_name.substring(file.file_name.lastIndexOf("/") + 1) }), file.id)}>
                                            <Icon color='red' name='delete' /> <br />
                                        </Link>} />
                                </React.Fragment>
                            );
                        })
                    )}
                    <br />
                    <Accordion>
                        <Accordion.Title
                            active={activeIndex === 1}
                            index={1}
                            onClick={this.handleClick}>
                            <Icon name='plus' />
                            <b>{t("Add new files")}</b> <Icon name='attach' />
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 1}>
                            <Form.Field>
                                <Input icon='attach' multiple type="file" id="file_name" onChange={this.handleFileSelect} />
                            </Form.Field>
                            {file_names.length > 0 && (
                                file_names.map((file, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <Icon name='file outline' />
                                            <b>{file.name} </b> <br />
                                        </React.Fragment>
                                    );
                                })
                            )}
                        </Accordion.Content>
                    </Accordion>
                    <br />

                    {(!create && task.company) && (
                        <React.Fragment>
                            <br />
                            {t("Task created")} {new Date(task.date_created).toLocaleString('fi-FI')}<br />
                            {t("Last modified")} {new Date(task.last_modified).toLocaleString('fi-FI')} <br />
                            {t("Author")} {task.author.first_name} {task.author.last_name} <br /> <br />
                        </React.Fragment>
                    )}
                    {error && <Message error header={t("Error")} content={error} />}
                    <Button primary type="submit" loading={loading} disabled={loading}>
                        {create ? (t("Create")) : (t("Save"))}
                    </Button>
                </Form>
                <Confirm
                    content={delete_confirm_text}
                    open={delete_confirm_open}
                    onCancel={() => this.closeDeleteConfirm()}
                    onConfirm={() => this.handleDeleteFile(delete_item)}
                />
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companytaskdetails')(CompanyTaskDetails))
);