// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 19.3.2020 TS Initial version
// 28.3.2020 TS Added Alarm status to sensor if any of the variables has High Alarm or Low Alarm.
// 15.6.2020 TS Changed date created to device address in sensors list. text align to "center" for alarm status ok.
// 27.6.2020 TS Added link alarms list if alarm activated
// 06.02.2021 TS Measure interval * 2 in check if online
// 18.02.2021 TS Changes to table
// 7.3.2021 TS Added sensor group display value
// 4.8.2021 TS Increased sensor "Offline" limit to 3 x measure interval
// 14.7.2022 TS Added admin info to status
// 18.8.2023 TS Added Cellular to network status Popup. Status now available to all.
// 7.1.2024 1.0.8 TS Polygon texts and titles

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Menu, Confirm, Input, Icon, Popup, Responsive, Grid} from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, getWidth, exportToCsv  } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { fetchSensorGroupsURL, deleteSensorDetailsURL, sensorGroupAlarmNotificationChangeURL } from "../../constants";
  

class Sensors extends React.Component {
    state = {
        sensor_groups: null,
        activeMenuItem: this.props.t("Active"),
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        active_sensor: null,
        error: null,
        loading: false,
        success_mgs: null,
        activeIndex: 0,
        activeIndex2: 0,
        search: ""
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchGroupList();

    }

    handleFetchGroupList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchSensorGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    sensor_groups: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleDeleteSensor = sensor_id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(deleteSensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchGroupList();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    sensorDetails = id => {
        this.props.history.push(`/sensor/status-variables/${id}/`)
    };

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name });

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    openSensorDetails = sensor => {
        this.sensorDetails(sensor.id);
    };

    openSensorAlarm = sensor => {
        this.props.history.push(`/sensor/alarms/${sensor.id}/`)
    };

    handleGetAlarmsStatus = sensor => {
        const { t } = this.props;
        if (sensor.last_status) {
            let i = 0;
            for (i in sensor.sensorstatusvariables) {
                if (sensor.sensorstatusvariables[i].status.includes("Alarm") && sensor.sensorstatusvariables[i].status !== "Alarm on" && sensor.sensorstatusvariables[i].status !== "Alarm off") {
                    return <Table.Cell error  onClick={() => this.openSensorAlarm(sensor)}><Header as="h5" color="red"><Popup content={t(sensor.sensorstatusvariables[i].status)} trigger={<Icon name="attention" />} /></Header></Table.Cell>
                }
            }
            const sensor_last_status_sec = new Date(sensor.last_status).getTime() / 1000;
            const current_time_sec = new Date().getTime() / 1000;
            if ((current_time_sec - sensor_last_status_sec) > sensor.measure_interval * 3) {
                return <Table.Cell textAlign="center"  onClick={() => this.openSensorDetails(sensor)}><Header as="h5" color="red"><Popup content={t("Offline")} trigger={<Icon name="attention" />} /> </Header></Table.Cell>
            } else {
                return <Table.Cell  textAlign="center"  onClick={() => this.openSensorDetails(sensor)}><Header as="h5" color="green"><Popup content={t("No alarms")} trigger={<Icon name="check circle outline" />} /></Header></Table.Cell>
            }
        } else {
            return <Table.Cell  textAlign="center"  onClick={() => this.openSensorDetails(sensor)}><Header as="h5" color="red"><Popup content={t("Offline")} trigger={<Icon name="attention" />} /></Header></Table.Cell>
        }
    };

    handleGetStatus = sensor => {
        const { t } = this.props;
        if (sensor.last_status) {
            const sensor_last_status_sec = new Date(sensor.last_status).getTime() / 1000;
            const current_time_sec = new Date().getTime() / 1000;
            if ((current_time_sec - sensor_last_status_sec) > sensor.measure_interval * 3) {
                if (this.props.activeRoles && this.props.activeRoles.includes("A"))
                    return <Table.Cell textAlign="center"  onClick={() => this.openSensorDetails(sensor)}><Header as="h5" color="red"><Popup content={t("Offline") + " " + sensor.last_rssi + " " + sensor.last_sf + " " + sensor.last_lrc} trigger={<Icon name="attention" />} /> </Header></Table.Cell>
                else
                    return <Table.Cell textAlign="center"  onClick={() => this.openSensorDetails(sensor)}><Header as="h5" color="red"><Popup content={t("Offline")} trigger={<Icon name="attention" />} /> </Header></Table.Cell>
            } else {
                // if (this.props.activeRoles && this.props.activeRoles.includes("A"))
                //     return <Table.Cell textAlign="center"  onClick={() => this.openSensorDetails(sensor)}><Header as="h5" color="green"><Popup content={t("Online") + " " + sensor.last_rssi + " " + sensor.last_sf + " " + sensor.last_lrc} trigger={<Icon name="check circle outline" />} /></Header></Table.Cell>
                // else
                //     return <Table.Cell textAlign="center"  onClick={() => this.openSensorDetails(sensor)}><Header as="h5" color="green"><Popup content={t("Online")} trigger={<Icon name="check circle outline" />} /></Header></Table.Cell>
                // LoRaWAN device
                if (sensor.device_type === "L")
                {
                    return <Table.Cell textAlign="center"  onClick={() => this.openSensorDetails(sensor)}><Header as="h5" color="green"><Popup content={t("Online") + " " + sensor.last_rssi + " " + sensor.last_sf + " " + sensor.last_lrc} trigger={<Icon name="check circle outline" />} /></Header></Table.Cell>
                }
                else
                {
                    return <Table.Cell textAlign="center"  onClick={() => this.openSensorDetails(sensor)}><Header as="h5" color="green"><Popup content={t("Online") + " " + sensor.network_mode + " " + sensor.rsrp + " " + sensor.mccmnc + " " + sensor.cell_id} trigger={<Icon name="check circle outline" />} /></Header></Table.Cell>
                }
            }
        } else {
            return <Table.Cell textAlign="center"  onClick={() => this.openSensorDetails(sensor)}><Header as="h5" color="red"><Popup content={t("Offline")} trigger={<Icon name="attention" />} /></Header></Table.Cell>
        }
    }

    handleGetFilterList = sensors => {
        const { search } = this.state;
        let filtered_list = [];
        filtered_list = sensors.filter((sensor) => {
            return sensor.local_machine.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                sensor.local_name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                sensor.model_name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                sensor.device_address.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                sensor.local_unit.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                sensor.local_line.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
                
        })
        return filtered_list;
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleGetFilterListToExport = sensor_groups => {
        let i = 0;
        let x = 0;
        let sensors = [];
        for (i in sensor_groups) {
            for (x in sensor_groups[i].sensors) {
                sensors.push(sensor_groups[i].sensors[x]);
            }
        }
        return this.handleGetFilterList(sensors);
    };

    handleGroupNotificationChange = sensor_group_id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .post(sensorGroupAlarmNotificationChangeURL(activeCompanyId, sensor_group_id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchGroupList();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    }

    handleLastStatusDisplay = (sensor, row) => {
        //const { t } = this.props;
        let disp_value = "";
        let title = "";
        let i = 0;
        for (i in sensor.sensorstatusvariables) {
            if (sensor.sensorstatusvariables[i].is_active && sensor.sensorstatusvariables[i].is_display_value)
            {
                if (row === 1)
                {
                    if (sensor.sensorstatusvariables[i].name === "Corrected temperature TH probe" ||
                        sensor.sensorstatusvariables[i].name === "Temperature TH Probe" || 
                        sensor.sensorstatusvariables[i].name === "Corrected humidity TH probe" ||
                        sensor.sensorstatusvariables[i].name === "Humidity TH Probe")
                    {
                        if (disp_value === "")
                        {
                            disp_value = sensor.sensorstatusvariables[i].last_value + sensor.sensorstatusvariables[i].measurement_unit;
                        }
                        else
                        {
                            disp_value = disp_value + " " + sensor.sensorstatusvariables[i].last_value + sensor.sensorstatusvariables[i].measurement_unit;
                        }

                        if (title === "" && sensor.sensorstatusvariables[i].display_name.includes("concrete A"))
                        {
                            title = " - Betoni A";
                        }
                        else if (title === "" && sensor.sensorstatusvariables[i].display_name.includes("concrete B"))
                        {
                            title = " - Betoni B";
                        }
                        else if (title === "" && sensor.sensorstatusvariables[i].display_name.includes("air"))
                        {
                            title = " - Sisäilma";
                        }
                    }
                }
                else if (row === 2)
                {
                    if (sensor.sensorstatusvariables[i].name === "Corrected temperature TH probe 2" ||
                        sensor.sensorstatusvariables[i].name === "Temperature TH Probe 2" || 
                        sensor.sensorstatusvariables[i].name === "Corrected humidity TH probe 2" ||
                        sensor.sensorstatusvariables[i].name === "Humidity TH Probe 2")
                    {
                        if (disp_value === "")
                        {
                            disp_value = sensor.sensorstatusvariables[i].last_value + sensor.sensorstatusvariables[i].measurement_unit;
                        }
                        else
                        {
                            disp_value = disp_value + " " + sensor.sensorstatusvariables[i].last_value + sensor.sensorstatusvariables[i].measurement_unit;
                        }

                        if (title === "" && sensor.sensorstatusvariables[i].display_name.includes("concrete A"))
                        {
                            title = " - Betoni A";
                        }
                        else if (title === "" && sensor.sensorstatusvariables[i].display_name.includes("concrete B"))
                        {
                            title = " - Betoni B";
                        }
                        else if (title === "" && sensor.sensorstatusvariables[i].display_name.includes("air"))
                        {
                            title = " - Sisäilma";
                        }
                    }
                }
                else if (row === 3)
                {
                    if (sensor.sensorstatusvariables[i].name === "Corrected temperature TH probe 3" ||
                        sensor.sensorstatusvariables[i].name === "Temperature TH Probe 3" || 
                        sensor.sensorstatusvariables[i].name === "Corrected humidity TH probe 3" ||
                        sensor.sensorstatusvariables[i].name === "Humidity TH Probe 3")
                    {
                        if (disp_value === "")
                        {
                            disp_value = sensor.sensorstatusvariables[i].last_value + sensor.sensorstatusvariables[i].measurement_unit;
                        }
                        else
                        {
                            disp_value = disp_value + " " + sensor.sensorstatusvariables[i].last_value + sensor.sensorstatusvariables[i].measurement_unit;
                        }
                        if (title === "" && sensor.sensorstatusvariables[i].display_name.includes("concrete A"))
                        {
                            title = " - Betoni A";
                        }
                        else if (title === "" && sensor.sensorstatusvariables[i].display_name.includes("concrete B"))
                        {
                            title = " - Betoni B";
                        }
                        else if (title === "" && sensor.sensorstatusvariables[i].display_name.includes("air"))
                        {
                            title = " - Sisäilma";
                        }
                    }
                }
            }
        }
        if (disp_value !== "")
        {
            if (row === 1)
            {
                if (title !== "")
                {
                    disp_value = disp_value + title;
                }
                else
                {
                    disp_value = disp_value + " - Sisäilma";
                }
            }
            else if (row === 2)
            {
                if (title !== "")
                {
                    disp_value = disp_value + title;
                }
                else
                {
                    disp_value = disp_value + " - Pinta";
                }
            }
            else if (row === 3)
            {
                if (title !== "")
                {
                    disp_value = disp_value + title;
                }
                else
                {
                    disp_value = disp_value + " - Arviointi";
                }
            }
        }
        else
        {
            disp_value = sensor.last_display_value;
        }
        return disp_value;
    }

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Local unit"),
                t("Local line"),
                t("Local machine"),
                t("Local name"),
                t("Model name"),
                t("Device name"),
                t("UUID"),
                t("Device description"),
                t("Device address"),
                t("Device EUI"),
                t("Last status"),
                t("Measure Interval"),
                t("Date created"),
                t("Last modified"),
                t("Author")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    local_unit: items[i].local_unit,
                    local_line: items[i].local_line,
                    local_machine: items[i].local_machine,
                    local_name: items[i].local_name,
                    model_name: items[i].model_name,
                    device_name: items[i].device_name,
                    uuid: items[i].uuid,
                    device_description: items[i].device_description,
                    device_address: items[i].device_address,
                    device_eui: items[i].device_eui,
                    last_status: items[i].last_status,
                    measure_interval: items[i].measure_interval,
                    date_created: new Date(items[i].date_created).toLocaleString('fi-FI'),
                    settings_last_modified_time: items[i].settings_last_modified_time ? new Date(items[i].settings_last_modified_time).toLocaleString('fi-FI') : "",
                    settings_last_modified_author: items[i].settings_last_modified_author.username
                    // last_modified: new Date(items[i].last_modified).toLocaleString('fi-FI'),
                    // author: items[i].author.username
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    checkIfOnlyCustomerRole = (roles) => {
        // Only Client role activated to limit access
        if (roles && roles.length === 1 && roles.includes("C")) {
            return true;
        }
        return false;
    };

    render() {
        const { error, loading, sensor_groups, activeMenuItem, delete_confirm_open, delete_confirm_text, delete_item, search } = this.state;
        const { isAuthenticated, t, activeRoles } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        //console.log(this.state);
        const logo = localStorage.getItem("Logo");
        return (
            <Shell is_desktop={is_desktop}>
                {(logo && activeRoles) && (
                    <>
                        {(sensor_groups && is_desktop) && (
                            <Button.Group floated="right" basic size='small'>
                                <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(this.handleGetFilterListToExport(sensor_groups))} />
                            </Button.Group>
                        )}
                        {logo === "Polygon" ? (
                            <Header as="h3">{t("Kohteet/Anturit")}</Header>
                        ) : (
                            <Header as="h3">{t("Sensors")}</Header>
                        )}
                        
                        {(this.props.activeRoles && this.props.activeRoles.includes("A")) && (
                            <Link to="/sensor/sensor-details/create/">
                                <Button floated="right" color="green">
                                    {t("+ Sensor")}
                                </Button>
                            </Link>
                        )}
                        <Input
                            floated="right"
                            size="mini"
                            icon='search'
                            value={search}
                            name="search"
                            onChange={this.handleChange} />
                        {error && (
                            <Message
                                error
                                header={t("There was an error")}
                                content={JSON.stringify(error)}
                            />
                        )}
                        {loading && (
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>
                                <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                            </Segment>
                        )}

                        {sensor_groups && (
                            <React.Fragment>
                                <Menu attached="top" tabular>
                                    <Menu.Item
                                        name={t("Active")}
                                        active={activeMenuItem === t("Active")}
                                        onClick={this.handleMenuItemClick} />
                                    <Menu.Item
                                        name={t("Archive")}
                                        active={activeMenuItem === t("Archive")}
                                        onClick={this.handleMenuItemClick} />
                                </Menu>

                                {sensor_groups.map((sensor_group) => {
                                    return (
                                        <React.Fragment key={sensor_group.id}>
                                            <div>

                                                {sensor_group.sensors.length > 0 && (
                                                    <React.Fragment>
                                                        <br></br>
                                                        <Grid columns="2">
                                                            <Grid.Column>
                                                            {!this.checkIfOnlyCustomerRole(activeRoles) ? (
                                                                <Link to={`/sensor/sensor-group-details/${sensor_group.id}/`}>
                                                                    {(logo === "Polygon" && sensor_group.name === "Default group") ? (
                                                                        <Header as="h4" color="blue">
                                                                            {t("TMP VARASTO")}
                                                                        </Header>
                                                                    ) : (
                                                                        <Header as="h4" color="blue">
                                                                            {sensor_group.name} {sensor_group.display_value !== "" ? (`: ${sensor_group.display_value}`) : ""}
                                                                        </Header>
                                                                    )}
                                                                </Link>
                                                            ) : (
                                                                <>
                                                                    {(logo === "Polygon" && sensor_group.name === "Default group") ? (
                                                                        <Header as="h4" color="blue">
                                                                            {t("TMP VARASTO")}
                                                                        </Header>
                                                                    ) : (
                                                                        <Header as="h4" color="blue">
                                                                            {sensor_group.name} {sensor_group.display_value !== "" ? (`: ${sensor_group.display_value}`) : ""}
                                                                        </Header>
                                                                    )}
                                                                </>
                                                            )}
                                                            </Grid.Column>
                                                            {!this.checkIfOnlyCustomerRole(activeRoles) && (
                                                                <Grid.Column textAlign="right">
                                                                    {sensor_group.alarm_notification_status === "A" && (
                                                                        <Button positive content={t(sensor_group.alarm_notification_status_display)} onClick={() => this.handleGroupNotificationChange(sensor_group.id)}/>
                                                                    )}
                                                                    {sensor_group.alarm_notification_status === "E" && (
                                                                        <Button primary content={t(sensor_group.alarm_notification_status_display)} onClick={() => this.handleGroupNotificationChange(sensor_group.id)}/>
                                                                    )}
                                                                    {sensor_group.alarm_notification_status === "S" && (
                                                                        <Button color="orange" content={t(sensor_group.alarm_notification_status_display)} onClick={() => this.handleGroupNotificationChange(sensor_group.id)}/>
                                                                    )}
                                                                    {sensor_group.alarm_notification_status === "O" && (
                                                                        <Button negative content={t(sensor_group.alarm_notification_status_display)} onClick={() => this.handleGroupNotificationChange(sensor_group.id)}/>
                                                                    )}
                                                                </Grid.Column>
                                                            )}
                                                        </Grid>
                                                        <Table striped selectable celled >
                                                            {is_desktop && (
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width="1"/>
                                                                        {logo === "Polygon" ? (
                                                                            <>
                                                                                <Table.HeaderCell width="4">{t("Anturinimi / Sarjanro.")}</Table.HeaderCell>
                                                                                {/* <Table.HeaderCell collapsing>{t("Address")}</Table.HeaderCell> */}
                                                                                <Table.HeaderCell width="4">{t("Kohde / Sijainti")}</Table.HeaderCell>
                                                                                {/* <Table.HeaderCell >{t("Local name")}</Table.HeaderCell> */}
                                                                                <Table.HeaderCell width="5">{t("Viimeisin mittausarvo")}</Table.HeaderCell>
                                                                                <Table.HeaderCell width="1">{t("Status")}</Table.HeaderCell>
                                                                                <Table.HeaderCell width="1">{t("Alarms")}</Table.HeaderCell>
                                                                                {activeMenuItem === t("Archive") && (
                                                                                    <Table.HeaderCell />
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Table.HeaderCell width="4">{t("Model and address")}</Table.HeaderCell>
                                                                                {/* <Table.HeaderCell collapsing>{t("Address")}</Table.HeaderCell> */}
                                                                                <Table.HeaderCell width="5">{t("Local machine")}</Table.HeaderCell>
                                                                                {/* <Table.HeaderCell >{t("Local name")}</Table.HeaderCell> */}
                                                                                <Table.HeaderCell width="4">{t("Last status")}</Table.HeaderCell>
                                                                                <Table.HeaderCell width="1">{t("Status")}</Table.HeaderCell>
                                                                                <Table.HeaderCell width="1">{t("Alarms")}</Table.HeaderCell>
                                                                                {activeMenuItem === t("Archive") && (
                                                                                    <Table.HeaderCell />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        
                                                                    </Table.Row>
                                                                </Table.Header>
                                                            )}
                                                            <Table.Body>
                                                                {this.handleGetFilterList(sensor_group.sensors).map((sensor) => {
                                                                    if ((activeMenuItem === t("Active") && sensor.is_active) || (activeMenuItem === t("Archive") && !sensor.is_active)) {
                                                                        let _last_display_value = sensor.last_display_value;
                                                                        if (_last_display_value !== "")
                                                                        {
                                                                            _last_display_value = _last_display_value.replace("\\n", "\n");
                                                                            _last_display_value = _last_display_value.replace("\\n", "\n");
                                                                        }
                                                                        return (
                                                                            <Table.Row key={sensor.id}>
                                                                                <Table.Cell  onClick={() => this.openSensorDetails(sensor)}>
                                                                                <Image src={sensor.sensor_model.thumbnail_image_url} size="mini"/>
                                                                                </Table.Cell>
                                                                                
                                                                                <Table.Cell  onClick={() => this.openSensorDetails(sensor)}>
                                                                                    {!is_desktop && (<React.Fragment><b>{t("Model and address")}</b><br/></React.Fragment>)}
                                                                                    
                                                                                    <Header as="h5">
                                                                                        {sensor.device_name}
                                                                                        <Header sub>{sensor.device_eui}</Header>
                                                                                    </Header>
                                                                                </Table.Cell>
                                                                                {/* <Table.Cell  onClick={() => this.openSensorDetails(sensor)}>
                                                                                    {!is_desktop && (<React.Fragment><b>{t("Local machine")}</b><br/></React.Fragment>)}
                                                                                    {sensor.local_machine}
                                                                                </Table.Cell> */}
                                                                                <Table.Cell  onClick={() => this.openSensorDetails(sensor)}>
                                                                                    {!is_desktop && (<React.Fragment><b>{t("Local machine")}</b><br/></React.Fragment>)}
                                                                                    {sensor.local_unit} 
                                                                                    {sensor.local_unit ? (`/${sensor.local_line}`) : (sensor.local_line)} 
                                                                                    {sensor.local_line ? (`/${sensor.local_machine}`) : (sensor.local_machine)}
                                                                                    {sensor.local_machine ? (`/${sensor.local_name}`) : (sensor.local_name)} 
                                                                                    {sensor.device_location_address && (
                                                                                    <>
                                                                                        <br/>
                                                                                        <Header sub>
                                                                                            {sensor.device_location_address} {sensor.device_location_address && ","} {sensor.device_location_postal_code} {sensor.device_location_city}
                                                                                        </Header>
                                                                                    </>
                                                                                    )}
                                                                                </Table.Cell>
                                                                                {/* <Table.Cell  onClick={() => this.openSensorDetails(sensor)}>
                                                                                    {!is_desktop && (<React.Fragment><b>{t("Local name")}</b><br/></React.Fragment>)}
                                                                                    {sensor.local_name}
                                                                                </Table.Cell> */}
                                                                                {/* <Table.Cell collapsing onClick={() => this.openSensorDetails(sensor)}>
                                                                                    {!is_desktop && (<React.Fragment><b>{t("Address")}</b><br/></React.Fragment>)}
                                                                                    {sensor.device_address}
                                                                                </Table.Cell> */}
                                                                                <Table.Cell  onClick={() => this.openSensorDetails(sensor)}>
                                                                                    {!is_desktop && (<React.Fragment><b>{t("Last status")}</b><br/></React.Fragment>)}
                                                                                    {(logo === "Polygon" && sensor.sensor_model.model_name === "CAPROC-TH-PROBE-3") ? (
                                                                                        <>
                                                                                            <Header as="h5">{this.handleLastStatusDisplay(sensor,1)}<br/>{this.handleLastStatusDisplay(sensor,2)}<br/>{this.handleLastStatusDisplay(sensor,3)}</Header>
                                                                                            {/* <Header as="h5">{this.handleLastStatusDisplay(sensor,2)}</Header>
                                                                                            <Header as="h5">{this.handleLastStatusDisplay(sensor,3)}</Header> */}
                                                                                        </>
                                                                                    ) : (
                                                                                        <Header as="h5">{_last_display_value} <br/></Header>
                                                                                    )}
                                                                                    {sensor.last_status ? (new Date(sensor.last_status).toLocaleString('fi-FI')) : ("")}
                                                                                </Table.Cell>
                                                                                {this.handleGetStatus(sensor)}
                                                                                {this.handleGetAlarmsStatus(sensor)}
                                                                                {activeMenuItem === t("Archive") && (
                                                                                    <Table.Cell  textAlign='center'><Icon link color="red"  name='trash alternate outline'
                                                                                        onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { sensor_name: sensor.local_name }), sensor.id)} /></Table.Cell>
                                                                                )}
                                                                            </Table.Row>
                                                                        );
                                                                    }
                                                                })}
                                                            </Table.Body>
                                                        </Table>
                                                    </React.Fragment>
                                                )}

                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                                <Confirm
                                    content={delete_confirm_text}
                                    open={delete_confirm_open}
                                    onCancel={() => this.closeDeleteConfirm()}
                                    onConfirm={() => this.handleDeleteSensor(delete_item)}
                                />

                            </React.Fragment>
                        )}
                    </>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRoles: state.company.activeRoles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensors')(Sensors))
);