import PropTypes from "prop-types";
import React, { Component } from "react";
import {
    Button,
    Container,
    Dropdown,
    Icon,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Image,
    Flag,
    DropdownMenu
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/auth";
import { setActiveCompany } from "../../store/actions/company";
import { getWidth } from "../../utils";
import { withTranslation } from 'react-i18next';
import Logo from "../../assets/images/Caproc-logo-small-70.png";
import Logo_Lunni from  "../../assets/images/Lunni-logo.png";
import Logo_propipe from "../../assets/images/pipepro.png";
import Logo_green_up from "../../assets/images/greenup.png";
import Logo_polygon from "../../assets/images/polygon.png";

class MobileContainer extends Component {
    state = {
        company_name: null
    };

    handleSidebarHide = () => this.setState({ sidebarOpened: false });

    handleToggle = () => this.setState({ sidebarOpened: true });

    setActiveCompanyId = (company_id, company_name, roles) => {
        this.props.setCompany(company_id, company_name, roles);
        this.setState({ company_name });
        this.props.history.push("/")
    }

    handleLogout = () => {
        this.setState({ company_name: null });
        this.props.logout();
    }

    checkIfOnlyCustomerRole = (roles) => {
        // Only Client role activated to limit access
        if (roles && roles.length === 1 && roles.includes("C")) {
            return true;
        }
        return false;
    };

    render() {
        const { children, isAuthenticated, userCompanies, activeCompanyId, t, i18n, activeRoles } = this.props;
        const { sidebarOpened, fixed, company_name } = this.state;
        const logo = localStorage.getItem("Logo");
        return (
            <Responsive
                as={Sidebar.Pushable}
                getWidth={getWidth}
                maxWidth={Responsive.onlyMobile.maxWidth}
            >
                <Sidebar
                    as={Menu}
                    animation="push"
                    inverted
                    onHide={this.handleSidebarHide}
                    vertical
                    visible={sidebarOpened}
                    onClick={this.handleSidebarHide}
                >
                    {/* <Menu.Item
                        active={this.props.location.pathname === "/"}
                        onClick={() => this.props.history.push("/")}
                    >
                        {t('Home')}
                    </Menu.Item> */}
                    {(isAuthenticated && logo && activeRoles) && (
                        <>
                            {(logo === "Lunni-logo.png" || logo === "Lunni" || logo === "lunni") && (
                                <Menu.Item
                                    active={this.props.location.pathname === "/"}
                                    onClick={() => this.props.history.push("/")}
                                    content={<Image src={Logo_Lunni} size='small' verticalAlign="bottom" />}
                                    fitted="vertically"
                                    position="left"/>
                            )}
                            {(logo === "pipepro.png" || logo === "Pipepro" || logo === "PipePro" || logo === "pipepro") && (
                                <Menu.Item
                                    active={this.props.location.pathname === "/"}
                                    onClick={() => this.props.history.push("/")}
                                    content={<Image src={Logo_propipe} size='small' verticalAlign="bottom" />}
                                    fitted="vertically"
                                    position="left"/>
                            )}
                            {(logo === "greenup.png" || logo === "GreenUp" || logo === "greenup") && (
                                <Menu.Item
                                    active={this.props.location.pathname === "/"}
                                    onClick={() => this.props.history.push("/")}
                                    content={<Image src={Logo_green_up} size='small' verticalAlign="bottom" />}
                                    fitted="vertically"
                                    position="left"/>
                            )}
                            {(logo === "Caproc" || logo === "caproc" || logo === "") && (
                                <Menu.Item
                                    active={this.props.location.pathname === "/"}
                                    onClick={() => this.props.history.push("/")}
                                    content={<Image src={Logo} size='small' verticalAlign="bottom" />}
                                    fitted="vertically"
                                    position="left"/>
                            )}
                            {(logo === "Polygon" || logo === "polygon") && (
                                <Menu.Item
                                    active={this.props.location.pathname === "/"}
                                    onClick={() => this.props.history.push("/")}
                                    content={<Image src={Logo_polygon} size='small' verticalAlign="bottom" />}
                                    fitted="vertically"
                                    position="left"/>
                            )}
                        </>
                    )}

                    {(userCompanies && activeCompanyId) && (
                        <React.Fragment>
                            <Menu.Item
                                active={this.props.location.pathname === "/sensors/"}
                                onClick={() => this.props.history.push("/sensors/")}
                            >
                                {t('Sensors')}
                            </Menu.Item>
                        </React.Fragment>
                    )}
                    <Menu.Item position="left">
                        {isAuthenticated ? (
                            <React.Fragment>
                                {(userCompanies !== null && userCompanies.length > 0) &&
                                    <Dropdown item direction="left" text={company_name ? (company_name) : (localStorage.getItem("activeCompanyName"))} >
                                        <Dropdown.Menu onClick={this.handleSidebarHide}>
                                            {userCompanies.map((item, key) =>
                                                <Dropdown.Item
                                                    key={key}
                                                    text={item.name}
                                                    onClick={() =>
                                                        this.setActiveCompanyId(item.id, item.name, item.roles)
                                                    }
                                                />
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                <Dropdown item direction="left" text={localStorage.getItem("userFullName") !== "" ? (localStorage.getItem("userFullName")) : (localStorage.getItem("userName"))}>
                                    <Dropdown.Menu onClick={this.handleSidebarHide}>
                                        <Dropdown.Item
                                            text={t('Account')}
                                            onClick={() =>
                                                this.props.history.push("/account/account-details")
                                            }
                                        />
                                        {(userCompanies && !this.checkIfOnlyCustomerRole(activeRoles)) && (
                                            <Dropdown.Item
                                                text={t('Company')}
                                                onClick={() =>
                                                    this.props.history.push("/company/company-details")
                                                }
                                            />
                                        )}
                                        <Dropdown.Item
                                            text={t('Logout')}
                                            onClick={() =>
                                                this.handleLogout()
                                            }
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown item button direction="left"  trigger={<Flag name={i18n.language === "en" ? "gb" : i18n.language} />} >
                                    <DropdownMenu onClick={this.handleSidebarHide}>
                                        <Dropdown.Item flag="fi" onClick={() => i18n.changeLanguage('fi')} />
                                        <Dropdown.Item flag="gb" onClick={() => i18n.changeLanguage('en')} />
                                    </DropdownMenu>
                                </Dropdown>

                            </React.Fragment>
                        ) : (
                                <React.Fragment>
                                    <Button
                                        inverted={!fixed}
                                        onClick={() => this.props.history.push("/login")}
                                    >
                                        {t('Login')}
                                    </Button>
                                    <Dropdown item direction="left" trigger={<Flag name={i18n.language === "en" ? "gb" : i18n.language} />} >
                                        <DropdownMenu onClick={this.handleSidebarHide}>
                                            <Dropdown.Item flag="fi" onClick={() => i18n.changeLanguage('fi')} />
                                            <Dropdown.Item flag="gb" onClick={() => i18n.changeLanguage('en')} />
                                        </DropdownMenu>
                                    </Dropdown>

                                </React.Fragment>
                            )}
                    </Menu.Item>
                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarOpened}>
                    <Segment
                        inverted
                        textAlign="center"
                        style={{ padding: "1em 0em" }}
                        vertical
                    >
                        <Container>
                            <Menu inverted pointing secondary size="large">
                                <Menu.Item onClick={this.handleToggle}>
                                    <Icon name="sidebar" />
                                </Menu.Item>
                                {(isAuthenticated && logo) && (
                                    <>
                                        {(logo === "Lunni-logo.png" || logo === "Lunni" || logo === "lunni") && (
                                            <Menu.Item
                                                active={this.props.location.pathname === "/"}
                                                onClick={() => this.props.history.push("/")}
                                                content={<Image src={Logo_Lunni} size='small' verticalAlign="bottom" />}
                                                fitted="vertically"
                                                position="left"/>
                                        )}
                                        {(logo === "pipepro.png" || logo === "Pipepro" || logo === "PipePro" || logo === "pipepro") && (
                                            <Menu.Item
                                                active={this.props.location.pathname === "/"}
                                                onClick={() => this.props.history.push("/")}
                                                content={<Image src={Logo_propipe} size='small' verticalAlign="bottom" />}
                                                fitted="vertically"
                                                position="left"/>
                                        )}
                                        {(logo === "greenup.png" || logo === "GreenUp" || logo === "greenup") && (
                                            <Menu.Item
                                                active={this.props.location.pathname === "/"}
                                                onClick={() => this.props.history.push("/")}
                                                content={<Image src={Logo_green_up} size='small' verticalAlign="bottom" />}
                                                fitted="vertically"
                                                position="left"/>
                                        )}
                                        {(logo === "Caproc" || logo === "caproc" || logo === "") && (
                                            <Menu.Item
                                                active={this.props.location.pathname === "/"}
                                                onClick={() => this.props.history.push("/")}
                                                content={<Image src={Logo} size='small' verticalAlign="bottom" />}
                                                fitted="vertically"
                                                position="left"/>
                                        )}
                                        {(logo === "Polygon" || logo === "polygon") && (
                                            <Menu.Item
                                                active={this.props.location.pathname === "/"}
                                                onClick={() => this.props.history.push("/")}
                                                content={<Image src={Logo_polygon} size='small' verticalAlign="bottom" />}
                                                fitted="vertically"
                                                position="left"/>
                                        )}
                                    </>
                                )}
                                <Menu.Item position="right">
                                    {isAuthenticated ? (
                                        <React.Fragment>
                                            {/* {userCompanies &&
                                                <Dropdown item text={company_name ? (company_name) : (localStorage.getItem("activeCompanyName"))}>
                                                    <Dropdown.Menu>
                                                        {userCompanies.map((item, key) =>
                                                            <Dropdown.Item
                                                                key={key}
                                                                text={item.name}
                                                                onClick={() =>
                                                                    this.setActiveCompanyId(item.id, item.name)
                                                                }
                                                            />
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            } */}
                                            <Dropdown item icon="user outline" direction="left">
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        // text={t('Account')}
                                                        text={localStorage.getItem("userFullName") !== "" ? (localStorage.getItem("userFullName")) : (localStorage.getItem("userName"))}
                                                        onClick={() =>
                                                            this.props.history.push("/account/account-details")
                                                        }
                                                    />
                                                    {userCompanies &&
                                                        <Dropdown.Item
                                                            text={t('Company')}
                                                            onClick={() =>
                                                                this.props.history.push("/company/company-details")
                                                            }
                                                        />
                                                    }
                                                    <Dropdown.Item
                                                        text={t('Logout')}
                                                        onClick={() =>
                                                            this.handleLogout()
                                                        }
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </React.Fragment>
                                    ) : (
                                            <React.Fragment>
                                                <Button
                                                    type="button"
                                                    inverted
                                                    onClick={() => this.props.history.push("/login")}
                                                    icon="sign-in"
                                                    size="large"
                                                />
                                            </React.Fragment>
                                        )}
                                </Menu.Item>
                            </Menu>
                        </Container>
                    </Segment>

                    {children}
                </Sidebar.Pusher>
            </Responsive>
        );
    }
}

MobileContainer.propTypes = {
    children: PropTypes.node
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        activeCompanyId: state.company.activeCompanyId,
        userCompanies: state.company.userCompanies,
        activeRoles: state.company.activeRoles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout()),
        setCompany: (id, name, roles) => dispatch(setActiveCompany(id, name, roles))
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('desktopcontainer')(MobileContainer))
);