import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import landingpage_fi from "./translations/fi/landingpage.json";
import landingpage_en from "./translations/en/landingpage.json";
import desktopcontainer_fi from "./translations/fi/Layout/desktopcontainer.json";
import desktopcontainer_en from "./translations/en/Layout/desktopcontainer.json";
import accountdetails_fi from "./translations/fi/Account/accountdetails.json";
import accountdetails_en from "./translations/en/Account/accountdetails.json";
import changepassword_fi from "./translations/fi/Account/changepassword.json";
import changepassword_en from "./translations/en/Account/changepassword.json";
import accountshell_fi from "./translations/fi/Account/shell.json";
import accountshell_en from "./translations/en/Account/shell.json";
import companydetails_fi from "./translations/fi/Company/companydetails.json";
import companydetails_en from "./translations/en/Company/companydetails.json";
import companyinvoicingdetails_fi from "./translations/fi/Company/companyinvoicingdetails.json";
import companyinvoicingdetails_en from "./translations/en/Company/companyinvoicingdetails.json";
import companyusers_fi from "./translations/fi/Company/companyusers.json";
import companyusers_en from "./translations/en/Company/companyusers.json";
import companyuserdetails_fi from "./translations/fi/Company/companyuserdetails.json";
import companyuserdetails_en from "./translations/en/Company/companyuserdetails.json";
import companyfiles_fi from "./translations/fi/Company/companyfiles.json";
import companyfiles_en from "./translations/en/Company/companyfiles.json";
import companyfiledetails_fi from "./translations/fi/Company/companyfiledetails.json";
import companyfiledetails_en from "./translations/en/Company/companyfiledetails.json";
import companycontacts_fi from "./translations/fi/Company/companycontacts.json";
import companycontacts_en from "./translations/en/Company/companycontacts.json";
import companycontactdetails_fi from "./translations/fi/Company/companycontactdetails.json";
import companycontactdetails_en from "./translations/en/Company/companycontactdetails.json";
import companytasks_fi from "./translations/fi/Company/companytasks.json";
import companytasks_en from "./translations/en/Company/companytasks.json";
import companytaskdetails_fi from "./translations/fi/Company/companytaskdetails.json";
import companytaskdetails_en from "./translations/en/Company/companytaskdetails.json";
import productdetails_fi from "./translations/fi/Company/productdetails.json";
import productdetails_en from "./translations/en/Company/productdetails.json";
import productgroupdetails_fi from "./translations/fi/Company/productgroupdetails.json";
import productgroupdetails_en from "./translations/en/Company/productgroupdetails.json";
import productgroups_fi from "./translations/fi/Company/productgroups.json";
import productgroups_en from "./translations/en/Company/productgroups.json";
import products_fi from "./translations/fi/Company/products.json";
import products_en from "./translations/en/Company/products.json";
import companyshell_fi from "./translations/fi/Company/shell.json";
import companyshell_en from "./translations/en/Company/shell.json";
import customeraddresses_fi from "./translations/fi/Customer/customeraddresses.json";
import customeraddresses_en from "./translations/en/Customer/customeraddresses.json";
import customercontactdetails_fi from "./translations/fi/Customer/customercontactdetails.json";
import customercontactdetails_en from "./translations/en/Customer/customercontactdetails.json";
import customercontacts_fi from "./translations/fi/Customer/customercontacts.json";
import customercontacts_en from "./translations/en/Customer/customercontacts.json";
import customerdatacollections_fi from "./translations/fi/Customer/customerdatacollections.json";
import customerdatacollections_en from "./translations/en/Customer/customerdatacollections.json";
import customerdatacollectiondetails_fi from "./translations/fi/Customer/customerdatacollectiondetails.json";
import customerdatacollectiondetails_en from "./translations/en/Customer/customerdatacollectiondetails.json";
import customerdatacollectionitems_fi from "./translations/fi/Customer/customerdatacollectionitems.json";
import customerdatacollectionitems_en from "./translations/en/Customer/customerdatacollectionitems.json";
import customerdatacollectionadditem_fi from "./translations/fi/Customer/customerdatacollectionadditem.json";
import customerdatacollectionadditem_en from "./translations/en/Customer/customerdatacollectionadditem.json";
import customerdetails_fi from "./translations/fi/Customer/customerdetails.json";
import customerdetails_en from "./translations/en/Customer/customerdetails.json";
import customergroupdetails_fi from "./translations/fi/Customer/customergroupdetails.json";
import customergroupdetails_en from "./translations/en/Customer/customergroupdetails.json";
import customergroups_fi from "./translations/fi/Customer/customergroups.json";
import customergroups_en from "./translations/en/Customer/customergroups.json";
import customerinvoicingdetails_fi from "./translations/fi/Customer/customerinvoicingdetails.json";
import customerinvoicingdetails_en from "./translations/en/Customer/customerinvoicingdetails.json";
import customers_fi from "./translations/fi/Customer/customers.json";
import customers_en from "./translations/en/Customer/customers.json";
import customerfiles_fi from "./translations/fi/Customer/customerfiles.json";
import customerfiles_en from "./translations/en/Customer/customerfiles.json";
import customerfiledetails_fi from "./translations/fi/Customer/customerfiledetails.json";
import customerfiledetails_en from "./translations/en/Customer/customerfiledetails.json";
import customertasks_fi from "./translations/fi/Customer/customertasks.json";
import customertasks_en from "./translations/en/Customer/customertasks.json";
import customertaskdetails_fi from "./translations/fi/Customer/customertaskdetails.json";
import customertaskdetails_en from "./translations/en/Customer/customertaskdetails.json";
import customershell_fi from "./translations/fi/Customer/shell.json";
import customershell_en from "./translations/en/Customer/shell.json";
import invoicedetails_fi from "./translations/fi/Invoice/invoicedetails.json";
import invoicedetails_en from "./translations/en/Invoice/invoicedetails.json";
import invoices_fi from "./translations/fi/Invoice/invoices.json";
import invoices_en from "./translations/en/Invoice/invoices.json";
import invoiceshell_fi from "./translations/fi/Invoice/shell.json";
import invoiceshell_en from "./translations/en/Invoice/shell.json";
import login_fi from "./translations/fi/login.json";
import login_en from "./translations/en/login.json";
import passwordreset_fi from "./translations/fi/passwordreset.json";
import passwordreset_en from "./translations/en/passwordreset.json";
import passwordresetemail_fi from "./translations/fi/passwordresetemail.json";
import passwordresetemail_en from "./translations/en/passwordresetemail.json";
import contracts_fi from "./translations/fi/Contract/contracts.json";
import contracts_en from "./translations/en/Contract/contracts.json";
import contractdetails_fi from "./translations/fi/Contract/contractdetails.json";
import contractdetails_en from "./translations/en/Contract/contractdetails.json";
import contractgroups_fi from "./translations/fi/Contract/contractgroups.json";
import contractgroups_en from "./translations/en/Contract/contractgroups.json";
import contractgroupdetails_fi from "./translations/fi/Contract/contractgroupdetails.json";
import contractgroupdetails_en from "./translations/en/Contract/contractgroupdetails.json";
import contractshell_fi from "./translations/fi/Contract/contractshell.json";
import contractshell_en from "./translations/en/Contract/contractshell.json";
import messagecreate_fi from "./translations/fi/Message/messagecreate.json";
import messagecreate_en from "./translations/en/Message/messagecreate.json";
import messages_fi from "./translations/fi/Message/messages.json";
import messages_en from "./translations/en/Message/messages.json";
import reportinvoices_fi from "./translations/fi/Report/reportinvoices.json";
import reportinvoices_en from "./translations/en/Report/reportinvoices.json";
import reportinvoicescustomers_fi from "./translations/fi/Report/reportinvoicescustomers.json";
import reportinvoicescustomers_en from "./translations/en/Report/reportinvoicescustomers.json";
import reportinvoicesproducts_fi from "./translations/fi/Report/reportinvoicesproducts.json";
import reportinvoicesproducts_en from "./translations/en/Report/reportinvoicesproducts.json";
import reportshell_fi from "./translations/fi/Report/reportshell.json";
import reportshell_en from "./translations/en/Report/reportshell.json";

import sensordetails_fi from "./translations/fi/Sensor/sensordetails.json";
import sensordetails_en from "./translations/en/Sensor/sensordetails.json";
import sensorgroupdetails_fi from "./translations/fi/Sensor/sensorgroupdetails.json";
import sensorgroupdetails_en from "./translations/en/Sensor/sensorgroupdetails.json";
import sensorgroups_fi from "./translations/fi/Sensor/sensorgroups.json";
import sensorgroups_en from "./translations/en/Sensor/sensorgroups.json";
import sensorreports_fi from "./translations/fi/Sensor/sensorreports.json";
import sensorreports_en from "./translations/en/Sensor/sensorreports.json";
import sensors_fi from "./translations/fi/Sensor/sensors.json";
import sensors_en from "./translations/en/Sensor/sensors.json";
import sensorshell_fi from "./translations/fi/Sensor/sensorshell.json";
import sensorshell_en from "./translations/en/Sensor/sensorshell.json";
import sensorstatus_fi from "./translations/fi/Sensor/sensorstatus.json";
import sensorstatus_en from "./translations/en/Sensor/sensorstatus.json";
import sensorstatusvariabledetails_fi from "./translations/fi/Sensor/sensorstatusvariabledetails.json";
import sensorstatusvariabledetails_en from "./translations/en/Sensor/sensorstatusvariabledetails.json";
import sensorstatusvariables_fi from "./translations/fi/Sensor/sensorstatusvariables.json";
import sensorstatusvariables_en from "./translations/en/Sensor/sensorstatusvariables.json";
import sensorbatterychanges_fi from "./translations/fi/Sensor/sensorbatterychanges.json";
import sensorbatterychanges_en from "./translations/en/Sensor/sensorbatterychanges.json";
import sensoralarms_fi from "./translations/fi/Sensor/sensoralarms.json";
import sensoralarms_en from "./translations/en/Sensor/sensoralarms.json";
import sensormap_fi from "./translations/fi/Sensor/sensormap.json";
import sensormap_en from "./translations/en/Sensor/sensormap.json";
import sensorconsumaplereport_fi from "./translations/fi/Sensor/sensorconsumaplereport.json";
import sensorconsumaplereport_en from "./translations/en/Sensor/sensorconsumaplereport.json";
import sensordownlinksettings_fi from "./translations/fi/Sensor/sensordownlinksettings.json";
import sensordownlinksettings_en from "./translations/en/Sensor/sensordownlinksettings.json";
import sensordownlinksettingsrelay_fi from "./translations/fi/Sensor/sensordownlinksettingsrelay.json";
import sensordownlinksettingsrelay_en from "./translations/en/Sensor/sensordownlinksettingsrelay.json";
import sensormovetoothercompany_fi from "./translations/fi/Sensor/sensormovetoothercompany.json";
import sensormovetoothercompany_en from "./translations/en/Sensor/sensormovetoothercompany.json";


// the translations
const resources = {
    en: {
        landingpage: landingpage_en,
        desktopcontainer: desktopcontainer_en,
        accountdetails: accountdetails_en,
        changepassword: changepassword_en,
        accountshell: accountshell_en,
        companydetails: companydetails_en,
        companyinvoicingdetails: companyinvoicingdetails_en,
        companyusers: companyusers_en,
        companyuserdetails: companyuserdetails_en,
        companyfiles: companyfiles_en,
        companyfiledetails: companyfiledetails_en,
        companycontacts: companycontacts_en,
        companycontactdetails: companycontactdetails_en,
        companytasks: companytasks_en,
        companytaskdetails: companytaskdetails_en,
        productdetails: productdetails_en,
        productgroupdetails: productgroupdetails_en,
        productgroups: productgroups_en,
        products: products_en,
        companyshell: companyshell_en,
        customeraddresses: customeraddresses_en,
        customercontactdetails: customercontactdetails_en,
        customercontacs: customercontacts_en,
        customerdatacollections: customerdatacollections_en,
        customerdatacollectiondetails: customerdatacollectiondetails_en,
        customerdatacollectionitems: customerdatacollectionitems_en,
        customerdatacollectionadditem: customerdatacollectionadditem_en,
        customerdetails: customerdetails_en,
        customergroupdetails: customergroupdetails_en,
        customergroups: customergroups_en,
        customerinvoicingdetails: customerinvoicingdetails_en,
        customers: customers_en,
        customerfiles: customerfiles_en,
        customerfiledetails: customerfiledetails_en,
        customertasks: customertasks_en,
        customertaskdetails: customertaskdetails_en,
        customershell: customershell_en,
        invoicedetails: invoicedetails_en,
        invoices: invoices_en,
        invoiceshell: invoiceshell_en,
        login: login_en,
        passwordreset: passwordreset_en,
        passwordresetemail: passwordresetemail_en,
        contracts: contracts_en,
        contractdetails: contractdetails_en,
        contractgroups: contractgroups_en,
        contractgroupdetails: contractgroupdetails_en,
        contractshell: contractshell_en,
        messages: messages_en,
        messagecreate: messagecreate_en,
        reportinvoices: reportinvoices_en,
        reportinvoicescustomers: reportinvoicescustomers_en,
        reportinvoicesproducts: reportinvoicesproducts_en,
        reportshell: reportshell_en,

        sensordetails: sensordetails_en,
        sensorgroupdetails: sensorgroupdetails_en,
        sensorgroups: sensorgroups_en,
        sensorreports: sensorreports_en,
        sensors: sensors_en,
        sensorshell: sensorshell_en,
        sensorstatus: sensorstatus_en,
        sensorstatusvariabledetails: sensorstatusvariabledetails_en,
        sensorstatusvariables: sensorstatusvariables_en,
        sensorbatterychanges: sensorbatterychanges_en,
        sensoralarms: sensoralarms_en,
        sensormap: sensormap_en,
        sensorconsumaplereport: sensorconsumaplereport_en,
        sensordownlinksettings: sensordownlinksettings_en,
        sensordownlinksettingsrelay: sensordownlinksettingsrelay_en,
        sensormovetoothercompany: sensormovetoothercompany_en
    },
    fi: {
        landingpage: landingpage_fi,
        desktopcontainer: desktopcontainer_fi,
        accountdetails: accountdetails_fi,
        changepassword: changepassword_fi,
        accountshell: accountshell_fi,
        companydetails: companydetails_fi,
        companyinvoicingdetails: companyinvoicingdetails_fi,
        companyusers: companyusers_fi,
        companyuserdetails: companyuserdetails_fi,
        companyfiles: companyfiles_fi,
        companyfiledetails: companyfiledetails_fi,
        companycontacts: companycontacts_fi,
        companycontactdetails: companycontactdetails_fi,
        companytasks: companytasks_fi,
        companytaskdetails: companytaskdetails_fi,
        productdetails: productdetails_fi,
        productgroupdetails: productgroupdetails_fi,
        productgroups: productgroups_fi,
        products: products_fi,
        companyshell: companyshell_fi,
        customeraddresses: customeraddresses_fi,
        customercontactdetails: customercontactdetails_fi,
        customercontacts: customercontacts_fi,
        customerdatacollections: customerdatacollections_fi,
        customerdatacollectiondetails: customerdatacollectiondetails_fi,
        customerdatacollectionitems: customerdatacollectionitems_fi,
        customerdatacollectionadditem: customerdatacollectionadditem_fi,
        customerdetails: customerdetails_fi,
        customergroupdetails: customergroupdetails_fi,
        customergroups: customergroups_fi,
        customerinvoicingdetails: customerinvoicingdetails_fi,
        customers: customers_fi,
        customerfiles: customerfiles_fi,
        customerfiledetails: customerfiledetails_fi,
        customertasks: customertasks_fi,
        customertaskdetails: customertaskdetails_fi,
        customershell: customershell_fi,
        invoicedetails: invoicedetails_fi,
        invoices: invoices_fi,
        invoiceshell: invoiceshell_fi,
        login: login_fi,
        passwordreset: passwordreset_fi,
        passwordresetemail: passwordresetemail_fi,
        contracts: contracts_fi,
        contractdetails: contractdetails_fi,
        contractgroups: contractgroups_fi,
        contractgroupdetails: contractgroupdetails_fi,
        contractshell: contractshell_fi,
        messages: messages_fi,
        messagecreate: messagecreate_fi,
        reportinvoices: reportinvoices_fi,
        reportinvoicescustomers: reportinvoicescustomers_fi,
        reportinvoicesproducts: reportinvoicesproducts_fi,
        reportshell: reportshell_fi,

        sensordetails: sensordetails_fi,
        sensorgroupdetails: sensorgroupdetails_fi,
        sensorgroups: sensorgroups_fi,
        sensorreports: sensorreports_fi,
        sensors: sensors_fi,
        sensorshell: sensorshell_fi,
        sensorstatus: sensorstatus_fi,
        sensorstatusvariabledetails: sensorstatusvariabledetails_fi,
        sensorstatusvariables: sensorstatusvariables_fi,
        sensorbatterychanges: sensorbatterychanges_fi,
        sensoralarms: sensoralarms_fi,
        sensormap: sensormap_fi,
        sensorconsumaplereport: sensorconsumaplereport_fi,
        sensordownlinksettings: sensordownlinksettings_fi,
        sensordownlinksettingsrelay: sensordownlinksettingsrelay_fi,
        sensormovetoothercompany: sensormovetoothercompany_fi
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "fi",

        keySeparator: false, // we do not use keys in form messages.welcome
        nsSeparator: false,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;