// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 22.3.2020 TS Initial version
// 24.6.2020 TS Added Decentlab
// 26.6.2020 TS export to CSV
// 25.7.2020 TS Added DIGITAL-MATTER-YABBY
// 11.9.2020 TS Added ELSYS-ERS and ELSYS-EMS
// 19.1.2021 TS Added ELSYS-ELT-2
// 3.3.2021 TS Added NETVOX
// 14.5.2021 TS Added DINGTEK
// 07.05.2021 TS Added Caproc CMF
// 04.08.2021 TS Increased sensor "Offline" limit to 3 x measure interval

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Responsive} from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, getWidth, exportToCsv } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { sensorDetailsURL } from "../../constants";
  

class SensorStatusVariables extends React.Component {
    state = {
        sensor: null,
        status_variables: null,
        error: null,
        loading: false,
        success_mgs: null,
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchSensor();

    }

    handleFetchSensor = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    status_variables: res.data.sensorstatusvariables,
                    loading: false,
                    error: null
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    sensorVariableDetails = (sensor_id, id) => {
        this.props.history.push(`/sensor/${sensor_id}/status-variable-details/${id}/`)
    };


    handleGetStatus = sensor => {
        const { t } = this.props;
        if (sensor.last_status) {
            const sensor_last_status_sec = new Date(sensor.last_status).getTime() / 1000;
            const current_time_sec = new Date().getTime() / 1000;
            if ((current_time_sec - sensor_last_status_sec) > sensor.measure_interval * 3) {
                return <font color="red"><strong >{t("Offline")}</strong></font>
            } else {
                return <font color="green"><strong >{t("Online")}</strong></font>
            }
        } else {
            return <font color="red"><strong >{t("Offline")}</strong></font>
        }
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        const { sensor } = this.state;
        if (items !== null) {
            let titles = []
            let items_to_csv = [];
            if (sensor.model_name === "WISE-2410-EA") {
                titles = [
                    t("Variable"),
                    t("Axis"),
                    t("High limit"),
                    t("Low limit"),
                    t("Reference"),
                    t("Alarm active"),
                    t("Variable active")
                ];
                let i = 0;
                for (i in items) {
                    items_to_csv.push({
                        variable: t(items[i].display_name),
                        axis: items[i].sub_group,
                        high_limit: `${items[i].alarm_critical_high_limit}/${items[i].alarm_high_limit}`,
                        low_limit: `${items[i].alarm_critical_low_limit}/${items[i].alarm_low_limit}`,
                        reference: items[i].reference_value,
                        alarm_active: items[i].is_alarm_enabled ? t("Yes") : t("No"),
                        is_active: items[i].is_active ? t("Yes") : t("No"),
                    });
                }
    
            } else if (sensor.model_name === "DECENTLAB-DL-MBX") {
                titles = [
                    t("Variable"),
                    t("High limit"),
                    t("Low limit"),
                    t("Reference"),
                    t("Alarm active"),
                    t("Variable active")
                ];
                let i = 0;
                for (i in items) {
                    items_to_csv.push({
                        variable: t(items[i].display_name),
                        high_limit: `${items[i].alarm_critical_high_limit}/${items[i].alarm_high_limit}`,
                        low_limit: `${items[i].alarm_critical_low_limit}/${items[i].alarm_low_limit}`,
                        reference: items[i].reference_value,
                        alarm_active: items[i].is_alarm_enabled ? t("Yes") : t("No"),
                        is_active: items[i].is_active ? t("Yes") : t("No"),
                    });
                }
    
            } else if (sensor.model_name === "DIGITAL-MATTER-YABBY") {
                titles = [
                    t("Variable"),
                    t("High limit"),
                    t("Low limit"),
                    t("On/off limit"),
                    t("Alarm active"),
                    t("Variable active")
                ];
                let i = 0;
                for (i in items) {
                    items_to_csv.push({
                        variable: t(items[i].display_name),
                        high_limit: items[i].data_type !== "B" ? (`${items[i].alarm_critical_high_limit}/${items[i].alarm_high_limit}`) : "",
                        low_limit: items[i].data_type !== "B" ? (`${items[i].alarm_critical_low_limit}/${items[i].alarm_low_limit}`) : "",
                        onoff_limit: items[i].data_type === "B" ? (items[i].alarm_boolean_limit ? t("Yes") : t("No")) : "",
                        alarm_active: items[i].is_alarm_enabled ? t("Yes") : t("No"),
                        is_active: items[i].is_active ? t("Yes") : t("No"),
                    });
                }
    
            }

            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    handleGetSensorCategories = sensor => {
        let return_string = sensor.local_name;
        if (sensor.local_machine !== null && sensor.local_machine !== "")
        {
            return_string = return_string + "/" + sensor.local_machine;
        }
        if (sensor.local_line !== null && sensor.local_line !== "")
        {
            return_string = return_string + "/" + sensor.local_line;
        }
        if (sensor.local_unit !== null && sensor.local_unit !== "")
        {
            return_string = return_string + "/" + sensor.local_unit;
        }
        return return_string;
    };

    render() {
        const { error, loading, sensor } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        return (
            <Shell active_sensor_id={sensor && (sensor.id)} active_sensor_name={sensor && (sensor.local_name)} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>
                {(sensor && is_desktop) && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(sensor.sensorstatusvariables)} />
                    </Button.Group>
                )}
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {sensor && (
                    <React.Fragment>
                        <Header as="h3">{t("Sensor variable settings")}</Header>
                        <b >{t("Sensor")}: </b>{this.handleGetSensorCategories(sensor)}<br/>
                        <b >{t("Sensor model")}: </b> {sensor.device_name} <br/>
                        <b >{t("Sensor status")}: </b> {this.handleGetStatus(sensor)} 
                        {sensor.model_name === "WISE-2410-EA" ? (
                            <React.Fragment>
                                <Header as="h4" color="blue">{t("Accelerometer")}</Header>
                                <div>
                                    <Table striped selectable celled>
                                        {is_desktop && (
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell collapsing>{t("Variable")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Axis")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("High limit")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Low limit")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Reference")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Alarm active")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Variable active")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        )}
                                        <Table.Body>
                                            {sensor.sensorstatusvariables.map((variable, key) => {
                                                if (variable.group === "Accelerometer") {
                                                    return (
                                                        <Table.Row key={key} onClick={() => this.sensorVariableDetails(sensor.id, variable.id)}>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Variable")}</b><br/></React.Fragment>)}
                                                                {t(variable.display_name)}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Axis")}</b><br/></React.Fragment>)}
                                                                {variable.sub_group}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("High limit")}</b><br/></React.Fragment>)}
                                                                {variable.alarm_critical_high_limit}/{variable.alarm_high_limit}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {!is_desktop && (<React.Fragment><b>{t("Low limit")}</b><br/></React.Fragment>)}
                                                                {variable.alarm_low_limit}/{variable.alarm_critical_low_limit}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {!is_desktop && (<React.Fragment><b>{t("Reference")}</b><br/></React.Fragment>)}
                                                                {variable.reference_value}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Alarm active")}</b><br/></React.Fragment>)}
                                                                {variable.is_alarm_enabled ? (<font color="green">{t("Yes")}</font>) : (<font color="red">{t("No")}</font>)}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Variable active")}</b><br/></React.Fragment>)}
                                                                {variable.is_active ? (<font color="green">{t("Yes")}</font>) : (<font color="red">{t("No")}</font>)}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                }
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                                <Header as="h4" color="blue">{t("Device")}</Header>
                                <div>
                                    <Table striped selectable celled>
                                        {is_desktop && (
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell collapsing>{t("Variable")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("High limit")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Low limit")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Reference")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Alarm active")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Variable active")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        )}
                                        <Table.Body>
                                            {sensor.sensorstatusvariables.map((variable, key) => {
                                                if (variable.group !== "Accelerometer" && variable.name !== "Time" && variable.name !== "PowerSrc") {
                                                    return (
                                                        <Table.Row key={key} onClick={() => this.sensorVariableDetails(sensor.id, variable.id)}>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Variable")}</b><br/></React.Fragment>)}
                                                                {t(variable.display_name)}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("High limit")}</b><br/></React.Fragment>)}
                                                                {variable.alarm_critical_high_limit}/{variable.alarm_high_limit}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {!is_desktop && (<React.Fragment><b>{t("Low limit")}</b><br/></React.Fragment>)}
                                                                {variable.alarm_low_limit}/{variable.alarm_critical_low_limit}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {!is_desktop && (<React.Fragment><b>{t("Reference")}</b><br/></React.Fragment>)}
                                                                {variable.reference_value}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Alarm active")}</b><br/></React.Fragment>)}
                                                                {variable.is_alarm_enabled ? (<font color="green">{t("Yes")}</font>) : (<font color="red">{t("No")}</font>)}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Variable active")}</b><br/></React.Fragment>)}
                                                                {variable.is_active ? (<font color="green">{t("Yes")}</font>) : (<font color="red">{t("No")}</font>)}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                }
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </React.Fragment>
                        ) : (
                            <>
                            {sensor.model_name === "DECENTLAB-DL-MBX" ? (
                                <React.Fragment>
                                    <Header as="h4" color="blue">{t("Device")}</Header>
                                    <Table striped selectable celled>
                                        {is_desktop && (
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell collapsing>{t("Variable")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("High limit")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Low limit")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Reference")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Alarm active")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Variable active")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        )}
                                        <Table.Body>
                                            {sensor.sensorstatusvariables.map((variable, key) => {
                                                if (variable.group !== "Accelerometer" && variable.name !== "Time" && variable.name !== "PowerSrc") {
                                                    return (
                                                        <Table.Row key={key} onClick={() => this.sensorVariableDetails(sensor.id, variable.id)}>
                                                            <Table.Cell >
                                                                    {!is_desktop && (<React.Fragment><b>{t("Variable")}</b><br/></React.Fragment>)}
                                                                    {t(variable.display_name)}
                                                                </Table.Cell>
                                                                <Table.Cell >
                                                                    {!is_desktop && (<React.Fragment><b>{t("High limit")}</b><br/></React.Fragment>)}
                                                                    {variable.alarm_critical_high_limit}/{variable.alarm_high_limit}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {!is_desktop && (<React.Fragment><b>{t("Low limit")}</b><br/></React.Fragment>)}
                                                                    {variable.alarm_low_limit}/{variable.alarm_critical_low_limit}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {!is_desktop && (<React.Fragment><b>{t("Reference")}</b><br/></React.Fragment>)}
                                                                    {variable.reference_value}
                                                                </Table.Cell>
                                                                <Table.Cell >
                                                                    {!is_desktop && (<React.Fragment><b>{t("Alarm active")}</b><br/></React.Fragment>)}
                                                                    {variable.is_alarm_enabled ? (<font color="green">{t("Yes")}</font>) : (<font color="red">{t("No")}</font>)}
                                                                </Table.Cell>
                                                                <Table.Cell >
                                                                    {!is_desktop && (<React.Fragment><b>{t("Variable active")}</b><br/></React.Fragment>)}
                                                                    {variable.is_active ? (<font color="green">{t("Yes")}</font>) : (<font color="red">{t("No")}</font>)}
                                                                </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                }
                                            })}
                                        </Table.Body>
                                    </Table>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Header as="h4" color="blue">{t("Device")}</Header>
                                    <Table striped selectable celled>
                                        {is_desktop && (
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell collapsing>{t("Variable")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("High limit")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Low limit")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("On/Off limit")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Alarm active")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Variable active")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        )}
                                        <Table.Body>
                                            {sensor.sensorstatusvariables.map((variable, key) => {
                                                    return (
                                                        <>
                                                        {(variable.group === "position" || variable.group === "status" || variable.group === "Consumption" || variable.group === "Container" || variable.group === "Report") && (
                                                            <Table.Row key={key} onClick={() => this.sensorVariableDetails(sensor.id, variable.id)}>
                                                                <Table.Cell >
                                                                        {!is_desktop && (<React.Fragment><b>{t("Variable")}</b><br/></React.Fragment>)}
                                                                        {t(variable.display_name)}
                                                                    </Table.Cell>
                                                                    <Table.Cell >
                                                                        {!is_desktop && (<React.Fragment><b>{t("High limit")}</b><br/></React.Fragment>)}
                                                                        {variable.data_type !== "B" ? (`${variable.alarm_critical_high_limit}/${variable.alarm_high_limit}`) : ""}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {!is_desktop && (<React.Fragment><b>{t("Low limit")}</b><br/></React.Fragment>)}
                                                                        {variable.data_type !== "B" ? (`${variable.alarm_low_limit}/${variable.alarm_critical_low_limit}`) : ""}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {!is_desktop && (<React.Fragment><b>{t("On/Off limit")}</b><br/></React.Fragment>)}
                                                                        {(variable.data_type === "B" && variable.is_alarm_enabled) ? (variable.alarm_boolean_limit ? t("Yes") : t("No")) : ""}
                                                                    </Table.Cell>
                                                                    <Table.Cell >
                                                                        {!is_desktop && (<React.Fragment><b>{t("Alarm active")}</b><br/></React.Fragment>)}
                                                                        {variable.is_alarm_enabled ? (<font color="green">{t("Yes")}</font>) : (<font color="red">{t("No")}</font>)}
                                                                    </Table.Cell>
                                                                    <Table.Cell >
                                                                        {!is_desktop && (<React.Fragment><b>{t("Variable active")}</b><br/></React.Fragment>)}
                                                                        {variable.is_active ? (<font color="green">{t("Yes")}</font>) : (<font color="red">{t("No")}</font>)}
                                                                    </Table.Cell>
                                                            </Table.Row>
                                                        )}
                                                        </>
                                                    );
                                            })}
                                        </Table.Body>
                                    </Table>
                                </React.Fragment>
                            )}
                            </>
                        )}
                    </React.Fragment>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensorstatusvariables')(SensorStatusVariables))
);