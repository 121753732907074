// Copyright Caproc Oy
// System name: IoT
// System module: Sensor
// Author: TS
//
// 21.3.2020 TS Initial version
// 24.9.2023 TS Fix to CSV export. Statistics value to fixed 3 decimals.

import React from "react";
import { Message, Header, Segment, Dimmer, Loader, Image,  Grid, Radio, Container, Button, Responsive, Select } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, getISOFormattedDate, exportToCsv, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { sensorDetailsURL, fetchSensorConsumptionReportURL } from "../../constants";
import {  Bar  } from 'react-chartjs-2';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';


class SensorConsumapleReport extends React.Component {
    state = {
        report: null,
        sensor: null,
        error: null,
        loading: false,
        search_day_date: null,
        search_month: null,
        search_year: null,
        year_range_start: null,
        year_range_end: null,
        search: "",
        fetch: false,
        screen_size: "normal",
        variable_options: [],
        variable_choices: [],
        tableData: [],
        offset: 0,
        limit: 30,
        startup_counter: 0,
        report_type: "day",
        month_options: [],
        year_options: [],
        chart_data: {}
    }

    componentDidMount() {
        this.props.setActivity();
        this.handleSetMonthOptions();
        this.handleSetYearOptions();
        this.handleFetchSensor();
        this.handleDateChange(new Date());
        this.setState({ 
            fetch: true,
            measurement_unit: this.props.match.params.measurement_unit
        });
        this.handleSetReportType(this.props.match.params.report_type);
        
    }

    handleSetMonthOptions() {
        let months_list = [];

        let i;
        for (i = 1; i < 13; i++) {
            months_list.push({
                key: i,
                text: i.toString(),
                value: i
            });
        }
        this.setState({
            month_options: months_list
        });
    }

    handleSetYearOptions() {
        let time_stamp = new Date();
        let year = time_stamp.getFullYear();
        let years_list = [];

        let i;
        // Ten years back for now
        for (i = 0; i < 10; i++) {
            years_list.push({
                key: year - i,
                text: (year - i).toString(),
                value: year - i
            });
        }
        this.setState({
            year_options: years_list
        });
    }


    handleRadioChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleSetReportType = type => {
        if (type === "day") {
            this.handleDateChange(new Date());
            this.setState({
                report_type: type,
                search_month: null,
                search_year: null
            });
        } else if (type === "month") {
            this.setState({
                report_type: type,
                search_day_date: null,
                search_month: new Date().getMonth() + 1,
                search_year: new Date().getFullYear()
            });
        } else if (type === "year") {
            this.setState({
                report_type: type,
                search_day_date: null,
                search_month: null,
                search_year: new Date().getFullYear()
            });
        }
    };

    radioButtons = () => {
        return (
            <React.Fragment>
                <Radio
                    name="screen_size"
                    label={this.props.t("Normal")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "normal"}
                    value="normal"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Wide")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "wide"}
                    value="wide"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Full screen")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "full"}
                    value="full"
                />  
            </React.Fragment>
        );
    };

    handleFetchReport = () => {
        this.setState({
            loading: true
        });
        const { search_day_date, search_month, search_year, report_type, year_range_start, year_range_end, measurement_unit} = this.state;
        const { activeCompanyId, t } = this.props;
        const { sensor_id } = this.props.match.params;
        authAxios
            .get(fetchSensorConsumptionReportURL(activeCompanyId, sensor_id, measurement_unit, report_type, search_day_date, search_year, search_month, null, year_range_start, year_range_end))
            .then(res => {
                this.setState({
                    report: res.data,
                    error: null,
                    loading: false
                });
                this.handleTotals(res.data);
                this.handleChartData(res.data);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleChartData = report => {
        let {
            chart_data,
            report_type,
            measurement_unit
        } = this.state;
        const { t, i18n } = this.props;
        const default_dataset1 = {
            label: "",
            backgroundColor: 'rgb(78, 150, 220)',
            borderColor: 'rgb(255, 255, 255)',
            data: []
        };
        const default_dataset2 = {
            label: "",
            backgroundColor: 'rgb(100, 140, 180)',
            borderColor: 'rgb(255, 255, 255)',
            data: []
        };
        chart_data.labels = [];
        chart_data.datasets = [];

        let chart_data_dataset = {
            ...default_dataset1,
            label: `${t("Consumption")} (${measurement_unit})`,
            average: 0,
            total_sum: 0,
            pcs: 0,
            highest: null,
            lowest: null
        };
        let i = 0;
        let month_text = "";
        let lang_code = "";
        if (i18n.language === "en") {
            lang_code = "en-EN";
        } else if (i18n.language === "fi") {
            lang_code = "fi-FI";
        }
        for (i in report) {
            if (report_type === "day") {
                chart_data.labels.push(`${report[i].day}.${report[i].month}.${report[i].year} ${t("at")} ${report[i].hour}.00`);
            } else if (report_type === "month") {
                chart_data.labels.push(`${report[i].day}.${report[i].month}.${report[i].year}`);
            } else if (report_type === "year") {
                month_text = new Intl.DateTimeFormat(lang_code, { month: 'long' }).format(new Date().setMonth(parseInt(report[i].month) - 1))
                month_text = month_text.charAt(0).toUpperCase() + month_text.slice(1);
                chart_data.labels.push(`${month_text}/${report[i].year}`);
            }

            chart_data_dataset.data.push(report[i].statistic_value.toFixed(3));
            if (chart_data_dataset.highest === null || (parseFloat(chart_data_dataset.highest) < parseFloat(report[i].statistic_value))) {
                chart_data_dataset.highest = parseFloat(report[i].statistic_value);
            }
            if (chart_data_dataset.lowest === null || (parseFloat(chart_data_dataset.lowest) > parseFloat(report[i].statistic_value))) {
                chart_data_dataset.lowest = parseFloat(report[i].statistic_value);
            }
            chart_data_dataset.total_sum += parseFloat(report[i].statistic_value);
            chart_data_dataset.pcs += 1;
        }
        if (chart_data_dataset.pcs > 0) {
            chart_data_dataset.average = parseFloat((chart_data_dataset.total_sum / chart_data_dataset.pcs).toFixed(3));
        }

        chart_data_dataset.label = 
        `${chart_data_dataset.label} (${t("avg")}: ${chart_data_dataset.average.toFixed(3)} ${t("Highest")}: ${chart_data_dataset.highest ? chart_data_dataset.highest.toFixed(3) : 0} ${t("Lowest")}: ${chart_data_dataset.lowest ? chart_data_dataset.lowest.toFixed(3) : 0})`; 

        chart_data.datasets.push(chart_data_dataset);
        this.setState({
            chart_data: chart_data
        });
    };

    handleTotals = report => {
        let { total_sum } = this.state;
        let i = 0;
        total_sum = 0;

        for (i in report) {
            total_sum += report[i].statistic_value;
        }
        this.setState({
            total_sum
        });
    };


    handleFetchSensor = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    loading: false,
                    error: null
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    handleDateChange = value => {
        if (value !== null) {
            value.setHours(0);
            value.setMinutes(0);
            value.setSeconds(0);
            this.setState({
                search_day_date: getISOFormattedDate(value, "Date"),
                // fetch: true
            });
        }
    };

    handleResetSeconds = value => {
        if (value !== null) {
            value.setSeconds(0);
        }
        return value;
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleExportToCSV = items => {
        const { t, i18n } = this.props;
        let { report_type } = this.state;
        if (items !== null) {
            const titles = [
                t("Time"),
                t("Amount"),
                t("Unit")
            ];
            let items_to_csv = [];
            let i = 0;
            let event_time = ""; 
            let month_text = "";
            let lang_code = "";
            if (i18n.language === "en") {
                lang_code = "en-EN";
            } else if (i18n.language === "fi") {
                lang_code = "fi-FI";
            }
            for (i in items) {
                if (report_type === "day") {
                    event_time = `${items[i].day}.${items[i].month}.${items[i].year} ${t("at")} ${items[i].hour}.00`;
                } else if (report_type === "month") {
                    event_time = `${items[i].day}.${items[i].month}.${items[i].year}`;
                } else if (report_type === "year") {
                    month_text = new Intl.DateTimeFormat(lang_code, { month: 'long' }).format(new Date().setMonth(parseInt(items[i].month) - 1))
                    month_text = month_text.charAt(0).toUpperCase() + month_text.slice(1);
                    event_time = `${month_text}/${items[i].year}`;
                }
                items_to_csv.push({
                    event_time: event_time,
                    amount: items[i].statistic_value.toFixed(3),
                    unit: items[i].measurement_unit
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    handleRefresh = () => {
        this.setState({
            fetch: true
        });
    };

    main = () => {
        const { error, loading, report, report_type, search_month, search_year, search_day_date, month_options, year_options,
            sensor, chart_data, total_sum, measurement_unit } = this.state;
        const { t } = this.props;
        const options = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            min: 0
                        },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: measurement_unit
                        }  
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }


        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        return (
            <React.Fragment>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(report)} />
                    </Button.Group>
                )}
                
                <Header as="h3">{t("Sensor report")}</Header>
                <Button icon='refresh' floated="right" color="green" label={t("Refresh")} onClick={() => this.handleRefresh()}/>
                {is_desktop ? (
                    <>
                        <Grid>
                            <Grid.Column width="3">
                        <Button.Group basic size='small'>
                            <Button active={report_type === "day"} content={t("Day")} onClick={() => this.handleSetReportType("day")} />
                            <Button active={report_type === "month"} content={t("Month")} onClick={() => this.handleSetReportType("month")} />
                            <Button active={report_type === "year"} content={t("Year")} onClick={() => this.handleSetReportType("year")} />
                        </Button.Group>
                        <br/>
                        {report_type === "day" && (
                            <>
                                <SemanticDatepicker
                                    firstDayOfWeek="1"
                                    disabled={loading}
                                    locale={fiLocale}
                                    placeholder={search_day_date !== null ? (new Date(search_day_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                    onDateChange={selected => this.handleDateChange(selected)}
                                    format='DD.MM.YYYY' />
                            </>
                        )}
                        {report_type === "month" && (
                            <>  
                                <Grid columns="2">
                                    <Grid.Column>
                                        <Select
                                            compact
                                            name="search_month"
                                            placeholder={(new Date().getMonth() + 1).toString()}
                                            value={search_month}
                                            options={month_options}
                                            onChange={this.handleSelectChange} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Select
                                            compact
                                            name="search_year"
                                            placeholder={new Date().getFullYear().toString()}
                                            value={search_year}
                                            options={year_options}
                                            onChange={this.handleSelectChange} />
                                    </Grid.Column>
                                </Grid>
                            </>
                        )}
                        {report_type === "year" && (
                            <>
                                <Select
                                    compact
                                    name="search_year"
                                    placeholder={new Date().getFullYear().toString()}
                                    value={search_year}
                                    options={year_options}
                                    onChange={this.handleSelectChange} />
                            </>
                        )}
                        </Grid.Column>
                        </Grid>
                    </>
                ) : (
                    <React.Fragment>
                        {/* <SemanticDatepicker
                                firstDayOfWeek="1"
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleEndDateChange(selected)}
                            format='DD.MM.YYYY' /> */}
                        <br/>
                    </React.Fragment>
                )}
                <br/>
                
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {(report && sensor) && (
                    <React.Fragment>
                        <Header textAlign="center" as="h3">{t("Consumption report")}</Header>
                        <Bar data={chart_data} options={options} />
                        <Header textAlign="center" as="h4">{t("Total")} {total_sum && total_sum.toFixed(3)} {measurement_unit}</Header>        
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    render() {
        const {  fetch, sensor} = this.state;
        const { isAuthenticated } = this.props;

        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (fetch) {
            if (sensor !== null) {
                this.handleFetchReport();
                this.setState({ fetch: false }); 
            } 
        }
        
        //console.log(this.state);
        return (
            <React.Fragment>
                {this.state.screen_size === "normal" && (
                    <Shell active_sensor_id={sensor && (sensor.id)} active_sensor_name={sensor && (sensor.local_name)} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>
                        <this.radioButtons/>
                        <this.main />
                    </Shell>
                )}
                {this.state.screen_size === "wide" && (
                    <Container>
                        <Segment vertical>
                            <this.radioButtons/>    
                            <this.main/>
                        </Segment>
                    </Container>
                )}{this.state.screen_size === "full" && (
                    <React.Fragment>
                        <this.radioButtons/>
                        <this.main />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensorconsumaplereport')(SensorConsumapleReport))
);