// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 26.6.2020 TS Initial version
// 28.7.2020 TS Added translations
// 4.8.2021 TS Increased sensor "Offline" limit to 3 x measure interval

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Responsive, Grid, Input} from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import Shell from "./Shell";
import { authAxios, getWidth, exportToCsv, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {  sensorDetailsURL, updateSensorAlarmURL, fetchSensorAlarmsURL } from "../../constants";
  

class SensorAlarms extends React.Component {
    state = {
        sensor: null,
        error: null,
        loading: false,
        success_mgs: null,
        search_start_date: null,
        search_end_date: null,
        search: "",
        fetch: false,
        alarms: null
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchSensor();
        let init_start_date = new Date();
        init_start_date.setDate(init_start_date.getDate() - 7);
        this.handleStartDateChange(init_start_date);
        this.handleEndDateChange(new Date());
    }

    handleStartDateChange = value => {
        if (value !== null) {
            value.setHours(0);
            value.setMinutes(0);
            value.setSeconds(0);
            this.setState({
                search_start_date: getISOFormattedDate(value, "Date"),
                fetch: true
            });
        }
    };

    handleEndDateChange = value => {
        if (value !== null) {
            // Get also values from current search end date, so move end date timestamp to next mid night.
            value.setDate(value.getDate() + 1);
            value.setHours(0);
            value.setMinutes(0);
            value.setSeconds(0);
            this.setState({
                search_end_date: getISOFormattedDate(value, "Date"),
                fetch: true
            });
        }
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleFetchAlarms = () => {
        this.setState({
            loading: true
        });
        const { search_start_date, search_end_date } = this.state;
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(fetchSensorAlarmsURL(activeCompanyId, sensor_id, search_start_date, search_end_date))
            .then(res => {
                this.setState({
                    alarms: res.data,
                    loading: false,
                    error: null
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    handleFetchSensor = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    loading: false,
                    error: null
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleGetStatus = sensor => {
        const { t } = this.props;
        if (sensor.last_status) {
            const sensor_last_status_sec = new Date(sensor.last_status).getTime() / 1000;
            const current_time_sec = new Date().getTime() / 1000;
            if ((current_time_sec - sensor_last_status_sec) > sensor.measure_interval * 3) {
                return <font color="red"><strong >{t("Offline")}</strong></font>
            } else {
                return <font color="green"><strong >{t("Online")}</strong></font>
            }
        } else {
            return <font color="red"><strong >{t("Offline")}</strong></font>
        }
    };

    handleAcknowledge = sensor_alarm => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const updatedSensorAlarm = {
            ...sensor_alarm,
            is_acknowledged: !sensor_alarm.is_acknowledged
        };
        authAxios
            .put(updateSensorAlarmURL(activeCompanyId, sensor_alarm.id), {
                ...updatedSensorAlarm
            })
            .then(res => {
                this.setState({
                    error: null,
                    loading: false,
                    fetch: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Time"),
                t("Alarm type"),
                t("Sensor variable"),
                t("Alarm limit"),
                t("Received value")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    alarm_time: new Date(items[i].date_created).toLocaleString('fi-FI'),
                    alarm_type: items[i].alarm_type,
                    sensor_variable: items[i].sensor_variable,
                    alarm_limit: items[i].alarm_limit,
                    received_value: items[i].received_value
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    handleGetSensorCategories = sensor => {
        let return_string = sensor.local_name;
        if (sensor.local_machine !== null && sensor.local_machine !== "")
        {
            return_string = return_string + "/" + sensor.local_machine;
        }
        if (sensor.local_line !== null && sensor.local_line !== "")
        {
            return_string = return_string + "/" + sensor.local_line;
        }
        if (sensor.local_unit !== null && sensor.local_unit !== "")
        {
            return_string = return_string + "/" + sensor.local_unit;
        }
        return return_string;
    };


    render() {
        const { error, loading, sensor, search_start_date, search_end_date, search, fetch, alarms } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }

        if (fetch) {
            if (search_start_date !== null && search_end_date !== null) {
                this.handleFetchAlarms();
                this.setState({ fetch: false });
            }
        }

        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);

        let filtered_items = [];

        // Filter data for search
        if (alarms) {
            filtered_items = alarms.filter((data) => {
                return data.alarm_type.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.sensor_variable.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.alarm_limit.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.received_value.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
        }

        return (
            <Shell active_sensor_id={sensor && (sensor.id)} active_sensor_name={sensor && (sensor.local_name)} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(filtered_items)} />
                    </Button.Group>
                )}
                
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {sensor && (
                    <React.Fragment>
                        <Header as="h3">{t("Sensor alarms")}</Header>
                        <b >{t("Sensor")}: </b>{this.handleGetSensorCategories(sensor)}<br/>
                        <b >{t("Sensor model")}: </b> {sensor.device_name} <br/>
                        <b >{t("Sensor status")}: </b> {this.handleGetStatus(sensor)} <br/>
                        <br/>
                        {is_desktop ? (
                            <Grid columns="2">
                                <Grid.Column width={9}>
                                    <SemanticDatepicker
                                        firstDayOfWeek="1"
                                        size="mini"
                                        disabled={loading}
                                        locale={fiLocale}
                                        placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                        onDateChange={selected => this.handleStartDateChange(selected)}
                                        format='DD.MM.YYYY' />
                                    <SemanticDatepicker
                                        firstDayOfWeek="1"
                                        size="mini"
                                        disabled={loading}
                                        label="->"
                                        locale={fiLocale}
                                        placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                        onDateChange={selected => this.handleEndDateChange(selected)}
                                        format='DD.MM.YYYY' />

                                </Grid.Column>
                                <Grid.Column floated="right" width={6} textAlign="right">
                                    <Input
                                        size="mini"
                                        icon='search'
                                        value={search}
                                        name="search"
                                        onChange={this.handleChange} />
                                    
                                </Grid.Column>
                            </Grid>
                        ) : (
                            <React.Fragment>
                                <SemanticDatepicker
                                        firstDayOfWeek="1"
                                        disabled={loading}
                                        locale={fiLocale}
                                        placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                        onDateChange={selected => this.handleStartDateChange(selected)}
                                        format='DD.MM.YYYY' />
                                <SemanticDatepicker
                                    firstDayOfWeek="1"
                                    disabled={loading}
                                    locale={fiLocale}
                                    placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                    onDateChange={selected => this.handleEndDateChange(selected)}
                                    format='DD.MM.YYYY' />
                                <br/>
                                <Input
                                    fluid
                                    icon='search'
                                    value={search}
                                    name="search"
                                    onChange={this.handleChange} />
                            </React.Fragment>
                        )}
                        <Header as="h4" color="blue">{t("Alarms")}</Header>
                        {alarms && (
                            <Table striped selectable celled>
                                {is_desktop && (
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell >{t("Time")}</Table.HeaderCell>
                                            <Table.HeaderCell >{t("Alarm type")}</Table.HeaderCell>
                                            <Table.HeaderCell >{t("Sensor variable")}</Table.HeaderCell>
                                            <Table.HeaderCell >{t("Alarm limit")}</Table.HeaderCell>
                                            <Table.HeaderCell >{t("Received value")}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                )}
                                <Table.Body>
                                    {filtered_items.map((alarm, key) => {
                                        return (
                                            <Table.Row key={key} onClick={() => this.handleAcknowledge(alarm)}>
                                                <Table.Cell >
                                                    {!is_desktop && (<React.Fragment><b>{t("Time")}</b><br/></React.Fragment>)}
                                                    {alarm.is_acknowledged ? new Date(alarm.date_created).toLocaleString('fi-FI') : <b>{new Date(alarm.date_created).toLocaleString('fi-FI')}</b>}
                                                </Table.Cell>
                                                <Table.Cell >
                                                    {!is_desktop && (<React.Fragment><b>{t("Alarm type")}</b><br/></React.Fragment>)}
                                                    {alarm.is_acknowledged ? t(alarm.alarm_type) : <b>{t(alarm.alarm_type)}</b>}</Table.Cell>
                                                <Table.Cell >
                                                    {!is_desktop && (<React.Fragment><b>{t("Sensor variable")}</b><br/></React.Fragment>)}
                                                    {alarm.is_acknowledged ? t(alarm.sensor_variable) : <b>{t(alarm.sensor_variable)}</b>}
                                                </Table.Cell>
                                                <Table.Cell >
                                                    {!is_desktop && (<React.Fragment><b>{t("Alarm limit")}</b><br/></React.Fragment>)}
                                                    {alarm.is_acknowledged ? t(alarm.alarm_limit) : <b>{t(alarm.alarm_limit)}</b>}
                                                </Table.Cell>
                                                <Table.Cell >
                                                    {!is_desktop && (<React.Fragment><b>{t("Received value")}</b><br/></React.Fragment>)}
                                                    {alarm.is_acknowledged ? t(alarm.received_value) : <b>{t(alarm.received_value)}</b>}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        )}
                    </React.Fragment>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensoralarms')(SensorAlarms))
);