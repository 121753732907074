import React from "react";
import { Grid, Container, Segment, Header, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';


const Shell = props => (
    <Segment vertical>
        <Container>
            <Grid container stackable verticalAlign="top" divided columns={2}>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header as="h3">{props.t("Company")}</Header>
                        <Menu vertical fluid>
                            <Menu.Item>
                                <Header as="h4">{props.t("Basic details")}</Header>
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/company/company-details"}
                                onClick={() => props.history.push("/company/company-details")}
                                name="company-details"
                            >
                                {props.t("Details")}
                            </Menu.Item>
                            {/* <Menu.Item
                                active={props.location.pathname === "/company/invoicing-details"}
                                onClick={() => props.history.push("/company/invoicing-details")}
                                name="invoicing-details"
                            >
                                {props.t("Invoicing")}
                            </Menu.Item> 
                            <Menu.Item
                                active={props.location.pathname === "/company/contacts"}
                                onClick={() => props.history.push("/company/contacts")}
                                name="company-contacts"
                            >
                                {props.t("Contacts")}
                            </Menu.Item>*/}
                            {(props.activeRoles && (props.activeRoles.includes("A") || props.activeRoles.includes("S"))) ? (
                                <Menu.Item
                                    active={props.location.pathname === "/company/users"}
                                    onClick={() => props.history.push("/company/users")}
                                    name="company-users"
                                >
                                    {props.t("Users")}
                                </Menu.Item>
                            ) : (
                                <Menu.Item
                                    disabled={true}
                                    active={props.location.pathname === "/company/users"}
                                    onClick={() => props.history.push("/company/users")}
                                    name="company-users"
                                >
                                    {props.t("Users")}
                                </Menu.Item>

                            )}
                              {/*
                            <Menu.Item
                                active={props.location.pathname === "/company/files"}
                                onClick={() => props.history.push("/company/files")}
                                name="company-files"
                            >
                                {props.t("Files")}
                            </Menu.Item>
                            <Menu.Item>
                                <Header as="h4">{props.t("Products")}</Header>
                            </Menu.Item>

                            <Menu.Item
                                active={props.location.pathname === "/product/products"}
                                onClick={() => props.history.push("/product/products")}
                                name="products"
                            >
                                {props.t("Products")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/product/product-groups"}
                                onClick={() => props.history.push("/product/product-groups")}
                                name="product-groups"
                            >
                                {props.t("Product Groups")}
                            </Menu.Item>
                            <Menu.Item>
                                <Header as="h4">{props.t("Tasks")}</Header>
                            </Menu.Item> 
                            <Menu.Item
                                active={props.location.pathname === "/company/tasks"}
                                onClick={() => props.history.push("/company/tasks")}
                                name="tasks"
                            >
                                {props.t("Completed tasks")}
                            </Menu.Item>
                            */}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={13}>{props.children}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment>
);

const mapStateToProps = state => {
    return {
        activeRoles: state.company.activeRoles
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(withTranslation('companyshell')(Shell))
);