// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 22.3.2020 TS Initial version
// 14.6.2020 TS Added email/sms alarm limits
// 24.6.2020 TS Added Decentlab
// 27.6.2020 TS Added last sensor value. Alarm counter and critical alarm counter reset to zero on variable update.
// 04.08.2021 TS Increased sensor "Offline" limit to 3 x measure interval
// 10.3.2022 TS Added Sensor status variable parameter updates
// 6.7.2022 TS Removed Admin restriction for variable in status and reports view settings
// 21.9.2022 TS Removed empty value check from 2-point calibration. This allows to add values after each calibration step.


import React from "react";
import { Form, Input, Message, Header, Button,  Checkbox, Dimmer, Loader, Segment, Image, Popup, Select, Radio } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {  sensorStatusVariableDetailsURL, updateSensorStatusVariableDetailsURL, sensorDetailsURL, updateSensorStatusVariableParametersURL} from "../../constants";


class SensorStatusVariableDetails extends React.Component {
    state = {
        sensor_variable: null,
        sensorstatusvariableparameters: null,
        sensor: null,
        error: null,
        parameter_error: null,
        success_mgs: null,
        loading: false,
        component_loading: false,
        alarm_counter_options: []
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleDetails();
        this.handleFetchSensor();
        this.createNumberOptions();
    }

    createNumberOptions = () => {
        let options = [];
        for (let i = 1; i < 100; i++) {
            options.push({
                key: i,
                text: i.toString(),
                value: i
            })
        }
        this.setState({
            alarm_counter_options: options
        });
    };

    handleFetchSensor = (id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    loading: false,
                    error: null
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { status_variable_id } = this.props.match.params;

        authAxios
            .get(sensorStatusVariableDetailsURL(activeCompanyId, status_variable_id))
            .then(res => {
                this.setState({
                    sensor_variable: res.data,
                    sensorstatusvariableparameters: res.data.sensorstatusvariableparameters,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            sensor_variable,
            sensorstatusvariableparameters,
            sensor
        } = this.state;
        const { activeCompanyId, t } = this.props;
        let is_parameters_ok = true;
        let false_parameter_name;
        let is_2point_calibration = this.handleIs2PointCalibration();

        if (sensorstatusvariableparameters.length > 0) {
            let skip = false;
            sensorstatusvariableparameters.map((parameter, key) => {
                if (parameter.name !== "is-inverse-display-value" && parameter.name !== "2-point-calibration-active") {

                    if (parameter.name.startsWith("2-point-calibration") && !is_2point_calibration) {
                        skip = true;
                    }
                    // if ((isNaN(parameter.value) || parameter.value === '') && !skip) {
                    if ((isNaN(parameter.value)) && !skip) {
                        is_parameters_ok = false;
                        false_parameter_name = parameter.name;
                    }
                    skip = false;
                }
            })
        }

        // Check that limit values are in correct format
        if (!is_parameters_ok) {
            this.setState({
                error: t("Please check parameter value") + " '" + t(false_parameter_name) + "'. " + t("Must be number"),
                success_mgs: null,
                loading: false
            });
        }
        else if (sensor_variable.data_type !== "B" && (isNaN(sensor_variable.alarm_high_limit) || sensor_variable.alarm_high_limit.toString().valueOf('.') === -1)) {
            this.setState({
                error: t("Please check format of Alarm high limit. Decimal separator is dot(.) instead of comma (,). e.g. 0.025"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor_variable.data_type !== "B" && (isNaN(sensor_variable.alarm_low_limit) || sensor_variable.alarm_low_limit.toString().valueOf('.') === -1)) {
            this.setState({
                error: t("Please check format of Alarm low limit. Decimal separator is dot(.) instead of comma (,). e.g. 0.025"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor_variable.data_type !== "B" && (isNaN(sensor_variable.alarm_critical_low_limit) || sensor_variable.alarm_critical_low_limit.toString().valueOf('.') === -1)) {
            this.setState({
                error: t("Please check format of Alarm critical low limit. Decimal separator is dot(.) instead of comma (,). e.g. 0.025"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor_variable.data_type !== "B" && (isNaN(sensor_variable.alarm_critical_high_limit) || sensor_variable.alarm_critical_high_limit.toString().valueOf('.') === -1)) {
            this.setState({
                error: t("Please check format of Alarm critical high limit. Decimal separator is dot(.) instead of comma (,). e.g. 0.025"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor_variable.data_type !== "B" && (sensor_variable.reference_value !== "" && (isNaN(sensor_variable.reference_value) || sensor_variable.reference_value.toString().valueOf('.') === -1))) {
            this.setState({
                error: t("Please check format of Reference value. Decimal separator is dot(.) instead of comma (,). e.g. 0.025"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor_variable.data_type !== "B" && (sensor_variable.is_alarm_enabled && parseFloat(sensor_variable.alarm_low_limit) >= parseFloat(sensor_variable.alarm_high_limit))) {
                this.setState({
                    error: t("Alarm low limit needs to be less than high limit. Please check values"),
                    success_mgs: null,
                    loading: false
                });
        } else if (sensor_variable.data_type !== "B" && (sensor_variable.is_alarm_enabled && parseFloat(sensor_variable.alarm_critical_low_limit) >= parseFloat(sensor_variable.alarm_critical_high_limit))) {
                this.setState({
                    error: t("Alarm critical low limit needs to be less than critical high limit. Please check values"),
                    success_mgs: null,
                    loading: false
                });
        } else if (sensor_variable.data_type !== "B" && (sensor_variable.is_alarm_enabled && parseFloat(sensor_variable.alarm_critical_high_limit) < parseFloat(sensor_variable.alarm_high_limit))) {
                this.setState({
                    error: t("Alarm critical high limit needs to be more than high limit or the same. Please check values"),
                    success_mgs: null,
                    loading: false
                });
        } else if (sensor_variable.data_type !== "B" && (sensor_variable.is_alarm_enabled && parseFloat(sensor_variable.alarm_critical_low_limit) > parseFloat(sensor_variable.alarm_low_limit))) {
                this.setState({
                    error: t("Alarm critical low limit needs to be less than low limit or the same. Please check values"),
                    success_mgs: null,
                    loading: false
                });
        } else if (sensor_variable.data_type !== "B" && (sensor_variable.value_offset !== "" && (isNaN(sensor_variable.value_offset) || sensor_variable.value_offset.toString().valueOf('.') === -1))) {
            this.setState({
                error: t("Please check format of Value offset value. Decimal separator is dot(.) instead of comma (,). e.g. 0.025"),
                success_mgs: null,
                loading: false
            });
    
        } else {
            authAxios
                .post(updateSensorStatusVariableParametersURL(activeCompanyId), {
                    parameters: sensorstatusvariableparameters
                })
                .then(res => {
                    let status = "";
                    if (sensor_variable.is_alarm_enabled) {
                        status = "Alarm on";
                    } else {
                        status = "Alarm off";
                    }
                    const updatedDetails = {
                        ...sensor_variable,
                        status: status,
                        author: localStorage.getItem("UserID"),
                        alarm_counter: 0,
                        alarm_critical_counter: 0,
                        settings_last_modified_author: localStorage.getItem("UserID"),
                        settings_last_modified_time: getISOFormattedDate(new Date(), "DateTime"),
                        value_offset: sensor_variable.value_offset === "" ? 0 : sensor_variable.value_offset
                    };
                    authAxios
                        .put(updateSensorStatusVariableDetailsURL(activeCompanyId, sensor_variable.id), {
                            ...updatedDetails
                        })
                        .then(res => {
                            this.props.history.push(`/sensor/status-variable-settings/${sensor.id}`)
                        })
                        .catch(err => {
                            this.setState({
                                loading: false,
                                success_mgs: null,
                                error: err
                            });
                        });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
            
        }
    };
    handleChange = e => {
        const { sensor_variable } = this.state;
        const updatedDetails = {
            ...sensor_variable,
            [e.target.name]: e.target.value,
        };
        this.setState({
            sensor_variable: updatedDetails,
            success_mgs: null,
            error: null,
        });
    };

    handleParameterChange = (e, { name, value }) => {
        const { sensorstatusvariableparameters } = this.state;
        this.setState({
            sensorstatusvariableparameters: sensorstatusvariableparameters.map(
            obj => (obj.name === e.target.name ? Object.assign(obj, { value: e.target.value.toString() }) : obj)
          ),
        });
    };

    handleParameterCheckboxChange = (e, { name, checked }) => {
        const { sensorstatusvariableparameters } = this.state;
        this.setState({
            sensorstatusvariableparameters: sensorstatusvariableparameters.map(
            obj => (obj.name === name ? Object.assign(obj, { value: checked ? "True" : "False" }) : obj)
          ),
        });
    };

    handleIs2PointCalibration = () => {
        const { sensorstatusvariableparameters } = this.state;
        let i = 0;
        for (i in sensorstatusvariableparameters) {
            if (sensorstatusvariableparameters[i].name === "2-point-calibration-active") {
                if (sensorstatusvariableparameters[i].value === "True") {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        return false;
    }

    handleCheckboxChange = (e, { name, checked }) => {
        const { sensor_variable } = this.state;
        
        const updatedDetails = {
            ...sensor_variable,
            [name]: checked
        };
        this.setState({
            sensor_variable: updatedDetails,
            success_mgs: null,
            error: null,
        });
    };

    handleSelectChange = (e, { name, value }) => {
        const { sensor_variable } = this.state;
        const updatedDetails = {
            ...sensor_variable,
            [name]: value
        };
        this.setState({
            sensor_variable: updatedDetails,
            success_mgs: null,
            error: null
        });
    };

    handleRadioChange = (e, {name, value }) => {
        const { sensor_variable } = this.state;
        const updatedDetails = {
            ...sensor_variable,
            [name]: value
        };
        this.setState({
            sensor_variable: updatedDetails,
            success_mgs: null,
            error: null
        });
    }

    handleGetStatus = sensor => {
        const { t } = this.props;
        if (sensor.last_status) {
            const sensor_last_status_sec = new Date(sensor.last_status).getTime() / 1000;
            const current_time_sec = new Date().getTime() / 1000;
            if ((current_time_sec - sensor_last_status_sec) > sensor.measure_interval * 3) {
                return <font color="red"><strong >{t("Offline")}</strong></font>
            } else {
                return <font color="green"><strong >{t("Online")}</strong></font>
            }
        } else {
            return <font color="red"><strong >{t("Offline")}</strong></font>
        }
    };
    
    handleGetSensorCategories = sensor => {
        let return_string = sensor.local_name;
        if (sensor.local_machine !== null && sensor.local_machine !== "")
        {
            return_string = return_string + "/" + sensor.local_machine;
        }
        if (sensor.local_line !== null && sensor.local_line !== "")
        {
            return_string = return_string + "/" + sensor.local_line;
        }
        if (sensor.local_unit !== null && sensor.local_unit !== "")
        {
            return_string = return_string + "/" + sensor.local_unit;
        }
        return return_string;
    };


    render() {
        const {
            sensor_variable,
            sensorstatusvariableparameters,
            error,
            parameter_error,
            success_mgs,
            loading,
            sensor

        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell active_sensor_id={sensor && (sensor.id)} active_sensor_name={sensor&& (sensor.local_name)} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>

                <React.Fragment>
                    {loading && (
                        <Segment>
                            <Dimmer active inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>
                            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                        </Segment>
                    )}

                    {(sensor_variable && sensor) && (
                        <React.Fragment>
                             <Header as="h3">{t("Sensor variable details")}</Header>
                            <b >{t("Sensor")}: </b>{this.handleGetSensorCategories(sensor)}<br/>
                            <b >{t("Sensor model")}: </b> {sensor.device_name} <br/>
                            <b >{t("Sensor status")}: </b> {this.handleGetStatus(sensor)} 

                            <br/>
                            <br/>
                            <Form onSubmit={this.handleSubmit} error={error !== null}>
                                <Form.Field >
                                    <label>{t("Variable name")}</label>
                                    &nbsp;&nbsp;{t(sensor_variable.display_name)}
                                </Form.Field>
                                {sensor_variable.sub_group !== "" && (
                                    <Form.Field >
                                        <label>{t("Axis")}</label>
                                        &nbsp;&nbsp;{t(sensor_variable.sub_group)}
                                    </Form.Field>
                                )}
                                <Form.Field >
                                    <label>{t("Last received value (measured)")}</label>
                                    &nbsp;&nbsp;{t(sensor_variable.value_from_sensor)}<br/>
                                    &nbsp;&nbsp;{sensor_variable.event_time ? `(${new Date(sensor_variable.event_time).toLocaleString('fi-FI')})` : ""}
                                </Form.Field>
                                {(sensor_variable.data_type !== "B" && sensor_variable.data_type !== "S") && (
                                    <Form.Field >
                                        <label>{t("Last received value (with offset)")}</label>
                                        &nbsp;&nbsp;{t(sensor_variable.last_value)}<br/>
                                        {/* &nbsp;&nbsp;{sensor_variable.event_time ? `(${new Date(sensor_variable.event_time).toLocaleString('fi-FI')})` : ""} */}
                                    </Form.Field>
                                )}
                                {sensor_variable.data_type !== "S" && (
                                    <>
                                        <hr/>
                                        <Form.Field>
                                            <Checkbox
                                                label={<label>{t("Alarm enabled")}</label>}
                                                name="is_alarm_enabled"
                                                onChange={this.handleCheckboxChange}
                                                checked={sensor_variable.is_alarm_enabled}
                                            />
                                        </Form.Field>
                                        <div style={{paddingLeft: 20, paddingBottom: 10}}>
                                            {sensor_variable.data_type !== "B" && (
                                                <>
                                                    <Form.Field required width={4}>
                                                        <label>{t("Alarm critical high limit")}</label>
                                                        <Input
                                                            disabled={!sensor_variable.is_alarm_enabled}
                                                            required
                                                            value={sensor_variable.alarm_critical_high_limit}
                                                            name="alarm_critical_high_limit"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                    <Form.Field required width={4}>
                                                        <label>{t("Alarm high limit")}</label>
                                                        <Input
                                                            disabled={!sensor_variable.is_alarm_enabled}
                                                            required
                                                            value={sensor_variable.alarm_high_limit}
                                                            name="alarm_high_limit"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                    <Form.Field required width={4}>
                                                        <label>{t("Alarm low limit")}</label>
                                                        <Input
                                                            disabled={!sensor_variable.is_alarm_enabled}
                                                            required
                                                            value={sensor_variable.alarm_low_limit}
                                                            name="alarm_low_limit"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                    <Form.Field required width={4}>
                                                        <label>{t("Alarm critical low limit")}</label>
                                                        <Input
                                                            disabled={!sensor_variable.is_alarm_enabled}
                                                            required
                                                            value={sensor_variable.alarm_critical_low_limit}
                                                            name="alarm_critical_low_limit"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                </>
                                            )}
                                            {sensor_variable.data_type === "B" && (
                                                <>
                                                    <Form.Field>
                                                        <label>{t("Alarm when status is")}:</label>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Radio
                                                            disabled={!sensor_variable.is_alarm_enabled}
                                                            label={t("Yes")}
                                                            name='alarm_boolean_limit'
                                                            value={true}
                                                            checked={sensor_variable.alarm_boolean_limit === true}
                                                            onChange={this.handleRadioChange}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Radio
                                                            disabled={!sensor_variable.is_alarm_enabled}
                                                            label={t("No")}
                                                            name='alarm_boolean_limit'
                                                            value={false}
                                                            checked={sensor_variable.alarm_boolean_limit === false}
                                                            onChange={this.handleRadioChange}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            disabled={!sensor_variable.is_alarm_enabled}
                                                            label={<label>{t("Alarm is critical")}</label>}
                                                            name="is_boolean_alarm_critical"
                                                            onChange={this.handleCheckboxChange}
                                                            checked={sensor_variable.is_boolean_alarm_critical}
                                                        />
                                                    </Form.Field>
                                                </>
                                            )}
                                            <Form.Field required width={4}>
                                                <Popup content={t("Limits the amount of alarm email/sms messages if the status variable value stays above/below given alarm limit. Counter is reset when the value is not in alarm status anymore")}
                                                    trigger={<label>{t("Alarm email/sms amount")}</label>} />
                                                <Select
                                                    disabled={!sensor_variable.is_alarm_enabled}
                                                    search
                                                    options={this.state.alarm_counter_options}
                                                    value={sensor_variable.alarm_counter_limit}
                                                    placeholder={sensor_variable.alarm_counter_limit.toString()}
                                                    name="alarm_counter_limit"
                                                    onChange={this.handleSelectChange} />
                                            </Form.Field>
                                            <Form.Field required width={4}>
                                                <Popup content={t("Limits the amount of critical alarm email/sms alarm messages if the status variable value stays above/below given alarm limit. Counter is reset when the value is not in critical alarm status anymore")}
                                                    trigger={<label>{t("Critical alarm email/sms amount")}</label>} />
                                                <Select
                                                    disabled={!sensor_variable.is_alarm_enabled}
                                                    search
                                                    options={this.state.alarm_counter_options}
                                                    value={sensor_variable.alarm_critical_counter_limit}
                                                    placeholder={sensor_variable.alarm_critical_counter_limit.toString()}
                                                    name="alarm_critical_counter_limit"
                                                    onChange={this.handleSelectChange} />
                                            </Form.Field>
                                            {/* {sensor_variable.data_type !== "B" && (
                                                <>
                                                    <hr/>
                                                    <Form.Field >
                                                        <Popup content={t("Reference/zero value is used to calculate deviation between reference value and last measured value from the sensor")}
                                                            trigger={<label>{t("Reference value")}</label>} />
                                                        <Input
                                                            disabled={!sensor_variable.is_alarm_enabled}
                                                            value={sensor_variable.reference_value}
                                                            name="reference_value"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                </>
                                            )} */}
                                        </div>
                                    </>
                                )}
                                {(sensor_variable.data_type !== "B" && sensor_variable.data_type !== "S") && (
                                    <>
                                        <hr/>
                                        <Form.Field width={4}>
                                            <Popup content={t("Calibration offset to measured value. Use decimal value with comma as decimal separator.")}
                                                trigger={<label>{t("Offset to value")}</label>} />
                                            <Input
                                                //disabled={!sensor_variable.is_alarm_enabled}
                                                value={sensor_variable.value_offset}
                                                name="value_offset"
                                                onChange={this.handleChange} />
                                        </Form.Field>
                                    </>
                                )}

                                {(sensorstatusvariableparameters && sensorstatusvariableparameters.length > 0) && (
                                    <>
                                        <hr/>
                                        <Form.Field >
                                            <Header as="h4">{t("Parameters")}</Header>
                                        </Form.Field> 
                                        {sensorstatusvariableparameters.map((parameter, key) => {
                                            if (parameter.name !== "is-inverse-display-value" && parameter.name !== "water-level-setup" && !parameter.name.startsWith("2-point-calibration"))
                                            {
                                                return (
                                                    <Form.Field key={key} width={4}>
                                                        <label>{t(parameter.name)}</label>
                                                        <Input
                                                            value={parameter.value}
                                                            name={parameter.name}
                                                            onChange={this.handleParameterChange} />
                                                    </Form.Field>
                                                );
                                            } else if (parameter.name === "is-inverse-display-value")
                                            {
                                                return(
                                                    <Form.Field key={key}>
                                                        <Checkbox
                
                                                            label={<label>{t("is-inverse-display-value")}</label>}
                                                            name="is-inverse-display-value"
                                                            onChange={this.handleParameterCheckboxChange}
                                                            checked={parameter.value === "True"}
                                                        />
                                                    </Form.Field>
                                                );
                                            } else if (parameter.name === "2-point-calibration-active")
                                            {
                                                return(
                                                    <>
                                                    <Form.Field key={key}>
                                                        <Checkbox
                                                            label={<label>{t("2-point-calibration-active")}</label>}
                                                            name="2-point-calibration-active"
                                                            onChange={this.handleParameterCheckboxChange}
                                                            checked={parameter.value === "True"}
                                                        />
                                                    </Form.Field>
                                                    {parameter.value === "True" && (
                                                        <>
                                                            <div style={{paddingLeft: 20, paddingBottom: 10}}>
                                                                {/* <Popup content={t("Limits the amount of alarm email/sms messages if the status variable value stays above/below given alarm limit. Counter is reset when the value is not in alarm status anymore")}
                                                                    trigger={<label><strong>{t("1. Calibration point (low value)")}</strong></label>} /> */}
                                                                <label><strong>{t("1. Calibration point (low value)")}</strong></label>
                                                                <br/>
                                                                <br/>
                                                                <div style={{paddingLeft: 20, paddingBottom: 10}}>
                                                                    {sensorstatusvariableparameters.map((_parameter, _key) => {
                                                                        if (_parameter.name === "2-point-calibration-low-target-value")
                                                                        {
                                                                            return(
                                                                                <Form.Field key={_key} width={4}>
                                                                                    <label>{t("2-point-calibration-low-target-value")} ({sensor_variable.measurement_unit})</label>
                                                                                    <Input
                                                                                        value={_parameter.value}
                                                                                        name="2-point-calibration-low-target-value"
                                                                                        onChange={this.handleParameterChange} />
                                                                                </Form.Field>
                                                                            );
                                                                        } else if (_parameter.name === "2-point-calibration-low-target-measured-value")
                                                                        {
                                                                            return(
                                                                                <Form.Field key={_key} width={4}>
                                                                                    <label>{t("2-point-calibration-low-target-measured-value")} ({sensor_variable.measurement_unit})</label>
                                                                                    <Input
                                                                                        value={_parameter.value}
                                                                                        name="2-point-calibration-low-target-measured-value"
                                                                                        onChange={this.handleParameterChange} />
                                                                                </Form.Field>
                                                                            );
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <div style={{paddingLeft: 20, paddingBottom: 10}}>
                                                                {/* <Popup content={t("Limits the amount of alarm email/sms messages if the status variable value stays above/below given alarm limit. Counter is reset when the value is not in alarm status anymore")}
                                                                    trigger={<label><strong>{t("2. Calibration point (high value)")}</strong></label>} /> */}
                                                                <label><strong>{t("2. Calibration point (high value)")}</strong></label>
                                                                <br/>
                                                                <br/>
                                                                <div style={{paddingLeft: 20, paddingBottom: 10}}>
                                                                    {sensorstatusvariableparameters.map((_parameter, _key) => {
                                                                        if (_parameter.name === "2-point-calibration-high-target-value")
                                                                        {
                                                                            return(
                                                                                <Form.Field key={_key} width={4}>
                                                                                    <label>{t("2-point-calibration-high-target-value")} ({sensor_variable.measurement_unit})</label>
                                                                                    <Input
                                                                                        value={_parameter.value}
                                                                                        name="2-point-calibration-high-target-value"
                                                                                        onChange={this.handleParameterChange} />
                                                                                </Form.Field>
                                                                            );
                                                                        } else if (_parameter.name === "2-point-calibration-high-target-measured-value")
                                                                        {
                                                                            return(
                                                                                <Form.Field key={_key} width={4}>
                                                                                    <label>{t("2-point-calibration-high-target-measured-value")} ({sensor_variable.measurement_unit})</label>
                                                                                    <Input
                                                                                        value={_parameter.value}
                                                                                        name="2-point-calibration-high-target-measured-value"
                                                                                        onChange={this.handleParameterChange} />
                                                                                </Form.Field>
                                                                            );
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    </>
                                                );
                                            }
                                        })}
                                    </>
                                )}
                                <hr/>
                                <Form.Field>
                                    <Checkbox
                                        label={<label>{t("Variable active")}</label>}
                                        name="is_active"
                                        onChange={this.handleCheckboxChange}
                                        checked={sensor_variable.is_active}
                                    />
                                </Form.Field>
                                {/* {this.props.activeRoles.includes("A") && (
                                    <> */}
                                    <Form.Field>
                                        <Checkbox
                                            label={<label>{t("Report value")}</label>}
                                            name="is_report_value"
                                            onChange={this.handleCheckboxChange}
                                            checked={sensor_variable.is_report_value}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox
                                            label={<label>{t("Display value")}</label>}
                                            name="is_display_value"
                                            onChange={this.handleCheckboxChange}
                                            checked={sensor_variable.is_display_value}
                                        />
                                    </Form.Field>
                                    {/* </>
                                )} */}
                                <label>{t("Created")}:</label>&nbsp;
                                {new Date(sensor_variable.date_created).toLocaleString('fi-FI')}<br/>
                                <label>{t("Last modified")}:</label>&nbsp;
                                {sensor_variable.settings_last_modified_time ? new Date(sensor_variable.settings_last_modified_time).toLocaleString('fi-FI') : ""}&nbsp;{sensor_variable.settings_last_modified_author && (sensor_variable.settings_last_modified_author.username)}<br/><br/>

                                {error && <Message error header={t("Error")} content={error} />}
                                {success_mgs && <Message positive header={success_mgs} />}
                                <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleSubmit(e)}>
                                    {(t("Save"))}
                                </Button>
                            </Form>
                        </React.Fragment>
                    )}
                </React.Fragment>
            </Shell>

        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRoles: state.company.activeRoles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensorstatusvariabledetails')(SensorStatusVariableDetails))
);