// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 22.6.2020 TS Initial version
// 4.8.2021 TS Increased sensor "Offline" limit to 3 x measure interval


import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Modal, Container, Form, Input, Icon, Responsive} from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import Shell from "./Shell";
import { authAxios, getISOFormattedDate, getWidth, exportToCsv } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {  sensorDetailsURL, createSensorBatteryChangeURL, deleteBatteryChangeURL } from "../../constants";
  

class SensorBatteryChanges extends React.Component {
    state = {
        sensor: null,
        error: null,
        loading: false,
        success_mgs: null,
        add_change_modal_open: false,
        battery_change: {
            change_date: null,
            description: "",
            author: ""
        }
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchSensor();

    }

    handleFetchSensor = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    loading: false,
                    error: null
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleRefresh = () => {
        this.handleFetchSensor();
    };

    handleGetStatus = sensor => {
        const { t } = this.props;
        if (sensor.last_status) {
            const sensor_last_status_sec = new Date(sensor.last_status).getTime() / 1000;
            const current_time_sec = new Date().getTime() / 1000;
            if ((current_time_sec - sensor_last_status_sec) > sensor.measure_interval * 3) {
                return <font color="red"><strong >{t("Offline")}</strong></font>
            } else {
                return <font color="green"><strong >{t("Online")}</strong></font>
            }
        } else {
            return <font color="red"><strong >{t("Offline")}</strong></font>
        }
    };

    handleAddChange = () => {
        this.setState({
            add_change_modal_open: true,
            battery_change: {
                description: "",
                author: ""
            }
        });
        this.handleDateChange(new Date());
    };

    closeAddModal = () => {
        this.setState({
            add_change_modal_open: false,
            battery_change: {
                change_date: null,
                description: "",
                author: ""
            }
        });
    };

    handleDateChange = value => {
        const { battery_change } = this.state;
        const updated_battery_change = {
            ...battery_change,
            change_date: getISOFormattedDate(new Date(value), "DateTime"),
        };
        this.setState({
            battery_change: updated_battery_change,
            error: null
        });
    };

    handleChange = e => {
        const { battery_change } = this.state;
        const updated_battery_change = {
            ...battery_change,
            [e.target.name]: e.target.value
        }
        this.setState({
            battery_change: updated_battery_change,
            error: null
        });   
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { battery_change, sensor } = this.state;
        const { activeCompanyId, t } = this.props;
        if (battery_change.change_date === null) {
            this.setState({
                error: t("Please select change date"),
                success_mgs: null,
                loading: false
            });
        } else {
            const updatedDetails = {
                ...battery_change,
                company: activeCompanyId,
                sensor: sensor.id
            }
            authAxios
                .post(createSensorBatteryChangeURL(activeCompanyId), {
                    ...updatedDetails
                })
                .then(res => {
                    this.handleFetchSensor();
                    this.closeAddModal();
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }
    }

    handleDelete = id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(deleteBatteryChangeURL(activeCompanyId, id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchSensor();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    }

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Change date"),
                t("Description"),
                t("Author")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    change_date: new Date(items[i].change_date).toLocaleString('fi-FI'),
                    description: items[i].description,
                    author: items[i].author
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    handleGetSensorCategories = sensor => {
        let return_string = sensor.local_name;
        if (sensor.local_machine !== null && sensor.local_machine !== "")
        {
            return_string = return_string + "/" + sensor.local_machine;
        }
        if (sensor.local_line !== null && sensor.local_line !== "")
        {
            return_string = return_string + "/" + sensor.local_line;
        }
        if (sensor.local_unit !== null && sensor.local_unit !== "")
        {
            return_string = return_string + "/" + sensor.local_unit;
        }
        return return_string;
    };

    render() {
        const { error, loading, battery_change, sensor, success_mgs, add_change_modal_open } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        return (
            <Shell active_sensor_id={sensor && (sensor.id)} active_sensor_name={sensor && (sensor.local_name)} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(sensor.sensorbatterychanges)} />
                    </Button.Group>
                )}
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {sensor && (
                    <React.Fragment>
                        <Header as="h3">{t("Sensor battery changes")}</Header>
                        <b >{t("Sensor")}: </b>{this.handleGetSensorCategories(sensor)}<br/>
                        <b >{t("Sensor model")}: </b> {sensor.device_name} <br/>
                        <b >{t("Sensor status")}: </b> {this.handleGetStatus(sensor)} 
                        <Button type="button" floated="right" color="green" content={t("+ Battery change")} onClick={() => this.handleAddChange()}/>

                        <Header as="h4" color="blue">{t("Change log")}</Header>
                        <Table striped selectable celled>
                            {is_desktop && (
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell >{t("Change date")}</Table.HeaderCell>
                                        <Table.HeaderCell >{t("Description")}</Table.HeaderCell>
                                        <Table.HeaderCell >{t("Author")}</Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>
                            )}
                            <Table.Body>
                                {sensor.sensorbatterychanges.map((change, key) => {
                                    return (
                                        <Table.Row key={key}>
                                            <Table.Cell >
                                                {!is_desktop && (<React.Fragment><b>{t("Change date")}</b><br/></React.Fragment>)}
                                                {new Date(change.change_date).toLocaleString('fi-FI')}
                                            </Table.Cell>
                                            <Table.Cell >
                                                {!is_desktop && (<React.Fragment><b>{t("Description")}</b><br/></React.Fragment>)}
                                                {change.description}
                                            </Table.Cell>
                                            <Table.Cell >
                                                {!is_desktop && (<React.Fragment><b>{t("Author")}</b><br/></React.Fragment>)}
                                                {change.author}
                                            </Table.Cell>
                                            <Table.Cell  textAlign='center'><Icon link color="red" name="trash alternate outline" onClick={() => this.handleDelete(change.id)}/></Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </React.Fragment>
                )}
                <Modal size='small' open={add_change_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Add battery change")}</Header>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            <Form error={error !== null}>
                                <Form.Field required>
                                <label>{t("Change date")}</label>
                                    <SemanticDatepicker
                                        firstDayOfWeek="1"
                                        disabled={loading}
                                        locale={fiLocale}
                                        placeholder={battery_change && battery_change.change_date}
                                        onDateChange={selected => this.handleDateChange(selected)}
                                        format='DD.MM.YYYY' />
                                </Form.Field>
                                <Form.Field >
                                    <label>{t("Description")} {battery_change.description ? (`${battery_change.description.length}/200`) : ("0/200")}</label>
                                    <Input
                                        value={battery_change.description}
                                        name="description"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field >
                                    <label>{t("Author")} {battery_change.author ? (`${battery_change.author.length}/100`) : ("0/100")}</label>
                                    <Input
                                        required
                                        value={battery_change.author}
                                        name="author"
                                        onChange={this.handleChange} />
                                </Form.Field>

                                {error && <Message error header={t("Error")} content={error} />}
                                {success_mgs && <Message positive header={success_mgs} />}
                                <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleSubmit(e)}>
                                    {t("Save")}
                                </Button>
                            </Form>
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeAddModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensorbatterychanges')(SensorBatteryChanges))
);