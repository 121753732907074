import PropTypes from "prop-types";
import React, { Component } from "react";
import {
    Button,
    Container,
    Dropdown,
    Menu,
    Responsive,
    Segment,
    Visibility,
    Flag,
    DropdownMenu,
    Image
} from "semantic-ui-react";
import { connect } from "react-redux";
import { logout } from "../../store/actions/auth";
import { setActiveCompany } from "../../store/actions/company";
import { withRouter } from "react-router-dom";
import { getWidth } from "../../utils";
import { withTranslation } from 'react-i18next';
import Logo from "../../assets/images/Caproc-logo-small-70.png";
import Logo_Lunni from  "../../assets/images/Lunni-logo.png";
import Logo_propipe from "../../assets/images/pipepro.png";
import Logo_green_up from "../../assets/images/greenup.png";
import Logo_polygon from "../../assets/images/polygon.png";


class DesktopContainer extends Component {
    state = {
        company_name: null,
        fixed: false
    };

    hideFixedMenu = () => this.setState({ fixed: false });
    showFixedMenu = () => this.setState({ fixed: true });

    setActiveCompanyId = (company_id, company_name, roles) => {
        this.props.setCompany(company_id, company_name, roles);
        this.setState({ company_name });
        this.props.history.push("/")
    };

    handleLogout = () => {
        this.setState({ company_name: null });
        this.props.logout();
    };

    checkIfOnlyCustomerRole = (roles) => {
        // Only Client role activated to limit access
        if (roles && roles.length === 1 && roles.includes("C")) {
            return true;
        }
        return false;
    };

    render() {
        const { children, isAuthenticated, userCompanies, activeCompanyId, activeRoles, t, i18n } = this.props;
        const { fixed, company_name } = this.state;
        // console.log(localStorage.getItem("Logo"));
        const logo = localStorage.getItem("Logo");
        return (
            <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
                <Visibility
                    once={false}
                    onBottomPassed={this.showFixedMenu}
                    onBottomPassedReverse={this.hideFixedMenu}
                >
                    <Segment
                        inverted
                        textAlign="center"
                        style={{ padding: "1em 0em" }}
                        vertical
                    >
                        <Menu
                            fixed={fixed ? "top" : null}
                            inverted={!fixed}
                            // pointing={!fixed}
                            // secondary={!fixed}
                            size="large"

                        >
                            <Container>
                                {(isAuthenticated && logo && activeRoles) && (
                                    <>
                                        {(logo === "Lunni-logo.png" || logo === "Lunni" || logo === "lunni") && (
                                            <Menu.Item
                                                active={this.props.location.pathname === "/"}
                                                onClick={() => this.props.history.push("/")}
                                                content={<Image src={Logo_Lunni} size='small' verticalAlign="bottom" />}
                                                fitted="vertically"
                                                position="left"/>
                                        )}
                                        {(logo === "pipepro.png" || logo === "Pipepro" || logo === "PipePro" || logo === "pipepro") && (
                                            <Menu.Item
                                                active={this.props.location.pathname === "/"}
                                                onClick={() => this.props.history.push("/")}
                                                content={<Image src={Logo_propipe} size='small' verticalAlign="bottom" />}
                                                fitted="vertically"
                                                position="left"/>
                                        )}
                                        {(logo === "greenup.png" || logo === "GreenUp" || logo === "greenup") && (
                                            <Menu.Item
                                                active={this.props.location.pathname === "/"}
                                                onClick={() => this.props.history.push("/")}
                                                content={<Image src={Logo_green_up} size='small' verticalAlign="bottom" />}
                                                fitted="vertically"
                                                position="left"/>
                                        )}
                                        {(logo === "Caproc" || logo === "caproc" || logo === "") && (
                                            <Menu.Item
                                                active={this.props.location.pathname === "/"}
                                                onClick={() => this.props.history.push("/")}
                                                content={<Image src={Logo} size='small' verticalAlign="bottom" />}
                                                fitted="vertically"
                                                position="left"/>
                                        )}
                                        {(logo === "Polygon" || logo === "polygon") && (
                                            <Menu.Item
                                                active={this.props.location.pathname === "/"}
                                                onClick={() => this.props.history.push("/")}
                                                content={<Image src={Logo_polygon} size='small' verticalAlign="bottom" />}
                                                fitted="vertically"
                                                position="left"/>
                                        )}
                                    </>
                                )}
                                {(userCompanies && activeCompanyId) && (
                                    <React.Fragment>
                                         <Menu.Item
                                             active={this.props.location.pathname === "/sensors/"}
                                             onClick={() => this.props.history.push("/sensors/")}
                                             content={t('Sensors')}
                                             position="left"
                                         />
                                         {/* <Menu.Item
                                             active={this.props.location.pathname === "/loratest/"}
                                             onClick={() => this.props.history.push("/loratest/")}
                                             content={t('Lora test')}
                                         /> */}

                                    </React.Fragment>
                                )}

                                <Menu.Menu position="right">
                                    {isAuthenticated ? (
                                        <React.Fragment>
                                            {(userCompanies !== null && userCompanies.length > 0) &&
                                                <Dropdown item text={company_name ? (company_name) : (localStorage.getItem("activeCompanyName"))}>
                                                    <Dropdown.Menu>
                                                        {userCompanies.map((item, key) =>
                                                            <Dropdown.Item
                                                                key={key}
                                                                text={item.name}
                                                                onClick={() =>
                                                                    this.setActiveCompanyId(item.id, item.name, item.roles)
                                                                }
                                                            />
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                            <Dropdown item text={localStorage.getItem("userFullName") !== "" ? (localStorage.getItem("userFullName")) : (localStorage.getItem("userName"))}>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        text={t('Account')}
                                                        onClick={() =>
                                                            this.props.history.push("/account/account-details")
                                                        }
                                                    />
                                                    {(userCompanies && !this.checkIfOnlyCustomerRole(activeRoles)) && (
                                                        <Dropdown.Item
                                                            text={t('Company')}
                                                            onClick={() =>
                                                                this.props.history.push("/company/company-details")
                                                            }
                                                        />
                                                    )}
                                                    <Dropdown.Item
                                                        text={t('Logout')}
                                                        onClick={() =>
                                                            this.handleLogout()
                                                        }
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown item button trigger={<Flag name={i18n.language === "en" ? "gb" : i18n.language} />} >
                                                <DropdownMenu>
                                                    <Dropdown.Item flag="fi" onClick={() => i18n.changeLanguage('fi')} />
                                                    <Dropdown.Item flag="gb" onClick={() => i18n.changeLanguage('en')} />
                                                </DropdownMenu>
                                            </Dropdown>

                                        </React.Fragment>
                                    ) : (
                                            <React.Fragment>
                                                <Button
                                                    inverted={!fixed}
                                                    onClick={() => this.props.history.push("/login")}
                                                >
                                                    {t('Login')}
                                                </Button>
                                                {/* <Button
                                                    inverted={!fixed}
                                                    primary={fixed}
                                                    style={{ marginLeft: "0.5em" }}
                                                    onClick={() => this.props.history.push("/signup")}
                                                >
                                                    {t('Signup')}
                                                </Button> */}
                                                <Dropdown item trigger={<Flag name={i18n.language === "en" ? "gb" : i18n.language} />} >
                                                    <DropdownMenu>
                                                        <Dropdown.Item flag="fi" onClick={() => i18n.changeLanguage('fi')} />
                                                        <Dropdown.Item flag="gb" onClick={() => i18n.changeLanguage('en')} />
                                                    </DropdownMenu>
                                                </Dropdown>

                                            </React.Fragment>
                                        )}
                                </Menu.Menu>
                            </Container>
                        </Menu>
                    </Segment>
                </Visibility>

                {children}
            </Responsive>
        );
    }
}

DesktopContainer.propTypes = {
    children: PropTypes.node
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        activeCompanyId: state.company.activeCompanyId,
        userCompanies: state.company.userCompanies,
        activeRoles: state.company.activeRoles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout()),
        setCompany: (id, name, roles) => dispatch(setActiveCompany(id, name, roles))
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('desktopcontainer')(DesktopContainer))
);