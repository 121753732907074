// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Project
// Author: TS
//
// 1.2.2020 TS Initial version
// 8.3.2020 TS Project URLs
// 14.3.2020 TS Project task URLs
// 24.3.2020 TS Changed host 
// 17.1.2023 TS Sensor report to CSV
// 30.6.2023 TS Added deleteSensorStatusValueURL

let DEBUG = false;
export let host = "http://127.0.0.1:8000";
if (DEBUG === false) {
    host = "https://iot.caproc.fi";
}
const apiURL = "/api";

export const endpoint = `${host}${apiURL}`;
// User
export const accountDetailsURL = `${endpoint}/account-details/`;
export const updateAccountDetailsURL = `${endpoint}/update-account/`;
export const changePasswordURL = `${host}/rest-auth/password/change/`;
export const logoutURL = `${host}/rest-auth/logout/`;
export const loginURL = `${host}/rest-auth/login/`;
export const signupURL = `${host}/rest-auth/registration/`;
export const resetPasswordURL = `${host}/rest-auth/password/reset/`;
export const resetPasswordConfirmURL = `${host}/rest-auth/password/reset/confirm/`;
export const userExistsURL = company_id => `${endpoint}/${company_id}/user-exists/`;
// Company
export const companyDetailsURL = (company_id, id) => `${endpoint}/${company_id}/companies/${id}/`;
export const updateCompanyDetailsURL = (company_id, id) => `${endpoint}/${company_id}/companies/${id}/update/`;
export const companyListURL = `${endpoint}/companies/`;
// Company users
export const companyUsersListURL = company_id => `${endpoint}/${company_id}/users/`;
export const companyActiveUsersListURL = company_id => `${endpoint}/${company_id}/active-users/`;
export const companyUserDetailsURL = (company_id, id) => `${endpoint}/${company_id}/user-details/${id}/`;
export const companyUserDetailsUpdateURL = (company_id, id) => `${endpoint}/${company_id}/user-details/${id}/update/`;
export const companyUserRoleOptionsURL = (company_id) => `${endpoint}/${company_id}/company/user-role-options/`;
// Company files
export const companyFilesListURL = company_id => `${endpoint}/${company_id}/files/`;
export const companyFileDetailsURL = (company_id, id) => `${endpoint}/${company_id}/file/${id}/`;
export const companyFileDetailsUpdateURL = (company_id, id) => `${endpoint}/${company_id}/file/${id}/update/`;
export const companyFileUploadURL = (company_id) => `${endpoint}/${company_id}/file/upload/`;
export const companyFileDeleteURL = (company_id, id) => `${endpoint}/${company_id}/file/${id}/delete/`;
export const companyFileDownloadURL = (company_id, id) => `${endpoint}/${company_id}/file/${id}/download/`;
// Company contacts
export const updateCompanyContactURL = (company_id, id) => `${endpoint}/${company_id}/contact/${id}/update/`;
export const companyContactsURL = (company_id) => `${endpoint}/${company_id}/contacts/`;
export const createCompanyContactURL = (company_id) => `${endpoint}/${company_id}/contact/create/`;
export const deleteCompanyContactURL = (company_id, id) => `${endpoint}/${company_id}/contact/${id}/delete/`;
export const companyContactDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contact/${id}/`;
export const companyContactRoleOptionsURL = (company_id) => `${endpoint}/${company_id}/contact-role-options/`;
// Company user roles
export const companyAccountRolesURL = company_id => `${endpoint}/${company_id}/account-roles/`;
// Countries
export const countriesURL = `${endpoint}/countries/`;
// Company invoicing details
export const invoicingDetailsURL = company_id => `${endpoint}/${company_id}/companies/invoicing/`;
export const updateInvoicingDetailsURL = (company_id, id) => `${endpoint}/${company_id}/companies/invoicing/${id}/update/`;
// Product
export const fetchProductsURL = company_id => `${endpoint}/${company_id}/products/`;
export const fetchProductGroupsURL = company_id => `${endpoint}/${company_id}/products/groups/`;
export const productDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/${id}/`;
export const updateProductDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/${id}/update/`;
export const createProductDetailsURL = (company_id) => `${endpoint}/${company_id}/products/create/`;
export const deleteProductDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/${id}/delete/`;
// Product Group
export const productGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/groups/${id}/`;
export const updateProductGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/groups/${id}/update/`;
export const createProductGroupDetailsURL = (company_id) => `${endpoint}/${company_id}/products/groups/create/`;
export const deleteProductGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/groups/${id}/delete/`;

export const fetchMeasureUnitsURL = (company_id, language_id) => `${endpoint}/${company_id}/product_measure_units/${language_id}/`;
export const fetchCustomerGroupsURL = company_id => `${endpoint}/${company_id}/customers/groups/`;
// Customer
export const customerDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/${id}/`;
export const updateCustomerDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/${id}/update/`;
export const createCustomerDetailsURL = (company_id) => `${endpoint}/${company_id}/customers/create/`;
export const deleteCustomerDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/${id}/delete/`;
export const fetchCustomersURL = company_id => `${endpoint}/${company_id}/customers/`;
// Customer Group
export const customerGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/groups/${id}/`;
export const updateCustomerGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/groups/${id}/update/`;
export const createCustomerGroupDetailsURL = (company_id) => `${endpoint}/${company_id}/customers/groups/create/`;
export const deleteCustomerGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/groups/${id}/delete/`;
// Customer invoicing details
export const updateCustomerInvoicingDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/invoicing/${id}/update/`;
export const customerInvoicingDetailsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/invoicing/`;
export const customerInvoicingMethodOptionsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/invoicing/invoicing-method-options/`;
// Customer addresses
export const updateCustomerAddressURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/addresses/${id}/update/`;
export const customerAddressesURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/addresses/`;
// Customer contacts
export const updateCustomerContactURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/contact/${id}/update/`;
export const customerContactsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/contacts/`;
export const createCustomerContactURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/contact/create/`;
export const deleteCustomerContactURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/contact/${id}/delete/`;
export const customerContactDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/contact/${id}/`;
export const customerContactRoleOptionsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/contact-role-options/`;
// Customer data collections
export const updateCustomerDataCollectionURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection/${id}/update/`;
export const customerDataCollectionsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collections/`;
export const createCustomerDataCollectionURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection/create/`;
export const deleteCustomerDataCollectionURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection/${id}/delete/`;
export const customerDataCollectionDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection/${id}/`;
// Data collection items
export const customerDataCollectionItemsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection-items/`;
export const customerDataCollectionItemDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection-item/${id}/`;
export const createCustomerDataCollectionItemURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection-item/create/`;
export const deleteCustomerDataCollectionItemURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection-item/${id}/delete/`;
// Customer files
export const customerFilesListURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/files/`;
export const customerFileDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/file/${id}/`;
export const customerFileDetailsUpdateURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/file/${id}/update/`;
export const customerFileUploadURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/file/upload/`;
export const customerFileDeleteURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/file/${id}/delete/`;
export const customerFileDownloadURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/file/${id}/download/`;
// Invoices
export const fetchOpenInvoicesURL = (company_id, status) => `${endpoint}/${company_id}/invoices/${status}/`;
export const updateInvoiceDetailsURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/update/`;
export const deleteInvoiceURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/delete/`;
export const creditInvoiceURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/credit/`;
export const invoiceDetailsURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/`;
export const createInvoiceURL = (company_id) => `${endpoint}/${company_id}/invoice/create/`;
// Invoice items
export const updateInvoiceItemURL = (company_id, invoice_id, id) => `${endpoint}/${company_id}/invoice/${invoice_id}/item/${id}/update/`;
export const createInvoiceItemURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/item/create/`;
export const deleteInvoiceItemURL = (company_id, invoice_id, id) => `${endpoint}/${company_id}/invoice/${invoice_id}/item/${id}/delete/`;
// Invoice PDF
export const fetchInvoicePdfURL = (company_id, invoice_id) => `${endpoint}/${company_id}/invoice-pdf/${invoice_id}/`;
export const saveAndSendInvoicePdfURL = (company_id, invoice_id) => `${endpoint}/${company_id}/invoice-pdf/${invoice_id}/save-and-send/`;
export const sendInvoiceReminderURL = (company_id, invoice_id) => `${endpoint}/${company_id}/invoice-pdf/${invoice_id}/send-reminder/`;
// Open invoices statistics
export const openInvoicesStatistics = (company_id) => `${endpoint}/${company_id}/open-invoices-statistics/`;
// Contract group
export const fetchContractGroupsURL = company_id => `${endpoint}/${company_id}/contract/groups/`;
export const contractGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/group/${id}/`;
export const updateContractGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/group/${id}/update/`;
export const createContractGroupDetailsURL = (company_id) => `${endpoint}/${company_id}/contract/group/create/`;
export const deleteContractGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/group/${id}/delete/`;
// Contract
export const deleteContractDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/${id}/delete/`;
export const contractDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/${id}/`;
export const updateContractDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/${id}/update/`;
export const createContractURL = (company_id) => `${endpoint}/${company_id}/contract/create/`;
// Contract items
export const updateContractItemURL = (company_id, contract_id, id) => `${endpoint}/${company_id}/contract/${contract_id}/item/${id}/update/`;
export const createContractItemURL = (company_id, id) => `${endpoint}/${company_id}/contract/${id}/item/create/`;
export const deleteContractItemURL = (company_id, contract_id, id) => `${endpoint}/${company_id}/contract/${contract_id}/item/${id}/delete/`;
// Messages
export const messagesListURL = company_id => `${endpoint}/${company_id}/messages/`;
export const messageAttachmentDownloadURL = (company_id, id) => `${endpoint}/${company_id}/message/attachment/${id}/download/`;
export const messageAttachmentUploadURL = (company_id, id) => `${endpoint}/${company_id}/message/${id}/attachment/upload/`;
export const messageCreateURL = company_id => `${endpoint}/${company_id}/message/create/`;
export const messageSendURL = (company_id, id) => `${endpoint}/${company_id}/message/${id}/send/`;
// Recepient groups
export const recepientGroupsURL = company_id => `${endpoint}/${company_id}/recepient-groups/`;
// Tasks/Company
export const companyTaskListURL = company_id => `${endpoint}/${company_id}/tasks/`;
export const companyTaskDetailsURL = (company_id, id) => `${endpoint}/${company_id}/task/${id}/`;
export const companyTaskFileDownloadURL = (company_id, id) => `${endpoint}/${company_id}/task/file/${id}/download/`;
export const companyTaskFileUploadURL = (company_id, id) => `${endpoint}/${company_id}/task/${id}/file/upload/`;
export const companyTaskCreateURL = company_id => `${endpoint}/${company_id}/task/create/`;
export const companyTaskUpdateURL = (company_id, id) => `${endpoint}/${company_id}/task/${id}/update/`;
export const companyTaskDeleteURL = (company_id, id) => `${endpoint}/${company_id}/task/${id}/delete/`;
export const companyTaskFileDeleteURL = (company_id, id) => `${endpoint}/${company_id}/task/file/${id}/delete/`;
// Tasks/Customer
export const customerTaskListURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/tasks/`;
export const customerTaskDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/${id}/`;
export const customerTaskFileDownloadURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/file/${id}/download/`;
export const customerTaskFileUploadURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/${id}/file/upload/`;
export const customerTaskCreateURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/task/create/`;
export const customerTaskUpdateURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/${id}/update/`;
export const customerTaskDeleteURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/${id}/delete/`;
export const customerTaskFileDeleteURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/file/${id}/delete/`;
// Reports
export const reportInvoicesByMonthURL = (company_id, start_date, end_date) => `${endpoint}/${company_id}/report/invoices/month/${start_date}/${end_date}/`;
export const reportCustomerInvoicesURL = (company_id, start_date, end_date) => `${endpoint}/${company_id}/report/invoices/customers/${start_date}/${end_date}/`;
export const reportProductInvoicesURL = (company_id, start_date, end_date) => `${endpoint}/${company_id}/report/invoices/products/${start_date}/${end_date}/`;
// Dashboard
export const fetchDashboardFinaStatisticsURL = (company_id, year, month) => `${endpoint}/${company_id}/dashboard-statistics/fina/${year}/${month}/`;
// Project group
export const fetchProjectGroupsURL = company_id => `${endpoint}/${company_id}/project/groups/`;
export const projectGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/group/${id}/`;
export const updateProjectGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/group/${id}/update/`;
export const createProjectGroupDetailsURL = (company_id) => `${endpoint}/${company_id}/project/group/create/`;
export const deleteProjectGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/group/${id}/delete/`;
// Project
export const deleteProjectDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/${id}/delete/`;
export const projectDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/${id}/`;
export const updateProjectDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/${id}/update/`;
export const updateProjectActivityURL = (company_id, id) => `${endpoint}/${company_id}/project/${id}/update/activity/`;
export const createProjectURL = (company_id) => `${endpoint}/${company_id}/project/create/`;
export const fetchProjectListURL = company_id => `${endpoint}/${company_id}/projects/`;
// Project member
export const updateProjectMemberURL = (company_id, project_id, id) => `${endpoint}/${company_id}/project/${project_id}/member/${id}/update/`;
export const createProjectMemberURL = (company_id, project_id) => `${endpoint}/${company_id}/project/${project_id}/member/create/`;
export const deleteProjectMemberURL = (company_id, project_id, id) => `${endpoint}/${company_id}/project/${project_id}/member/${id}/delete/`;
// Project product
export const updateProjectProductURL = (company_id, project_id, id) => `${endpoint}/${company_id}/project/${project_id}/product/${id}/update/`;
export const createProjectProductURL = (company_id, project_id) => `${endpoint}/${company_id}/project/${project_id}/product/create/`;
export const deleteProjectProductURL = (company_id, project_id, id) => `${endpoint}/${company_id}/project/${project_id}/product/${id}/delete/`;
// Project tasks
export const fetchProjectTasksURL = company_id => `${endpoint}/${company_id}/project-tasks/`;
export const fetchProjectTasksForProjectURL = (company_id, project_id) => `${endpoint}/${company_id}/project/${project_id}/tasks/`;
export const fetchProjectTasksWithConditionsURL = (company_id, customer_id, project_id, author_id, status_id, start_date, end_date) => `${endpoint}/${company_id}/project/tasks/${customer_id}/${project_id}/${author_id}/${status_id}/${start_date}/${end_date}/`;
export const projectTaskDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/task/${id}/`;
export const updateProjectTaskURL = (company_id, id) => `${endpoint}/${company_id}/project/task/${id}/update/`;
export const createProjectTaskURL = (company_id) => `${endpoint}/${company_id}/project/task/create/`;
export const deleteProjectTaskURL = (company_id, id) => `${endpoint}/${company_id}/project/task/${id}/delete/`;
// Project type options
export const projectTypeOptionsURL = company_id => `${endpoint}/${company_id}/project-type-options/`;
export const projectTaskTypeOptionsURL = company_id => `${endpoint}/${company_id}/project-task-type-options/`;

// Sensor
// Sensor group
export const fetchSensorGroupsURL = company_id => `${endpoint}/${company_id}/sensor/groups/`;
export const sensorGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/sensor/group/${id}/`;
export const updateSensorGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/sensor/group/${id}/update/`;
export const createSensorGroupDetailsURL = (company_id) => `${endpoint}/${company_id}/sensor/group/create/`;
export const deleteSensorGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/sensor/group/${id}/delete/`;
export const sensorGroupAlarmNotificationChangeURL = (company_id, id) => `${endpoint}/${company_id}/sensor/group-alarm-notifications/${id}/change/`;
// Sensor
export const deleteSensorDetailsURL = (company_id, id) => `${endpoint}/${company_id}/sensor/${id}/delete/`;
export const sensorDetailsURL = (company_id, id) => `${endpoint}/${company_id}/sensor/${id}/`;
export const updateSensorDetailsURL = (company_id, id) => `${endpoint}/${company_id}/sensor/${id}/update/`;
export const sensorsWithLocationsURL = (company_id) => `${endpoint}/${company_id}/sensors/locations/`;

// export const updateProjectActivityURL = (company_id, id) => `${endpoint}/${company_id}/sensor/${id}/update/activity/`;
export const createSensorURL = (company_id) => `${endpoint}/${company_id}/sensor/create/`;
export const fetchSensorListURL = company_id => `${endpoint}/${company_id}/sensors/`;
// Sensor status 
export const fetchSensorStatusVariablesURL = (company_id, sensor_id) => `${endpoint}/${company_id}/sensor/${sensor_id}/status-variables/`;
// Sensor values
export const fetchSensorValuesURL = (company_id, sensor_id, start_date, end_date) => `${endpoint}/${company_id}/sensor/${sensor_id}/values/${start_date}/${end_date}/`;
export const fetchSensorValuesForVariablesURL = (company_id, sensor_id, start_date, end_date, variables) => `${endpoint}/${company_id}/sensor/${sensor_id}/values/${start_date}/${end_date}/${variables}/`;
export const fetchSensorValuesForVariablesAvgURL = (company_id, sensor_id, start_date, end_date, time_frame, variables) => `${endpoint}/${company_id}/sensor/${sensor_id}/values-avg/${start_date}/${end_date}/${time_frame}/${variables}/`;
export const fetchSensorValuesForReportsURL = (company_id, sensor_id, start_date, end_date) => `${endpoint}/${company_id}/sensor/${sensor_id}/report-values/${start_date}/${end_date}/`;
export const deleteSensorStatusValueURL = (company_id, id) => `${endpoint}/${company_id}/sensor-value/${id}/delete/`;

// Sensor models
export const fetchSensorModels = (company_id) => `${endpoint}/${company_id}/sensor-models/`;
// Sensor status variable settings
export const sensorStatusVariableDetailsURL = (company_id,  id) => `${endpoint}/${company_id}/sensor/status-variable-details/${id}/`;
export const updateSensorStatusVariableDetailsURL = (company_id, id) => `${endpoint}/${company_id}/sensor/status-variable-details/${id}/update/`;
// Sensor status variable parameters
export const updateSensorStatusVariableParametersURL = (company_id) => `${endpoint}/${company_id}/update-status-variable-parameters/`;
// Sensor battery change
export const createSensorBatteryChangeURL = (company_id) => `${endpoint}/${company_id}/sensor/battery-change/create/`;
export const deleteBatteryChangeURL = (company_id, id) => `${endpoint}/${company_id}/sensor/battery-change/${id}/delete/`;
// Sensor alarm
export const updateSensorAlarmURL = (company_id, id) => `${endpoint}/${company_id}/sensor/alarm/${id}/update/`;
export const fetchSensorAlarmsURL = (company_id, sensor_id, start_date, end_date) => `${endpoint}/${company_id}/sensor/${sensor_id}/alarms/${start_date}/${end_date}/`;
// Sensor consumption statistics
export const sensorConsumptionStatsURL = (company_id,  id, measurement_unit) => `${endpoint}/${company_id}/sensor/${id}/consumption-statistics/${measurement_unit}/`;
export const fetchSensorConsumptionReportURL = (company_id, sensor_id, measurement_unit, report_type, search_date, search_year, search_month, search_week, year_range_start, year_range_end) => `${endpoint}/${company_id}/sensor-consumption-report/${sensor_id}/${measurement_unit}/${report_type}/${search_date}/${search_year}/${search_month}/${search_week}/${year_range_start}/${year_range_end}/`;
// Sensor update measure interval
export const updateSensorMeasureIntervalURL = (company_id, id) => `${endpoint}/${company_id}/sensor/${id}/update-measure-interval/`;
export const sendDownlinkSettingsURL = (company_id, id) => `${endpoint}/${company_id}/sensor/${id}/send-downlink-settings/`;
export const sensorDownlinkDetailsURL = (company_id, id) => `${endpoint}/${company_id}/sensor/${id}/get-downlink-settings/`;

// Sensor report to CSV
export const sensorReportToCsvURL = company_id => `${endpoint}/${company_id}/sensor-report-to-csv/`;

export const sensorMoveToOtherCompanyURL = (company_id, dest_company_id) => `${endpoint}/${company_id}/sensor-move-to-other-company/${dest_company_id}/`;

// LoRa test
export const postLoraTestURL = () => `${endpoint}/lora/uplink/`;
// Export to CSV
export const exportToCsvURL = company_id => `${endpoint}/${company_id}/export-csv/`;
