// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 21.3.2020 TS Initial version
// 25.3.2020 TS Changed Chart as default
// 15.6.2020 TS Mobile mode titles. Export to CSV.
// 16.6.2020 TS Added charts for Battery Voltage and "extra" WISE-2410 variables. Also chart choice select now added.
// 24.6.2020 TS Added Decentlab
// 27.6.2020 TS Added avg, highest and lowest values to each dataset in charts
// 15.9.2020 TS Added Elsys ERS
// 19.1.2021 TS Added Elsys ELT 2
// 26.1.2021 TS Added Wastecontrol
// 31.1.2021 TS Added selection for data accuracy
// 8.2.2021 TS Value decimal separator from . to , in CSV export
// 27.2.2021 TS Report value check to Decentlab variables in handleVariableOptions
// 4.3.2021 TS Added Netvox RB11E and R313A
// 7.3.2021 TS Added table sort by column
// 13.3.2021 TS Added Wastecontrol LoRa
// 19.3.2021 TS Added Caproc water level probe
// 14.4.2021 TS Added Dingtek
// 07.05.2021 TS Added Caproc CMF-T
// 18.7.2021 TS Added AQUA-H21-OPTOD
// 2.8.2021 TS Added PR-26D and PAA-26X
// 20.8.2021 TS Fixes to PAA-26X
// 14.9.2021 TS Added DECENTLAB-DL-PR26
// 18.9.2021 TS Added CAPROC-RANGE
// 27.9.2021 TS Adde CAPROC-RADAR, CAPROC-IC 
// 15.10.2021 TS Added internal temp and humi for CMF-DS18B20
// 22.10.2021 TS Added AQUA-H21 and AQUA-OPTOD
// 25.2.2022 TS TH-PROBE
// 08.06.2022 TS Added AQUA-8010 in to reports.
// 11.06.2022 TS Added Keller PAA-36X-CTD
// 10.7.2022 TS T-PROBE and OC-TH
// 14.7.2022 TS Corrected/calibrated temp and humi for TH-PROBE
// 24.7.2022 TS Aqualabo PHEHT, C4E, NTU and STACSENSE
// 25.7.2022 TS RADAR-FLOW
// 23.8.2022 TS Scale
// 19.10.2022 TS Ignore "" values for reports
// 17.1.2023 TS Sensor report to CSV. Full and display values options.
// 26.5.2023 TS Decentlab DL-PAR
// 30.6.2023 TS Added sensor value delete option for Superuser and Admin.
// 21.8.2023 TS Added IC-DP
// 13.9.2023 TS Added mass flow differential pressure to IC-DP
// 27.10.2023 TS Added HAN reader
// 22.11.2023 TS Added RAIN
// 13.1.2024 TS 1.0.9 Added WCIA
// 18.6.2024 TS Added serial number and device id into csv export file name

import React from "react";
import _ from 'lodash'
import { Message, Header, Table, Segment, Dimmer, Loader, Image,  Grid, Menu, Radio, Container, Button, Responsive, Select, Popup, Icon, Confirm } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate, exportToCsv, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { sensorDetailsURL, fetchSensorValuesForVariablesAvgURL, sensorReportToCsvURL, deleteSensorStatusValueURL } from "../../constants";
import {  Bar, Line  } from 'react-chartjs-2';



class SensorReportsV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            report: null,
            sensor: null,
            activeMenuItem: this.props.t("Chart"),
            error: null,
            loading: false,
            search_start_date: null,
            search_end_date: null,
            search: "",
            fetch: false,
            all_velocity_RMS_data: {},
            all_acceleration_peak_data: {},
            all_acceleration_RMS_data: {},
            all_kurtosis_data: {},
            all_skewness_data: {},
            all_crest_factor_data: {},
            all_deviation_data: {},
            all_displacement_data: {},
            temperature_data: {},
            temperature_external_data: {},
            humidity_external_data: {},
            battery_data: {},
            distance_data: {},
            distance_deviation_data: {},
            filling_percentage_data: {},
            filling_volume_data: {},
            x_axis_velocity_RMS_data: {},
            y_axis_velocity_RMS_data: {},
            z_axis_velocity_RMS_data: {},
            humidity_data: {},
            wcia_data: {},
            wcia_text: null,
            pressure_data: {},
            light_data: {},
            waterleak_data: {},
            angle_data: {},
            angle_text: null,
            motion_data: {},
            door_closed_data: {},
            temperature_text: null,
            temperature_external_text: null,
            humidity_external_text: null,
            distance_text: null,
            filling_percentage_text: null,
            filling_volume_text: null,
            distance_deviation_text: "",
            in_trip_data: {},
            speed_data: {},
            water_level_data: {},
            water_level_text: null,
            water_temp_data: {},
            water_temp_text: null,
            water_pressure_data: {},
            water_pressure_text: null,
            water_pressure_abs_data: {},
            water_pressure_abs_text: null,
            water_comp_conductivity_data: {},
            water_comp_conductivity_text: null,
            water_raw_conductivity_data: {},
            water_raw_conductivity_text: null,
            sensor_depth_data: {},
            sensor_depth_text: null,
            h21_temp_data: {},
            h21_temp_text: null,
            h21_conductivity_data: {},
            h21_conductivity_text: null,
            h21_sensor_depth_data: {},
            h21_sensor_depth_text: null,
            screen_size: "normal",
            variable_options: [],
            variable_choices: [],
            sensor: null,
            tableData: [],
            offset: 0,
            limit: 30,
            startup_counter: 0,
            is_mounted: false,
            accuracy_options: [],
            accuracy_choice: "hour",
            column: null,
            direction: null,
            voc_index_data: [],
            voc_index_text: null,
            scd4x_temperature_data: [],
            scd4x_temperature_text: null,
            scd4x_humidity_data: [],
            scd4x_humidity_text: null,
            scd4x_wcia_data: [],
            scd4x_wcia_text: null,
            sfa30_temperature_data: [],
            sfa30_temperature_text: null,
            sfa30_humidity_data: [],
            sfa30_humidity_text: null,
            sfa30_wcia_data: [],
            sfa30_wcia_text: null,
            co2_data: [],
            co2_text: null,
            hcho_data: [],
            hcho_text: null,
            temperature_data_2: [],
            temperature_text_2: null,
            humidity_data_2: [],
            humidity_text_2: null,
            wcia_data_2: [],
            wcia_text_2: null,
            temperature_data_3: [],
            temperature_text_3: null,
            humidity_data_3: [],
            humidity_text_3: null,
            wcia_data_3: [],
            wcia_text_3: null,
            corrected_temperature_data: [],
            corrected_temperature_data_2: [],
            corrected_temperature_data_3: [],
            corrected_humidity_data: [],
            corrected_humidity_data_2: [],
            corrected_humidity_data_3: [],
            corrected_wcia_data: [],
            corrected_wcia_data_2: [],
            corrected_wcia_data_3: [],
            corrected_temperature_text: null,
            corrected_temperature_text_2: null,
            corrected_temperature_text_3: null,
            corrected_humidity_text: null,
            corrected_humidity_text_2: null,
            corrected_humidity_text_3: null,
            corrected_wcia_text: null,
            corrected_wcia_text_2: null,
            corrected_wcia_text_3: null,
            optod_temp_data: {},
            optod_temp_text: null,
            optod_o2_sat_data: {},
            optod_o2_sat_text: null,
            optod_o2_mgl_data: {},
            optod_o2_mgl_text: null,
            pheht_temp_data: {},
            pheht_temp_text: null,
            pheht_ph_data: {},
            pheht_ph_text: null,
            pheht_redox_data: {},
            pheht_redox_text: null,
            pheht_ph_mv_data: {},
            pheht_ph_mv_text: null,
            c4e_temp_data: {},
            c4e_temp_text: null,
            c4e_conductivity_data: {},
            c4e_conductivity_text: null,
            c4e_salinity_data: {},
            c4e_salinity_text: null,
            c4e_tot_dissolved_solids_data: {},
            c4e_tot_dissolved_solids_text: null,
            ntu_temp_data: {},
            ntu_temp_text: null,
            ntu_turbidity_ntu_data: {},
            ntu_turbidity_ntu_text: null,
            ntu_turbidity_fnu_data: {},
            ntu_turbidity_fnu_text: null,
            ntu_tu_turbidity_data: {},
            ntu_tu_turbidity_text: null,
            stacsense_temp_data: {},
            stacsense_temp_text: null,
            stacsense_cas254_data: {},
            stacsense_cas254_text: null,
            stacsense_cod_data: {},
            stacsense_cod_text: null,
            stacsense_bod_data: {},
            stacsense_bod_text: null,
            stacsense_toc_data: {},
            stacsense_toc_text: null,
            v_notch_water_level_data: {},
            v_notch_water_level_text: "",
            v_notch_water_flow_data: {},
            v_notch_water_flow_text: "",
            scale_weight_data: {},
            scale_weight_text: null,
            scale_amount_data: {},
            scale_amount_text: null,
            scale_filling_percentage_data: {},
            scale_filling_percentage_text: null,
            radiation_data: {},
            radiation_text: null,
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: "",
            value_delete_ok: false,
            dp_pressure_data: {},
            dp_pressure_text: null,
            dp_pressure_mass_flow_data: {},
            dp_pressure_mass_flow_data_text: null,
            dp_temp_data: {},
            dp_temp_text: null,
            _1_0_1_8_0_data: {},
            _1_0_1_8_0_text: null,
            _1_0_2_8_0_data: {},
            _1_0_2_8_0_text: null,
            _1_0_3_8_0_data: {},
            _1_0_3_8_0_text: null,
            _1_0_4_8_0_data: {},
            _1_0_4_8_0_text: null,
            _1_0_1_7_0_data: {},
            _1_0_1_7_0_text: null,
            _1_0_2_7_0_data: {},
            _1_0_2_7_0_text: null,
            _1_0_3_7_0_data: {},
            _1_0_3_7_0_text: null,
            _1_0_4_7_0_data: {},
            _1_0_4_7_0_text: null,
            _1_0_21_7_0_data: {},
            _1_0_21_7_0_text: null,
            _1_0_22_7_0_data: {},
            _1_0_22_7_0_text: null,
            _1_0_41_7_0_data: {},
            _1_0_41_7_0_text: null,
            _1_0_42_7_0_data: {},
            _1_0_42_7_0_text: null,
            _1_0_61_7_0_data: {},
            _1_0_61_7_0_text: null,
            _1_0_62_7_0_data: {},
            _1_0_62_7_0_text: null,
            _1_0_23_7_0_data: {},
            _1_0_23_7_0_text: null,
            _1_0_24_7_0_data: {},
            _1_0_24_7_0_text: null,
            _1_0_43_7_0_data: {},
            _1_0_43_7_0_text: null,
            _1_0_44_7_0_data: {},
            _1_0_44_7_0_text: null,
            _1_0_63_7_0_data: {},
            _1_0_63_7_0_text: null,
            _1_0_64_7_0_data: {},
            _1_0_64_7_0_text: null,
            _1_0_32_7_0_data: {},
            _1_0_32_7_0_text: null,
            _1_0_52_7_0_data: {},
            _1_0_52_7_0_text: null,
            _1_0_72_7_0_data: {},
            _1_0_72_7_0_text: null,
            _1_0_31_7_0_data: {},
            _1_0_31_7_0_text: null,
            _1_0_51_7_0_data: {},
            _1_0_51_7_0_text: null,
            _1_0_71_7_0_data: {},
            _1_0_71_7_0_text: null,
            rain_tip_counter_data: {},
            rain_tip_counter_text: null,
            rain_tip_counter_cumulative_data: {},
            rain_tip_counter_cumulative_text: null,
            rain_precipitation_data: {},
            rain_precipitation_text: null,
            rain_precipitation_cumulative_data: {},
            rain_precipitation_cumulative_text: null
        };
        window.onscroll = () => {
            if (this.state.activeMenuItem === this.props.t("Table")) {
                if (this.state.error || this.state.loading || (this.state.report && this.state.report.length < this.state.offset)) return;
                if (
                    document.documentElement.scrollHeight -
                    document.documentElement.scrollTop ===
                    document.documentElement.clientHeight
                ) {
                    this.setState({
                        offset: this.state.offset + this.state.limit
                    });
                }
            }
        };
    }

    componentDidMount() {
        this.props.setActivity();
        let init_start_date = new Date();
        // init_start_date.setDate(init_start_date.getDate() - 1);
        this.handleStartDateChange(init_start_date);
        this.handleFetchSensor();
        this.handleEndDateChange(new Date());
        this.setState({ fetch: true});
        this.handleSetAccuracyOptions();
    }

    handleSetAccuracyOptions = () => {
        const { t } = this.props;
        const options = [
            {
                key: "all",
                text: t("All"),
                value: "all",
            },
            {
                key: "hour",
                text: t("Hour avg"),
                value: "hour"
            },
            {
                key: "day",
                text: t("Day avg"),
                value: "day"
            }

        ];
        this.setState({
            accuracy_options: options
        });
    };

    handleVariableOptions = sensor => {
        const { t } = this.props;
        let options = [];
        let default_choices = this.state.variable_choices;
        if (sensor !== null) {
            let options_value = null;
            let i = 0;
            
            for (i in sensor.sensorstatusvariables) {
                if (!sensor.sensorstatusvariables[i].is_active) continue;
                if (sensor.model_name === "WISE-2410-EA" && sensor.sensorstatusvariables[i].name !== "PowerSrc" && sensor.sensorstatusvariables[i].name !== "Time") {
                    options_value = null;
                    // Get options only once. Not for each axis
                    if (sensor.sensorstatusvariables[i].group === "Accelerometer" && sensor.sensorstatusvariables[i].sub_group === "X-Axis") {
                        options_value = {
                            key: sensor.sensorstatusvariables[i].name, 
                            text: t(sensor.sensorstatusvariables[i].display_name), 
                            value: sensor.sensorstatusvariables[i].name
                        };
                    } else if (sensor.sensorstatusvariables[i].group !== "Accelerometer") {
                        options_value = {
                            key: sensor.sensorstatusvariables[i].name, 
                            text: t(sensor.sensorstatusvariables[i].display_name), 
                            value: sensor.sensorstatusvariables[i].name
                        };
                    }
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                } else if (sensor.model_name === "DECENTLAB-DL-MBX" && sensor.sensorstatusvariables[i].is_report_value) {
                    options_value = null;
                    if (sensor.sensorstatusvariables[i].group === "Report") {
                        options_value = {
                            key: sensor.sensorstatusvariables[i].name, 
                            text: t(sensor.sensorstatusvariables[i].display_name), 
                            value: sensor.sensorstatusvariables[i].name
                        };    
                    } else {
                        options_value = {
                            key: sensor.sensorstatusvariables[i].group, 
                            text: t(sensor.sensorstatusvariables[i].display_name), 
                            value: sensor.sensorstatusvariables[i].group
                        };
                    }
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                } else if (sensor.model_name === "DIGITAL-MATTER-YABBY" && sensor.sensorstatusvariables[i].group === "position" && sensor.sensorstatusvariables[i].is_report_value) {
                    options_value = null;
                    options_value = {
                        key: sensor.sensorstatusvariables[i].name, 
                        text: t(sensor.sensorstatusvariables[i].display_name), 
                        value: sensor.sensorstatusvariables[i].name
                    };
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                    let init_start_date = new Date();
                    init_start_date.setDate(init_start_date.getDate() - 7);
                    this.handleStartDateChange(init_start_date);
                } else if ((sensor.model_name === "ELSYS-ERS" || sensor.model_name === "ELSYS-EMS" || sensor.model_name === "ELSYS-ELT-2") && (sensor.sensorstatusvariables[i].group === "status" || sensor.sensorstatusvariables[i].group === "Report") && sensor.sensorstatusvariables[i].is_report_value) {
                    options_value = null;
                    options_value = {
                        key: sensor.sensorstatusvariables[i].name, 
                        text: t(sensor.sensorstatusvariables[i].display_name), 
                        value: sensor.sensorstatusvariables[i].name
                    };
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                } else if (sensor.model_name.startsWith("WASTECONTROL") && sensor.sensorstatusvariables[i].is_report_value) {
                    options_value = null;
                    options_value = {
                        key: sensor.sensorstatusvariables[i].name, 
                        text: t(sensor.sensorstatusvariables[i].display_name), 
                        value: sensor.sensorstatusvariables[i].name
                    };
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                    let init_start_date = new Date();
                    init_start_date.setDate(init_start_date.getDate() - 7);
                    this.handleStartDateChange(init_start_date);
                } else if (sensor.model_name.startsWith("NETVOX") && sensor.sensorstatusvariables[i].is_report_value) {
                    options_value = null;
                    options_value = {
                        key: sensor.sensorstatusvariables[i].name, 
                        text: t(sensor.sensorstatusvariables[i].display_name), 
                        value: sensor.sensorstatusvariables[i].name
                    };
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                    let init_start_date = new Date();
                    init_start_date.setDate(init_start_date.getDate() - 7);
                    this.handleStartDateChange(init_start_date);
                } else if (sensor.model_name.startsWith("CAPROC") && sensor.sensorstatusvariables[i].is_report_value) {
                    options_value = null;
                    options_value = {
                        key: sensor.sensorstatusvariables[i].name, 
                        text: t(sensor.sensorstatusvariables[i].display_name), 
                        value: sensor.sensorstatusvariables[i].name
                    };
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                    let init_start_date = new Date();
                    init_start_date.setDate(init_start_date.getDate() - 7);
                    this.handleStartDateChange(init_start_date);
                } else if (sensor.sensorstatusvariables[i].is_report_value) {
                    options_value = null;
                    options_value = {
                        key: sensor.sensorstatusvariables[i].name, 
                        text: t(sensor.sensorstatusvariables[i].display_name), 
                        value: sensor.sensorstatusvariables[i].name
                    };
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                    let init_start_date = new Date();
                    init_start_date.setDate(init_start_date.getDate() - 7);
                    this.handleStartDateChange(init_start_date);
                }
                if (sensor.sensorstatusvariables[i].is_report_value && sensor.sensorstatusvariables[i].is_default_report_value) {
                    default_choices.push(sensor.sensorstatusvariables[i].name);
                }
            }  
            // Add also distance deviation
            // if (sensor.model_name === "DECENTLAB-DL-MBX") {
                // options_value = null;
                // options_value = {
                //     key: "Distance deviation", 
                //     text: t("Distance deviation (mm)"), 
                //     value: "Distance deviation"
                // };
                // if (options_value !== null && !options.includes(options_value)) {
                //     options.push(options_value);
                // }
            // }
            if (sensor.model_name === "WISE-2410-EA") {
                // Set default chart to start with
                let default_choices = [];
                default_choices.push("OAVelocity");
                default_choices.push("Peakmg");
                default_choices.push("RMSmg");
                default_choices.push("SenVal");
                this.setState({
                    variable_choices: default_choices
                });
            }         
        }
        this.setState({
            variable_options: options,
            variable_choices: default_choices
        });
    };

    handleRadioChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    radioButtons = () => {
        return (
            <React.Fragment>
                <Radio
                    name="screen_size"
                    label={this.props.t("Normal")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "normal"}
                    value="normal"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Wide")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "wide"}
                    value="wide"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Full screen")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "full"}
                    value="full"
                />  
            </React.Fragment>
        );
    };

    handleMenuItemClick = (e, { name }) => {
        this.setState({ activeMenuItem: name });
        const { t } = this.props;
        if (name == t("Chart")) {
            this.handleLineChartData(this.state.report);
        } else if (name == t("Table")) {
            this.setState({
                offset: 0
            });
        }
    };

    handleFetchReport = () => {
        this.setState({
            loading: true
        });
        const { search_start_date, search_end_date, variable_choices, variable_options, accuracy_choice } = this.state;
        const { activeCompanyId, t } = this.props;
        const { sensor_id } = this.props.match.params;
        let search_variables = [];
        if (variable_choices.length === 0) {
            if (variable_options.length > 0) {
                let i = 0;
                for (i in variable_options) {
                    search_variables.push(variable_options[i].value);
                }
            } 
        } else {
            search_variables = variable_choices;
        }
        if (search_start_date === null || search_end_date === null || search_variables.length === 0 ) {
            this.setState({
                loading: false
            });
            return;
        } 
        let _accuracy_choice = accuracy_choice;
        // Allow all data search only if the search range is less than 24 hours
        if ((new Date(search_end_date) - new Date(search_start_date)) / 1000 / 3600 > 24) {
            if (accuracy_choice == "all") {
                this.setState({
                    accuracy_choice: "hour"
                })
                _accuracy_choice = "hour";
            }
        }

        authAxios
            .get(fetchSensorValuesForVariablesAvgURL(activeCompanyId, sensor_id, search_start_date, search_end_date, _accuracy_choice, search_variables))
            .then(res => {
                this.setState({
                    report: res.data,
                    error: null,
                    loading: false,
                    value_delete_ok: _accuracy_choice === "all" ? true : false
                });
                if (this.state.activeMenuItem == t("Chart")) {
                    this.handleLineChartData(res.data);
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    // handleFetchFullCSVReport = () => {
    //     this.setState({
    //         loading: true
    //     });
    //     const { search_start_date, search_end_date, variable_choices, variable_options, accuracy_choice } = this.state;
    //     const { activeCompanyId, t } = this.props;
    //     const { sensor_id } = this.props.match.params;
    //     let search_variables = [];
    //     if (variable_choices.length === 0) {
    //         if (variable_options.length > 0) {
    //             let i = 0;
    //             for (i in variable_options) {
    //                 search_variables.push(variable_options[i].value);
    //             }
    //         } 
    //     } else {
    //         search_variables = variable_choices;
    //     }
    //     if (search_start_date === null || search_end_date === null || search_variables.length === 0 ) {
    //         this.setState({
    //             loading: false
    //         });
    //         return;
    //     } 
    //     let _accuracy_choice = accuracy_choice;

    //     authAxios
    //         .get(fetchSensorValuesForVariablesAvgURL(activeCompanyId, sensor_id, search_start_date, search_end_date, _accuracy_choice, search_variables))
    //         .then(res => {
    //             this.handleExportToCSV(res.data);
    //             this.setState({
    //                 error: null,
    //                 loading: false
    //             });
    //         })
    //         .catch(err => {
    //             this.setState({
    //                 loading: false,
    //                 error: err
    //             });
    //         });
    // };

    handleFetchFullCSVReport = () => {
        this.setState({
            loading: true
        });
        const { search_start_date, search_end_date, variable_choices, variable_options, accuracy_choice, sensor } = this.state;
        const { activeCompanyId, t, i18n } = this.props;
        const { sensor_id } = this.props.match.params;
        let search_variables = [];
        if (variable_choices.length === 0) {
            if (variable_options.length > 0) {
                let i = 0;
                for (i in variable_options) {
                    search_variables.push(variable_options[i].value);
                }
            } 
        } else {
            search_variables = variable_choices;
        }
        if (search_start_date === null || search_end_date === null || search_variables.length === 0 ) {
            this.setState({
                loading: false
            });
            return;
        } 
        let _accuracy_choice = accuracy_choice;
        
        let file_name = "export";
        
        if (sensor !== null) {
            if (sensor.device_eui === sensor.device_serial_number) {
                file_name = sensor.device_eui;
            } else {
                file_name = sensor.device_serial_number + "_" + sensor.device_eui;
            }
        }

        let report_language = i18n.language;
        authAxios
            .put(sensorReportToCsvURL(activeCompanyId), { 
                responseType: 'blob',
                sensor_id: sensor_id,
                search_start_date: search_start_date,
                search_end_date: search_end_date,
                accuracy_choice: _accuracy_choice,
                search_variables: search_variables,
                report_language: report_language
            })
            .then(res => {
                //Create a Blob from the data stream
                const res_file = new Blob(
                    ["\uFEFF"+res.data]); // Add extra characters for CSV
                //Build a URL from the file
                const fileURL = URL.createObjectURL(res_file);
                // Get the file name from the URL
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', file_name + ".csv"); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };


    handleFetchSensor = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    loading: false,
                    error: null
                });
                this.handleVariableOptions(res.data);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleLineChartData = report => {
        this.setState({
            loading: true
        });
        const { t } = this.props;
        const { sensor } = this.state;
        const default_dataset_red = {
            label: "mm/s",
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            data: [],
            fill: false
        };
        const default_dataset_blue = {
            label: "",
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            data: [],
            fill: false
        };
        const default_dataset_green = {
            label: "",
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            data: [],
            fill: false
        };

        const default_bar_dataset_blue = {
            label: "",
            backgroundColor: 'rgb(78, 150, 220)',
            borderColor: 'rgb(255, 255, 255)',
            data: []
        };

        if (sensor.model_name === "WISE-2410-EA") {
            // let {
            //     all_velocity_RMS_data,
            //     all_acceleration_RMS_data,
            //     all_acceleration_peak_data,
            //     temperature_data,
            //     x_axis_velocity_RMS_data,
            //     y_axis_velocity_RMS_data,
            //     z_axis_velocity_RMS_data,
            //     all_kurtosis_data,
            //     all_skewness_data,
            //     all_crest_factor_data,
            //     all_deviation_data,
            //     all_displacement_data,
            //     battery_data
            // } = this.state;

            let _all_velocity_RMS_data = {};
            _all_velocity_RMS_data.labels = [];
            _all_velocity_RMS_data.datasets = [];

            let _all_acceleration_RMS_data = {};
            _all_acceleration_RMS_data.labels = [];
            _all_acceleration_RMS_data.datasets = [];

            let _all_acceleration_peak_data = {};
            _all_acceleration_peak_data.labels = [];
            _all_acceleration_peak_data.datasets = [];

            let _battery_data = {};
            _battery_data.labels = [];
            _battery_data.datasets = [];

            let _temperature_data = {};
            _temperature_data.labels = [];
            _temperature_data.datasets = [];

            let _all_kurtosis_data = {};
            _all_kurtosis_data.labels = [];
            _all_kurtosis_data.datasets = [];

            let _all_crest_factor_data = {};
            _all_crest_factor_data.labels = [];
            _all_crest_factor_data.datasets = [];

            let _all_skewness_data = {};
            _all_skewness_data.labels = [];
            _all_skewness_data.datasets = [];

            let _all_deviation_data = {};
            _all_deviation_data.labels = [];
            _all_deviation_data.datasets = [];

            let _all_displacement_data = {};
            _all_displacement_data.labels = [];
            _all_displacement_data.datasets = [];

            // x_axis_velocity_RMS_data.labels = [];
            // x_axis_velocity_RMS_data.datasets = [];
            // y_axis_velocity_RMS_data.labels = [];
            // y_axis_velocity_RMS_data.datasets = [];
            // z_axis_velocity_RMS_data.labels = [];
            // z_axis_velocity_RMS_data.datasets = [];

            // Velocity RMS
            let x_axis_velocity_RMS_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Velocity RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_velocity_RMS_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Velocity RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_velocity_RMS_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Velocity RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Acceleration RMS
            let x_axis_acceleration_RMS_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Acceleration RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_acceleration_RMS_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Acceleration RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_acceleration_RMS_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Acceleration RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Acceleration Peak
            let x_axis_acceleration_peak_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Acceleration Peak"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_acceleration_peak_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Acceleration Peak"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_acceleration_peak_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Acceleration Peak"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Kurtosis
            let x_axis_kurtosis_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Kurtosis"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_kurtosis_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Kurtosis"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_kurtosis_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Kurtosis"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Skewness
            let x_axis_skewness_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Skewness"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_skewness_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Skewness"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_skewness_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Skewness"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Crest factor
            let x_axis_crest_factor_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Crest factor"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_crest_factor_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Crest factor"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_crest_factor_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Crest factor"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Deviation
            let x_axis_deviation_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Deviation"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_deviation_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Deviation"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_deviation_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Deviation"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Displacement
            let x_axis_displacement_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Displacement"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_displacement_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Displacement"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_displacement_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Displacement"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
        
            // Temperature
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Surface temperature"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);
                // Velocity RMS
                if (report[i].name === "OAVelocity") {
                    if (!_all_velocity_RMS_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_velocity_RMS_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        // x_axis_velocity_RMS_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        x_axis_velocity_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_velocity_RMS_dataset.highest === null || (parseFloat(x_axis_velocity_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_velocity_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_velocity_RMS_dataset.lowest === null || (parseFloat(x_axis_velocity_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_velocity_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_velocity_RMS_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_velocity_RMS_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        // y_axis_velocity_RMS_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        y_axis_velocity_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_velocity_RMS_dataset.highest === null || (parseFloat(y_axis_velocity_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_velocity_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_velocity_RMS_dataset.lowest === null || (parseFloat(y_axis_velocity_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_velocity_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_velocity_RMS_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_velocity_RMS_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        // z_axis_velocity_RMS_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        z_axis_velocity_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_velocity_RMS_dataset.highest === null || (parseFloat(z_axis_velocity_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_velocity_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_velocity_RMS_dataset.lowest === null || (parseFloat(z_axis_velocity_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_velocity_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_velocity_RMS_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_velocity_RMS_dataset.pcs += 1;
                    }
                // Acceleration Peak
                } else if (report[i].name === "Peakmg") {
                    if (!_all_acceleration_peak_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_acceleration_peak_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_acceleration_peak_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_acceleration_peak_dataset.highest === null || (parseFloat(x_axis_acceleration_peak_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_acceleration_peak_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_acceleration_peak_dataset.lowest === null || (parseFloat(x_axis_acceleration_peak_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_acceleration_peak_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_acceleration_peak_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_acceleration_peak_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_acceleration_peak_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_acceleration_peak_dataset.highest === null || (parseFloat(y_axis_acceleration_peak_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_acceleration_peak_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_acceleration_peak_dataset.lowest === null || (parseFloat(y_axis_acceleration_peak_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_acceleration_peak_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_acceleration_peak_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_acceleration_peak_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_acceleration_peak_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_acceleration_peak_dataset.highest === null || (parseFloat(z_axis_acceleration_peak_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_acceleration_peak_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_acceleration_peak_dataset.lowest === null || (parseFloat(z_axis_acceleration_peak_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_acceleration_peak_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_acceleration_peak_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_acceleration_peak_dataset.pcs += 1;
                    }
                // Acceleration RMS
                } else if (report[i].name === "RMSmg") {
                    if (!_all_acceleration_RMS_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_acceleration_RMS_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_acceleration_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_acceleration_RMS_dataset.highest === null || (parseFloat(x_axis_acceleration_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_acceleration_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_acceleration_RMS_dataset.lowest === null || (parseFloat(x_axis_acceleration_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_acceleration_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_acceleration_RMS_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_acceleration_RMS_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_acceleration_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_acceleration_RMS_dataset.highest === null || (parseFloat(y_axis_acceleration_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_acceleration_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_acceleration_RMS_dataset.lowest === null || (parseFloat(y_axis_acceleration_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_acceleration_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_acceleration_RMS_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_acceleration_RMS_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_acceleration_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_acceleration_RMS_dataset.highest === null || (parseFloat(z_axis_acceleration_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_acceleration_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_acceleration_RMS_dataset.lowest === null || (parseFloat(z_axis_acceleration_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_acceleration_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_acceleration_RMS_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_acceleration_RMS_dataset.pcs += 1;
                    }
                // Kurtosis
                } else if (report[i].name === "Kurtosis") {
                    if (!_all_kurtosis_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_kurtosis_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_kurtosis_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_kurtosis_dataset.highest === null || (parseFloat(x_axis_kurtosis_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_kurtosis_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_kurtosis_dataset.lowest === null || (parseFloat(x_axis_kurtosis_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_kurtosis_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_kurtosis_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_kurtosis_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_kurtosis_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_kurtosis_dataset.highest === null || (parseFloat(y_axis_kurtosis_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_kurtosis_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_kurtosis_dataset.lowest === null || (parseFloat(y_axis_kurtosis_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_kurtosis_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_kurtosis_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_kurtosis_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_kurtosis_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_kurtosis_dataset.highest === null || (parseFloat(z_axis_kurtosis_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_kurtosis_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_kurtosis_dataset.lowest === null || (parseFloat(z_axis_kurtosis_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_kurtosis_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_kurtosis_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_kurtosis_dataset.pcs += 1;
                    }
                // Skewness
                } else if (report[i].name === "Skewness") {
                    if (!_all_skewness_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_skewness_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_skewness_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_skewness_dataset.highest === null || (parseFloat(x_axis_skewness_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_skewness_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_skewness_dataset.lowest === null || (parseFloat(x_axis_skewness_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_skewness_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_skewness_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_skewness_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_skewness_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_skewness_dataset.highest === null || (parseFloat(y_axis_skewness_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_skewness_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_skewness_dataset.lowest === null || (parseFloat(y_axis_skewness_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_skewness_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_skewness_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_skewness_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_skewness_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_skewness_dataset.highest === null || (parseFloat(z_axis_skewness_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_skewness_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_skewness_dataset.lowest === null || (parseFloat(z_axis_skewness_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_skewness_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_skewness_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_skewness_dataset.pcs += 1;
                    }
                // Crest factor
                } else if (report[i].name === "CrestFactor") {
                    if (!_all_crest_factor_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_crest_factor_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_crest_factor_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_crest_factor_dataset.highest === null || (parseFloat(x_axis_crest_factor_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_crest_factor_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_crest_factor_dataset.lowest === null || (parseFloat(x_axis_crest_factor_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_crest_factor_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_crest_factor_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_crest_factor_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_crest_factor_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_crest_factor_dataset.highest === null || (parseFloat(y_axis_crest_factor_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_crest_factor_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_crest_factor_dataset.lowest === null || (parseFloat(y_axis_crest_factor_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_crest_factor_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_crest_factor_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_crest_factor_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_crest_factor_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_crest_factor_dataset.highest === null || (parseFloat(z_axis_crest_factor_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_crest_factor_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_crest_factor_dataset.lowest === null || (parseFloat(z_axis_crest_factor_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_crest_factor_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_crest_factor_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_crest_factor_dataset.pcs += 1;
                    }
                // Standard deviation
                } else if (report[i].name === "Deviation") {
                    if (!_all_deviation_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_deviation_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_deviation_dataset.highest === null || (parseFloat(x_axis_deviation_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_deviation_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_deviation_dataset.lowest === null || (parseFloat(x_axis_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_deviation_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_deviation_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_deviation_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_deviation_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_deviation_dataset.highest === null || (parseFloat(y_axis_deviation_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_deviation_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_deviation_dataset.lowest === null || (parseFloat(y_axis_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_deviation_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_deviation_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_deviation_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_deviation_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_deviation_dataset.highest === null || (parseFloat(z_axis_deviation_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_deviation_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_deviation_dataset.lowest === null || (parseFloat(z_axis_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_deviation_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_deviation_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_deviation_dataset.pcs += 1;
                    }
                // Displacement
                } else if (report[i].name === "Displacement") {
                    if (!_all_displacement_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_displacement_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_displacement_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_displacement_dataset.highest === null || (parseFloat(x_axis_displacement_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_displacement_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_displacement_dataset.lowest === null || (parseFloat(x_axis_displacement_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_displacement_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_displacement_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_displacement_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_displacement_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_displacement_dataset.highest === null || (parseFloat(y_axis_displacement_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_displacement_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_displacement_dataset.lowest === null || (parseFloat(y_axis_displacement_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_displacement_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_displacement_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_displacement_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_displacement_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_displacement_dataset.highest === null || (parseFloat(z_axis_displacement_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_displacement_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_displacement_dataset.lowest === null || (parseFloat(z_axis_displacement_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_displacement_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_displacement_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_displacement_dataset.pcs += 1;
                    }
                // Temperature
                } else if (report[i].group === "TempHumi" && report[i].name === "SenVal") {
                    _temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value))
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;
                    
                    if (this.state.temperature_text === null) {
                        this.setState({
                            temperature_text: t(report[i].display_name)
                        });
                    }
                // Battery Voltage
                } else if (report[i].group === "Device" && report[i].name === "BatteryVolt") {
                    _battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value))
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    
                    if (this.state.battery_text === null) {
                        this.setState({
                            battery_text: t(report[i].display_name)
                        });
                    }
                }
            }
            if (x_axis_velocity_RMS_dataset.pcs > 0) {
                x_axis_velocity_RMS_dataset.average = parseFloat((x_axis_velocity_RMS_dataset.total_sum / x_axis_velocity_RMS_dataset.pcs).toFixed(2));
            }
            x_axis_velocity_RMS_dataset.label = 
                `${x_axis_velocity_RMS_dataset.label} (${t("avg")}: ${x_axis_velocity_RMS_dataset.average.toString()} ${t("Highest")}: ${x_axis_velocity_RMS_dataset.highest ? x_axis_velocity_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_velocity_RMS_dataset.lowest ? x_axis_velocity_RMS_dataset.lowest.toString() : 0})`; 

            _all_velocity_RMS_data.datasets.push(x_axis_velocity_RMS_dataset);

            if (y_axis_velocity_RMS_dataset.pcs > 0) {
                y_axis_velocity_RMS_dataset.average = parseFloat((y_axis_velocity_RMS_dataset.total_sum / y_axis_velocity_RMS_dataset.pcs).toFixed(2));
            }
            y_axis_velocity_RMS_dataset.label = 
                `${y_axis_velocity_RMS_dataset.label} (${t("avg")}: ${y_axis_velocity_RMS_dataset.average.toString()} ${t("Highest")}: ${y_axis_velocity_RMS_dataset.highest ? y_axis_velocity_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_velocity_RMS_dataset.lowest ? y_axis_velocity_RMS_dataset.lowest.toString() : 0})`; 

            _all_velocity_RMS_data.datasets.push(y_axis_velocity_RMS_dataset);

            if (z_axis_velocity_RMS_dataset.pcs > 0) {
                z_axis_velocity_RMS_dataset.average = parseFloat((z_axis_velocity_RMS_dataset.total_sum / z_axis_velocity_RMS_dataset.pcs).toFixed(2));
            }
            z_axis_velocity_RMS_dataset.label = 
                `${z_axis_velocity_RMS_dataset.label} (${t("avg")}: ${z_axis_velocity_RMS_dataset.average.toString()} ${t("Highest")}: ${z_axis_velocity_RMS_dataset.highest ? z_axis_velocity_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_velocity_RMS_dataset.lowest ? z_axis_velocity_RMS_dataset.lowest.toString() : 0})`; 

            _all_velocity_RMS_data.datasets.push(z_axis_velocity_RMS_dataset);

            if (x_axis_acceleration_RMS_dataset.pcs > 0) {
                x_axis_acceleration_RMS_dataset.average = parseFloat((x_axis_acceleration_RMS_dataset.total_sum / x_axis_acceleration_RMS_dataset.pcs).toFixed(2));
            }
            x_axis_acceleration_RMS_dataset.label = 
                `${x_axis_acceleration_RMS_dataset.label} (${t("avg")}: ${x_axis_acceleration_RMS_dataset.average.toString()} ${t("Highest")}: ${x_axis_acceleration_RMS_dataset.highest ? x_axis_acceleration_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_acceleration_RMS_dataset.lowest ? x_axis_acceleration_RMS_dataset.lowest.toString() : 0})`; 

            _all_acceleration_RMS_data.datasets.push(x_axis_acceleration_RMS_dataset);

            if (y_axis_acceleration_RMS_dataset.pcs > 0) {
                y_axis_acceleration_RMS_dataset.average = parseFloat((y_axis_acceleration_RMS_dataset.total_sum / y_axis_acceleration_RMS_dataset.pcs).toFixed(2));
            }
            y_axis_acceleration_RMS_dataset.label = 
                `${y_axis_acceleration_RMS_dataset.label} (${t("avg")}: ${y_axis_acceleration_RMS_dataset.average.toString()} ${t("Highest")}: ${y_axis_acceleration_RMS_dataset.highest ? y_axis_acceleration_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_acceleration_RMS_dataset.lowest ? y_axis_acceleration_RMS_dataset.lowest.toString() : 0})`; 

            _all_acceleration_RMS_data.datasets.push(y_axis_acceleration_RMS_dataset);

            if (z_axis_acceleration_RMS_dataset.pcs > 0) {
                z_axis_acceleration_RMS_dataset.average = parseFloat((z_axis_acceleration_RMS_dataset.total_sum / z_axis_acceleration_RMS_dataset.pcs).toFixed(2));
            }
            z_axis_acceleration_RMS_dataset.label = 
                `${z_axis_acceleration_RMS_dataset.label} (${t("avg")}: ${z_axis_acceleration_RMS_dataset.average.toString()} ${t("Highest")}: ${z_axis_acceleration_RMS_dataset.highest ? z_axis_acceleration_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_acceleration_RMS_dataset.lowest ? z_axis_acceleration_RMS_dataset.lowest.toString() : 0})`; 

            _all_acceleration_RMS_data.datasets.push(z_axis_acceleration_RMS_dataset);

            if (x_axis_acceleration_peak_dataset.pcs > 0) {
                x_axis_acceleration_peak_dataset.average = parseFloat((x_axis_acceleration_peak_dataset.total_sum / x_axis_acceleration_peak_dataset.pcs).toFixed(2));
            }
            x_axis_acceleration_peak_dataset.label = 
                `${x_axis_acceleration_peak_dataset.label} (${t("avg")}: ${x_axis_acceleration_peak_dataset.average.toString()} ${t("Highest")}: ${x_axis_acceleration_peak_dataset.highest ? x_axis_acceleration_peak_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_acceleration_peak_dataset.lowest ? x_axis_acceleration_peak_dataset.lowest.toString() : 0})`; 

            _all_acceleration_peak_data.datasets.push(x_axis_acceleration_peak_dataset);

            if (y_axis_acceleration_peak_dataset.pcs > 0) {
                y_axis_acceleration_peak_dataset.average = parseFloat((y_axis_acceleration_peak_dataset.total_sum / y_axis_acceleration_peak_dataset.pcs).toFixed(2));
            }
            y_axis_acceleration_peak_dataset.label = 
                `${y_axis_acceleration_peak_dataset.label} (${t("avg")}: ${y_axis_acceleration_peak_dataset.average.toString()} ${t("Highest")}: ${y_axis_acceleration_peak_dataset.highest ? y_axis_acceleration_peak_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_acceleration_peak_dataset.lowest ? y_axis_acceleration_peak_dataset.lowest.toString() : 0})`; 

            _all_acceleration_peak_data.datasets.push(y_axis_acceleration_peak_dataset);

            if (z_axis_acceleration_peak_dataset.pcs > 0) {
                z_axis_acceleration_peak_dataset.average = parseFloat((z_axis_acceleration_peak_dataset.total_sum / z_axis_acceleration_peak_dataset.pcs).toFixed(2));
            }
            z_axis_acceleration_peak_dataset.label = 
                `${z_axis_acceleration_peak_dataset.label} (${t("avg")}: ${z_axis_acceleration_peak_dataset.average.toString()} ${t("Highest")}: ${z_axis_acceleration_peak_dataset.highest ? z_axis_acceleration_peak_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_acceleration_peak_dataset.lowest ? z_axis_acceleration_peak_dataset.lowest.toString() : 0})`; 

            _all_acceleration_peak_data.datasets.push(z_axis_acceleration_peak_dataset);

            if (x_axis_kurtosis_dataset.pcs > 0) {
                x_axis_kurtosis_dataset.average = parseFloat((x_axis_kurtosis_dataset.total_sum / x_axis_kurtosis_dataset.pcs).toFixed(2));
            }
            x_axis_kurtosis_dataset.label = 
                `${x_axis_kurtosis_dataset.label} (${t("avg")}: ${x_axis_kurtosis_dataset.average.toString()} ${t("Highest")}: ${x_axis_kurtosis_dataset.highest ? x_axis_kurtosis_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_kurtosis_dataset.lowest ? x_axis_kurtosis_dataset.lowest.toString() : 0})`; 

            _all_kurtosis_data.datasets.push(x_axis_kurtosis_dataset);

            if (y_axis_kurtosis_dataset.pcs > 0) {
                y_axis_kurtosis_dataset.average = parseFloat((y_axis_kurtosis_dataset.total_sum / y_axis_kurtosis_dataset.pcs).toFixed(2));
            }
            y_axis_kurtosis_dataset.label = 
                `${y_axis_kurtosis_dataset.label} (${t("avg")}: ${y_axis_kurtosis_dataset.average.toString()} ${t("Highest")}: ${y_axis_kurtosis_dataset.highest ? y_axis_kurtosis_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_kurtosis_dataset.lowest ? y_axis_kurtosis_dataset.lowest.toString() : 0})`; 

            _all_kurtosis_data.datasets.push(y_axis_kurtosis_dataset);

            if (z_axis_kurtosis_dataset.pcs > 0) {
                z_axis_kurtosis_dataset.average = parseFloat((z_axis_kurtosis_dataset.total_sum / z_axis_kurtosis_dataset.pcs).toFixed(2));
            }
            z_axis_kurtosis_dataset.label = 
                `${z_axis_kurtosis_dataset.label} (${t("avg")}: ${z_axis_kurtosis_dataset.average.toString()} ${t("Highest")}: ${z_axis_kurtosis_dataset.highest ? z_axis_kurtosis_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_kurtosis_dataset.lowest ? z_axis_kurtosis_dataset.lowest.toString() : 0})`; 

            _all_kurtosis_data.datasets.push(z_axis_kurtosis_dataset);

            if (x_axis_skewness_dataset.pcs > 0) {
                x_axis_skewness_dataset.average = parseFloat((x_axis_skewness_dataset.total_sum / x_axis_skewness_dataset.pcs).toFixed(2));
            }
            x_axis_skewness_dataset.label = 
                `${x_axis_skewness_dataset.label} (${t("avg")}: ${x_axis_skewness_dataset.average.toString()} ${t("Highest")}: ${x_axis_skewness_dataset.highest ? x_axis_skewness_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_skewness_dataset.lowest ? x_axis_skewness_dataset.lowest.toString() : 0})`; 

            _all_skewness_data.datasets.push(x_axis_skewness_dataset);

            if (y_axis_skewness_dataset.pcs > 0) {
                y_axis_skewness_dataset.average = parseFloat((y_axis_skewness_dataset.total_sum / y_axis_skewness_dataset.pcs).toFixed(2));
            }
            y_axis_skewness_dataset.label = 
                `${y_axis_skewness_dataset.label} (${t("avg")}: ${y_axis_skewness_dataset.average.toString()} ${t("Highest")}: ${y_axis_skewness_dataset.highest ? y_axis_skewness_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_skewness_dataset.lowest ? y_axis_skewness_dataset.lowest.toString() : 0})`; 

            _all_skewness_data.datasets.push(y_axis_skewness_dataset);

            if (z_axis_skewness_dataset.pcs > 0) {
                z_axis_skewness_dataset.average = parseFloat((z_axis_skewness_dataset.total_sum / z_axis_skewness_dataset.pcs).toFixed(2));
            }
            z_axis_skewness_dataset.label = 
                `${z_axis_skewness_dataset.label} (${t("avg")}: ${z_axis_skewness_dataset.average.toString()} ${t("Highest")}: ${z_axis_skewness_dataset.highest ? z_axis_skewness_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_skewness_dataset.lowest ? z_axis_skewness_dataset.lowest.toString() : 0})`; 

            _all_skewness_data.datasets.push(z_axis_skewness_dataset);

            if (x_axis_crest_factor_dataset.pcs > 0) {
                x_axis_crest_factor_dataset.average = parseFloat((x_axis_crest_factor_dataset.total_sum / x_axis_crest_factor_dataset.pcs).toFixed(2));
            }
            x_axis_crest_factor_dataset.label = 
                `${x_axis_crest_factor_dataset.label} (${t("avg")}: ${x_axis_crest_factor_dataset.average.toString()} ${t("Highest")}: ${x_axis_crest_factor_dataset.highest ? x_axis_crest_factor_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_crest_factor_dataset.lowest ? x_axis_crest_factor_dataset.lowest.toString() : 0})`; 

            _all_crest_factor_data.datasets.push(x_axis_crest_factor_dataset);

            if (y_axis_crest_factor_dataset.pcs > 0) {
                y_axis_crest_factor_dataset.average = parseFloat((y_axis_crest_factor_dataset.total_sum / y_axis_crest_factor_dataset.pcs).toFixed(2));
            }
            y_axis_crest_factor_dataset.label = 
                `${y_axis_crest_factor_dataset.label} (${t("avg")}: ${y_axis_crest_factor_dataset.average.toString()} ${t("Highest")}: ${y_axis_crest_factor_dataset.highest ? y_axis_crest_factor_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_crest_factor_dataset.lowest ? y_axis_crest_factor_dataset.lowest.toString() : 0})`; 

            _all_crest_factor_data.datasets.push(y_axis_crest_factor_dataset);

            if (z_axis_crest_factor_dataset.pcs > 0) {
                z_axis_crest_factor_dataset.average = parseFloat((z_axis_crest_factor_dataset.total_sum / z_axis_crest_factor_dataset.pcs).toFixed(2));
            }
            z_axis_crest_factor_dataset.label = 
                `${z_axis_crest_factor_dataset.label} (${t("avg")}: ${z_axis_crest_factor_dataset.average.toString()} ${t("Highest")}: ${z_axis_crest_factor_dataset.highest ? z_axis_crest_factor_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_crest_factor_dataset.lowest ? z_axis_crest_factor_dataset.lowest.toString() : 0})`; 

            _all_crest_factor_data.datasets.push(z_axis_crest_factor_dataset);

            if (x_axis_deviation_dataset.pcs > 0) {
                x_axis_deviation_dataset.average = parseFloat((x_axis_deviation_dataset.total_sum / x_axis_deviation_dataset.pcs).toFixed(2));
            }
            x_axis_deviation_dataset.label = 
                `${x_axis_deviation_dataset.label} (${t("avg")}: ${x_axis_deviation_dataset.average.toString()} ${t("Highest")}: ${x_axis_deviation_dataset.highest ? x_axis_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_deviation_dataset.lowest ? x_axis_deviation_dataset.lowest.toString() : 0})`; 

            _all_deviation_data.datasets.push(x_axis_deviation_dataset);

            if (y_axis_deviation_dataset.pcs > 0) {
                y_axis_deviation_dataset.average = parseFloat((y_axis_deviation_dataset.total_sum / y_axis_deviation_dataset.pcs).toFixed(2));
            }
            y_axis_deviation_dataset.label = 
                `${y_axis_deviation_dataset.label} (${t("avg")}: ${y_axis_deviation_dataset.average.toString()} ${t("Highest")}: ${y_axis_deviation_dataset.highest ? y_axis_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_deviation_dataset.lowest ? y_axis_deviation_dataset.lowest.toString() : 0})`; 

            _all_deviation_data.datasets.push(y_axis_deviation_dataset);

            if (z_axis_deviation_dataset.pcs > 0) {
                z_axis_deviation_dataset.average = parseFloat((z_axis_deviation_dataset.total_sum / z_axis_deviation_dataset.pcs).toFixed(2));
            }
            z_axis_deviation_dataset.label = 
                `${z_axis_deviation_dataset.label} (${t("avg")}: ${z_axis_deviation_dataset.average.toString()} ${t("Highest")}: ${z_axis_deviation_dataset.highest ? z_axis_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_deviation_dataset.lowest ? z_axis_deviation_dataset.lowest.toString() : 0})`; 

            _all_deviation_data.datasets.push(z_axis_deviation_dataset);

            if (x_axis_displacement_dataset.pcs > 0) {
                x_axis_displacement_dataset.average = parseFloat((x_axis_displacement_dataset.total_sum / x_axis_displacement_dataset.pcs).toFixed(2));
            }
            x_axis_displacement_dataset.label = 
                `${x_axis_displacement_dataset.label} (${t("avg")}: ${x_axis_displacement_dataset.average.toString()} ${t("Highest")}: ${x_axis_displacement_dataset.highest ? x_axis_displacement_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_displacement_dataset.lowest ? x_axis_displacement_dataset.lowest.toString() : 0})`; 

            _all_displacement_data.datasets.push(x_axis_displacement_dataset);

            if (y_axis_displacement_dataset.pcs > 0) {
                y_axis_displacement_dataset.average = parseFloat((y_axis_displacement_dataset.total_sum / y_axis_displacement_dataset.pcs).toFixed(2));
            }
            y_axis_displacement_dataset.label = 
                `${y_axis_displacement_dataset.label} (${t("avg")}: ${y_axis_displacement_dataset.average.toString()} ${t("Highest")}: ${y_axis_displacement_dataset.highest ? y_axis_displacement_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_displacement_dataset.lowest ? y_axis_displacement_dataset.lowest.toString() : 0})`; 

            _all_displacement_data.datasets.push(y_axis_displacement_dataset);

            if (z_axis_displacement_dataset.pcs > 0) {
                z_axis_displacement_dataset.average = parseFloat((z_axis_displacement_dataset.total_sum / z_axis_displacement_dataset.pcs).toFixed(2));
            }
            z_axis_displacement_dataset.label = 
                `${z_axis_displacement_dataset.label} (${t("avg")}: ${z_axis_displacement_dataset.average.toString()} ${t("Highest")}: ${z_axis_displacement_dataset.highest ? z_axis_displacement_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_displacement_dataset.lowest ? z_axis_displacement_dataset.lowest.toString() : 0})`; 

            _all_displacement_data.datasets.push(z_axis_displacement_dataset);

            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 
            _battery_data.datasets.push(battery_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 
            _temperature_data.datasets.push(temperature_dataset);

            // x_axis_velocity_RMS_data.datasets.push(x_axis_velocity_RMS_dataset);
            // y_axis_velocity_RMS_data.datasets.push(y_axis_velocity_RMS_dataset);
            // z_axis_velocity_RMS_data.datasets.push(z_axis_velocity_RMS_dataset);

            this.setState({
                // x_axis_velocity_RMS_data: x_axis_velocity_RMS_data,
                // y_axis_velocity_RMS_data: y_axis_velocity_RMS_data,
                // z_axis_velocity_RMS_data: z_axis_velocity_RMS_data,
                all_velocity_RMS_data: _all_velocity_RMS_data,
                all_acceleration_RMS_data: _all_acceleration_RMS_data,
                all_acceleration_peak_data: _all_acceleration_peak_data,
                all_kurtosis_data: _all_kurtosis_data,
                all_skewness_data: _all_skewness_data,
                all_crest_factor_data: _all_crest_factor_data,
                all_deviation_data: _all_deviation_data,
                all_displacement_data: _all_displacement_data,
                temperature_data: _temperature_data,
                battery_data: _battery_data,
                loading: false
            })
        } else if (sensor.model_name === "DECENTLAB-DL-MBX") {
            let { battery_data, distance_data, filling_volume_data, filling_percentage_data,  distance_deviation_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            distance_data.labels = [];
            distance_data.datasets = [];

            distance_deviation_data.labels = [];
            distance_deviation_data.datasets = [];

            filling_volume_data.labels = [];
            filling_volume_data.datasets = [];

            filling_percentage_data.labels = [];
            filling_percentage_data.datasets = [];

            // Distance
            let distance_dataset = {
                ...default_dataset_blue,
                label: t("Distance (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling volume
            let filling_volume_dataset = {
                ...default_dataset_blue,
                label: t("Filling volume (l)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling percentage
            let filling_percentage_dataset = {
                ...default_dataset_blue,
                label: t("Filling percentage (%)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Distance deviation
            let distance_deviation_dataset = {
                ...default_dataset_blue,
                label: "",
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Distance
                if (report[i].group === "Distance") {
                    distance_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_dataset.data.push(parseFloat(report[i].value));
                    if (distance_dataset.highest === null || (parseFloat(distance_dataset.highest) < parseFloat(report[i].value))) {
                        distance_dataset.highest = parseFloat(report[i].value);
                    }
                    if (distance_dataset.lowest === null || (parseFloat(distance_dataset.lowest) > parseFloat(report[i].value))) {
                        distance_dataset.lowest = parseFloat(report[i].value);
                    }
                    distance_dataset.total_sum += parseFloat(report[i].value);
                    distance_dataset.pcs += 1;

                    // distance_deviation_dataset.data.push(parseFloat(report[i].deviation));
                    // if (distance_deviation_dataset.highest === null || (parseFloat(distance_deviation_dataset.highest) < parseFloat(report[i].deviation))) {
                    //     distance_deviation_dataset.highest = parseFloat(report[i].deviation);
                    // }
                    // if (distance_deviation_dataset.lowest === null || (parseFloat(distance_deviation_dataset.lowest) > parseFloat(report[i].deviation))) {
                    //     distance_deviation_dataset.lowest = parseFloat(report[i].deviation);
                    // }
                    // distance_deviation_dataset.total_sum += parseFloat(report[i].deviation);
                    // distance_deviation_dataset.pcs += 1;
                    // this.setState({
                    //     distance_text: t(report[i].display_name),
                    //     distance_deviation_text: t("Distance deviation (mm)")
                    // });
                // Filling volume
                } else if (report[i].group === "Report" && report[i].name === "fillingVolume") {
                    filling_volume_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_volume_dataset.data.push(parseFloat(report[i].value));
                    if (filling_volume_dataset.highest === null || (parseFloat(filling_volume_dataset.highest) < parseFloat(report[i].value))) {
                        filling_volume_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_volume_dataset.lowest === null || (parseFloat(filling_volume_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_volume_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_volume_dataset.total_sum += parseFloat(report[i].value);
                    filling_volume_dataset.pcs += 1;
                    this.setState({
                        filling_volume_text: t(report[i].display_name)
                    });
                // Filling percentage
                } else if (report[i].group === "Report" && report[i].name === "fillingPercentage") {
                    filling_percentage_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_percentage_dataset.data.push(parseFloat(report[i].value));
                    if (filling_percentage_dataset.highest === null || (parseFloat(filling_percentage_dataset.highest) < parseFloat(report[i].value))) {
                        filling_percentage_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_percentage_dataset.lowest === null || (parseFloat(filling_percentage_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_percentage_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_percentage_dataset.total_sum += parseFloat(report[i].value);
                    filling_percentage_dataset.pcs += 1;
                    this.setState({
                        filling_percentage_text: t(report[i].display_name)
                    });
                // Deviation
                } else if (report[i].group === "Report" && report[i].name === "deviation") {
                    distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_deviation_dataset.data.push(parseFloat(report[i].value));
                    if (distance_deviation_dataset.highest === null || (parseFloat(distance_deviation_dataset.highest) < parseFloat(report[i].value))) {
                        distance_deviation_dataset.highest = parseFloat(report[i].value);
                    }
                    if (distance_deviation_dataset.lowest === null || (parseFloat(distance_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                        distance_deviation_dataset.lowest = parseFloat(report[i].value);
                    }
                    distance_deviation_dataset.total_sum += parseFloat(report[i].value);
                    distance_deviation_dataset.pcs += 1;
                    this.setState({
                        distance_deviation_text: t(report[i].display_name)
                    });
                // Battery Voltage
                } else if (report[i].group === "Battery voltage") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            if (distance_dataset.pcs > 0) {
                distance_dataset.average = parseFloat((distance_dataset.total_sum / distance_dataset.pcs).toFixed(2));
            }
            distance_dataset.label = 
                `${distance_dataset.label} (${t("avg")}: ${distance_dataset.average.toString()} ${t("Highest")}: ${distance_dataset.highest ? distance_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_dataset.lowest ? distance_dataset.lowest.toString() : 0})`; 

            distance_data.datasets.push(distance_dataset);

            if (filling_volume_dataset.pcs > 0) {
                filling_volume_dataset.average = parseFloat((filling_volume_dataset.total_sum / filling_volume_dataset.pcs).toFixed(2));
            }
            filling_volume_dataset.label = 
                `${filling_volume_dataset.label} (${t("avg")}: ${filling_volume_dataset.average.toString()} ${t("Highest")}: ${filling_volume_dataset.highest ? filling_volume_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_volume_dataset.lowest ? filling_volume_dataset.lowest.toString() : 0})`; 

            filling_volume_data.datasets.push(filling_volume_dataset);

            if (filling_percentage_dataset.pcs > 0) {
                filling_percentage_dataset.average = parseFloat((filling_percentage_dataset.total_sum / filling_percentage_dataset.pcs).toFixed(2));
            }
            filling_percentage_dataset.label = 
                `${filling_percentage_dataset.label} (${t("avg")}: ${filling_percentage_dataset.average.toString()} ${t("Highest")}: ${filling_percentage_dataset.highest ? filling_percentage_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_percentage_dataset.lowest ? filling_percentage_dataset.lowest.toString() : 0})`; 

            filling_percentage_data.datasets.push(filling_percentage_dataset);

            if (distance_deviation_dataset.pcs > 0) {
                distance_deviation_dataset.average = parseFloat((distance_deviation_dataset.total_sum / distance_deviation_dataset.pcs).toFixed(2));
            }
            distance_deviation_dataset.label = 
                `${distance_deviation_dataset.label} (${t("avg")}: ${distance_deviation_dataset.average.toString()} ${t("Highest")}: ${distance_deviation_dataset.highest ? distance_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_deviation_dataset.lowest ? distance_deviation_dataset.lowest.toString() : 0})`; 

            distance_deviation_data.datasets.push(distance_deviation_dataset);

            this.setState({
                battery_data: battery_data,
                distance_data: distance_data,
                distance_deviation_data: distance_deviation_data,
                filling_volume_data: filling_volume_data,
                filling_percentage_data: filling_percentage_data,
                loading: false
            })
        } else if (sensor.model_name === "DECENTLAB-DL-PAR") {
                let { battery_data, radiation_data } = this.state;
    
                battery_data.labels = [];
                battery_data.datasets = [];
    
                radiation_data.labels = [];
                radiation_data.datasets = [];
        
                // Radiation
                let radiation_dataset = {
                    ...default_dataset_blue,
                    label: t("Solar radiation (PAR) (μmol/m2/s)"),
                    average: 0,
                    total_sum: 0,
                    pcs: 0,
                    highest: null,
                    lowest: null,
                    data: []
                };
                // Battery
                let battery_dataset = {
                    ...default_dataset_green,
                    label: t("Battery Voltage (V)"),
                    average: 0,
                    total_sum: 0,
                    pcs: 0,
                    highest: null,
                    lowest: null,
                    data: []
                };
                for (let i = 0; i < report.length; i++) {
                    let event_time = new Date(report[i].event_time);
    
                    // Radiation
                    if (report[i].name === "External photosynthetically active radiation PAR") {
                        radiation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        radiation_dataset.data.push(parseFloat(report[i].value));
                        if (radiation_dataset.highest === null || (parseFloat(radiation_dataset.highest) < parseFloat(report[i].value))) {
                            radiation_dataset.highest = parseFloat(report[i].value);
                        }
                        if (radiation_dataset.lowest === null || (parseFloat(radiation_dataset.lowest) > parseFloat(report[i].value))) {
                            radiation_dataset.lowest = parseFloat(report[i].value);
                        }
                        radiation_dataset.total_sum += parseFloat(report[i].value);
                        radiation_dataset.pcs += 1;
                        this.setState({
                            radiation_text: t(report[i].display_name)
                        });
                    // Battery Voltage
                    } else if (report[i].name === "Battery voltage") {
                        battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        battery_dataset.data.push(parseFloat(report[i].value));
                        if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                            battery_dataset.highest = parseFloat(report[i].value);
                        }
                        if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                            battery_dataset.lowest = parseFloat(report[i].value);
                        }
                        battery_dataset.total_sum += parseFloat(report[i].value);
                        battery_dataset.pcs += 1;
                        this.setState({
                            battery_text: t(report[i].display_name)
                        });
                    }
                }
                if (battery_dataset.pcs > 0) {
                    battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
                }
                battery_dataset.label = 
                    `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 
    
                battery_data.datasets.push(battery_dataset);
    
                if (radiation_dataset.pcs > 0) {
                    radiation_dataset.average = parseFloat((radiation_dataset.total_sum / radiation_dataset.pcs).toFixed(2));
                }
                radiation_dataset.label = 
                    `${radiation_dataset.label} (${t("avg")}: ${radiation_dataset.average.toString()} ${t("Highest")}: ${radiation_dataset.highest ? radiation_dataset.highest.toString() : 0} ${t("Lowest")}: ${radiation_dataset.lowest ? radiation_dataset.lowest.toString() : 0})`; 
    
                radiation_data.datasets.push(radiation_dataset);
    
    
                this.setState({
                    battery_data: battery_data,
                    radiation_data: radiation_data,
                    loading: false
                })
            
        } else if (sensor.model_name.startsWith("WASTECONTROL-VU3")) {
            let { distance_data, filling_volume_data, filling_percentage_data, distance_deviation_data} = this.state;

            distance_data.labels = [];
            distance_data.datasets = [];

            distance_deviation_data.labels = [];
            distance_deviation_data.datasets = [];

            filling_volume_data.labels = [];
            filling_volume_data.datasets = [];

            filling_percentage_data.labels = [];
            filling_percentage_data.datasets = [];

            // Distance
            let distance_dataset = {
                ...default_dataset_blue,
                label: t("Distance (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling volume
            let filling_volume_dataset = {
                ...default_dataset_blue,
                label: t("Filling volume (l)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling percentage
            let filling_percentage_dataset = {
                ...default_dataset_blue,
                label: t("Filling percentage (%)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Distance deviation
            let distance_deviation_dataset = {
                ...default_dataset_blue,
                label: t("Distance deviation (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Distance
                if (report[i].name === "Distance") {
                    distance_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_dataset.data.push(parseFloat(report[i].value));
                    if (distance_dataset.highest === null || (parseFloat(distance_dataset.highest) < parseFloat(report[i].value))) {
                        distance_dataset.highest = parseFloat(report[i].value);
                    }
                    if (distance_dataset.lowest === null || (parseFloat(distance_dataset.lowest) > parseFloat(report[i].value))) {
                        distance_dataset.lowest = parseFloat(report[i].value);
                    }
                    distance_dataset.total_sum += parseFloat(report[i].value);
                    distance_dataset.pcs += 1;

                // Filling volume
                } else if (report[i].group === "Report" && report[i].name === "fillingVolume") {
                    filling_volume_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_volume_dataset.data.push(parseFloat(report[i].value));
                    if (filling_volume_dataset.highest === null || (parseFloat(filling_volume_dataset.highest) < parseFloat(report[i].value))) {
                        filling_volume_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_volume_dataset.lowest === null || (parseFloat(filling_volume_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_volume_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_volume_dataset.total_sum += parseFloat(report[i].value);
                    filling_volume_dataset.pcs += 1;
                    this.setState({
                        filling_volume_text: t(report[i].display_name)
                    });
                // Filling percentage
                } else if (report[i].group === "Report" && report[i].name === "fillingPercentage") {
                    filling_percentage_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_percentage_dataset.data.push(parseFloat(report[i].value));
                    if (filling_percentage_dataset.highest === null || (parseFloat(filling_percentage_dataset.highest) < parseFloat(report[i].value))) {
                        filling_percentage_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_percentage_dataset.lowest === null || (parseFloat(filling_percentage_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_percentage_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_percentage_dataset.total_sum += parseFloat(report[i].value);
                    filling_percentage_dataset.pcs += 1;
                    this.setState({
                        filling_percentage_text: t(report[i].display_name)
                    });
                // Deviation
                } else if (report[i].group === "Report" && report[i].name === "deviation") {
                    distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_deviation_dataset.data.push(parseFloat(report[i].value));
                    if (distance_deviation_dataset.highest === null || (parseFloat(distance_deviation_dataset.highest) < parseFloat(report[i].value))) {
                        distance_deviation_dataset.highest = parseFloat(report[i].value);
                    }
                    if (distance_deviation_dataset.lowest === null || (parseFloat(distance_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                        distance_deviation_dataset.lowest = parseFloat(report[i].value);
                    }
                    distance_deviation_dataset.total_sum += parseFloat(report[i].value);
                    distance_deviation_dataset.pcs += 1;
                    this.setState({
                        distance_deviation_text: t(report[i].display_name)
                    });
                }
            }

            if (distance_dataset.pcs > 0) {
                distance_dataset.average = parseFloat((distance_dataset.total_sum / distance_dataset.pcs).toFixed(2));
            }
            distance_dataset.label = 
                `${distance_dataset.label} (${t("avg")}: ${distance_dataset.average.toString()} ${t("Highest")}: ${distance_dataset.highest ? distance_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_dataset.lowest ? distance_dataset.lowest.toString() : 0})`; 

            distance_data.datasets.push(distance_dataset);

            if (filling_volume_dataset.pcs > 0) {
                filling_volume_dataset.average = parseFloat((filling_volume_dataset.total_sum / filling_volume_dataset.pcs).toFixed(2));
            }
            filling_volume_dataset.label = 
                `${filling_volume_dataset.label} (${t("avg")}: ${filling_volume_dataset.average.toString()} ${t("Highest")}: ${filling_volume_dataset.highest ? filling_volume_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_volume_dataset.lowest ? filling_volume_dataset.lowest.toString() : 0})`; 

            filling_volume_data.datasets.push(filling_volume_dataset);

            if (filling_percentage_dataset.pcs > 0) {
                filling_percentage_dataset.average = parseFloat((filling_percentage_dataset.total_sum / filling_percentage_dataset.pcs).toFixed(2));
            }
            filling_percentage_dataset.label = 
                `${filling_percentage_dataset.label} (${t("avg")}: ${filling_percentage_dataset.average.toString()} ${t("Highest")}: ${filling_percentage_dataset.highest ? filling_percentage_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_percentage_dataset.lowest ? filling_percentage_dataset.lowest.toString() : 0})`; 

            filling_percentage_data.datasets.push(filling_percentage_dataset);

            if (distance_deviation_dataset.pcs > 0) {
                distance_deviation_dataset.average = parseFloat((distance_deviation_dataset.total_sum / distance_deviation_dataset.pcs).toFixed(2));
            }
            distance_deviation_dataset.label = 
                `${distance_deviation_dataset.label} (${t("avg")}: ${distance_deviation_dataset.average.toString()} ${t("Highest")}: ${distance_deviation_dataset.highest ? distance_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_deviation_dataset.lowest ? distance_deviation_dataset.lowest.toString() : 0})`; 

            distance_deviation_data.datasets.push(distance_deviation_dataset);

            this.setState({
                distance_data: distance_data,
                distance_deviation_data: distance_deviation_data,
                filling_volume_data: filling_volume_data,
                filling_percentage_data: filling_percentage_data,
                loading: false
            })
        } else if (sensor.model_name.startsWith("DINGTEK") || sensor.model_name.startsWith("CAPROC-RANGE") || sensor.model_name.startsWith("CAPROC-RADAR")) {
            let { distance_data, filling_volume_data, filling_percentage_data, distance_deviation_data, temperature_data, angle_data, v_notch_water_flow_data, v_notch_water_level_data} = this.state;

            distance_data.labels = [];
            distance_data.datasets = [];

            distance_deviation_data.labels = [];
            distance_deviation_data.datasets = [];

            filling_volume_data.labels = [];
            filling_volume_data.datasets = [];

            filling_percentage_data.labels = [];
            filling_percentage_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            angle_data.labels = [];
            angle_data.datasets = [];

            v_notch_water_flow_data.labels = [];
            v_notch_water_flow_data.datasets = [];

            v_notch_water_level_data.labels = [];
            v_notch_water_level_data.datasets = [];

            // Distance
            let distance_dataset = {
                ...default_dataset_blue,
                label: t("Distance (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling volume
            let filling_volume_dataset = {
                ...default_dataset_blue,
                label: t("Filling volume (l)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling percentage
            let filling_percentage_dataset = {
                ...default_dataset_blue,
                label: t("Filling percentage (%)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Distance deviation
            let distance_deviation_dataset = {
                ...default_dataset_blue,
                label: t("Distance deviation (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Temperature
            let temperature_dataset = {
                ...default_dataset_blue,
                label: t("Temperature (°C)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Tilt angle
            let angle_dataset = {
                ...default_dataset_blue,
                label: t("Tilt angle (deg)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // V-notch water level
            let v_notch_water_level_dataset = {
                ...default_dataset_blue,
                label: t("V-notch water level"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // V-notch water flow
            let v_notch_water_flow_dataset = {
                ...default_dataset_blue,
                label: t("V-notch water flow"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Distance
                if (report[i].name === "Distance") {
                    if (report[i].value != "")
                    {
                        distance_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        distance_dataset.data.push(parseFloat(report[i].value));
                        if (distance_dataset.highest === null || (parseFloat(distance_dataset.highest) < parseFloat(report[i].value))) {
                            distance_dataset.highest = parseFloat(report[i].value);
                        }
                        if (distance_dataset.lowest === null || (parseFloat(distance_dataset.lowest) > parseFloat(report[i].value))) {
                            distance_dataset.lowest = parseFloat(report[i].value);
                        }
                        distance_dataset.total_sum += parseFloat(report[i].value);
                        distance_dataset.pcs += 1;
                    }
                    this.setState({
                        distance_text: t(report[i].display_name)
                    });

                // Filling volume
                } else if (report[i].group === "Report" && report[i].name === "fillingVolume") {
                    if (report[i].value != "")
                    {
                        filling_volume_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        filling_volume_dataset.data.push(parseFloat(report[i].value));
                        if (filling_volume_dataset.highest === null || (parseFloat(filling_volume_dataset.highest) < parseFloat(report[i].value))) {
                            filling_volume_dataset.highest = parseFloat(report[i].value);
                        }
                        if (filling_volume_dataset.lowest === null || (parseFloat(filling_volume_dataset.lowest) > parseFloat(report[i].value))) {
                            filling_volume_dataset.lowest = parseFloat(report[i].value);
                        }
                        filling_volume_dataset.total_sum += parseFloat(report[i].value);
                        filling_volume_dataset.pcs += 1;
                    }
                    this.setState({
                        filling_volume_text: t(report[i].display_name)
                    });
                // Filling percentage
                } else if (report[i].group === "Report" && report[i].name === "fillingPercentage") {
                    if (report[i].value != "")
                    {
                        filling_percentage_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        filling_percentage_dataset.data.push(parseFloat(report[i].value));
                        if (filling_percentage_dataset.highest === null || (parseFloat(filling_percentage_dataset.highest) < parseFloat(report[i].value))) {
                            filling_percentage_dataset.highest = parseFloat(report[i].value);
                        }
                        if (filling_percentage_dataset.lowest === null || (parseFloat(filling_percentage_dataset.lowest) > parseFloat(report[i].value))) {
                            filling_percentage_dataset.lowest = parseFloat(report[i].value);
                        }
                        filling_percentage_dataset.total_sum += parseFloat(report[i].value);
                        filling_percentage_dataset.pcs += 1;
                    }
                    this.setState({
                        filling_percentage_text: t(report[i].display_name)
                    });
                // Deviation
                } else if (report[i].group === "Report" && report[i].name === "deviation") {
                    if (report[i].value != "")
                    {
                        distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        distance_deviation_dataset.data.push(parseFloat(report[i].value));
                        if (distance_deviation_dataset.highest === null || (parseFloat(distance_deviation_dataset.highest) < parseFloat(report[i].value))) {
                            distance_deviation_dataset.highest = parseFloat(report[i].value);
                        }
                        if (distance_deviation_dataset.lowest === null || (parseFloat(distance_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                            distance_deviation_dataset.lowest = parseFloat(report[i].value);
                        }
                        distance_deviation_dataset.total_sum += parseFloat(report[i].value);
                        distance_deviation_dataset.pcs += 1;
                    }
                    this.setState({
                        distance_deviation_text: t(report[i].display_name)
                    });
                // V-notch water level
                } else if (report[i].group === "Report" && report[i].name === "V-notch water level") {
                    if (report[i].value != "")
                    {
                        v_notch_water_level_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        v_notch_water_level_dataset.data.push(parseFloat(report[i].value));
                        if (v_notch_water_level_dataset.highest === null || (parseFloat(v_notch_water_level_dataset.highest) < parseFloat(report[i].value))) {
                            v_notch_water_level_dataset.highest = parseFloat(report[i].value);
                        }
                        if (v_notch_water_level_dataset.lowest === null || (parseFloat(v_notch_water_level_dataset.lowest) > parseFloat(report[i].value))) {
                            v_notch_water_level_dataset.lowest = parseFloat(report[i].value);
                        }
                        v_notch_water_level_dataset.total_sum += parseFloat(report[i].value);
                        v_notch_water_level_dataset.pcs += 1;
                    }
                    this.setState({
                        v_notch_water_level_text: t(report[i].display_name)
                    });
                // V-notch water flow
                } else if (report[i].group === "Report" && report[i].name === "V-notch water flow") {
                    if (report[i].value != "")
                    {
                        v_notch_water_flow_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        v_notch_water_flow_dataset.data.push(parseFloat(report[i].value));
                        if (v_notch_water_flow_dataset.highest === null || (parseFloat(v_notch_water_flow_dataset.highest) < parseFloat(report[i].value))) {
                            v_notch_water_flow_dataset.highest = parseFloat(report[i].value);
                        }
                        if (v_notch_water_flow_dataset.lowest === null || (parseFloat(v_notch_water_flow_dataset.lowest) > parseFloat(report[i].value))) {
                            v_notch_water_flow_dataset.lowest = parseFloat(report[i].value);
                        }
                        v_notch_water_flow_dataset.total_sum += parseFloat(report[i].value);
                        v_notch_water_flow_dataset.pcs += 1;
                    }
                    this.setState({
                        v_notch_water_flow_text: t(report[i].display_name)
                    });
                // Temperature
                } else if (report[i].name === "Temperature") {
                    if (report[i].value != "")
                    {
                        temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        temperature_dataset.data.push(parseFloat(report[i].value));
                        if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                            temperature_dataset.highest = parseFloat(report[i].value);
                        }
                        if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                            temperature_dataset.lowest = parseFloat(report[i].value);
                        }
                        temperature_dataset.total_sum += parseFloat(report[i].value);
                        temperature_dataset.pcs += 1;
                    }
                    this.setState({
                        temperature_text: t(report[i].display_name)
                    });
                // Tilt angle
                } else if (report[i].name === "Tilt angle") {
                    if (report[i].value != "")
                    {
                        angle_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        angle_dataset.data.push(parseFloat(report[i].value));
                        if (angle_dataset.highest === null || (parseFloat(angle_dataset.highest) < parseFloat(report[i].value))) {
                            angle_dataset.highest = parseFloat(report[i].value);
                        }
                        if (angle_dataset.lowest === null || (parseFloat(angle_dataset.lowest) > parseFloat(report[i].value))) {
                            angle_dataset.lowest = parseFloat(report[i].value);
                        }
                        angle_dataset.total_sum += parseFloat(report[i].value);
                        angle_dataset.pcs += 1;
                    }
                    this.setState({
                        angle_text: t(report[i].display_name)
                    });
                }
            }

            if (distance_dataset.pcs > 0) {
                distance_dataset.average = parseFloat((distance_dataset.total_sum / distance_dataset.pcs).toFixed(2));
            }
            distance_dataset.label = 
            `${distance_dataset.label} (${t("avg")}: ${distance_dataset.average.toString()} ${t("Highest")}: ${distance_dataset.highest ? distance_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_dataset.lowest ? distance_dataset.lowest.toString() : 0})`; 

            distance_data.datasets.push(distance_dataset);
            

            if (filling_volume_dataset.pcs > 0) {
                filling_volume_dataset.average = parseFloat((filling_volume_dataset.total_sum / filling_volume_dataset.pcs).toFixed(2));
            }
            filling_volume_dataset.label = 
            `${filling_volume_dataset.label} (${t("avg")}: ${filling_volume_dataset.average.toString()} ${t("Highest")}: ${filling_volume_dataset.highest ? filling_volume_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_volume_dataset.lowest ? filling_volume_dataset.lowest.toString() : 0})`; 

            filling_volume_data.datasets.push(filling_volume_dataset);
            
            if (filling_percentage_dataset.pcs > 0) {
                filling_percentage_dataset.average = parseFloat((filling_percentage_dataset.total_sum / filling_percentage_dataset.pcs).toFixed(2));
            }
            filling_percentage_dataset.label = 
            `${filling_percentage_dataset.label} (${t("avg")}: ${filling_percentage_dataset.average.toString()} ${t("Highest")}: ${filling_percentage_dataset.highest ? filling_percentage_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_percentage_dataset.lowest ? filling_percentage_dataset.lowest.toString() : 0})`; 

            filling_percentage_data.datasets.push(filling_percentage_dataset);
            
            if (distance_deviation_dataset.pcs > 0) {
                distance_deviation_dataset.average = parseFloat((distance_deviation_dataset.total_sum / distance_deviation_dataset.pcs).toFixed(2));
            }
            distance_deviation_dataset.label = 
            `${distance_deviation_dataset.label} (${t("avg")}: ${distance_deviation_dataset.average.toString()} ${t("Highest")}: ${distance_deviation_dataset.highest ? distance_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_deviation_dataset.lowest ? distance_deviation_dataset.lowest.toString() : 0})`; 

            distance_deviation_data.datasets.push(distance_deviation_dataset);
            
            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
            `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);
            
            if (angle_dataset.pcs > 0) {
                angle_dataset.average = parseFloat((angle_dataset.total_sum / angle_dataset.pcs).toFixed(2));
            }
            angle_dataset.label = 
            `${angle_dataset.label} (${t("avg")}: ${angle_dataset.average.toString()} ${t("Highest")}: ${angle_dataset.highest ? angle_dataset.highest.toString() : 0} ${t("Lowest")}: ${angle_dataset.lowest ? angle_dataset.lowest.toString() : 0})`; 

            angle_data.datasets.push(angle_dataset);
            
            if (v_notch_water_flow_dataset.pcs > 0) {
                v_notch_water_flow_dataset.average = parseFloat((v_notch_water_flow_dataset.total_sum / v_notch_water_flow_dataset.pcs).toFixed(2));
            }
            v_notch_water_flow_dataset.label = 
            `${v_notch_water_flow_dataset.label} (${t("avg")}: ${v_notch_water_flow_dataset.average.toString()} ${t("Highest")}: ${v_notch_water_flow_dataset.highest ? v_notch_water_flow_dataset.highest.toString() : 0} ${t("Lowest")}: ${v_notch_water_flow_dataset.lowest ? v_notch_water_flow_dataset.lowest.toString() : 0})`; 

            v_notch_water_flow_data.datasets.push(v_notch_water_flow_dataset);
            
            if (v_notch_water_level_dataset.pcs > 0) {
                v_notch_water_level_dataset.average = parseFloat((v_notch_water_level_dataset.total_sum / v_notch_water_level_dataset.pcs).toFixed(2));
            }
            v_notch_water_level_dataset.label = 
            `${v_notch_water_level_dataset.label} (${t("avg")}: ${v_notch_water_level_dataset.average.toString()} ${t("Highest")}: ${v_notch_water_level_dataset.highest ? v_notch_water_level_dataset.highest.toString() : 0} ${t("Lowest")}: ${v_notch_water_level_dataset.lowest ? v_notch_water_level_dataset.lowest.toString() : 0})`; 

            v_notch_water_level_data.datasets.push(v_notch_water_level_dataset);
            
            this.setState({
                distance_data: distance_data,
                distance_deviation_data: distance_deviation_data,
                filling_volume_data: filling_volume_data,
                filling_percentage_data: filling_percentage_data,
                temperature_data: temperature_data,
                angle_data: angle_data,
                v_notch_water_flow_data: v_notch_water_flow_data,
                v_notch_water_level_data: v_notch_water_level_data,
                loading: false
            })
        } else if (sensor.model_name.startsWith("CAPROC-WL")) {
            let { distance_data, pressure_data, temperature_data, filling_volume_data, filling_percentage_data, distance_deviation_data, battery_data} = this.state;

            distance_data.labels = [];
            distance_data.datasets = [];

            distance_deviation_data.labels = [];
            distance_deviation_data.datasets = [];

            filling_volume_data.labels = [];
            filling_volume_data.datasets = [];

            filling_percentage_data.labels = [];
            filling_percentage_data.datasets = [];

            pressure_data.labels = [];
            pressure_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            // Water level
            let distance_dataset = {
                ...default_dataset_blue,
                label: t("Water level (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling volume
            let filling_volume_dataset = {
                ...default_dataset_blue,
                label: t("Filling volume (l)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling percentage
            let filling_percentage_dataset = {
                ...default_dataset_blue,
                label: t("Filling percentage (%)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Distance deviation
            let distance_deviation_dataset = {
                ...default_dataset_blue,
                label: t("Distance deviation (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            // Water pressure
            let pressure_dataset = {
                ...default_dataset_blue,
                label: t("Water pressure (kPa)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Water temperature
            let temperature_dataset = {
                ...default_dataset_red,
                label: t("Water temperature (°C)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Water level
                if (report[i].name === "Water level") {
                    distance_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_dataset.data.push(parseFloat(report[i].value));
                    if (distance_dataset.highest === null || (parseFloat(distance_dataset.highest) < parseFloat(report[i].value))) {
                        distance_dataset.highest = parseFloat(report[i].value);
                    }
                    if (distance_dataset.lowest === null || (parseFloat(distance_dataset.lowest) > parseFloat(report[i].value))) {
                        distance_dataset.lowest = parseFloat(report[i].value);
                    }
                    distance_dataset.total_sum += parseFloat(report[i].value);
                    distance_dataset.pcs += 1;

                // Filling volume
                } else if (report[i].group === "Report" && report[i].name === "fillingVolume") {
                    filling_volume_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_volume_dataset.data.push(parseFloat(report[i].value));
                    if (filling_volume_dataset.highest === null || (parseFloat(filling_volume_dataset.highest) < parseFloat(report[i].value))) {
                        filling_volume_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_volume_dataset.lowest === null || (parseFloat(filling_volume_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_volume_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_volume_dataset.total_sum += parseFloat(report[i].value);
                    filling_volume_dataset.pcs += 1;
                    this.setState({
                        filling_volume_text: t(report[i].display_name)
                    });
                // Filling percentage
                } else if (report[i].group === "Report" && report[i].name === "fillingPercentage") {
                    filling_percentage_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_percentage_dataset.data.push(parseFloat(report[i].value));
                    if (filling_percentage_dataset.highest === null || (parseFloat(filling_percentage_dataset.highest) < parseFloat(report[i].value))) {
                        filling_percentage_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_percentage_dataset.lowest === null || (parseFloat(filling_percentage_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_percentage_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_percentage_dataset.total_sum += parseFloat(report[i].value);
                    filling_percentage_dataset.pcs += 1;
                    this.setState({
                        filling_percentage_text: t(report[i].display_name)
                    });
                // Deviation
                } else if (report[i].group === "Report" && report[i].name === "deviation") {
                    distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_deviation_dataset.data.push(parseFloat(report[i].value));
                    if (distance_deviation_dataset.highest === null || (parseFloat(distance_deviation_dataset.highest) < parseFloat(report[i].value))) {
                        distance_deviation_dataset.highest = parseFloat(report[i].value);
                    }
                    if (distance_deviation_dataset.lowest === null || (parseFloat(distance_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                        distance_deviation_dataset.lowest = parseFloat(report[i].value);
                    }
                    distance_deviation_dataset.total_sum += parseFloat(report[i].value);
                    distance_deviation_dataset.pcs += 1;
                    this.setState({
                        distance_deviation_text: t(report[i].display_name)
                    });
                // Water pressure
                } else if (report[i].name === "Water pressure") {
                    pressure_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pressure_dataset.data.push(parseFloat(report[i].value));
                    if (pressure_dataset.highest === null || (parseFloat(pressure_dataset.highest) < parseFloat(report[i].value))) {
                        pressure_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pressure_dataset.lowest === null || (parseFloat(pressure_dataset.lowest) > parseFloat(report[i].value))) {
                        pressure_dataset.lowest = parseFloat(report[i].value);
                    }
                    pressure_dataset.total_sum += parseFloat(report[i].value);
                    pressure_dataset.pcs += 1;
                    this.setState({
                        pressure_text: t(report[i].display_name)
                    });
                // Water temperature
                } else if (report[i].name === "Water temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;
                    this.setState({
                        temperature_text: t(report[i].display_name)
                    });
                // Battery voltage
                } else if (report[i].name === "Battery voltage") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }

            }

            if (distance_dataset.pcs > 0) {
                distance_dataset.average = parseFloat((distance_dataset.total_sum / distance_dataset.pcs).toFixed(2));
            }
            distance_dataset.label = 
                `${distance_dataset.label} (${t("avg")}: ${distance_dataset.average.toString()} ${t("Highest")}: ${distance_dataset.highest ? distance_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_dataset.lowest ? distance_dataset.lowest.toString() : 0})`; 

            distance_data.datasets.push(distance_dataset);

            if (filling_volume_dataset.pcs > 0) {
                filling_volume_dataset.average = parseFloat((filling_volume_dataset.total_sum / filling_volume_dataset.pcs).toFixed(2));
            }
            filling_volume_dataset.label = 
                `${filling_volume_dataset.label} (${t("avg")}: ${filling_volume_dataset.average.toString()} ${t("Highest")}: ${filling_volume_dataset.highest ? filling_volume_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_volume_dataset.lowest ? filling_volume_dataset.lowest.toString() : 0})`; 

            filling_volume_data.datasets.push(filling_volume_dataset);

            if (filling_percentage_dataset.pcs > 0) {
                filling_percentage_dataset.average = parseFloat((filling_percentage_dataset.total_sum / filling_percentage_dataset.pcs).toFixed(2));
            }
            filling_percentage_dataset.label = 
                `${filling_percentage_dataset.label} (${t("avg")}: ${filling_percentage_dataset.average.toString()} ${t("Highest")}: ${filling_percentage_dataset.highest ? filling_percentage_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_percentage_dataset.lowest ? filling_percentage_dataset.lowest.toString() : 0})`; 

            filling_percentage_data.datasets.push(filling_percentage_dataset);

            if (distance_deviation_dataset.pcs > 0) {
                distance_deviation_dataset.average = parseFloat((distance_deviation_dataset.total_sum / distance_deviation_dataset.pcs).toFixed(2));
            }
            distance_deviation_dataset.label = 
                `${distance_deviation_dataset.label} (${t("avg")}: ${distance_deviation_dataset.average.toString()} ${t("Highest")}: ${distance_deviation_dataset.highest ? distance_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_deviation_dataset.lowest ? distance_deviation_dataset.lowest.toString() : 0})`; 

            distance_deviation_data.datasets.push(distance_deviation_dataset);

            if (pressure_dataset.pcs > 0) {
                pressure_dataset.average = parseFloat((pressure_dataset.total_sum / pressure_dataset.pcs).toFixed(2));
            }
            pressure_dataset.label = 
                `${pressure_dataset.label} (${t("avg")}: ${pressure_dataset.average.toString()} ${t("Highest")}: ${pressure_dataset.highest ? pressure_dataset.highest.toString() : 0} ${t("Lowest")}: ${pressure_dataset.lowest ? pressure_dataset.lowest.toString() : 0})`; 

                pressure_data.datasets.push(pressure_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

                temperature_data.datasets.push(temperature_dataset);

            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);
    
            this.setState({
                distance_data: distance_data,
                distance_deviation_data: distance_deviation_data,
                filling_volume_data: filling_volume_data,
                filling_percentage_data: filling_percentage_data,
                temperature_data: temperature_data,
                pressure_data: pressure_data,
                battery_data: battery_data,
                loading: false
            })
        } else if (sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-H21") || sensor.model_name.startsWith("CAPROC-AQUA-PHEHT") || sensor.model_name.startsWith("CAPROC-AQUA-C4E") || sensor.model_name.startsWith("CAPROC-AQUA-NTU") || sensor.model_name.startsWith("CAPROC-AQUA-STACSENSE")) {
            let { water_level_data, h21_conductivity_data, h21_temp_data, h21_sensor_depth_data, optod_o2_mgl_data, optod_o2_sat_data, optod_temp_data, pheht_temp_data, pheht_ph_data, pheht_redox_data, pheht_ph_mv_data, c4e_temp_data, c4e_conductivity_data, c4e_salinity_data, c4e_tot_dissolved_solids_data, ntu_temp_data, ntu_turbidity_ntu_data, ntu_turbidity_fnu_data, ntu_tu_turbidity_data, stacsense_temp_data, stacsense_cas254_data, stacsense_cod_data, stacsense_bod_data, stacsense_toc_data, temperature_data, pressure_data, battery_data} = this.state;

            water_level_data.labels = [];
            water_level_data.datasets = [];

            h21_conductivity_data.labels = [];
            h21_conductivity_data.datasets = [];

            h21_temp_data.labels = [];
            h21_temp_data.datasets = [];

            h21_sensor_depth_data.labels = [];
            h21_sensor_depth_data.datasets = [];

            optod_o2_sat_data.labels = [];
            optod_o2_sat_data.datasets = [];

            optod_o2_mgl_data.labels = [];
            optod_o2_mgl_data.datasets = [];

            optod_temp_data.labels = [];
            optod_temp_data.datasets = [];

            pheht_temp_data.labels = [];
            pheht_temp_data.datasets = [];

            pheht_ph_data.labels = [];
            pheht_ph_data.datasets = [];

            pheht_redox_data.labels = [];
            pheht_redox_data.datasets = [];

            pheht_ph_mv_data.labels = [];
            pheht_ph_mv_data.datasets = [];

            c4e_temp_data.labels = [];
            c4e_temp_data.datasets = [];

            c4e_conductivity_data.labels = [];
            c4e_conductivity_data.datasets = [];

            c4e_salinity_data.labels = [];
            c4e_salinity_data.datasets = [];

            c4e_tot_dissolved_solids_data.labels = [];
            c4e_tot_dissolved_solids_data.datasets = [];

            ntu_temp_data.labels = [];
            ntu_temp_data.datasets = [];

            ntu_turbidity_ntu_data.labels = [];
            ntu_turbidity_ntu_data.datasets = [];

            ntu_turbidity_fnu_data.labels = [];
            ntu_turbidity_fnu_data.datasets = [];

            ntu_tu_turbidity_data.labels = [];
            ntu_tu_turbidity_data.datasets = [];

            stacsense_temp_data.labels = [];
            stacsense_temp_data.datasets = [];

            stacsense_cas254_data.labels = [];
            stacsense_cas254_data.datasets = [];

            stacsense_cod_data.labels = [];
            stacsense_cod_data.datasets = [];

            stacsense_bod_data.labels = [];
            stacsense_bod_data.datasets = [];

            stacsense_toc_data.labels = [];
            stacsense_toc_data.datasets = [];

            pressure_data.labels = [];
            pressure_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            battery_data.labels = [];
            battery_data.datasets = [];

            // Water level
            let water_level_dataset = {
                ...default_dataset_blue,
                label: t("Water level (from sea level)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // h21_conductivity
            let h21_conductivity_dataset = {
                ...default_dataset_red,
                label: t("Bulk electrical conductivity (EC)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // h21_temp
            let h21_temp_dataset = {
                ...default_dataset_red,
                label: t("Temperature (H21)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // h21_sensor_depth
            let h21_sensor_depth_dataset = {
                ...default_dataset_green,
                label: t("Sensor depth"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            // optod_o2_sat
            let optod_o2_sat_dataset = {
                ...default_dataset_blue,
                label: t("Oxygen %Sat"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // optod_o2_mgl
            let optod_o2_mgl_dataset = {
                ...default_dataset_blue,
                label: t("Oxygen mg/L"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // optod_temp
            let optod_temp_dataset = {
                ...default_dataset_blue,
                label: t("Temperature (OPTOD)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // PHEHT temp
            let pheht_temp_dataset = {
                ...default_dataset_blue,
                label: t("Temperature (PHEHT)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // PHEHT pH
            let pheht_pH_dataset = {
                ...default_dataset_blue,
                label: t("pH"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // PHEHT redox
            let pheht_redox_dataset = {
                ...default_dataset_blue,
                label: t("Redox"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // PHEHT pH mV
            let pheht_pH_mV_dataset = {
                ...default_dataset_blue,
                label: t("pH (mV)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // C4E temp
            let c4e_temp_dataset = {
                ...default_dataset_blue,
                label: t("Temperature (C4E)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // C4E conductivity
            let c4e_conductivity_dataset = {
                ...default_dataset_blue,
                label: t("Conductivity"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // C4E salinity
            let c4e_salinity_dataset = {
                ...default_dataset_blue,
                label: t("Salinity"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // C4E total dissolved solids
            let c4e_tot_dissolved_solids_dataset = {
                ...default_dataset_blue,
                label: t("Total dissolved solids"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // NTU temp
            let ntu_temp_dataset = {
                ...default_dataset_blue,
                label: t("Temperature (NTU)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // NTU Turbidity (NTU)
            let ntu_turbidity_ntu_dataset = {
                ...default_dataset_blue,
                label: t("Turbidity (NTU)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // NTU Turbidity (FNU)
            let ntu_turbidity_fnu_dataset = {
                ...default_dataset_blue,
                label: t("Turbidity (FNU)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // NTU TU turbidity
            let ntu_tu_turbidity_dataset = {
                ...default_dataset_blue,
                label: t("TU turbidity"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // STACSENSE temp
            let stacsense_temp_dataset = {
                ...default_dataset_blue,
                label: t("Temperature (STACSENSE)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // STACSENSE CAS254
            let stacsense_cas254_dataset = {
                ...default_dataset_blue,
                label: t("CAS254"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // STACSENSE COD
            let stacsense_cod_dataset = {
                ...default_dataset_blue,
                label: t("COD"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // STACSENSE BOD
            let stacsense_bod_dataset = {
                ...default_dataset_blue,
                label: t("BOD"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // STACSENSE TOC
            let stacsense_toc_dataset = {
                ...default_dataset_blue,
                label: t("TOC"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Air pressure
            let pressure_dataset = {
                ...default_dataset_blue,
                label: t("Air pressure"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature
            let temperature_dataset = {
                ...default_dataset_red,
                label: t("Temperature"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Water level
                if (report[i].name === "Water level") {
                    water_level_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    water_level_dataset.data.push(parseFloat(report[i].value));
                    if (water_level_dataset.highest === null || (parseFloat(water_level_dataset.highest) < parseFloat(report[i].value))) {
                        water_level_dataset.highest = parseFloat(report[i].value);
                    }
                    if (water_level_dataset.lowest === null || (parseFloat(water_level_dataset.lowest) > parseFloat(report[i].value))) {
                        water_level_dataset.lowest = parseFloat(report[i].value);
                    }
                    water_level_dataset.total_sum += parseFloat(report[i].value);
                    water_level_dataset.pcs += 1;
                    this.setState({
                        water_level_text: t(report[i].display_name)
                    });

                // h21_conductivity_dataset
                } else if (report[i].name === "External electrical conductivity H21") {
                    h21_conductivity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    h21_conductivity_dataset.data.push(parseFloat(report[i].value));
                    if (h21_conductivity_dataset.highest === null || (parseFloat(h21_conductivity_dataset.highest) < parseFloat(report[i].value))) {
                        h21_conductivity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (h21_conductivity_dataset.lowest === null || (parseFloat(h21_conductivity_dataset.lowest) > parseFloat(report[i].value))) {
                        h21_conductivity_dataset.lowest = parseFloat(report[i].value);
                    }
                    h21_conductivity_dataset.total_sum += parseFloat(report[i].value);
                    h21_conductivity_dataset.pcs += 1;
                    this.setState({
                        h21_conductivity_text: t(report[i].display_name)
                    });
                // h21_temp_dataset
                } else if (report[i].name === "External temperature H21") {
                    h21_temp_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    h21_temp_dataset.data.push(parseFloat(report[i].value));
                    if (h21_temp_dataset.highest === null || (parseFloat(h21_temp_dataset.highest) < parseFloat(report[i].value))) {
                        h21_temp_dataset.highest = parseFloat(report[i].value);
                    }
                    if (h21_temp_dataset.lowest === null || (parseFloat(h21_temp_dataset.lowest) > parseFloat(report[i].value))) {
                        h21_temp_dataset.lowest = parseFloat(report[i].value);
                    }
                    h21_temp_dataset.total_sum += parseFloat(report[i].value);
                    h21_temp_dataset.pcs += 1;
                    this.setState({
                        h21_temp_text: t(report[i].display_name)
                    });
                // h21_sensor_depth_dataset
                } else if (report[i].name === "External depth H21") {
                    h21_sensor_depth_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    h21_sensor_depth_dataset.data.push(parseFloat(report[i].value));
                    if (h21_sensor_depth_dataset.highest === null || (parseFloat(h21_sensor_depth_dataset.highest) < parseFloat(report[i].value))) {
                        h21_sensor_depth_dataset.highest = parseFloat(report[i].value);
                    }
                    if (h21_sensor_depth_dataset.lowest === null || (parseFloat(h21_sensor_depth_dataset.lowest) > parseFloat(report[i].value))) {
                        h21_sensor_depth_dataset.lowest = parseFloat(report[i].value);
                    }
                    h21_sensor_depth_dataset.total_sum += parseFloat(report[i].value);
                    h21_sensor_depth_dataset.pcs += 1;
                    this.setState({
                        h21_sensor_depth_text: t(report[i].display_name)
                    });
                // optod_o2_sat_dataset
                } else if (report[i].name === "External Sat percent OPTOD") {
                    optod_o2_sat_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    optod_o2_sat_dataset.data.push(parseFloat(report[i].value));
                    if (optod_o2_sat_dataset.highest === null || (parseFloat(optod_o2_sat_dataset.highest) < parseFloat(report[i].value))) {
                        optod_o2_sat_dataset.highest = parseFloat(report[i].value);
                    }
                    if (optod_o2_sat_dataset.lowest === null || (parseFloat(optod_o2_sat_dataset.lowest) > parseFloat(report[i].value))) {
                        optod_o2_sat_dataset.lowest = parseFloat(report[i].value);
                    }
                    optod_o2_sat_dataset.total_sum += parseFloat(report[i].value);
                    optod_o2_sat_dataset.pcs += 1;
                    this.setState({
                        optod_o2_sat_text: t(report[i].display_name)
                    });
                // optod_o2_mgl_dataset
                } else if (report[i].name === "External mgL OPTOD") {
                    optod_o2_mgl_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    optod_o2_mgl_dataset.data.push(parseFloat(report[i].value));
                    if (optod_o2_mgl_dataset.highest === null || (parseFloat(optod_o2_mgl_dataset.highest) < parseFloat(report[i].value))) {
                        optod_o2_mgl_dataset.highest = parseFloat(report[i].value);
                    }
                    if (optod_o2_mgl_dataset.lowest === null || (parseFloat(optod_o2_mgl_dataset.lowest) > parseFloat(report[i].value))) {
                        optod_o2_mgl_dataset.lowest = parseFloat(report[i].value);
                    }
                    optod_o2_mgl_dataset.total_sum += parseFloat(report[i].value);
                    optod_o2_mgl_dataset.pcs += 1;
                    this.setState({
                        optod_o2_mgl_text: t(report[i].display_name)
                    });
                // optod_temp_dataset
                } else if (report[i].name === "External temperature OPTOD") {
                    optod_temp_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    optod_temp_dataset.data.push(parseFloat(report[i].value));
                    if (optod_temp_dataset.highest === null || (parseFloat(optod_temp_dataset.highest) < parseFloat(report[i].value))) {
                        optod_temp_dataset.highest = parseFloat(report[i].value);
                    }
                    if (optod_temp_dataset.lowest === null || (parseFloat(optod_temp_dataset.lowest) > parseFloat(report[i].value))) {
                        optod_temp_dataset.lowest = parseFloat(report[i].value);
                    }
                    optod_temp_dataset.total_sum += parseFloat(report[i].value);
                    optod_temp_dataset.pcs += 1;
                    this.setState({
                        optod_temp_text: t(report[i].display_name)
                    });

                // PHEHT temp
                } else if (report[i].name === "External temperature PHEHT") {
                    pheht_temp_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pheht_temp_dataset.data.push(parseFloat(report[i].value));
                    if (pheht_temp_dataset.highest === null || (parseFloat(pheht_temp_dataset.highest) < parseFloat(report[i].value))) {
                        pheht_temp_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pheht_temp_dataset.lowest === null || (parseFloat(pheht_temp_dataset.lowest) > parseFloat(report[i].value))) {
                        pheht_temp_dataset.lowest = parseFloat(report[i].value);
                    }
                    pheht_temp_dataset.total_sum += parseFloat(report[i].value);
                    pheht_temp_dataset.pcs += 1;
                    this.setState({
                        pheht_temp_text: t(report[i].display_name)
                    });
                // PHEHT pH
                } else if (report[i].name === "External pH PHEHT") {
                    pheht_ph_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pheht_pH_dataset.data.push(parseFloat(report[i].value));
                    if (pheht_pH_dataset.highest === null || (parseFloat(pheht_pH_dataset.highest) < parseFloat(report[i].value))) {
                        pheht_pH_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pheht_pH_dataset.lowest === null || (parseFloat(pheht_pH_dataset.lowest) > parseFloat(report[i].value))) {
                        pheht_pH_dataset.lowest = parseFloat(report[i].value);
                    }
                    pheht_pH_dataset.total_sum += parseFloat(report[i].value);
                    pheht_pH_dataset.pcs += 1;
                    this.setState({
                        pheht_ph_text: t(report[i].display_name)
                    });
                // PHEHT redox
                } else if (report[i].name === "External redox PHEHT") {
                    pheht_redox_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pheht_redox_dataset.data.push(parseFloat(report[i].value));
                    if (pheht_redox_dataset.highest === null || (parseFloat(pheht_redox_dataset.highest) < parseFloat(report[i].value))) {
                        pheht_redox_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pheht_redox_dataset.lowest === null || (parseFloat(pheht_redox_dataset.lowest) > parseFloat(report[i].value))) {
                        pheht_redox_dataset.lowest = parseFloat(report[i].value);
                    }
                    pheht_redox_dataset.total_sum += parseFloat(report[i].value);
                    pheht_redox_dataset.pcs += 1;
                    this.setState({
                        pheht_redox_text: t(report[i].display_name)
                    });
                // PHEHT pH mV
                } else if (report[i].name === "External pH mV PHEHT") {
                    pheht_ph_mv_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pheht_pH_mV_dataset.data.push(parseFloat(report[i].value));
                    if (pheht_pH_mV_dataset.highest === null || (parseFloat(pheht_pH_mV_dataset.highest) < parseFloat(report[i].value))) {
                        pheht_pH_mV_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pheht_pH_mV_dataset.lowest === null || (parseFloat(pheht_pH_mV_dataset.lowest) > parseFloat(report[i].value))) {
                        pheht_pH_mV_dataset.lowest = parseFloat(report[i].value);
                    }
                    pheht_pH_mV_dataset.total_sum += parseFloat(report[i].value);
                    pheht_pH_mV_dataset.pcs += 1;
                    this.setState({
                        pheht_ph_mv_text: t(report[i].display_name)
                    });
                // C4E temp
                } else if (report[i].name === "External temperature C4E") {
                    c4e_temp_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    c4e_temp_dataset.data.push(parseFloat(report[i].value));
                    if (c4e_temp_dataset.highest === null || (parseFloat(c4e_temp_dataset.highest) < parseFloat(report[i].value))) {
                        c4e_temp_dataset.highest = parseFloat(report[i].value);
                    }
                    if (c4e_temp_dataset.lowest === null || (parseFloat(c4e_temp_dataset.lowest) > parseFloat(report[i].value))) {
                        c4e_temp_dataset.lowest = parseFloat(report[i].value);
                    }
                    c4e_temp_dataset.total_sum += parseFloat(report[i].value);
                    c4e_temp_dataset.pcs += 1;
                    this.setState({
                        c4e_temp_text: t(report[i].display_name)
                    });
                // C4E conductivity
                } else if (report[i].name === "External conductivity C4E") {
                    c4e_conductivity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    c4e_conductivity_dataset.data.push(parseFloat(report[i].value));
                    if (c4e_conductivity_dataset.highest === null || (parseFloat(c4e_conductivity_dataset.highest) < parseFloat(report[i].value))) {
                        c4e_conductivity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (c4e_conductivity_dataset.lowest === null || (parseFloat(c4e_conductivity_dataset.lowest) > parseFloat(report[i].value))) {
                        c4e_conductivity_dataset.lowest = parseFloat(report[i].value);
                    }
                    c4e_conductivity_dataset.total_sum += parseFloat(report[i].value);
                    c4e_conductivity_dataset.pcs += 1;
                    this.setState({
                        c4e_conductivity_text: t(report[i].display_name)
                    });
                // C4E salinity
                } else if (report[i].name === "External salinity C4E") {
                    c4e_salinity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    c4e_salinity_dataset.data.push(parseFloat(report[i].value));
                    if (c4e_salinity_dataset.highest === null || (parseFloat(c4e_salinity_dataset.highest) < parseFloat(report[i].value))) {
                        c4e_salinity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (c4e_salinity_dataset.lowest === null || (parseFloat(c4e_salinity_dataset.lowest) > parseFloat(report[i].value))) {
                        c4e_salinity_dataset.lowest = parseFloat(report[i].value);
                    }
                    c4e_salinity_dataset.total_sum += parseFloat(report[i].value);
                    c4e_salinity_dataset.pcs += 1;
                    this.setState({
                        c4e_salinity_text: t(report[i].display_name)
                    });
                // C4E Total dissolved solids
                } else if (report[i].name === "External tot dissolved solids C4E") {
                    c4e_tot_dissolved_solids_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    c4e_tot_dissolved_solids_dataset.data.push(parseFloat(report[i].value));
                    if (c4e_tot_dissolved_solids_dataset.highest === null || (parseFloat(c4e_tot_dissolved_solids_dataset.highest) < parseFloat(report[i].value))) {
                        c4e_tot_dissolved_solids_dataset.highest = parseFloat(report[i].value);
                    }
                    if (c4e_tot_dissolved_solids_dataset.lowest === null || (parseFloat(c4e_tot_dissolved_solids_dataset.lowest) > parseFloat(report[i].value))) {
                        c4e_tot_dissolved_solids_dataset.lowest = parseFloat(report[i].value);
                    }
                    c4e_tot_dissolved_solids_dataset.total_sum += parseFloat(report[i].value);
                    c4e_tot_dissolved_solids_dataset.pcs += 1;
                    this.setState({
                        c4e_tot_dissolved_solids_text: t(report[i].display_name)
                    });
                // NTU temp
                } else if (report[i].name === "External temperature NTU") {
                    ntu_temp_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    ntu_temp_dataset.data.push(parseFloat(report[i].value));
                    if (ntu_temp_dataset.highest === null || (parseFloat(ntu_temp_dataset.highest) < parseFloat(report[i].value))) {
                        ntu_temp_dataset.highest = parseFloat(report[i].value);
                    }
                    if (ntu_temp_dataset.lowest === null || (parseFloat(ntu_temp_dataset.lowest) > parseFloat(report[i].value))) {
                        ntu_temp_dataset.lowest = parseFloat(report[i].value);
                    }
                    ntu_temp_dataset.total_sum += parseFloat(report[i].value);
                    ntu_temp_dataset.pcs += 1;
                    this.setState({
                        ntu_temp_text: t(report[i].display_name)
                    });
                // NTU turbidity NTU
                } else if (report[i].name === "External turbidity ntu NTU") {
                    ntu_turbidity_ntu_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    ntu_turbidity_ntu_dataset.data.push(parseFloat(report[i].value));
                    if (ntu_turbidity_ntu_dataset.highest === null || (parseFloat(ntu_turbidity_ntu_dataset.highest) < parseFloat(report[i].value))) {
                        ntu_turbidity_ntu_dataset.highest = parseFloat(report[i].value);
                    }
                    if (ntu_turbidity_ntu_dataset.lowest === null || (parseFloat(ntu_turbidity_ntu_dataset.lowest) > parseFloat(report[i].value))) {
                        ntu_turbidity_ntu_dataset.lowest = parseFloat(report[i].value);
                    }
                    ntu_turbidity_ntu_dataset.total_sum += parseFloat(report[i].value);
                    ntu_turbidity_ntu_dataset.pcs += 1;
                    this.setState({
                        ntu_turbidity_ntu_text: t(report[i].display_name)
                    });
                // NTU turbidity FNU
                } else if (report[i].name === "External turbidity fnu NTU") {
                    ntu_turbidity_fnu_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    ntu_turbidity_fnu_dataset.data.push(parseFloat(report[i].value));
                    if (ntu_turbidity_fnu_dataset.highest === null || (parseFloat(ntu_turbidity_fnu_dataset.highest) < parseFloat(report[i].value))) {
                        ntu_turbidity_fnu_dataset.highest = parseFloat(report[i].value);
                    }
                    if (ntu_turbidity_fnu_dataset.lowest === null || (parseFloat(ntu_turbidity_fnu_dataset.lowest) > parseFloat(report[i].value))) {
                        ntu_turbidity_fnu_dataset.lowest = parseFloat(report[i].value);
                    }
                    ntu_turbidity_fnu_dataset.total_sum += parseFloat(report[i].value);
                    ntu_turbidity_fnu_dataset.pcs += 1;
                    this.setState({
                        ntu_turbidity_fnu_text: t(report[i].display_name)
                    });
                // NTU tu turbidity
                } else if (report[i].name === "External tu turbidity NTU") {
                    ntu_tu_turbidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    ntu_tu_turbidity_dataset.data.push(parseFloat(report[i].value));
                    if (ntu_tu_turbidity_dataset.highest === null || (parseFloat(ntu_tu_turbidity_dataset.highest) < parseFloat(report[i].value))) {
                        ntu_tu_turbidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (ntu_tu_turbidity_dataset.lowest === null || (parseFloat(ntu_tu_turbidity_dataset.lowest) > parseFloat(report[i].value))) {
                        ntu_tu_turbidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    ntu_tu_turbidity_dataset.total_sum += parseFloat(report[i].value);
                    ntu_tu_turbidity_dataset.pcs += 1;
                    this.setState({
                        ntu_tu_turbidity_text: t(report[i].display_name)
                    });
                // STACSENSE temp
                } else if (report[i].name === "External temperature STACSENSE") {
                    stacsense_temp_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    stacsense_temp_dataset.data.push(parseFloat(report[i].value));
                    if (stacsense_temp_dataset.highest === null || (parseFloat(stacsense_temp_dataset.highest) < parseFloat(report[i].value))) {
                        stacsense_temp_dataset.highest = parseFloat(report[i].value);
                    }
                    if (stacsense_temp_dataset.lowest === null || (parseFloat(stacsense_temp_dataset.lowest) > parseFloat(report[i].value))) {
                        stacsense_temp_dataset.lowest = parseFloat(report[i].value);
                    }
                    stacsense_temp_dataset.total_sum += parseFloat(report[i].value);
                    stacsense_temp_dataset.pcs += 1;
                    this.setState({
                        stacsense_temp_text: t(report[i].display_name)
                    });
                // STACSENSE CAS254
                } else if (report[i].name === "External CAS254 STACSENSE") {
                    stacsense_cas254_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    stacsense_cas254_dataset.data.push(parseFloat(report[i].value));
                    if (stacsense_cas254_dataset.highest === null || (parseFloat(stacsense_cas254_dataset.highest) < parseFloat(report[i].value))) {
                        stacsense_cas254_dataset.highest = parseFloat(report[i].value);
                    }
                    if (stacsense_cas254_dataset.lowest === null || (parseFloat(stacsense_cas254_dataset.lowest) > parseFloat(report[i].value))) {
                        stacsense_cas254_dataset.lowest = parseFloat(report[i].value);
                    }
                    stacsense_cas254_dataset.total_sum += parseFloat(report[i].value);
                    stacsense_cas254_dataset.pcs += 1;
                    this.setState({
                        stacsense_cas254_text: t(report[i].display_name)
                    });
                // STACSENSE COD
                } else if (report[i].name === "External COD STACSENSE") {
                    stacsense_cod_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    stacsense_cod_dataset.data.push(parseFloat(report[i].value));
                    if (stacsense_cod_dataset.highest === null || (parseFloat(stacsense_cod_dataset.highest) < parseFloat(report[i].value))) {
                        stacsense_cod_dataset.highest = parseFloat(report[i].value);
                    }
                    if (stacsense_cod_dataset.lowest === null || (parseFloat(stacsense_cod_dataset.lowest) > parseFloat(report[i].value))) {
                        stacsense_cod_dataset.lowest = parseFloat(report[i].value);
                    }
                    stacsense_cod_dataset.total_sum += parseFloat(report[i].value);
                    stacsense_cod_dataset.pcs += 1;
                    this.setState({
                        stacsense_cod_text: t(report[i].display_name)
                    });
                // STACSENSE BOD
                } else if (report[i].name === "External BOD STACSENSE") {
                    stacsense_bod_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    stacsense_bod_dataset.data.push(parseFloat(report[i].value));
                    if (stacsense_bod_dataset.highest === null || (parseFloat(stacsense_bod_dataset.highest) < parseFloat(report[i].value))) {
                        stacsense_bod_dataset.highest = parseFloat(report[i].value);
                    }
                    if (stacsense_bod_dataset.lowest === null || (parseFloat(stacsense_bod_dataset.lowest) > parseFloat(report[i].value))) {
                        stacsense_bod_dataset.lowest = parseFloat(report[i].value);
                    }
                    stacsense_bod_dataset.total_sum += parseFloat(report[i].value);
                    stacsense_bod_dataset.pcs += 1;
                    this.setState({
                        stacsense_bod_text: t(report[i].display_name)
                    });
                // STACSENSE TOC
                } else if (report[i].name === "External TOC STACSENSE") {
                    stacsense_toc_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    stacsense_toc_dataset.data.push(parseFloat(report[i].value));
                    if (stacsense_toc_dataset.highest === null || (parseFloat(stacsense_toc_dataset.highest) < parseFloat(report[i].value))) {
                        stacsense_toc_dataset.highest = parseFloat(report[i].value);
                    }
                    if (stacsense_toc_dataset.lowest === null || (parseFloat(stacsense_toc_dataset.lowest) > parseFloat(report[i].value))) {
                        stacsense_toc_dataset.lowest = parseFloat(report[i].value);
                    }
                    stacsense_toc_dataset.total_sum += parseFloat(report[i].value);
                    stacsense_toc_dataset.pcs += 1;
                    this.setState({
                        stacsense_toc_text: t(report[i].display_name)
                    });
                // Air pressure
                } else if (report[i].name === "Air pressure MS5XX") {
                    pressure_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pressure_dataset.data.push(parseFloat(report[i].value));
                    if (pressure_dataset.highest === null || (parseFloat(pressure_dataset.highest) < parseFloat(report[i].value))) {
                        pressure_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pressure_dataset.lowest === null || (parseFloat(pressure_dataset.lowest) > parseFloat(report[i].value))) {
                        pressure_dataset.lowest = parseFloat(report[i].value);
                    }
                    pressure_dataset.total_sum += parseFloat(report[i].value);
                    pressure_dataset.pcs += 1;
                    this.setState({
                        pressure_text: t(report[i].display_name)
                    });
                // Temperature
                } else if (report[i].name === "Temperature" || report[i].name === "Temperature MS5XX") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;
                    this.setState({
                        temperature_text: t(report[i].display_name)
                    });
                // Battery voltage
                } else if (report[i].name === "Battery voltage") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }

            }

            if (water_level_dataset.pcs > 0) {
                water_level_dataset.average = parseFloat((water_level_dataset.total_sum / water_level_dataset.pcs).toFixed(2));
            }
            water_level_dataset.label = 
                `${water_level_dataset.label} (${t("avg")}: ${water_level_dataset.average.toString()} ${t("Highest")}: ${water_level_dataset.highest ? water_level_dataset.highest.toString() : 0} ${t("Lowest")}: ${water_level_dataset.lowest ? water_level_dataset.lowest.toString() : 0})`; 

            water_level_data.datasets.push(water_level_dataset);

            if (h21_conductivity_dataset.pcs > 0) {
                h21_conductivity_dataset.average = parseFloat((h21_conductivity_dataset.total_sum / h21_conductivity_dataset.pcs).toFixed(2));
            }
            h21_conductivity_dataset.label = 
                `${h21_conductivity_dataset.label} (${t("avg")}: ${h21_conductivity_dataset.average.toString()} ${t("Highest")}: ${h21_conductivity_dataset.highest ? h21_conductivity_dataset.highest.toString() : 0} ${t("Lowest")}: ${h21_conductivity_dataset.lowest ? h21_conductivity_dataset.lowest.toString() : 0})`; 

            h21_conductivity_data.datasets.push(h21_conductivity_dataset);

            if (h21_temp_dataset.pcs > 0) {
                h21_temp_dataset.average = parseFloat((h21_temp_dataset.total_sum / h21_temp_dataset.pcs).toFixed(2));
            }
            h21_temp_dataset.label = 
                `${h21_temp_dataset.label} (${t("avg")}: ${h21_temp_dataset.average.toString()} ${t("Highest")}: ${h21_temp_dataset.highest ? h21_temp_dataset.highest.toString() : 0} ${t("Lowest")}: ${h21_temp_dataset.lowest ? h21_temp_dataset.lowest.toString() : 0})`; 

            h21_temp_data.datasets.push(h21_temp_dataset);

            if (h21_sensor_depth_dataset.pcs > 0) {
                h21_sensor_depth_dataset.average = parseFloat((h21_sensor_depth_dataset.total_sum / h21_sensor_depth_dataset.pcs).toFixed(2));
            }
            h21_sensor_depth_dataset.label = 
                `${h21_sensor_depth_dataset.label} (${t("avg")}: ${h21_sensor_depth_dataset.average.toString()} ${t("Highest")}: ${h21_sensor_depth_dataset.highest ? h21_sensor_depth_dataset.highest.toString() : 0} ${t("Lowest")}: ${h21_sensor_depth_dataset.lowest ? h21_sensor_depth_dataset.lowest.toString() : 0})`; 

            h21_sensor_depth_data.datasets.push(h21_sensor_depth_dataset);

            if (optod_o2_sat_dataset.pcs > 0) {
                optod_o2_sat_dataset.average = parseFloat((optod_o2_sat_dataset.total_sum / optod_o2_sat_dataset.pcs).toFixed(2));
            }
            optod_o2_sat_dataset.label = 
                `${optod_o2_sat_dataset.label} (${t("avg")}: ${optod_o2_sat_dataset.average.toString()} ${t("Highest")}: ${optod_o2_sat_dataset.highest ? optod_o2_sat_dataset.highest.toString() : 0} ${t("Lowest")}: ${optod_o2_sat_dataset.lowest ? optod_o2_sat_dataset.lowest.toString() : 0})`; 

            optod_o2_sat_data.datasets.push(optod_o2_sat_dataset);

            if (optod_o2_mgl_dataset.pcs > 0) {
                optod_o2_mgl_dataset.average = parseFloat((optod_o2_mgl_dataset.total_sum / optod_o2_mgl_dataset.pcs).toFixed(2));
            }
            optod_o2_mgl_dataset.label = 
                `${optod_o2_mgl_dataset.label} (${t("avg")}: ${optod_o2_mgl_dataset.average.toString()} ${t("Highest")}: ${optod_o2_mgl_dataset.highest ? optod_o2_mgl_dataset.highest.toString() : 0} ${t("Lowest")}: ${optod_o2_mgl_dataset.lowest ? optod_o2_mgl_dataset.lowest.toString() : 0})`; 

            optod_o2_mgl_data.datasets.push(optod_o2_mgl_dataset);

            if (optod_temp_dataset.pcs > 0) {
                optod_temp_dataset.average = parseFloat((optod_temp_dataset.total_sum / optod_temp_dataset.pcs).toFixed(2));
            }
            optod_temp_dataset.label = 
                `${optod_temp_dataset.label} (${t("avg")}: ${optod_temp_dataset.average.toString()} ${t("Highest")}: ${optod_temp_dataset.highest ? optod_temp_dataset.highest.toString() : 0} ${t("Lowest")}: ${optod_temp_dataset.lowest ? optod_temp_dataset.lowest.toString() : 0})`; 

            optod_temp_data.datasets.push(optod_temp_dataset);

            // PHEHT
            if (pheht_temp_dataset.pcs > 0) {
                pheht_temp_dataset.average = parseFloat((pheht_temp_dataset.total_sum / pheht_temp_dataset.pcs).toFixed(2));
            }
            pheht_temp_dataset.label = 
                `${pheht_temp_dataset.label} (${t("avg")}: ${pheht_temp_dataset.average.toString()} ${t("Highest")}: ${pheht_temp_dataset.highest ? pheht_temp_dataset.highest.toString() : 0} ${t("Lowest")}: ${pheht_temp_dataset.lowest ? pheht_temp_dataset.lowest.toString() : 0})`; 

            pheht_temp_data.datasets.push(pheht_temp_dataset);

            if (pheht_pH_dataset.pcs > 0) {
                pheht_pH_dataset.average = parseFloat((pheht_pH_dataset.total_sum / pheht_pH_dataset.pcs).toFixed(2));
            }
            pheht_pH_dataset.label = 
                `${pheht_pH_dataset.label} (${t("avg")}: ${pheht_pH_dataset.average.toString()} ${t("Highest")}: ${pheht_pH_dataset.highest ? pheht_pH_dataset.highest.toString() : 0} ${t("Lowest")}: ${pheht_pH_dataset.lowest ? pheht_pH_dataset.lowest.toString() : 0})`; 

            pheht_ph_data.datasets.push(pheht_pH_dataset);

            if (pheht_redox_dataset.pcs > 0) {
                pheht_redox_dataset.average = parseFloat((pheht_redox_dataset.total_sum / pheht_redox_dataset.pcs).toFixed(2));
            }
            pheht_redox_dataset.label = 
                `${pheht_redox_dataset.label} (${t("avg")}: ${pheht_redox_dataset.average.toString()} ${t("Highest")}: ${pheht_redox_dataset.highest ? pheht_redox_dataset.highest.toString() : 0} ${t("Lowest")}: ${pheht_redox_dataset.lowest ? pheht_redox_dataset.lowest.toString() : 0})`; 

            pheht_redox_data.datasets.push(pheht_redox_dataset);

            if (pheht_pH_mV_dataset.pcs > 0) {
                pheht_pH_mV_dataset.average = parseFloat((pheht_pH_mV_dataset.total_sum / pheht_pH_mV_dataset.pcs).toFixed(2));
            }
            pheht_pH_mV_dataset.label = 
                `${pheht_pH_mV_dataset.label} (${t("avg")}: ${pheht_pH_mV_dataset.average.toString()} ${t("Highest")}: ${pheht_pH_mV_dataset.highest ? pheht_pH_mV_dataset.highest.toString() : 0} ${t("Lowest")}: ${pheht_pH_mV_dataset.lowest ? pheht_pH_mV_dataset.lowest.toString() : 0})`; 

            pheht_ph_mv_data.datasets.push(pheht_pH_mV_dataset);

            // C4E
            if (c4e_temp_dataset.pcs > 0) {
                c4e_temp_dataset.average = parseFloat((c4e_temp_dataset.total_sum / c4e_temp_dataset.pcs).toFixed(2));
            }
            c4e_temp_dataset.label = 
                `${c4e_temp_dataset.label} (${t("avg")}: ${c4e_temp_dataset.average.toString()} ${t("Highest")}: ${c4e_temp_dataset.highest ? c4e_temp_dataset.highest.toString() : 0} ${t("Lowest")}: ${c4e_temp_dataset.lowest ? c4e_temp_dataset.lowest.toString() : 0})`; 

            c4e_temp_data.datasets.push(c4e_temp_dataset);

            if (c4e_conductivity_dataset.pcs > 0) {
                c4e_conductivity_dataset.average = parseFloat((c4e_conductivity_dataset.total_sum / c4e_conductivity_dataset.pcs).toFixed(2));
            }
            c4e_conductivity_dataset.label = 
                `${c4e_conductivity_dataset.label} (${t("avg")}: ${c4e_conductivity_dataset.average.toString()} ${t("Highest")}: ${c4e_conductivity_dataset.highest ? c4e_conductivity_dataset.highest.toString() : 0} ${t("Lowest")}: ${c4e_conductivity_dataset.lowest ? c4e_conductivity_dataset.lowest.toString() : 0})`; 

            c4e_conductivity_data.datasets.push(c4e_conductivity_dataset);

            if (c4e_salinity_dataset.pcs > 0) {
                c4e_salinity_dataset.average = parseFloat((c4e_salinity_dataset.total_sum / c4e_salinity_dataset.pcs).toFixed(2));
            }
            c4e_salinity_dataset.label = 
                `${c4e_salinity_dataset.label} (${t("avg")}: ${c4e_salinity_dataset.average.toString()} ${t("Highest")}: ${c4e_salinity_dataset.highest ? c4e_salinity_dataset.highest.toString() : 0} ${t("Lowest")}: ${c4e_salinity_dataset.lowest ? c4e_salinity_dataset.lowest.toString() : 0})`; 

            c4e_salinity_data.datasets.push(c4e_salinity_dataset);

            if (c4e_tot_dissolved_solids_dataset.pcs > 0) {
                c4e_tot_dissolved_solids_dataset.average = parseFloat((c4e_tot_dissolved_solids_dataset.total_sum / c4e_tot_dissolved_solids_dataset.pcs).toFixed(2));
            }
            c4e_tot_dissolved_solids_dataset.label = 
                `${c4e_tot_dissolved_solids_dataset.label} (${t("avg")}: ${c4e_tot_dissolved_solids_dataset.average.toString()} ${t("Highest")}: ${c4e_tot_dissolved_solids_dataset.highest ? c4e_tot_dissolved_solids_dataset.highest.toString() : 0} ${t("Lowest")}: ${c4e_tot_dissolved_solids_dataset.lowest ? c4e_tot_dissolved_solids_dataset.lowest.toString() : 0})`; 

            c4e_tot_dissolved_solids_data.datasets.push(c4e_tot_dissolved_solids_dataset);

            // NTU
            if (ntu_temp_dataset.pcs > 0) {
                ntu_temp_dataset.average = parseFloat((ntu_temp_dataset.total_sum / ntu_temp_dataset.pcs).toFixed(2));
            }
            ntu_temp_dataset.label = 
                `${ntu_temp_dataset.label} (${t("avg")}: ${ntu_temp_dataset.average.toString()} ${t("Highest")}: ${ntu_temp_dataset.highest ? ntu_temp_dataset.highest.toString() : 0} ${t("Lowest")}: ${ntu_temp_dataset.lowest ? ntu_temp_dataset.lowest.toString() : 0})`; 

            ntu_temp_data.datasets.push(ntu_temp_dataset);

            if (ntu_turbidity_ntu_dataset.pcs > 0) {
                ntu_turbidity_ntu_dataset.average = parseFloat((ntu_turbidity_ntu_dataset.total_sum / ntu_turbidity_ntu_dataset.pcs).toFixed(2));
            }
            ntu_turbidity_ntu_dataset.label = 
                `${ntu_turbidity_ntu_dataset.label} (${t("avg")}: ${ntu_turbidity_ntu_dataset.average.toString()} ${t("Highest")}: ${ntu_turbidity_ntu_dataset.highest ? ntu_turbidity_ntu_dataset.highest.toString() : 0} ${t("Lowest")}: ${ntu_turbidity_ntu_dataset.lowest ? ntu_turbidity_ntu_dataset.lowest.toString() : 0})`; 

            ntu_turbidity_ntu_data.datasets.push(ntu_turbidity_ntu_dataset);

            if (ntu_turbidity_fnu_dataset.pcs > 0) {
                ntu_turbidity_fnu_dataset.average = parseFloat((ntu_turbidity_fnu_dataset.total_sum / ntu_turbidity_fnu_dataset.pcs).toFixed(2));
            }
            ntu_turbidity_fnu_dataset.label = 
                `${ntu_turbidity_fnu_dataset.label} (${t("avg")}: ${ntu_turbidity_fnu_dataset.average.toString()} ${t("Highest")}: ${ntu_turbidity_fnu_dataset.highest ? ntu_turbidity_fnu_dataset.highest.toString() : 0} ${t("Lowest")}: ${ntu_turbidity_fnu_dataset.lowest ? ntu_turbidity_fnu_dataset.lowest.toString() : 0})`; 

            ntu_turbidity_fnu_data.datasets.push(ntu_turbidity_fnu_dataset);

            if (ntu_tu_turbidity_dataset.pcs > 0) {
                ntu_tu_turbidity_dataset.average = parseFloat((ntu_tu_turbidity_dataset.total_sum / ntu_tu_turbidity_dataset.pcs).toFixed(2));
            }
            ntu_tu_turbidity_dataset.label = 
                `${ntu_tu_turbidity_dataset.label} (${t("avg")}: ${ntu_tu_turbidity_dataset.average.toString()} ${t("Highest")}: ${ntu_tu_turbidity_dataset.highest ? ntu_tu_turbidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${ntu_tu_turbidity_dataset.lowest ? ntu_tu_turbidity_dataset.lowest.toString() : 0})`; 

            ntu_tu_turbidity_data.datasets.push(ntu_tu_turbidity_dataset);

            // STACSENSE
            if (stacsense_temp_dataset.pcs > 0) {
                stacsense_temp_dataset.average = parseFloat((stacsense_temp_dataset.total_sum / stacsense_temp_dataset.pcs).toFixed(2));
            }
            stacsense_temp_dataset.label = 
                `${stacsense_temp_dataset.label} (${t("avg")}: ${stacsense_temp_dataset.average.toString()} ${t("Highest")}: ${stacsense_temp_dataset.highest ? stacsense_temp_dataset.highest.toString() : 0} ${t("Lowest")}: ${stacsense_temp_dataset.lowest ? stacsense_temp_dataset.lowest.toString() : 0})`; 

            stacsense_temp_data.datasets.push(stacsense_temp_dataset);

            if (stacsense_cas254_dataset.pcs > 0) {
                stacsense_cas254_dataset.average = parseFloat((stacsense_cas254_dataset.total_sum / stacsense_cas254_dataset.pcs).toFixed(2));
            }
            stacsense_cas254_dataset.label = 
                `${stacsense_cas254_dataset.label} (${t("avg")}: ${stacsense_cas254_dataset.average.toString()} ${t("Highest")}: ${stacsense_cas254_dataset.highest ? stacsense_cas254_dataset.highest.toString() : 0} ${t("Lowest")}: ${stacsense_cas254_dataset.lowest ? stacsense_cas254_dataset.lowest.toString() : 0})`; 

            stacsense_cas254_data.datasets.push(stacsense_cas254_dataset);

            if (stacsense_cod_dataset.pcs > 0) {
                stacsense_cod_dataset.average = parseFloat((stacsense_cod_dataset.total_sum / stacsense_cod_dataset.pcs).toFixed(2));
            }
            stacsense_cod_dataset.label = 
                `${stacsense_cod_dataset.label} (${t("avg")}: ${stacsense_cod_dataset.average.toString()} ${t("Highest")}: ${stacsense_cod_dataset.highest ? stacsense_cod_dataset.highest.toString() : 0} ${t("Lowest")}: ${stacsense_cod_dataset.lowest ? stacsense_cod_dataset.lowest.toString() : 0})`; 

            stacsense_cod_data.datasets.push(stacsense_cod_dataset);

            if (stacsense_bod_dataset.pcs > 0) {
                stacsense_bod_dataset.average = parseFloat((stacsense_bod_dataset.total_sum / stacsense_bod_dataset.pcs).toFixed(2));
            }
            stacsense_bod_dataset.label = 
                `${stacsense_bod_dataset.label} (${t("avg")}: ${stacsense_bod_dataset.average.toString()} ${t("Highest")}: ${stacsense_bod_dataset.highest ? stacsense_bod_dataset.highest.toString() : 0} ${t("Lowest")}: ${stacsense_bod_dataset.lowest ? stacsense_bod_dataset.lowest.toString() : 0})`; 

            stacsense_bod_data.datasets.push(stacsense_bod_dataset);

            if (stacsense_toc_dataset.pcs > 0) {
                stacsense_toc_dataset.average = parseFloat((stacsense_toc_dataset.total_sum / stacsense_toc_dataset.pcs).toFixed(2));
            }
            stacsense_toc_dataset.label = 
                `${stacsense_toc_dataset.label} (${t("avg")}: ${stacsense_toc_dataset.average.toString()} ${t("Highest")}: ${stacsense_toc_dataset.highest ? stacsense_toc_dataset.highest.toString() : 0} ${t("Lowest")}: ${stacsense_toc_dataset.lowest ? stacsense_toc_dataset.lowest.toString() : 0})`; 

            stacsense_toc_data.datasets.push(stacsense_toc_dataset);

            if (pressure_dataset.pcs > 0) {
                pressure_dataset.average = parseFloat((pressure_dataset.total_sum / pressure_dataset.pcs).toFixed(2));
            }
            pressure_dataset.label = 
                `${pressure_dataset.label} (${t("avg")}: ${pressure_dataset.average.toString()} ${t("Highest")}: ${pressure_dataset.highest ? pressure_dataset.highest.toString() : 0} ${t("Lowest")}: ${pressure_dataset.lowest ? pressure_dataset.lowest.toString() : 0})`; 

            pressure_data.datasets.push(pressure_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);

            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);
    
            this.setState({
                water_level_data: water_level_data,
                h21_conductivity_data: h21_conductivity_data,
                h21_sensor_depth_data: h21_sensor_depth_data,
                h21_temp_data: h21_temp_data,
                optod_o2_sat_data: optod_o2_sat_data,
                optod_o2_mgl_data: optod_o2_mgl_data,
                optod_temp_data: optod_temp_data,
                pheht_temp_data: pheht_temp_data,
                pheht_pH_data: pheht_ph_data,
                pheht_redox_data: pheht_redox_data,
                pheht_ph_mv_data: pheht_ph_mv_data,
                c4e_temp_data: c4e_temp_data,
                c4e_conductivity_data: c4e_conductivity_data,
                c4e_salinity_data: c4e_salinity_data,
                c4e_tot_dissolved_solids_data: c4e_tot_dissolved_solids_data,
                ntu_temp_data: ntu_temp_data,
                ntu_turbidity_ntu_data: ntu_turbidity_ntu_data,
                ntu_turbidity_fnu_data: ntu_turbidity_fnu_data,
                ntu_tu_turbidity_data: ntu_tu_turbidity_data,
                stacsense_temp_data: stacsense_temp_data,
                stacsense_cas254_data: stacsense_cas254_data,
                stacsense_cod_data: stacsense_cod_data,
                stacsense_bod_data: stacsense_bod_data,
                stacsense_toc_data: stacsense_toc_data,
                pressure_data: pressure_data,
                temperature_data: temperature_data,
                battery_data: battery_data,
                loading: false
            })
        } else if (sensor.model_name.startsWith("CAPROC-AQUA-26") || sensor.model_name.startsWith("DECENTLAB-DL-PR26") || sensor.model_name.startsWith("CAPROC-AQUA-8010") || sensor.model_name.startsWith("CAPROC-AQUA-36")) {
            let { water_level_data, water_temp_data, sensor_depth_data, water_pressure_data, water_pressure_abs_data, water_comp_conductivity_data, water_raw_conductivity_data, temperature_data, pressure_data, battery_data} = this.state;

            water_level_data.labels = [];
            water_level_data.datasets = [];

            water_temp_data.labels = [];
            water_temp_data.datasets = [];

            water_pressure_data.labels = [];
            water_pressure_data.datasets = [];

            water_pressure_abs_data.labels = [];
            water_pressure_abs_data.datasets = [];

            water_comp_conductivity_data.labels = [];
            water_comp_conductivity_data.datasets = [];

            water_raw_conductivity_data.labels = [];
            water_raw_conductivity_data.datasets = [];

            sensor_depth_data.labels = [];
            sensor_depth_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            pressure_data.labels = [];
            pressure_data.datasets = [];

            battery_data.labels = [];
            battery_data.datasets = [];

            // Water level
            let water_level_dataset = {
                ...default_dataset_blue,
                label: t("Water level (from sea level)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Water temperature
            let water_temp_dataset = {
                ...default_dataset_red,
                label: t("Water temperature"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            // Water pressure
            let water_pressure_dataset = {
                ...default_dataset_red,
                label: t("Water pressure"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Water pressure (absolute)
            let water_pressure_abs_dataset = {
                ...default_dataset_red,
                label: t("Water pressure (absolute)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            // Water conductivity (temperature compensated)
            let water_comp_conductivity_dataset = {
                ...default_dataset_red,
                label: t("Temp compensated conductivity"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Water conductivity (raw)
            let water_raw_conductivity_dataset = {
                ...default_dataset_red,
                label: t("Raw conductivity"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Sensor depth
            let sensor_depth_dataset = {
                ...default_dataset_green,
                label: t("Sensor depth"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Atmosphere pressure
            let pressure_dataset = {
                ...default_dataset_red,
                label: t("Air pressure"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Temperature
            let temperature_dataset = {
                ...default_dataset_red,
                label: t("Temperature"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Water level
                if (report[i].name === "Water level") {
                    water_level_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    water_level_dataset.data.push(parseFloat(report[i].value));
                    if (water_level_dataset.highest === null || (parseFloat(water_level_dataset.highest) < parseFloat(report[i].value))) {
                        water_level_dataset.highest = parseFloat(report[i].value);
                    }
                    if (water_level_dataset.lowest === null || (parseFloat(water_level_dataset.lowest) > parseFloat(report[i].value))) {
                        water_level_dataset.lowest = parseFloat(report[i].value);
                    }
                    water_level_dataset.total_sum += parseFloat(report[i].value);
                    water_level_dataset.pcs += 1;
                    this.setState({
                        water_level_text: t(report[i].display_name)
                    });

                // Water pressure
                } else if (report[i].name.startsWith("External water pressure")) {
                    water_pressure_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    water_pressure_dataset.data.push(parseFloat(report[i].value));
                    if (water_pressure_dataset.highest === null || (parseFloat(water_pressure_dataset.highest) < parseFloat(report[i].value))) {
                        water_pressure_dataset.highest = parseFloat(report[i].value);
                    }
                    if (water_pressure_dataset.lowest === null || (parseFloat(water_pressure_dataset.lowest) > parseFloat(report[i].value))) {
                        water_pressure_dataset.lowest = parseFloat(report[i].value);
                    }
                    water_pressure_dataset.total_sum += parseFloat(report[i].value);
                    water_pressure_dataset.pcs += 1;
                    this.setState({
                        water_pressure_text: t(report[i].display_name)
                    });
                // Water pressure (absolute)
                } else if (report[i].name.startsWith("External absolute water pressure")) {
                    water_pressure_abs_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    water_pressure_abs_dataset.data.push(parseFloat(report[i].value));
                    if (water_pressure_abs_dataset.highest === null || (parseFloat(water_pressure_abs_dataset.highest) < parseFloat(report[i].value))) {
                        water_pressure_abs_dataset.highest = parseFloat(report[i].value);
                    }
                    if (water_pressure_abs_dataset.lowest === null || (parseFloat(water_pressure_abs_dataset.lowest) > parseFloat(report[i].value))) {
                        water_pressure_abs_dataset.lowest = parseFloat(report[i].value);
                    }
                    water_pressure_abs_dataset.total_sum += parseFloat(report[i].value);
                    water_pressure_abs_dataset.pcs += 1;
                    this.setState({
                        water_pressure_abs_text: t(report[i].display_name)
                    });
            
                // Water temperature
                } else if (report[i].name.startsWith("External water temperature")) {
                    water_temp_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    water_temp_dataset.data.push(parseFloat(report[i].value));
                    if (water_temp_dataset.highest === null || (parseFloat(water_temp_dataset.highest) < parseFloat(report[i].value))) {
                        water_temp_dataset.highest = parseFloat(report[i].value);
                    }
                    if (water_temp_dataset.lowest === null || (parseFloat(water_temp_dataset.lowest) > parseFloat(report[i].value))) {
                        water_temp_dataset.lowest = parseFloat(report[i].value);
                    }
                    water_temp_dataset.total_sum += parseFloat(report[i].value);
                    water_temp_dataset.pcs += 1;
                    this.setState({
                        water_temp_text: t(report[i].display_name)
                    });
                // Sensor depth
                } else if (report[i].name.startsWith("External sensor depth")) {
                    sensor_depth_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    sensor_depth_dataset.data.push(parseFloat(report[i].value));
                    if (sensor_depth_dataset.highest === null || (parseFloat(sensor_depth_dataset.highest) < parseFloat(report[i].value))) {
                        sensor_depth_dataset.highest = parseFloat(report[i].value);
                    }
                    if (sensor_depth_dataset.lowest === null || (parseFloat(sensor_depth_dataset.lowest) > parseFloat(report[i].value))) {
                        sensor_depth_dataset.lowest = parseFloat(report[i].value);
                    }
                    sensor_depth_dataset.total_sum += parseFloat(report[i].value);
                    sensor_depth_dataset.pcs += 1;
                    this.setState({
                        sensor_depth_text: t(report[i].display_name)
                    });
                // Conductivity (temp compensated)
                } else if (report[i].name.startsWith("External water temp compensated conductivity")) {
                    water_comp_conductivity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    water_comp_conductivity_dataset.data.push(parseFloat(report[i].value));
                    if (water_comp_conductivity_dataset.highest === null || (parseFloat(water_comp_conductivity_dataset.highest) < parseFloat(report[i].value))) {
                        water_comp_conductivity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (water_comp_conductivity_dataset.lowest === null || (parseFloat(water_comp_conductivity_dataset.lowest) > parseFloat(report[i].value))) {
                        water_comp_conductivity_dataset.lowest = parseFloat(report[i].value);
                    }
                    water_comp_conductivity_dataset.total_sum += parseFloat(report[i].value);
                    water_comp_conductivity_dataset.pcs += 1;
                    this.setState({
                        water_comp_conductivity_text: t(report[i].display_name)
                    });
                // Conductivity (raw)
                } else if (report[i].name.startsWith("External raw water conductivity")) {
                    water_raw_conductivity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    water_raw_conductivity_dataset.data.push(parseFloat(report[i].value));
                    if (water_raw_conductivity_dataset.highest === null || (parseFloat(water_raw_conductivity_dataset.highest) < parseFloat(report[i].value))) {
                        water_raw_conductivity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (water_raw_conductivity_dataset.lowest === null || (parseFloat(water_raw_conductivity_dataset.lowest) > parseFloat(report[i].value))) {
                        water_raw_conductivity_dataset.lowest = parseFloat(report[i].value);
                    }
                    water_raw_conductivity_dataset.total_sum += parseFloat(report[i].value);
                    water_raw_conductivity_dataset.pcs += 1;
                    this.setState({
                        water_raw_conductivity_text: t(report[i].display_name)
                    });

                // Air pressure
                } else if (report[i].name.startsWith("Air pressure")) {
                    pressure_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pressure_dataset.data.push(parseFloat(report[i].value));
                    if (pressure_dataset.highest === null || (parseFloat(pressure_dataset.highest) < parseFloat(report[i].value))) {
                        pressure_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pressure_dataset.lowest === null || (parseFloat(pressure_dataset.lowest) > parseFloat(report[i].value))) {
                        pressure_dataset.lowest = parseFloat(report[i].value);
                    }
                    pressure_dataset.total_sum += parseFloat(report[i].value);
                    pressure_dataset.pcs += 1;
                    this.setState({
                        pressure_text: t(report[i].display_name)
                    });
                // Temperature
                } else if (report[i].name.startsWith("Temperature")) {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;
                    this.setState({
                        temperature_text: t(report[i].display_name)
                    });
                // Battery voltage
                } else if (report[i].name === "Battery voltage") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }

            }

            if (water_level_dataset.pcs > 0) {
                water_level_dataset.average = parseFloat((water_level_dataset.total_sum / water_level_dataset.pcs).toFixed(2));
            }
            water_level_dataset.label = 
                `${water_level_dataset.label} (${t("avg")}: ${water_level_dataset.average.toString()} ${t("Highest")}: ${water_level_dataset.highest ? water_level_dataset.highest.toString() : 0} ${t("Lowest")}: ${water_level_dataset.lowest ? water_level_dataset.lowest.toString() : 0})`; 

            water_level_data.datasets.push(water_level_dataset);

            if (water_pressure_dataset.pcs > 0) {
                water_pressure_dataset.average = parseFloat((water_pressure_dataset.total_sum / water_pressure_dataset.pcs).toFixed(2));
            }
            water_pressure_dataset.label = 
                `${water_pressure_dataset.label} (${t("avg")}: ${water_pressure_dataset.average.toString()} ${t("Highest")}: ${water_pressure_dataset.highest ? water_pressure_dataset.highest.toString() : 0} ${t("Lowest")}: ${water_pressure_dataset.lowest ? water_pressure_dataset.lowest.toString() : 0})`; 

            water_pressure_data.datasets.push(water_pressure_dataset);

            if (water_pressure_abs_dataset.pcs > 0) {
                water_pressure_abs_dataset.average = parseFloat((water_pressure_abs_dataset.total_sum / water_pressure_abs_dataset.pcs).toFixed(2));
            }
            water_pressure_abs_dataset.label = 
                `${water_pressure_abs_dataset.label} (${t("avg")}: ${water_pressure_abs_dataset.average.toString()} ${t("Highest")}: ${water_pressure_abs_dataset.highest ? water_pressure_abs_dataset.highest.toString() : 0} ${t("Lowest")}: ${water_pressure_abs_dataset.lowest ? water_pressure_abs_dataset.lowest.toString() : 0})`; 

            water_pressure_abs_data.datasets.push(water_pressure_abs_dataset);

            if (water_comp_conductivity_dataset.pcs > 0) {
                water_comp_conductivity_dataset.average = parseFloat((water_comp_conductivity_dataset.total_sum / water_comp_conductivity_dataset.pcs).toFixed(2));
            }
            water_comp_conductivity_dataset.label = 
                `${water_comp_conductivity_dataset.label} (${t("avg")}: ${water_comp_conductivity_dataset.average.toString()} ${t("Highest")}: ${water_comp_conductivity_dataset.highest ? water_comp_conductivity_dataset.highest.toString() : 0} ${t("Lowest")}: ${water_comp_conductivity_dataset.lowest ? water_comp_conductivity_dataset.lowest.toString() : 0})`; 

            water_comp_conductivity_data.datasets.push(water_comp_conductivity_dataset);

            if (water_raw_conductivity_dataset.pcs > 0) {
                water_raw_conductivity_dataset.average = parseFloat((water_raw_conductivity_dataset.total_sum / water_raw_conductivity_dataset.pcs).toFixed(2));
            }
            water_raw_conductivity_dataset.label = 
                `${water_raw_conductivity_dataset.label} (${t("avg")}: ${water_raw_conductivity_dataset.average.toString()} ${t("Highest")}: ${water_raw_conductivity_dataset.highest ? water_raw_conductivity_dataset.highest.toString() : 0} ${t("Lowest")}: ${water_raw_conductivity_dataset.lowest ? water_raw_conductivity_dataset.lowest.toString() : 0})`; 

            water_raw_conductivity_data.datasets.push(water_raw_conductivity_dataset);

            if (water_temp_dataset.pcs > 0) {
                water_temp_dataset.average = parseFloat((water_temp_dataset.total_sum / water_temp_dataset.pcs).toFixed(2));
            }
            water_temp_dataset.label = 
                `${water_temp_dataset.label} (${t("avg")}: ${water_temp_dataset.average.toString()} ${t("Highest")}: ${water_temp_dataset.highest ? water_temp_dataset.highest.toString() : 0} ${t("Lowest")}: ${water_temp_dataset.lowest ? water_temp_dataset.lowest.toString() : 0})`; 

            water_temp_data.datasets.push(water_temp_dataset);

            if (sensor_depth_dataset.pcs > 0) {
                sensor_depth_dataset.average = parseFloat((sensor_depth_dataset.total_sum / sensor_depth_dataset.pcs).toFixed(2));
            }
            sensor_depth_dataset.label = 
                `${sensor_depth_dataset.label} (${t("avg")}: ${sensor_depth_dataset.average.toString()} ${t("Highest")}: ${sensor_depth_dataset.highest ? sensor_depth_dataset.highest.toString() : 0} ${t("Lowest")}: ${sensor_depth_dataset.lowest ? sensor_depth_dataset.lowest.toString() : 0})`; 
    
            sensor_depth_data.datasets.push(sensor_depth_dataset);

            if (pressure_dataset.pcs > 0) {
                pressure_dataset.average = parseFloat((pressure_dataset.total_sum / pressure_dataset.pcs).toFixed(2));
            }
            pressure_dataset.label = 
                `${pressure_dataset.label} (${t("avg")}: ${pressure_dataset.average.toString()} ${t("Highest")}: ${pressure_dataset.highest ? pressure_dataset.highest.toString() : 0} ${t("Lowest")}: ${pressure_dataset.lowest ? pressure_dataset.lowest.toString() : 0})`; 

            pressure_data.datasets.push(pressure_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);

            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);
    
            this.setState({
                water_level_data: water_level_data,
                water_temp_data: water_temp_data,
                sensor_depth_data: sensor_depth_data,
                water_pressure_data: water_pressure_data,
                water_pressure_abs_data: water_pressure_abs_data,
                water_comp_conductivity_data: water_comp_conductivity_data,
                water_raw_conductivity_data: water_raw_conductivity_data,
                pressure_data: pressure_data,
                temperature_data: temperature_data,
                battery_data: battery_data,
                loading: false
            })
        } else if (sensor.model_name.startsWith("CAPROC-CMF")) {
            let {  temperature_external_data, humidity_external_data, temperature_data, humidity_data} = this.state;

            temperature_external_data.labels = [];
            temperature_external_data.datasets = [];

            // Temperature (external probe)
            let temperature_external_dataset = {
                ...default_dataset_green,
                label: t("Temperature (°C) (External probe)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            humidity_external_data.labels = [];
            humidity_external_data.datasets = [];

            // Humidity (external probe)
            let humidity_external_dataset = {
                ...default_dataset_blue,
                label: t("Humidity (External probe)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            temperature_data.labels = [];
            temperature_data.datasets = [];

            // Temperature 
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            humidity_data.labels = [];
            humidity_data.datasets = [];

            // Humidity 
            let humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // // Battery
            // let battery_dataset = {
            //     ...default_dataset_green,
            //     label: t("Battery Voltage (V)"),
            //     average: 0,
            //     total_sum: 0,
            //     pcs: 0,
            //     highest: null,
            //     lowest: null,
            //     data: []
            // };
            

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature (external probe)
                if (report[i].name.startsWith("External temperature")) {
                    temperature_external_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_external_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_external_dataset.highest === null || (parseFloat(temperature_external_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_external_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_external_dataset.lowest === null || (parseFloat(temperature_external_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_external_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_external_dataset.total_sum += parseFloat(report[i].value);
                    temperature_external_dataset.pcs += 1;

                    this.setState({
                        temperature_external_text: t(report[i].display_name),
                    });
                }
                // Humidity (external probe)
                else if (report[i].name.startsWith("External humidity")) {
                    humidity_external_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_external_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_external_dataset.highest === null || (parseFloat(humidity_external_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_external_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_external_dataset.lowest === null || (parseFloat(humidity_external_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_external_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_external_dataset.total_sum += parseFloat(report[i].value);
                    humidity_external_dataset.pcs += 1;

                    this.setState({
                        humidity_external_text: t(report[i].display_name),
                    });
                }
                // Temperature
                else if (report[i].name === "Temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                }
                // Humidity
                else if (report[i].name === "Humidity") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;

                    this.setState({
                        humidity_text: t(report[i].display_name),
                    });
                }

                
                // // Battery voltage
                // } else if (report[i].name === "Battery voltage") {
                //     battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                //     battery_dataset.data.push(parseFloat(report[i].value));
                //     if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                //         battery_dataset.highest = parseFloat(report[i].value);
                //     }
                //     if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                //         battery_dataset.lowest = parseFloat(report[i].value);
                //     }
                //     battery_dataset.total_sum += parseFloat(report[i].value);
                //     battery_dataset.pcs += 1;
                //     this.setState({
                //         battery_text: t(report[i].display_name)
                //     });
                //}
            }

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);
            
            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);

            if (temperature_external_dataset.pcs > 0) {
                temperature_external_dataset.average = parseFloat((temperature_external_dataset.total_sum / temperature_external_dataset.pcs).toFixed(2));
            }
            temperature_external_dataset.label = 
                `${temperature_external_dataset.label} (${t("avg")}: ${temperature_external_dataset.average.toString()} ${t("Highest")}: ${temperature_external_dataset.highest ? temperature_external_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_external_dataset.lowest ? temperature_external_dataset.lowest.toString() : 0})`; 

            temperature_external_data.datasets.push(temperature_external_dataset);
            
            if (humidity_external_dataset.pcs > 0) {
                humidity_external_dataset.average = parseFloat((humidity_external_dataset.total_sum / humidity_external_dataset.pcs).toFixed(2));
            }
            humidity_external_dataset.label = 
                `${humidity_external_dataset.label} (${t("avg")}: ${humidity_external_dataset.average.toString()} ${t("Highest")}: ${humidity_external_dataset.highest ? humidity_external_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_external_dataset.lowest ? humidity_external_dataset.lowest.toString() : 0})`; 

            humidity_external_data.datasets.push(humidity_external_dataset);

            // if (battery_dataset.pcs > 0) {
            //     battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            // }
            // battery_dataset.label = 
            //     `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            // battery_data.datasets.push(battery_dataset);
    
            this.setState({
                temperature_external_data: temperature_external_data,
                humidity_external_data: humidity_external_data,
                temperature_data: temperature_data,
                humidity_data: humidity_data,
                // battery_data: battery_data,
                loading: false
            })
        

         } else if (sensor.model_name.startsWith("CAPROC-SCALE")) {
            let {  scale_weight_data, scale_amount_data, scale_filling_percentage_data, temperature_data} = this.state;

            scale_weight_data.labels = [];
            scale_weight_data.datasets = [];

            // Scale weight
            let scale_weight_dataset = {
                ...default_dataset_green,
                label: t("Scale weight"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            scale_amount_data.labels = [];
            scale_amount_data.datasets = [];

            // Scale amount
            let scale_amount_dataset = {
                ...default_dataset_red,
                label: t("Amount"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            scale_filling_percentage_data.labels = [];
            scale_filling_percentage_data.datasets = [];

            // Scale filling percentage
            let scale_filling_percentage_dataset = {
                ...default_dataset_blue,
                label: t("Filling percentage"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            temperature_data.labels = [];
            temperature_data.datasets = [];

            // Temperature 
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Scale weight
                if (report[i].name.startsWith("Scale weight")) {
                    scale_weight_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    scale_weight_dataset.data.push(parseFloat(report[i].value));
                    if (scale_weight_dataset.highest === null || (parseFloat(scale_weight_dataset.highest) < parseFloat(report[i].value))) {
                        scale_weight_dataset.highest = parseFloat(report[i].value);
                    }
                    if (scale_weight_dataset.lowest === null || (parseFloat(scale_weight_dataset.lowest) > parseFloat(report[i].value))) {
                        scale_weight_dataset.lowest = parseFloat(report[i].value);
                    }
                    scale_weight_dataset.total_sum += parseFloat(report[i].value);
                    scale_weight_dataset.pcs += 1;

                    this.setState({
                        scale_weight_text: t(report[i].display_name),
                    });
                }
                // Amount
                else if (report[i].name.startsWith("Amount")) {
                    scale_amount_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    scale_amount_dataset.data.push(parseFloat(report[i].value));
                    if (scale_amount_dataset.highest === null || (parseFloat(scale_amount_dataset.highest) < parseFloat(report[i].value))) {
                        scale_amount_dataset.highest = parseFloat(report[i].value);
                    }
                    if (scale_amount_dataset.lowest === null || (parseFloat(scale_amount_dataset.lowest) > parseFloat(report[i].value))) {
                        scale_amount_dataset.lowest = parseFloat(report[i].value);
                    }
                    scale_amount_dataset.total_sum += parseFloat(report[i].value);
                    scale_amount_dataset.pcs += 1;

                    this.setState({
                        scale_amount_text: t(report[i].display_name),
                    });
                }
                // Filling percentage
                else if (report[i].name.startsWith("Filling percentage")) {
                    scale_filling_percentage_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    scale_filling_percentage_dataset.data.push(parseFloat(report[i].value));
                    if (scale_filling_percentage_dataset.highest === null || (parseFloat(scale_filling_percentage_dataset.highest) < parseFloat(report[i].value))) {
                        scale_filling_percentage_dataset.highest = parseFloat(report[i].value);
                    }
                    if (scale_filling_percentage_dataset.lowest === null || (parseFloat(scale_filling_percentage_dataset.lowest) > parseFloat(report[i].value))) {
                        scale_filling_percentage_dataset.lowest = parseFloat(report[i].value);
                    }
                    scale_filling_percentage_dataset.total_sum += parseFloat(report[i].value);
                    scale_filling_percentage_dataset.pcs += 1;

                    this.setState({
                        scale_filling_percentage_text: t(report[i].display_name),
                    });
                }
                // Temperature
                else if (report[i].name === "Temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                }
            }

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);
            
            if (scale_weight_dataset.pcs > 0) {
                scale_weight_dataset.average = parseFloat((scale_weight_dataset.total_sum / scale_weight_dataset.pcs).toFixed(3));
            }
            scale_weight_dataset.label = 
                `${scale_weight_dataset.label} (${t("avg")}: ${scale_weight_dataset.average.toString()} ${t("Highest")}: ${scale_weight_dataset.highest ? scale_weight_dataset.highest.toString() : 0} ${t("Lowest")}: ${scale_weight_dataset.lowest ? scale_weight_dataset.lowest.toString() : 0})`; 

            scale_weight_data.datasets.push(scale_weight_dataset);

            if (scale_amount_dataset.pcs > 0) {
                scale_amount_dataset.average = parseFloat((scale_amount_dataset.total_sum / scale_amount_dataset.pcs).toFixed(1));
            }
            scale_amount_dataset.label = 
                `${scale_amount_dataset.label} (${t("avg")}: ${scale_amount_dataset.average.toString()} ${t("Highest")}: ${scale_amount_dataset.highest ? scale_amount_dataset.highest.toString() : 0} ${t("Lowest")}: ${scale_amount_dataset.lowest ? scale_amount_dataset.lowest.toString() : 0})`; 

            scale_amount_data.datasets.push(scale_amount_dataset);

            if (scale_filling_percentage_dataset.pcs > 0) {
                scale_filling_percentage_dataset.average = parseFloat((scale_filling_percentage_dataset.total_sum / scale_filling_percentage_dataset.pcs).toFixed(1));
            }
            scale_filling_percentage_dataset.label = 
                `${scale_filling_percentage_dataset.label} (${t("avg")}: ${scale_filling_percentage_dataset.average.toString()} ${t("Highest")}: ${scale_filling_percentage_dataset.highest ? scale_filling_percentage_dataset.highest.toString() : 0} ${t("Lowest")}: ${scale_filling_percentage_dataset.lowest ? scale_filling_percentage_dataset.lowest.toString() : 0})`; 

            scale_filling_percentage_data.datasets.push(scale_filling_percentage_dataset);
                
            this.setState({
                scale_weight_data: scale_weight_data,
                scale_amount_data: scale_amount_data,
                scale_filling_percentage_data: scale_filling_percentage_data,
                temperature_data: temperature_data,
                loading: false
            })
        } else if (sensor.model_name.startsWith("CAPROC-IC")) {
            let {  temperature_data, humidity_data, wcia_data, voc_index_data, scd4x_temperature_data, scd4x_humidity_data, co2_data, sfa30_temperature_data, sfa30_humidity_data, hcho_data, dp_pressure_data, dp_pressure_mass_flow_data, dp_temp_data, pressure_data} = this.state;

            temperature_data.labels = [];
            temperature_data.datasets = [];

            // Temperature 
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            humidity_data.labels = [];
            humidity_data.datasets = [];

            // Humidity 
            let humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            wcia_data.labels = [];
            wcia_data.datasets = [];

            // Water content in air 
            let wcia_dataset = {
                ...default_dataset_blue,
                label: t("WCIA"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            voc_index_data.labels = [];
            voc_index_data.datasets = [];

            // VOC index 
            let voc_index_dataset = {
                ...default_dataset_red,
                label: t("VOC index"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            scd4x_temperature_data.labels = [];
            scd4x_temperature_data.datasets = [];

            // Temperature SCD4x
            let scd4x_temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature SCD4x"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            scd4x_humidity_data.labels = [];
            scd4x_humidity_data.datasets = [];

            // Humidity SCD4x
            let scd4x_humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity SCD4x"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            co2_data.labels = [];
            co2_data.datasets = [];

            // CO2 
            let co2_dataset = {
                ...default_dataset_green,
                label: t("CO2"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            sfa30_temperature_data.labels = [];
            sfa30_temperature_data.datasets = [];

            // Temperature SFA30
            let sfa30_temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature SFA30"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            sfa30_humidity_data.labels = [];
            sfa30_humidity_data.datasets = [];

            // Humidity SFA30
            let sfa30_humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity SFA30"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            hcho_data.labels = [];
            hcho_data.datasets = [];

            // HCHO 
            let hcho_dataset = {
                ...default_dataset_blue,
                label: t("HCHO"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            dp_pressure_data.labels = [];
            dp_pressure_data.datasets = [];

            // Sensirion SDP 
            let dp_pressure_dataset = {
                ...default_dataset_blue,
                label: t("Differential pressure SDP"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            dp_pressure_mass_flow_data.labels = [];
            dp_pressure_mass_flow_data.datasets = [];

            // Sensirion SDP 
            let dp_pressure_mass_flow_dataset = {
                ...default_dataset_blue,
                label: t("Differential pressure SDP (mass flow)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            dp_temp_data.labels = [];
            dp_temp_data.datasets = [];

            // Sensirion SDP 
            let dp_temp_dataset = {
                ...default_dataset_blue,
                label: t("Temperature SDP"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            pressure_data.labels = [];
            pressure_data.datasets = [];

            // Air pressure 
            let pressure_dataset = {
                ...default_dataset_blue,
                label: t("Air pressure"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "Temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                }
                // Humidity
                else if (report[i].name === "Humidity") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;

                    this.setState({
                        humidity_text: t(report[i].display_name),
                    });
                }
                // Water content in air
                else if (report[i].name === "WCIA") {
                    wcia_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    wcia_dataset.data.push(parseFloat(report[i].value));
                    if (wcia_dataset.highest === null || (parseFloat(wcia_dataset.highest) < parseFloat(report[i].value))) {
                        wcia_dataset.highest = parseFloat(report[i].value);
                    }
                    if (wcia_dataset.lowest === null || (parseFloat(wcia_dataset.lowest) > parseFloat(report[i].value))) {
                        wcia_dataset.lowest = parseFloat(report[i].value);
                    }
                    wcia_dataset.total_sum += parseFloat(report[i].value);
                    wcia_dataset.pcs += 1;

                    this.setState({
                        wcia_text: t(report[i].display_name),
                    });
                }
                // Temperature SCD4x
                else if (report[i].name === "Temperature SCD4x") {
                    scd4x_temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    scd4x_temperature_dataset.data.push(parseFloat(report[i].value));
                    if (scd4x_temperature_dataset.highest === null || (parseFloat(scd4x_temperature_dataset.highest) < parseFloat(report[i].value))) {
                        scd4x_temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (scd4x_temperature_dataset.lowest === null || (parseFloat(scd4x_temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        scd4x_temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    scd4x_temperature_dataset.total_sum += parseFloat(report[i].value);
                    scd4x_temperature_dataset.pcs += 1;

                    this.setState({
                        scd4x_temperature_text: t(report[i].display_name),
                    });
                }
                // Humidity SCD4x
                else if (report[i].name === "Humidity SCD4x") {
                    scd4x_humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    scd4x_humidity_dataset.data.push(parseFloat(report[i].value));
                    if (scd4x_humidity_dataset.highest === null || (parseFloat(scd4x_humidity_dataset.highest) < parseFloat(report[i].value))) {
                        scd4x_humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (scd4x_humidity_dataset.lowest === null || (parseFloat(scd4x_humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        scd4x_humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    scd4x_humidity_dataset.total_sum += parseFloat(report[i].value);
                    scd4x_humidity_dataset.pcs += 1;

                    this.setState({
                        scd4x_humidity_text: t(report[i].display_name),
                    });
                }
                // Temperature SFA30
                else if (report[i].name === "Temperature SFA30") {
                    sfa30_temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    sfa30_temperature_dataset.data.push(parseFloat(report[i].value));
                    if (sfa30_temperature_dataset.highest === null || (parseFloat(sfa30_temperature_dataset.highest) < parseFloat(report[i].value))) {
                        sfa30_temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (sfa30_temperature_dataset.lowest === null || (parseFloat(sfa30_temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        sfa30_temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    sfa30_temperature_dataset.total_sum += parseFloat(report[i].value);
                    sfa30_temperature_dataset.pcs += 1;

                    this.setState({
                        sfa30_temperature_text: t(report[i].display_name),
                    });
                }
                // Humidity SFA30
                else if (report[i].name === "Humidity SFA30") {
                    sfa30_humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    sfa30_humidity_dataset.data.push(parseFloat(report[i].value));
                    if (sfa30_humidity_dataset.highest === null || (parseFloat(sfa30_humidity_dataset.highest) < parseFloat(report[i].value))) {
                        sfa30_humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (sfa30_humidity_dataset.lowest === null || (parseFloat(sfa30_humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        sfa30_humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    sfa30_humidity_dataset.total_sum += parseFloat(report[i].value);
                    sfa30_humidity_dataset.pcs += 1;

                    this.setState({
                        sfa30_humidity_text: t(report[i].display_name),
                    });
                }
                // VOC index
                else if (report[i].name === "VOC index SGP4x") {
                    voc_index_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    voc_index_dataset.data.push(parseFloat(report[i].value));
                    if (voc_index_dataset.highest === null || (parseFloat(voc_index_dataset.highest) < parseFloat(report[i].value))) {
                        voc_index_dataset.highest = parseFloat(report[i].value);
                    }
                    if (voc_index_dataset.lowest === null || (parseFloat(voc_index_dataset.lowest) > parseFloat(report[i].value))) {
                        voc_index_dataset.lowest = parseFloat(report[i].value);
                    }
                    voc_index_dataset.total_sum += parseFloat(report[i].value);
                    voc_index_dataset.pcs += 1;

                    this.setState({
                        voc_index_text: t(report[i].display_name),
                    });
                }
                // CO2
                else if (report[i].name === "CO2 SCD4x") {
                    co2_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    co2_dataset.data.push(parseFloat(report[i].value));
                    if (co2_dataset.highest === null || (parseFloat(co2_dataset.highest) < parseFloat(report[i].value))) {
                        co2_dataset.highest = parseFloat(report[i].value);
                    }
                    if (co2_dataset.lowest === null || (parseFloat(co2_dataset.lowest) > parseFloat(report[i].value))) {
                        co2_dataset.lowest = parseFloat(report[i].value);
                    }
                    co2_dataset.total_sum += parseFloat(report[i].value);
                    co2_dataset.pcs += 1;

                    this.setState({
                        co2_text: t(report[i].display_name),
                    });
                }
                // HCHO
                else if (report[i].name === "HCHO SFA30") {
                    hcho_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    hcho_dataset.data.push(parseFloat(report[i].value));
                    if (hcho_dataset.highest === null || (parseFloat(hcho_dataset.highest) < parseFloat(report[i].value))) {
                        hcho_dataset.highest = parseFloat(report[i].value);
                    }
                    if (hcho_dataset.lowest === null || (parseFloat(hcho_dataset.lowest) > parseFloat(report[i].value))) {
                        hcho_dataset.lowest = parseFloat(report[i].value);
                    }
                    hcho_dataset.total_sum += parseFloat(report[i].value);
                    hcho_dataset.pcs += 1;

                    this.setState({
                        hcho_text: t(report[i].display_name),
                    });
                }
                // Differential pressure SDP
                else if (report[i].name === "Differential pressure SDP") {
                    dp_pressure_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    dp_pressure_dataset.data.push(parseFloat(report[i].value));
                    if (dp_pressure_dataset.highest === null || (parseFloat(dp_pressure_dataset.highest) < parseFloat(report[i].value))) {
                        dp_pressure_dataset.highest = parseFloat(report[i].value);
                    }
                    if (dp_pressure_dataset.lowest === null || (parseFloat(dp_pressure_dataset.lowest) > parseFloat(report[i].value))) {
                        dp_pressure_dataset.lowest = parseFloat(report[i].value);
                    }
                    dp_pressure_dataset.total_sum += parseFloat(report[i].value);
                    dp_pressure_dataset.pcs += 1;

                    this.setState({
                        dp_pressure_text: t(report[i].display_name),
                    });
                }
                // Differential pressure SDP (mass flow)
                else if (report[i].name === "Differential pressure SDP (mass flow)") {
                    dp_pressure_mass_flow_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    dp_pressure_mass_flow_dataset.data.push(parseFloat(report[i].value));
                    if (dp_pressure_mass_flow_dataset.highest === null || (parseFloat(dp_pressure_mass_flow_dataset.highest) < parseFloat(report[i].value))) {
                        dp_pressure_mass_flow_dataset.highest = parseFloat(report[i].value);
                    }
                    if (dp_pressure_mass_flow_dataset.lowest === null || (parseFloat(dp_pressure_mass_flow_dataset.lowest) > parseFloat(report[i].value))) {
                        dp_pressure_mass_flow_dataset.lowest = parseFloat(report[i].value);
                    }
                    dp_pressure_mass_flow_dataset.total_sum += parseFloat(report[i].value);
                    dp_pressure_mass_flow_dataset.pcs += 1;

                    this.setState({
                        dp_pressure_mass_flow_data_text: t(report[i].display_name),
                    });
                }
                
                // Temperature SDP
                else if (report[i].name === "Temperature SDP") {
                    dp_temp_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    dp_temp_dataset.data.push(parseFloat(report[i].value));
                    if (dp_temp_dataset.highest === null || (parseFloat(dp_temp_dataset.highest) < parseFloat(report[i].value))) {
                        dp_temp_dataset.highest = parseFloat(report[i].value);
                    }
                    if (dp_temp_dataset.lowest === null || (parseFloat(dp_temp_dataset.lowest) > parseFloat(report[i].value))) {
                        dp_temp_dataset.lowest = parseFloat(report[i].value);
                    }
                    dp_temp_dataset.total_sum += parseFloat(report[i].value);
                    dp_temp_dataset.pcs += 1;

                    this.setState({
                        dp_temp_text: t(report[i].display_name),
                    });
                }
                // Air pressure
                else if (report[i].name === "Air pressure MS5XX") {
                    pressure_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pressure_dataset.data.push(parseFloat(report[i].value));
                    if (pressure_dataset.highest === null || (parseFloat(pressure_dataset.highest) < parseFloat(report[i].value))) {
                        pressure_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pressure_dataset.lowest === null || (parseFloat(pressure_dataset.lowest) > parseFloat(report[i].value))) {
                        pressure_dataset.lowest = parseFloat(report[i].value);
                    }
                    pressure_dataset.total_sum += parseFloat(report[i].value);
                    pressure_dataset.pcs += 1;

                    this.setState({
                        pressure_text: t(report[i].display_name),
                    });
                }
            }

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);
            
            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);

            if (wcia_dataset.pcs > 0) {
                wcia_dataset.average = parseFloat((wcia_dataset.total_sum / wcia_dataset.pcs).toFixed(2));
            }
            wcia_dataset.label = 
                `${wcia_dataset.label} (${t("avg")}: ${wcia_dataset.average.toString()} ${t("Highest")}: ${wcia_dataset.highest ? wcia_dataset.highest.toString() : 0} ${t("Lowest")}: ${wcia_dataset.lowest ? wcia_dataset.lowest.toString() : 0})`; 

            wcia_data.datasets.push(wcia_dataset);

            if (scd4x_temperature_dataset.pcs > 0) {
                scd4x_temperature_dataset.average = parseFloat((scd4x_temperature_dataset.total_sum / scd4x_temperature_dataset.pcs).toFixed(2));
            }
            scd4x_temperature_dataset.label = 
                `${scd4x_temperature_dataset.label} (${t("avg")}: ${scd4x_temperature_dataset.average.toString()} ${t("Highest")}: ${scd4x_temperature_dataset.highest ? scd4x_temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${scd4x_temperature_dataset.lowest ? scd4x_temperature_dataset.lowest.toString() : 0})`; 

            scd4x_temperature_data.datasets.push(scd4x_temperature_dataset);
            
            if (scd4x_humidity_dataset.pcs > 0) {
                scd4x_humidity_dataset.average = parseFloat((scd4x_humidity_dataset.total_sum / scd4x_humidity_dataset.pcs).toFixed(2));
            }
            scd4x_humidity_dataset.label = 
                `${scd4x_humidity_dataset.label} (${t("avg")}: ${scd4x_humidity_dataset.average.toString()} ${t("Highest")}: ${scd4x_humidity_dataset.highest ? scd4x_humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${scd4x_humidity_dataset.lowest ? scd4x_humidity_dataset.lowest.toString() : 0})`; 

            scd4x_humidity_data.datasets.push(scd4x_humidity_dataset);

            if (sfa30_temperature_dataset.pcs > 0) {
                sfa30_temperature_dataset.average = parseFloat((sfa30_temperature_dataset.total_sum / sfa30_temperature_dataset.pcs).toFixed(2));
            }
            sfa30_temperature_dataset.label = 
                `${sfa30_temperature_dataset.label} (${t("avg")}: ${sfa30_temperature_dataset.average.toString()} ${t("Highest")}: ${sfa30_temperature_dataset.highest ? sfa30_temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${sfa30_temperature_dataset.lowest ? sfa30_temperature_dataset.lowest.toString() : 0})`; 

            sfa30_temperature_data.datasets.push(sfa30_temperature_dataset);
            
            if (sfa30_humidity_dataset.pcs > 0) {
                sfa30_humidity_dataset.average = parseFloat((sfa30_humidity_dataset.total_sum / sfa30_humidity_dataset.pcs).toFixed(2));
            }
            sfa30_humidity_dataset.label = 
                `${sfa30_humidity_dataset.label} (${t("avg")}: ${sfa30_humidity_dataset.average.toString()} ${t("Highest")}: ${sfa30_humidity_dataset.highest ? sfa30_humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${sfa30_humidity_dataset.lowest ? sfa30_humidity_dataset.lowest.toString() : 0})`; 

            sfa30_humidity_data.datasets.push(sfa30_humidity_dataset);

            if (voc_index_dataset.pcs > 0) {
                voc_index_dataset.average = parseFloat((voc_index_dataset.total_sum / voc_index_dataset.pcs).toFixed(2));
            }
            voc_index_dataset.label = 
                `${voc_index_dataset.label} (${t("avg")}: ${voc_index_dataset.average.toString()} ${t("Highest")}: ${voc_index_dataset.highest ? voc_index_dataset.highest.toString() : 0} ${t("Lowest")}: ${voc_index_dataset.lowest ? voc_index_dataset.lowest.toString() : 0})`; 

            voc_index_data.datasets.push(voc_index_dataset);
            
            if (co2_dataset.pcs > 0) {
                co2_dataset.average = parseFloat((co2_dataset.total_sum / co2_dataset.pcs).toFixed(2));
            }
            co2_dataset.label = 
                `${co2_dataset.label} (${t("avg")}: ${co2_dataset.average.toString()} ${t("Highest")}: ${co2_dataset.highest ? co2_dataset.highest.toString() : 0} ${t("Lowest")}: ${co2_dataset.lowest ? co2_dataset.lowest.toString() : 0})`; 

            co2_data.datasets.push(co2_dataset);

            if (hcho_dataset.pcs > 0) {
                hcho_dataset.average = parseFloat((hcho_dataset.total_sum / hcho_dataset.pcs).toFixed(2));
            }
            hcho_dataset.label = 
                `${hcho_dataset.label} (${t("avg")}: ${hcho_dataset.average.toString()} ${t("Highest")}: ${hcho_dataset.highest ? hcho_dataset.highest.toString() : 0} ${t("Lowest")}: ${hcho_dataset.lowest ? hcho_dataset.lowest.toString() : 0})`; 

            hcho_data.datasets.push(hcho_dataset);

            if (dp_pressure_dataset.pcs > 0) {
                dp_pressure_dataset.average = parseFloat((dp_pressure_dataset.total_sum / dp_pressure_dataset.pcs).toFixed(2));
            }
            dp_pressure_dataset.label = 
                `${dp_pressure_dataset.label} (${t("avg")}: ${dp_pressure_dataset.average.toString()} ${t("Highest")}: ${dp_pressure_dataset.highest ? dp_pressure_dataset.highest.toString() : 0} ${t("Lowest")}: ${dp_pressure_dataset.lowest ? dp_pressure_dataset.lowest.toString() : 0})`; 

            dp_pressure_data.datasets.push(dp_pressure_dataset);

            if (dp_pressure_mass_flow_dataset.pcs > 0) {
                dp_pressure_mass_flow_dataset.average = parseFloat((dp_pressure_mass_flow_dataset.total_sum / dp_pressure_mass_flow_dataset.pcs).toFixed(2));
            }
            dp_pressure_mass_flow_dataset.label = 
                `${dp_pressure_mass_flow_dataset.label} (${t("avg")}: ${dp_pressure_mass_flow_dataset.average.toString()} ${t("Highest")}: ${dp_pressure_mass_flow_dataset.highest ? dp_pressure_mass_flow_dataset.highest.toString() : 0} ${t("Lowest")}: ${dp_pressure_mass_flow_dataset.lowest ? dp_pressure_mass_flow_dataset.lowest.toString() : 0})`; 

            dp_pressure_mass_flow_data.datasets.push(dp_pressure_mass_flow_dataset);

            if (dp_temp_dataset.pcs > 0) {
                dp_temp_dataset.average = parseFloat((dp_temp_dataset.total_sum / dp_temp_dataset.pcs).toFixed(2));
            }
            dp_temp_dataset.label = 
                `${dp_temp_dataset.label} (${t("avg")}: ${dp_temp_dataset.average.toString()} ${t("Highest")}: ${dp_temp_dataset.highest ? dp_temp_dataset.highest.toString() : 0} ${t("Lowest")}: ${dp_temp_dataset.lowest ? dp_temp_dataset.lowest.toString() : 0})`; 

            dp_temp_data.datasets.push(dp_temp_dataset);

            if (pressure_dataset.pcs > 0) {
                pressure_dataset.average = parseFloat((pressure_dataset.total_sum / pressure_dataset.pcs).toFixed(2));
            }
            pressure_dataset.label = 
                `${pressure_dataset.label} (${t("avg")}: ${pressure_dataset.average.toString()} ${t("Highest")}: ${pressure_dataset.highest ? pressure_dataset.highest.toString() : 0} ${t("Lowest")}: ${pressure_dataset.lowest ? pressure_dataset.lowest.toString() : 0})`; 

            pressure_data.datasets.push(pressure_dataset);

            this.setState({
                temperature_data: temperature_data,
                humidity_data: humidity_data,
                wcia_data: wcia_data,
                scd4x_temperature_data: scd4x_temperature_data,
                scd4x_humidity_data: scd4x_humidity_data,
                sfa30_temperature_data: sfa30_temperature_data,
                sfa30_humidity_data: sfa30_humidity_data,
                voc_index_data: voc_index_data,
                co2_data: co2_data,
                hcho_data, hcho_data,
                dp_pressure_data: dp_pressure_data,
                dp_pressure_mass_flow_data: dp_pressure_mass_flow_data,
                dp_temp_data: dp_temp_data,
                pressure_data: pressure_data,
                loading: false
            })

        } else if (sensor.model_name.startsWith("CAPROC-RAIN")) {
            let {  temperature_data, humidity_data, pressure_data, rain_precipitation_data, rain_precipitation_cumulative_data, rain_tip_counter_data, rain_tip_counter_cumulative_data} = this.state;

            temperature_data.labels = [];
            temperature_data.datasets = [];

            // Temperature 
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            humidity_data.labels = [];
            humidity_data.datasets = [];

            // Humidity 
            let humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            rain_precipitation_data.labels = [];
            rain_precipitation_data.datasets = [];

            // rain_precipitation_data
            let rain_precipitation_dataset = {
                ...default_bar_dataset_blue,
                label: t("Precipitation"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            rain_precipitation_cumulative_data.labels = [];
            rain_precipitation_cumulative_data.datasets = [];

            // rain_precipitation_cumulative_data
            let rain_precipitation_cumulative_dataset = {
                ...default_bar_dataset_blue,
                label: t("Precipitation (cumulative)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            rain_tip_counter_data.labels = [];
            rain_tip_counter_data.datasets = [];

            // rain_tip_counter_data
            let rain_tip_counter_dataset = {
                ...default_bar_dataset_blue,
                label: t("Rain tip counter"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            rain_tip_counter_cumulative_data.labels = [];
            rain_tip_counter_cumulative_data.datasets = [];

            // rain_tip_counter_cumulative_data
            let rain_tip_counter_cumulative_dataset = {
                ...default_bar_dataset_blue,
                label: t("Rain tip counter (cumulative)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            pressure_data.labels = [];
            pressure_data.datasets = [];

            // Air pressure 
            let pressure_dataset = {
                ...default_dataset_blue,
                label: t("Air pressure"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "Temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                }
                // Humidity
                else if (report[i].name === "Humidity") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;

                    this.setState({
                        humidity_text: t(report[i].display_name),
                    });
                }
                // Rain tip counter
                else if (report[i].name === "Rain tip counter") {
                    rain_tip_counter_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    rain_tip_counter_dataset.data.push(parseFloat(report[i].value));
                    if (rain_tip_counter_dataset.highest === null || (parseFloat(rain_tip_counter_dataset.highest) < parseFloat(report[i].value))) {
                        rain_tip_counter_dataset.highest = parseFloat(report[i].value);
                    }
                    if (rain_tip_counter_dataset.lowest === null || (parseFloat(rain_tip_counter_dataset.lowest) > parseFloat(report[i].value))) {
                        rain_tip_counter_dataset.lowest = parseFloat(report[i].value);
                    }
                    rain_tip_counter_dataset.total_sum += parseFloat(report[i].value);
                    rain_tip_counter_dataset.pcs += 1;

                    this.setState({
                        rain_tip_counter_text: t(report[i].display_name),
                    });
                }
                // Rain tip counter (cumulative)
                else if (report[i].name === "Rain tip cumulative counter") {
                    rain_tip_counter_cumulative_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    rain_tip_counter_cumulative_dataset.data.push(parseFloat(report[i].value));
                    if (rain_tip_counter_cumulative_dataset.highest === null || (parseFloat(rain_tip_counter_cumulative_dataset.highest) < parseFloat(report[i].value))) {
                        rain_tip_counter_cumulative_dataset.highest = parseFloat(report[i].value);
                    }
                    if (rain_tip_counter_cumulative_dataset.lowest === null || (parseFloat(rain_tip_counter_cumulative_dataset.lowest) > parseFloat(report[i].value))) {
                        rain_tip_counter_cumulative_dataset.lowest = parseFloat(report[i].value);
                    }
                    rain_tip_counter_cumulative_dataset.total_sum += parseFloat(report[i].value);
                    rain_tip_counter_cumulative_dataset.pcs += 1;

                    this.setState({
                        rain_tip_counter_cumulative_text: t(report[i].display_name),
                    });
                }
                // Precipitation
                else if (report[i].name === "Precipitation") {
                    rain_precipitation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    rain_precipitation_dataset.data.push(parseFloat(report[i].value));
                    if (rain_precipitation_dataset.highest === null || (parseFloat(rain_precipitation_dataset.highest) < parseFloat(report[i].value))) {
                        rain_precipitation_dataset.highest = parseFloat(report[i].value);
                    }
                    if (rain_precipitation_dataset.lowest === null || (parseFloat(rain_precipitation_dataset.lowest) > parseFloat(report[i].value))) {
                        rain_precipitation_dataset.lowest = parseFloat(report[i].value);
                    }
                    rain_precipitation_dataset.total_sum += parseFloat(report[i].value);
                    rain_precipitation_dataset.pcs += 1;

                    this.setState({
                        rain_precipitation_text: t(report[i].display_name),
                    });
                }
                // Cumulative precipitation
                else if (report[i].name === "Cumulative precipitation") {
                    rain_precipitation_cumulative_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    rain_precipitation_cumulative_dataset.data.push(parseFloat(report[i].value));
                    if (rain_precipitation_cumulative_dataset.highest === null || (parseFloat(rain_precipitation_cumulative_dataset.highest) < parseFloat(report[i].value))) {
                        rain_precipitation_cumulative_dataset.highest = parseFloat(report[i].value);
                    }
                    if (rain_precipitation_cumulative_dataset.lowest === null || (parseFloat(rain_precipitation_cumulative_dataset.lowest) > parseFloat(report[i].value))) {
                        rain_precipitation_cumulative_dataset.lowest = parseFloat(report[i].value);
                    }
                    rain_precipitation_cumulative_dataset.total_sum += parseFloat(report[i].value);
                    rain_precipitation_cumulative_dataset.pcs += 1;

                    this.setState({
                        rain_precipitation_cumulative_text: t(report[i].display_name),
                    });
                }
                
                // Air pressure
                else if (report[i].name === "Air pressure MS5XX") {
                    pressure_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pressure_dataset.data.push(parseFloat(report[i].value));
                    if (pressure_dataset.highest === null || (parseFloat(pressure_dataset.highest) < parseFloat(report[i].value))) {
                        pressure_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pressure_dataset.lowest === null || (parseFloat(pressure_dataset.lowest) > parseFloat(report[i].value))) {
                        pressure_dataset.lowest = parseFloat(report[i].value);
                    }
                    pressure_dataset.total_sum += parseFloat(report[i].value);
                    pressure_dataset.pcs += 1;

                    this.setState({
                        pressure_text: t(report[i].display_name),
                    });
                }
            }

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);
            
            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);

            if (rain_tip_counter_dataset.pcs > 0) {
                rain_tip_counter_dataset.average = parseFloat((rain_tip_counter_dataset.total_sum / rain_tip_counter_dataset.pcs).toFixed(2));
            }
            rain_tip_counter_dataset.label = 
                `${rain_tip_counter_dataset.label} (${t("avg")}: ${rain_tip_counter_dataset.average.toString()} ${t("Highest")}: ${rain_tip_counter_dataset.highest ? rain_tip_counter_dataset.highest.toString() : 0} ${t("Lowest")}: ${rain_tip_counter_dataset.lowest ? rain_tip_counter_dataset.lowest.toString() : 0})`; 

            rain_tip_counter_data.datasets.push(rain_tip_counter_dataset);
            
            if (rain_tip_counter_cumulative_dataset.pcs > 0) {
                rain_tip_counter_cumulative_dataset.average = parseFloat((rain_tip_counter_cumulative_dataset.total_sum / rain_tip_counter_cumulative_dataset.pcs).toFixed(2));
            }
            rain_tip_counter_cumulative_dataset.label = 
                `${rain_tip_counter_cumulative_dataset.label} (${t("avg")}: ${rain_tip_counter_cumulative_dataset.average.toString()} ${t("Highest")}: ${rain_tip_counter_cumulative_dataset.highest ? rain_tip_counter_cumulative_dataset.highest.toString() : 0} ${t("Lowest")}: ${rain_tip_counter_cumulative_dataset.lowest ? rain_tip_counter_cumulative_dataset.lowest.toString() : 0})`; 

            rain_tip_counter_cumulative_data.datasets.push(rain_tip_counter_cumulative_dataset);

            if (rain_precipitation_dataset.pcs > 0) {
                rain_precipitation_dataset.average = parseFloat((rain_precipitation_dataset.total_sum / rain_precipitation_dataset.pcs).toFixed(2));
            }
            rain_precipitation_dataset.label = 
                `${rain_precipitation_dataset.label} (${t("avg")}: ${rain_precipitation_dataset.average.toString()} ${t("Highest")}: ${rain_precipitation_dataset.highest ? rain_precipitation_dataset.highest.toString() : 0} ${t("Lowest")}: ${rain_precipitation_dataset.lowest ? rain_precipitation_dataset.lowest.toString() : 0})`; 

            rain_precipitation_data.datasets.push(rain_precipitation_dataset);
            
            if (rain_precipitation_cumulative_dataset.pcs > 0) {
                rain_precipitation_cumulative_dataset.average = parseFloat((rain_precipitation_cumulative_dataset.total_sum / rain_precipitation_cumulative_dataset.pcs).toFixed(2));
            }
            rain_precipitation_cumulative_dataset.label = 
                `${rain_precipitation_cumulative_dataset.label} (${t("avg")}: ${rain_precipitation_cumulative_dataset.average.toString()} ${t("Highest")}: ${rain_precipitation_cumulative_dataset.highest ? rain_precipitation_cumulative_dataset.highest.toString() : 0} ${t("Lowest")}: ${rain_precipitation_cumulative_dataset.lowest ? rain_precipitation_cumulative_dataset.lowest.toString() : 0})`; 

            rain_precipitation_cumulative_data.datasets.push(rain_precipitation_cumulative_dataset);

            if (pressure_dataset.pcs > 0) {
                pressure_dataset.average = parseFloat((pressure_dataset.total_sum / pressure_dataset.pcs).toFixed(2));
            }
            pressure_dataset.label = 
                `${pressure_dataset.label} (${t("avg")}: ${pressure_dataset.average.toString()} ${t("Highest")}: ${pressure_dataset.highest ? pressure_dataset.highest.toString() : 0} ${t("Lowest")}: ${pressure_dataset.lowest ? pressure_dataset.lowest.toString() : 0})`; 

            pressure_data.datasets.push(pressure_dataset);

            this.setState({
                temperature_data: temperature_data,
                humidity_data: humidity_data,
                rain_tip_counter_data: rain_tip_counter_data,
                rain_tip_counter_cumulative_data: rain_tip_counter_cumulative_data,
                rain_precipitation_data: rain_precipitation_data,
                rain_precipitation_cumulative_data: rain_precipitation_cumulative_data,
                pressure_data: pressure_data,
                loading: false
            })
        } else if (sensor.model_name.startsWith("CAPROC-TH-PROBE") || sensor.model_name.startsWith("CAPROC-OC-TH")) {
            let {  temperature_data, humidity_data, wcia_data, temperature_data_2, humidity_data_2, wcia_data_2, temperature_data_3, humidity_data_3, wcia_data_3, corrected_humidity_data, corrected_wcia_data, corrected_humidity_data_2, corrected_wcia_data_2, corrected_humidity_data_3, corrected_wcia_data_3, corrected_temperature_data, corrected_temperature_data_2, corrected_temperature_data_3} = this.state;

            temperature_data.labels = [];
            temperature_data.datasets = [];
            temperature_data_2.labels = [];
            temperature_data_2.datasets = [];
            temperature_data_3.labels = [];
            temperature_data_3.datasets = [];

            // Temperature 
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature TH Probe"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature 2
            let temperature_dataset_2 = {
                ...default_dataset_green,
                label: t("Temperature TH Probe 2"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature 3
            let temperature_dataset_3 = {
                ...default_dataset_green,
                label: t("Temperature TH Probe 3"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            corrected_temperature_data.labels = [];
            corrected_temperature_data.datasets = [];
            corrected_temperature_data_2.labels = [];
            corrected_temperature_data_2.datasets = [];
            corrected_temperature_data_3.labels = [];
            corrected_temperature_data_3.datasets = [];

            // Corrected Temperature 
            let corrected_temperature_dataset = {
                ...default_dataset_green,
                label: t("Corrected temperature TH probe"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature 2
            let corrected_temperature_dataset_2 = {
                ...default_dataset_green,
                label: t("Corrected temperature TH probe 2"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature 3
            let corrected_temperature_dataset_3 = {
                ...default_dataset_green,
                label: t("Corrected temperature TH probe 3"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            humidity_data.labels = [];
            humidity_data.datasets = [];
            humidity_data_2.labels = [];
            humidity_data_2.datasets = [];
            humidity_data_3.labels = [];
            humidity_data_3.datasets = [];

            wcia_data.labels = [];
            wcia_data.datasets = [];
            wcia_data_2.labels = [];
            wcia_data_2.datasets = [];
            wcia_data_3.labels = [];
            wcia_data_3.datasets = [];

            // Humidity 
            let humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity TH Probe"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Humidity 2
            let humidity_dataset_2 = {
                ...default_dataset_blue,
                label: t("Humidity TH Probe 2"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Humidity 3
            let humidity_dataset_3 = {
                ...default_dataset_blue,
                label: t("Humidity TH Probe 3"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // WCIA 
            let wcia_dataset = {
                ...default_dataset_blue,
                label: t("WCIA TH Probe"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // WCIA 2
            let wcia_dataset_2 = {
                ...default_dataset_blue,
                label: t("WCIA TH Probe 2"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // WCIA 3
            let wcia_dataset_3 = {
                ...default_dataset_blue,
                label: t("WCIA TH Probe 3"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            corrected_humidity_data.labels = [];
            corrected_humidity_data.datasets = [];
            corrected_humidity_data_2.labels = [];
            corrected_humidity_data_2.datasets = [];
            corrected_humidity_data_3.labels = [];
            corrected_humidity_data_3.datasets = [];

            corrected_wcia_data.labels = [];
            corrected_wcia_data.datasets = [];
            corrected_wcia_data_2.labels = [];
            corrected_wcia_data_2.datasets = [];
            corrected_wcia_data_3.labels = [];
            corrected_wcia_data_3.datasets = [];


            // Humidity 
            let corrected_humidity_dataset = {
                ...default_dataset_blue,
                label: t("Corrected humidity TH probe"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Humidity 2
            let corrected_humidity_dataset_2 = {
                ...default_dataset_blue,
                label: t("Corrected humidity TH probe 2"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Humidity 3
            let corrected_humidity_dataset_3 = {
                ...default_dataset_blue,
                label: t("Corrected humidity TH probe 3"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // WCIA 
            let corrected_wcia_dataset = {
                ...default_dataset_blue,
                label: t("Corrected WCIA TH probe"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // WCIA 2
            let corrected_wcia_dataset_2 = {
                ...default_dataset_blue,
                label: t("Corrected WCIA TH probe 2"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // WCIA 3
            let corrected_wcia_dataset_3 = {
                ...default_dataset_blue,
                label: t("Corrected WCIA TH probe 3"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "Temperature TH Probe") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                }
                // Humidity
                else if (report[i].name === "Humidity TH Probe") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;

                    this.setState({
                        humidity_text: t(report[i].display_name),
                    });
                }
                // WCIA
                else if (report[i].name === "WCIA TH Probe") {
                    wcia_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    wcia_dataset.data.push(parseFloat(report[i].value));
                    if (wcia_dataset.highest === null || (parseFloat(wcia_dataset.highest) < parseFloat(report[i].value))) {
                        wcia_dataset.highest = parseFloat(report[i].value);
                    }
                    if (wcia_dataset.lowest === null || (parseFloat(wcia_dataset.lowest) > parseFloat(report[i].value))) {
                        wcia_dataset.lowest = parseFloat(report[i].value);
                    }
                    wcia_dataset.total_sum += parseFloat(report[i].value);
                    wcia_dataset.pcs += 1;

                    this.setState({
                        wcia_text: t(report[i].display_name),
                    });
                }
                // Temperature 2
                else if (report[i].name === "Temperature TH Probe 2") {
                    temperature_data_2.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset_2.data.push(parseFloat(report[i].value));
                    if (temperature_dataset_2.highest === null || (parseFloat(temperature_dataset_2.highest) < parseFloat(report[i].value))) {
                        temperature_dataset_2.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset_2.lowest === null || (parseFloat(temperature_dataset_2.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset_2.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset_2.total_sum += parseFloat(report[i].value);
                    temperature_dataset_2.pcs += 1;

                    this.setState({
                        temperature_text_2: t(report[i].display_name),
                    });
                }
                // Humidity 2
                else if (report[i].name === "Humidity TH Probe 2") {
                    humidity_data_2.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset_2.data.push(parseFloat(report[i].value));
                    if (humidity_dataset_2.highest === null || (parseFloat(humidity_dataset_2.highest) < parseFloat(report[i].value))) {
                        humidity_dataset_2.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset_2.lowest === null || (parseFloat(humidity_dataset_2.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset_2.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset_2.total_sum += parseFloat(report[i].value);
                    humidity_dataset_2.pcs += 1;

                    this.setState({
                        humidity_text_2: t(report[i].display_name),
                    });
                }
                // WCIA 2
                else if (report[i].name === "WCIA TH Probe 2") {
                    wcia_data_2.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    wcia_dataset_2.data.push(parseFloat(report[i].value));
                    if (wcia_dataset_2.highest === null || (parseFloat(wcia_dataset_2.highest) < parseFloat(report[i].value))) {
                        wcia_dataset_2.highest = parseFloat(report[i].value);
                    }
                    if (wcia_dataset_2.lowest === null || (parseFloat(wcia_dataset_2.lowest) > parseFloat(report[i].value))) {
                        wcia_dataset_2.lowest = parseFloat(report[i].value);
                    }
                    wcia_dataset_2.total_sum += parseFloat(report[i].value);
                    wcia_dataset_2.pcs += 1;

                    this.setState({
                        wcia_text_2: t(report[i].display_name),
                    });
                }                
                // Temperature 3
                else if (report[i].name === "Temperature TH Probe 3") {
                    temperature_data_3.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset_3.data.push(parseFloat(report[i].value));
                    if (temperature_dataset_3.highest === null || (parseFloat(temperature_dataset_3.highest) < parseFloat(report[i].value))) {
                        temperature_dataset_3.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset_3.lowest === null || (parseFloat(temperature_dataset_3.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset_3.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset_3.total_sum += parseFloat(report[i].value);
                    temperature_dataset_3.pcs += 1;

                    this.setState({
                        temperature_text_3: t(report[i].display_name),
                    });
                }
                // Humidity 3
                else if (report[i].name === "Humidity TH Probe 3") {
                    humidity_data_3.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset_3.data.push(parseFloat(report[i].value));
                    if (humidity_dataset_3.highest === null || (parseFloat(humidity_dataset_3.highest) < parseFloat(report[i].value))) {
                        humidity_dataset_3.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset_3.lowest === null || (parseFloat(humidity_dataset_3.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset_3.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset_3.total_sum += parseFloat(report[i].value);
                    humidity_dataset_3.pcs += 1;

                    this.setState({
                        humidity_text_3: t(report[i].display_name),
                    });
                }
                // WCIA 3
                else if (report[i].name === "WCIA TH Probe 3") {
                    wcia_data_3.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    wcia_dataset_3.data.push(parseFloat(report[i].value));
                    if (wcia_dataset_3.highest === null || (parseFloat(wcia_dataset_3.highest) < parseFloat(report[i].value))) {
                        wcia_dataset_3.highest = parseFloat(report[i].value);
                    }
                    if (wcia_dataset_3.lowest === null || (parseFloat(wcia_dataset_3.lowest) > parseFloat(report[i].value))) {
                        wcia_dataset_3.lowest = parseFloat(report[i].value);
                    }
                    wcia_dataset_3.total_sum += parseFloat(report[i].value);
                    wcia_dataset_3.pcs += 1;

                    this.setState({
                        wcia_text_3: t(report[i].display_name),
                    });
                }
                // Corrected / calibrated values
                // Temperature
                else if (report[i].name === "Corrected temperature TH probe") {
                    corrected_temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    corrected_temperature_dataset.data.push(parseFloat(report[i].value));
                    if (corrected_temperature_dataset.highest === null || (parseFloat(corrected_temperature_dataset.highest) < parseFloat(report[i].value))) {
                        corrected_temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (corrected_temperature_dataset.lowest === null || (parseFloat(corrected_temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        corrected_temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    corrected_temperature_dataset.total_sum += parseFloat(report[i].value);
                    corrected_temperature_dataset.pcs += 1;

                    this.setState({
                        corrected_temperature_text: t(report[i].display_name),
                    });
                }
                // Humidity
                else if (report[i].name === "Corrected humidity TH probe") {
                    corrected_humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    corrected_humidity_dataset.data.push(parseFloat(report[i].value));
                    if (corrected_humidity_dataset.highest === null || (parseFloat(corrected_humidity_dataset.highest) < parseFloat(report[i].value))) {
                        corrected_humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (corrected_humidity_dataset.lowest === null || (parseFloat(corrected_humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        corrected_humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    corrected_humidity_dataset.total_sum += parseFloat(report[i].value);
                    corrected_humidity_dataset.pcs += 1;

                    this.setState({
                        corrected_humidity_text: t(report[i].display_name),
                    });
                }
                // WCIA
                else if (report[i].name === "Corrected WCIA TH probe") {
                    corrected_wcia_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    corrected_wcia_dataset.data.push(parseFloat(report[i].value));
                    if (corrected_wcia_dataset.highest === null || (parseFloat(corrected_wcia_dataset.highest) < parseFloat(report[i].value))) {
                        corrected_wcia_dataset.highest = parseFloat(report[i].value);
                    }
                    if (corrected_wcia_dataset.lowest === null || (parseFloat(corrected_wcia_dataset.lowest) > parseFloat(report[i].value))) {
                        corrected_wcia_dataset.lowest = parseFloat(report[i].value);
                    }
                    corrected_wcia_dataset.total_sum += parseFloat(report[i].value);
                    corrected_wcia_dataset.pcs += 1;

                    this.setState({
                        corrected_wcia_text: t(report[i].display_name),
                    });
                }
                // Temperature 2
                else if (report[i].name === "Corrected temperature TH probe 2") {
                    corrected_temperature_data_2.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    corrected_temperature_dataset_2.data.push(parseFloat(report[i].value));
                    if (corrected_temperature_dataset_2.highest === null || (parseFloat(corrected_temperature_dataset_2.highest) < parseFloat(report[i].value))) {
                        corrected_temperature_dataset_2.highest = parseFloat(report[i].value);
                    }
                    if (corrected_temperature_dataset_2.lowest === null || (parseFloat(corrected_temperature_dataset_2.lowest) > parseFloat(report[i].value))) {
                        corrected_temperature_dataset_2.lowest = parseFloat(report[i].value);
                    }
                    corrected_temperature_dataset_2.total_sum += parseFloat(report[i].value);
                    corrected_temperature_dataset_2.pcs += 1;

                    this.setState({
                        corrected_temperature_text_2: t(report[i].display_name),
                    });
                }
                // Humidity 2
                else if (report[i].name === "Corrected humidity TH probe 2") {
                    corrected_humidity_data_2.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    corrected_humidity_dataset_2.data.push(parseFloat(report[i].value));
                    if (corrected_humidity_dataset_2.highest === null || (parseFloat(corrected_humidity_dataset_2.highest) < parseFloat(report[i].value))) {
                        corrected_humidity_dataset_2.highest = parseFloat(report[i].value);
                    }
                    if (corrected_humidity_dataset_2.lowest === null || (parseFloat(corrected_humidity_dataset_2.lowest) > parseFloat(report[i].value))) {
                        corrected_humidity_dataset_2.lowest = parseFloat(report[i].value);
                    }
                    corrected_humidity_dataset_2.total_sum += parseFloat(report[i].value);
                    corrected_humidity_dataset_2.pcs += 1;

                    this.setState({
                        corrected_humidity_text_2: t(report[i].display_name),
                    });
                }
                // WCIA 2
                else if (report[i].name === "Corrected WCIA TH probe 2") {
                    corrected_wcia_data_2.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    corrected_wcia_dataset_2.data.push(parseFloat(report[i].value));
                    if (corrected_wcia_dataset_2.highest === null || (parseFloat(corrected_wcia_dataset_2.highest) < parseFloat(report[i].value))) {
                        corrected_wcia_dataset_2.highest = parseFloat(report[i].value);
                    }
                    if (corrected_wcia_dataset_2.lowest === null || (parseFloat(corrected_wcia_dataset_2.lowest) > parseFloat(report[i].value))) {
                        corrected_wcia_dataset_2.lowest = parseFloat(report[i].value);
                    }
                    corrected_wcia_dataset_2.total_sum += parseFloat(report[i].value);
                    corrected_wcia_dataset_2.pcs += 1;

                    this.setState({
                        corrected_wcia_text_2: t(report[i].display_name),
                    });
                }
                // Temperature 3
                else if (report[i].name === "Corrected temperature TH probe 3") {
                    corrected_temperature_data_3.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    corrected_temperature_dataset_3.data.push(parseFloat(report[i].value));
                    if (corrected_temperature_dataset_3.highest === null || (parseFloat(corrected_temperature_dataset_3.highest) < parseFloat(report[i].value))) {
                        corrected_temperature_dataset_3.highest = parseFloat(report[i].value);
                    }
                    if (corrected_temperature_dataset_3.lowest === null || (parseFloat(corrected_temperature_dataset_3.lowest) > parseFloat(report[i].value))) {
                        corrected_temperature_dataset_3.lowest = parseFloat(report[i].value);
                    }
                    corrected_temperature_dataset_3.total_sum += parseFloat(report[i].value);
                    corrected_temperature_dataset_3.pcs += 1;

                    this.setState({
                        corrected_temperature_text_3: t(report[i].display_name),
                    });
                }
                // Humidity 3
                else if (report[i].name === "Corrected humidity TH probe 3") {
                    corrected_humidity_data_3.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    corrected_humidity_dataset_3.data.push(parseFloat(report[i].value));
                    if (corrected_humidity_dataset_3.highest === null || (parseFloat(corrected_humidity_dataset_3.highest) < parseFloat(report[i].value))) {
                        corrected_humidity_dataset_3.highest = parseFloat(report[i].value);
                    }
                    if (corrected_humidity_dataset_3.lowest === null || (parseFloat(corrected_humidity_dataset_3.lowest) > parseFloat(report[i].value))) {
                        corrected_humidity_dataset_3.lowest = parseFloat(report[i].value);
                    }
                    corrected_humidity_dataset_3.total_sum += parseFloat(report[i].value);
                    corrected_humidity_dataset_3.pcs += 1;

                    this.setState({
                        corrected_humidity_text_3: t(report[i].display_name),
                    });
                }
                // WCIA 3
                else if (report[i].name === "Corrected WCIA TH probe 3") {
                    corrected_wcia_data_3.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    corrected_wcia_dataset_3.data.push(parseFloat(report[i].value));
                    if (corrected_wcia_dataset_3.highest === null || (parseFloat(corrected_wcia_dataset_3.highest) < parseFloat(report[i].value))) {
                        corrected_wcia_dataset_3.highest = parseFloat(report[i].value);
                    }
                    if (corrected_wcia_dataset_3.lowest === null || (parseFloat(corrected_wcia_dataset_3.lowest) > parseFloat(report[i].value))) {
                        corrected_wcia_dataset_3.lowest = parseFloat(report[i].value);
                    }
                    corrected_wcia_dataset_3.total_sum += parseFloat(report[i].value);
                    corrected_wcia_dataset_3.pcs += 1;

                    this.setState({
                        corrected_wcia_text_3: t(report[i].display_name),
                    });
                }
            }

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);
            
            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);

            if (wcia_dataset.pcs > 0) {
                wcia_dataset.average = parseFloat((wcia_dataset.total_sum / wcia_dataset.pcs).toFixed(2));
            }
            wcia_dataset.label = 
                `${wcia_dataset.label} (${t("avg")}: ${wcia_dataset.average.toString()} ${t("Highest")}: ${wcia_dataset.highest ? wcia_dataset.highest.toString() : 0} ${t("Lowest")}: ${wcia_dataset.lowest ? wcia_dataset.lowest.toString() : 0})`; 

            wcia_data.datasets.push(wcia_dataset);

            if (temperature_dataset_2.pcs > 0) {
                temperature_dataset_2.average = parseFloat((temperature_dataset_2.total_sum / temperature_dataset_2.pcs).toFixed(2));
            }
            temperature_dataset_2.label = 
                `${temperature_dataset_2.label} (${t("avg")}: ${temperature_dataset_2.average.toString()} ${t("Highest")}: ${temperature_dataset_2.highest ? temperature_dataset_2.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset_2.lowest ? temperature_dataset_2.lowest.toString() : 0})`; 

            temperature_data_2.datasets.push(temperature_dataset_2);
            
            if (humidity_dataset_2.pcs > 0) {
                humidity_dataset_2.average = parseFloat((humidity_dataset_2.total_sum / humidity_dataset_2.pcs).toFixed(2));
            }
            humidity_dataset_2.label = 
                `${humidity_dataset_2.label} (${t("avg")}: ${humidity_dataset_2.average.toString()} ${t("Highest")}: ${humidity_dataset_2.highest ? humidity_dataset_2.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset_2.lowest ? humidity_dataset_2.lowest.toString() : 0})`; 

            humidity_data_2.datasets.push(humidity_dataset_2);

            if (wcia_dataset_2.pcs > 0) {
                wcia_dataset_2.average = parseFloat((wcia_dataset_2.total_sum / wcia_dataset_2.pcs).toFixed(2));
            }
            wcia_dataset_2.label = 
                `${wcia_dataset_2.label} (${t("avg")}: ${wcia_dataset_2.average.toString()} ${t("Highest")}: ${wcia_dataset_2.highest ? wcia_dataset_2.highest.toString() : 0} ${t("Lowest")}: ${wcia_dataset_2.lowest ? wcia_dataset_2.lowest.toString() : 0})`; 

            wcia_data_2.datasets.push(wcia_dataset_2);

            if (temperature_dataset_3.pcs > 0) {
                temperature_dataset_3.average = parseFloat((temperature_dataset_3.total_sum / temperature_dataset_3.pcs).toFixed(2));
            }
            temperature_dataset_3.label = 
                `${temperature_dataset_3.label} (${t("avg")}: ${temperature_dataset_3.average.toString()} ${t("Highest")}: ${temperature_dataset_3.highest ? temperature_dataset_3.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset_3.lowest ? temperature_dataset_3.lowest.toString() : 0})`; 

            temperature_data_3.datasets.push(temperature_dataset_3);
            
            if (humidity_dataset_3.pcs > 0) {
                humidity_dataset_3.average = parseFloat((humidity_dataset_3.total_sum / humidity_dataset_3.pcs).toFixed(2));
            }
            humidity_dataset_3.label = 
                `${humidity_dataset_3.label} (${t("avg")}: ${humidity_dataset_3.average.toString()} ${t("Highest")}: ${humidity_dataset_3.highest ? humidity_dataset_3.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset_3.lowest ? humidity_dataset_3.lowest.toString() : 0})`; 

            humidity_data_3.datasets.push(humidity_dataset_3);

            if (wcia_dataset_3.pcs > 0) {
                wcia_dataset_3.average = parseFloat((wcia_dataset_3.total_sum / wcia_dataset_3.pcs).toFixed(2));
            }
            wcia_dataset_3.label = 
                `${wcia_dataset_3.label} (${t("avg")}: ${wcia_dataset_3.average.toString()} ${t("Highest")}: ${wcia_dataset_3.highest ? wcia_dataset_3.highest.toString() : 0} ${t("Lowest")}: ${wcia_dataset_3.lowest ? wcia_dataset_3.lowest.toString() : 0})`; 

            wcia_data_3.datasets.push(wcia_dataset_3);


            // Corrected
            if (corrected_temperature_dataset.pcs > 0) {
                corrected_temperature_dataset.average = parseFloat((corrected_temperature_dataset.total_sum / corrected_temperature_dataset.pcs).toFixed(2));
            }
            corrected_temperature_dataset.label = 
                `${corrected_temperature_dataset.label} (${t("avg")}: ${corrected_temperature_dataset.average.toString()} ${t("Highest")}: ${corrected_temperature_dataset.highest ? corrected_temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${corrected_temperature_dataset.lowest ? corrected_temperature_dataset.lowest.toString() : 0})`; 

            corrected_temperature_data.datasets.push(corrected_temperature_dataset);
            
            if (corrected_humidity_dataset.pcs > 0) {
                corrected_humidity_dataset.average = parseFloat((corrected_humidity_dataset.total_sum / corrected_humidity_dataset.pcs).toFixed(2));
            }
            corrected_humidity_dataset.label = 
                `${corrected_humidity_dataset.label} (${t("avg")}: ${corrected_humidity_dataset.average.toString()} ${t("Highest")}: ${corrected_humidity_dataset.highest ? corrected_humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${corrected_humidity_dataset.lowest ? corrected_humidity_dataset.lowest.toString() : 0})`; 

            corrected_humidity_data.datasets.push(corrected_humidity_dataset);

            if (corrected_wcia_dataset.pcs > 0) {
                corrected_wcia_dataset.average = parseFloat((corrected_wcia_dataset.total_sum / corrected_wcia_dataset.pcs).toFixed(2));
            }
            corrected_wcia_dataset.label = 
                `${corrected_wcia_dataset.label} (${t("avg")}: ${corrected_wcia_dataset.average.toString()} ${t("Highest")}: ${corrected_wcia_dataset.highest ? corrected_wcia_dataset.highest.toString() : 0} ${t("Lowest")}: ${corrected_wcia_dataset.lowest ? corrected_wcia_dataset.lowest.toString() : 0})`; 

            corrected_wcia_data.datasets.push(corrected_wcia_dataset);

            if (corrected_temperature_dataset_2.pcs > 0) {
                corrected_temperature_dataset_2.average = parseFloat((corrected_temperature_dataset_2.total_sum / corrected_temperature_dataset_2.pcs).toFixed(2));
            }
            corrected_temperature_dataset_2.label = 
                `${corrected_temperature_dataset_2.label} (${t("avg")}: ${corrected_temperature_dataset_2.average.toString()} ${t("Highest")}: ${corrected_temperature_dataset_2.highest ? corrected_temperature_dataset_2.highest.toString() : 0} ${t("Lowest")}: ${corrected_temperature_dataset_2.lowest ? corrected_temperature_dataset_2.lowest.toString() : 0})`; 

            corrected_temperature_data_2.datasets.push(corrected_temperature_dataset_2);
            
            if (corrected_humidity_dataset_2.pcs > 0) {
                corrected_humidity_dataset_2.average = parseFloat((corrected_humidity_dataset_2.total_sum / corrected_humidity_dataset_2.pcs).toFixed(2));
            }
            corrected_humidity_dataset_2.label = 
                `${corrected_humidity_dataset_2.label} (${t("avg")}: ${corrected_humidity_dataset_2.average.toString()} ${t("Highest")}: ${corrected_humidity_dataset_2.highest ? corrected_humidity_dataset_2.highest.toString() : 0} ${t("Lowest")}: ${corrected_humidity_dataset_2.lowest ? corrected_humidity_dataset_2.lowest.toString() : 0})`; 

            corrected_humidity_data_2.datasets.push(corrected_humidity_dataset_2);

            if (corrected_wcia_dataset_2.pcs > 0) {
                corrected_wcia_dataset_2.average = parseFloat((corrected_wcia_dataset_2.total_sum / corrected_wcia_dataset_2.pcs).toFixed(2));
            }
            corrected_wcia_dataset_2.label = 
                `${corrected_wcia_dataset_2.label} (${t("avg")}: ${corrected_wcia_dataset_2.average.toString()} ${t("Highest")}: ${corrected_wcia_dataset_2.highest ? corrected_wcia_dataset_2.highest.toString() : 0} ${t("Lowest")}: ${corrected_wcia_dataset_2.lowest ? corrected_wcia_dataset_2.lowest.toString() : 0})`; 

            corrected_wcia_data_2.datasets.push(corrected_wcia_dataset_2);

            if (corrected_temperature_dataset_3.pcs > 0) {
                corrected_temperature_dataset_3.average = parseFloat((corrected_temperature_dataset_3.total_sum / corrected_temperature_dataset_3.pcs).toFixed(2));
            }
            corrected_temperature_dataset_3.label = 
                `${corrected_temperature_dataset_3.label} (${t("avg")}: ${corrected_temperature_dataset_3.average.toString()} ${t("Highest")}: ${corrected_temperature_dataset_3.highest ? corrected_temperature_dataset_3.highest.toString() : 0} ${t("Lowest")}: ${corrected_temperature_dataset_3.lowest ? corrected_temperature_dataset_3.lowest.toString() : 0})`; 

            corrected_temperature_data_3.datasets.push(corrected_temperature_dataset_3);
            
            if (corrected_humidity_dataset_3.pcs > 0) {
                corrected_humidity_dataset_3.average = parseFloat((corrected_humidity_dataset_3.total_sum / corrected_humidity_dataset_3.pcs).toFixed(2));
            }
            corrected_humidity_dataset_3.label = 
                `${corrected_humidity_dataset_3.label} (${t("avg")}: ${corrected_humidity_dataset_3.average.toString()} ${t("Highest")}: ${corrected_humidity_dataset_3.highest ? corrected_humidity_dataset_3.highest.toString() : 0} ${t("Lowest")}: ${corrected_humidity_dataset_3.lowest ? corrected_humidity_dataset_3.lowest.toString() : 0})`; 

            corrected_humidity_data_3.datasets.push(corrected_humidity_dataset_3);

            if (corrected_wcia_dataset_3.pcs > 0) {
                corrected_wcia_dataset_3.average = parseFloat((corrected_wcia_dataset_3.total_sum / corrected_wcia_dataset_3.pcs).toFixed(2));
            }
            corrected_wcia_dataset_3.label = 
                `${corrected_wcia_dataset_3.label} (${t("avg")}: ${corrected_wcia_dataset_3.average.toString()} ${t("Highest")}: ${corrected_wcia_dataset_3.highest ? corrected_wcia_dataset_3.highest.toString() : 0} ${t("Lowest")}: ${corrected_wcia_dataset_3.lowest ? corrected_wcia_dataset_3.lowest.toString() : 0})`; 

            corrected_wcia_data_3.datasets.push(corrected_wcia_dataset_3);


            this.setState({
                temperature_data: temperature_data,
                humidity_data: humidity_data,
                wcia_data: wcia_data,
                temperature_data_2: temperature_data_2,
                humidity_data_2: humidity_data_2,
                wcia_data_2: wcia_data_2,
                temperature_data_3: temperature_data_3,
                humidity_data_3: humidity_data_3,
                wcia_data_3: wcia_data_3,
                corrected_temperature_data: corrected_temperature_data,
                corrected_humidity_data: corrected_humidity_data,
                corrected_wcia_data: corrected_wcia_data,
                corrected_temperature_data_2: corrected_temperature_data_2,
                corrected_humidity_data_2: corrected_humidity_data_2,
                corrected_wcia_data_2: corrected_wcia_data_2,
                corrected_temperature_data_3: corrected_temperature_data_3,
                corrected_humidity_data_3: corrected_humidity_data_3,
                corrected_wcia_data_3: corrected_wcia_data_3,
                loading: false
            })
            ////
        } else if (sensor.model_name.startsWith("CAPROC-T-PROBE")) {
            let {  temperature_data, temperature_data_2,temperature_data_3} = this.state;

            temperature_data.labels = [];
            temperature_data.datasets = [];
            temperature_data_2.labels = [];
            temperature_data_2.datasets = [];
            temperature_data_3.labels = [];
            temperature_data_3.datasets = [];

            // Temperature 
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature T Probe"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature 2
            let temperature_dataset_2 = {
                ...default_dataset_green,
                label: t("Temperature T Probe 2"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature 3
            let temperature_dataset_3 = {
                ...default_dataset_green,
                label: t("Temperature T Probe 3"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "Temperature T Probe") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                }
                // Temperature 2
                else if (report[i].name === "Temperature T Probe 2") {
                    temperature_data_2.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset_2.data.push(parseFloat(report[i].value));
                    if (temperature_dataset_2.highest === null || (parseFloat(temperature_dataset_2.highest) < parseFloat(report[i].value))) {
                        temperature_dataset_2.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset_2.lowest === null || (parseFloat(temperature_dataset_2.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset_2.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset_2.total_sum += parseFloat(report[i].value);
                    temperature_dataset_2.pcs += 1;

                    this.setState({
                        temperature_text_2: t(report[i].display_name),
                    });
                }
                // Temperature 3
                else if (report[i].name === "Temperature T Probe 3") {
                    temperature_data_3.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset_3.data.push(parseFloat(report[i].value));
                    if (temperature_dataset_3.highest === null || (parseFloat(temperature_dataset_3.highest) < parseFloat(report[i].value))) {
                        temperature_dataset_3.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset_3.lowest === null || (parseFloat(temperature_dataset_3.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset_3.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset_3.total_sum += parseFloat(report[i].value);
                    temperature_dataset_3.pcs += 1;

                    this.setState({
                        temperature_text_3: t(report[i].display_name),
                    });
                }
            }

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);
            
            if (temperature_dataset_2.pcs > 0) {
                temperature_dataset_2.average = parseFloat((temperature_dataset_2.total_sum / temperature_dataset_2.pcs).toFixed(2));
            }
            temperature_dataset_2.label = 
                `${temperature_dataset_2.label} (${t("avg")}: ${temperature_dataset_2.average.toString()} ${t("Highest")}: ${temperature_dataset_2.highest ? temperature_dataset_2.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset_2.lowest ? temperature_dataset_2.lowest.toString() : 0})`; 

            temperature_data_2.datasets.push(temperature_dataset_2);
            
            if (temperature_dataset_3.pcs > 0) {
                temperature_dataset_3.average = parseFloat((temperature_dataset_3.total_sum / temperature_dataset_3.pcs).toFixed(2));
            }
            temperature_dataset_3.label = 
                `${temperature_dataset_3.label} (${t("avg")}: ${temperature_dataset_3.average.toString()} ${t("Highest")}: ${temperature_dataset_3.highest ? temperature_dataset_3.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset_3.lowest ? temperature_dataset_3.lowest.toString() : 0})`; 

            temperature_data_3.datasets.push(temperature_dataset_3);
            
            this.setState({
                temperature_data: temperature_data,
                temperature_data_2: temperature_data_2,
                temperature_data_3: temperature_data_3,
                loading: false
            })
        } else if (sensor.model_name.startsWith("CAPROC-HAN")) {
            let { temperature_data, humidity_data, _1_0_1_8_0_data, _1_0_2_8_0_data, _1_0_3_8_0_data, _1_0_4_8_0_data, _1_0_1_7_0_data, _1_0_21_7_0_data, _1_0_22_7_0_data, _1_0_23_7_0_data, _1_0_24_7_0_data, _1_0_2_7_0_data, _1_0_31_7_0_data, _1_0_32_7_0_data, _1_0_3_7_0_data, _1_0_41_7_0_data, _1_0_42_7_0_data, _1_0_43_7_0_data, _1_0_44_7_0_data, _1_0_4_7_0_data, _1_0_51_7_0_data, _1_0_52_7_0_data, _1_0_61_7_0_data, _1_0_63_7_0_data, _1_0_62_7_0_data, _1_0_64_7_0_data, _1_0_71_7_0_data, _1_0_72_7_0_data} = this.state;

            _1_0_1_8_0_data.labels = [];
            _1_0_1_8_0_data.datasets = [];

            _1_0_2_8_0_data.labels = [];
            _1_0_2_8_0_data.datasets = [];

            _1_0_3_8_0_data.labels = [];
            _1_0_3_8_0_data.datasets = [];

            _1_0_4_8_0_data.labels = [];
            _1_0_4_8_0_data.datasets = [];

            _1_0_1_7_0_data.labels = [];
            _1_0_1_7_0_data.datasets = [];

            _1_0_2_7_0_data.labels = [];
            _1_0_2_7_0_data.datasets = [];

            _1_0_3_7_0_data.labels = [];
            _1_0_3_7_0_data.datasets = [];

            _1_0_4_7_0_data.labels = [];
            _1_0_4_7_0_data.datasets = [];

            _1_0_21_7_0_data.labels = [];
            _1_0_21_7_0_data.datasets = [];

            _1_0_22_7_0_data.labels = [];
            _1_0_22_7_0_data.datasets = [];

            _1_0_41_7_0_data.labels = [];
            _1_0_41_7_0_data.datasets = [];

            _1_0_42_7_0_data.labels = [];
            _1_0_42_7_0_data.datasets = [];

            _1_0_61_7_0_data.labels = [];
            _1_0_61_7_0_data.datasets = [];

            _1_0_62_7_0_data.labels = [];
            _1_0_62_7_0_data.datasets = [];

            _1_0_23_7_0_data.labels = [];
            _1_0_23_7_0_data.datasets = [];

            _1_0_24_7_0_data.labels = [];
            _1_0_24_7_0_data.datasets = [];

            _1_0_43_7_0_data.labels = [];
            _1_0_43_7_0_data.datasets = [];

            _1_0_44_7_0_data.labels = [];
            _1_0_44_7_0_data.datasets = [];

            _1_0_63_7_0_data.labels = [];
            _1_0_63_7_0_data.datasets = [];

            _1_0_64_7_0_data.labels = [];
            _1_0_64_7_0_data.datasets = [];

            _1_0_32_7_0_data.labels = [];
            _1_0_32_7_0_data.datasets = [];

            _1_0_52_7_0_data.labels = [];
            _1_0_52_7_0_data.datasets = [];

            _1_0_72_7_0_data.labels = [];
            _1_0_72_7_0_data.datasets = [];

            _1_0_31_7_0_data.labels = [];
            _1_0_31_7_0_data.datasets = [];

            _1_0_51_7_0_data.labels = [];
            _1_0_51_7_0_data.datasets = [];

            _1_0_71_7_0_data.labels = [];
            _1_0_71_7_0_data.datasets = [];

            humidity_data.labels = [];
            humidity_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];


            // 1-0:1.8.0
            let _1_0_1_8_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:1.8.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // 1-0:2.8.0
            let _1_0_2_8_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:2.8.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // 1-0:3.8.0
            let _1_0_3_8_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:3.8.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // 1-0:4.8.0
            let _1_0_4_8_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:4.8.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            // 1-0:1.7.0
            let _1_0_1_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:1.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:2.7.0
            let _1_0_2_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:2.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:3.7.0
            let _1_0_3_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:3.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:4.7.0
            let _1_0_4_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:4.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:21.7.0
            let _1_0_21_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:21.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:22.7.0
            let _1_0_22_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:22.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:41.7.0
            let _1_0_41_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:41.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:42.7.0
            let _1_0_42_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:42.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:61.7.0
            let _1_0_61_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:61.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:62.7.0
            let _1_0_62_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:62.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:23.7.0
            let _1_0_23_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:23.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:24.7.0
            let _1_0_24_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:24.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:43.7.0
            let _1_0_43_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:43.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:44.7.0
            let _1_0_44_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:44.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:63.7.0
            let _1_0_63_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:63.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:64.7.0
            let _1_0_64_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:64.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:32.7.0
            let _1_0_32_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:32.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:52.7.0
            let _1_0_52_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:52.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:72.7.0
            let _1_0_72_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:72.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:31.7.0
            let _1_0_31_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:31.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // 1-0:51.7.0
            let _1_0_51_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:51.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // 1-0:71.7.0
            let _1_0_71_7_0_dataset = {
                ...default_dataset_blue,
                label: t("1-0:71.7.0"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            // Temperature
            let temperature_dataset = {
                ...default_dataset_red,
                label: t("Temperature"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Humidity
            let humidity_dataset = {
                ...default_dataset_green,
                label: t("Humidity"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                if (report[i].name === "1-0:1.8.0") {
                    _1_0_1_8_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_1_8_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_1_8_0_dataset.highest === null || (parseFloat(_1_0_1_8_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_1_8_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_1_8_0_dataset.lowest === null || (parseFloat(_1_0_1_8_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_1_8_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_1_8_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_1_8_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_1_8_0_text: t(report[i].display_name)
                    });

                } else if (report[i].name === "1-0:2.8.0") {
                    _1_0_2_8_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_2_8_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_2_8_0_dataset.highest === null || (parseFloat(_1_0_2_8_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_2_8_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_2_8_0_dataset.lowest === null || (parseFloat(_1_0_2_8_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_2_8_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_2_8_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_2_8_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_2_8_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:3.8.0") {
                    _1_0_3_8_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_3_8_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_3_8_0_dataset.highest === null || (parseFloat(_1_0_3_8_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_3_8_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_3_8_0_dataset.lowest === null || (parseFloat(_1_0_3_8_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_3_8_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_3_8_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_3_8_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_3_8_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:4.8.0") {
                    _1_0_4_8_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_4_8_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_4_8_0_dataset.highest === null || (parseFloat(_1_0_4_8_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_4_8_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_4_8_0_dataset.lowest === null || (parseFloat(_1_0_4_8_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_4_8_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_4_8_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_4_8_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_4_8_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:1.7.0") {
                    _1_0_1_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_1_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_1_7_0_dataset.highest === null || (parseFloat(_1_0_1_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_1_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_1_7_0_dataset.lowest === null || (parseFloat(_1_0_1_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_1_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_1_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_1_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_1_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:2.7.0") {
                    _1_0_2_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_2_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_2_7_0_dataset.highest === null || (parseFloat(_1_0_2_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_2_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_2_7_0_dataset.lowest === null || (parseFloat(_1_0_2_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_2_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_2_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_2_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_2_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:3.7.0") {
                    _1_0_3_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_3_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_3_7_0_dataset.highest === null || (parseFloat(_1_0_3_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_3_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_3_7_0_dataset.lowest === null || (parseFloat(_1_0_3_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_3_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_3_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_3_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_3_7_0_text: t(report[i].display_name)
                    });

                } else if (report[i].name === "1-0:4.7.0") {
                    _1_0_4_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_4_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_4_7_0_dataset.highest === null || (parseFloat(_1_0_4_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_4_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_4_7_0_dataset.lowest === null || (parseFloat(_1_0_4_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_4_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_4_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_4_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_4_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:21.7.0") {
                    _1_0_21_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_21_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_21_7_0_dataset.highest === null || (parseFloat(_1_0_21_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_21_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_21_7_0_dataset.lowest === null || (parseFloat(_1_0_21_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_21_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_21_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_21_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_21_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:22.7.0") {
                    _1_0_22_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_22_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_22_7_0_dataset.highest === null || (parseFloat(_1_0_22_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_22_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_22_7_0_dataset.lowest === null || (parseFloat(_1_0_22_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_22_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_22_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_22_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_22_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:41.7.0") {
                    _1_0_41_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_41_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_41_7_0_dataset.highest === null || (parseFloat(_1_0_41_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_41_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_41_7_0_dataset.lowest === null || (parseFloat(_1_0_41_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_41_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_41_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_41_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_41_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:42.7.0") {
                    _1_0_42_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_42_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_42_7_0_dataset.highest === null || (parseFloat(_1_0_42_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_42_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_42_7_0_dataset.lowest === null || (parseFloat(_1_0_42_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_42_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_42_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_42_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_42_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:61.7.0") {
                    _1_0_61_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_61_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_61_7_0_dataset.highest === null || (parseFloat(_1_0_61_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_61_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_61_7_0_dataset.lowest === null || (parseFloat(_1_0_61_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_61_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_61_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_61_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_61_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:62.7.0") {
                    _1_0_62_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_62_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_62_7_0_dataset.highest === null || (parseFloat(_1_0_62_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_62_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_62_7_0_dataset.lowest === null || (parseFloat(_1_0_62_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_62_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_62_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_62_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_62_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:23.7.0") {
                    _1_0_23_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_23_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_23_7_0_dataset.highest === null || (parseFloat(_1_0_23_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_23_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_23_7_0_dataset.lowest === null || (parseFloat(_1_0_23_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_23_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_23_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_23_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_23_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:24.7.0") {
                    _1_0_24_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_24_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_24_7_0_dataset.highest === null || (parseFloat(_1_0_24_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_24_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_24_7_0_dataset.lowest === null || (parseFloat(_1_0_24_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_24_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_24_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_24_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_24_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:43.7.0") {
                    _1_0_43_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_43_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_43_7_0_dataset.highest === null || (parseFloat(_1_0_43_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_43_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_43_7_0_dataset.lowest === null || (parseFloat(_1_0_43_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_43_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_43_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_43_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_43_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:44.7.0") {
                    _1_0_44_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_44_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_44_7_0_dataset.highest === null || (parseFloat(_1_0_44_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_44_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_44_7_0_dataset.lowest === null || (parseFloat(_1_0_44_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_44_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_44_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_44_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_44_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:63.7.0") {
                    _1_0_63_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_63_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_63_7_0_dataset.highest === null || (parseFloat(_1_0_63_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_63_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_63_7_0_dataset.lowest === null || (parseFloat(_1_0_63_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_63_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_63_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_63_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_63_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:64.7.0") {
                    _1_0_64_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_64_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_64_7_0_dataset.highest === null || (parseFloat(_1_0_64_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_64_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_64_7_0_dataset.lowest === null || (parseFloat(_1_0_64_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_64_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_64_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_64_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_64_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:32.7.0") {
                    _1_0_32_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_32_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_32_7_0_dataset.highest === null || (parseFloat(_1_0_32_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_32_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_32_7_0_dataset.lowest === null || (parseFloat(_1_0_32_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_32_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_32_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_32_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_32_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:52.7.0") {
                    _1_0_52_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_52_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_52_7_0_dataset.highest === null || (parseFloat(_1_0_52_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_52_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_52_7_0_dataset.lowest === null || (parseFloat(_1_0_52_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_52_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_52_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_52_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_52_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:72.7.0") {
                    _1_0_72_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_72_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_72_7_0_dataset.highest === null || (parseFloat(_1_0_72_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_72_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_72_7_0_dataset.lowest === null || (parseFloat(_1_0_72_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_72_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_72_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_72_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_72_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:31.7.0") {
                    _1_0_31_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_31_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_31_7_0_dataset.highest === null || (parseFloat(_1_0_31_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_31_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_31_7_0_dataset.lowest === null || (parseFloat(_1_0_31_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_31_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_31_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_31_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_31_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:51.7.0") {
                    _1_0_51_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_51_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_51_7_0_dataset.highest === null || (parseFloat(_1_0_51_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_51_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_51_7_0_dataset.lowest === null || (parseFloat(_1_0_51_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_51_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_51_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_51_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_51_7_0_text: t(report[i].display_name)
                    });
                } else if (report[i].name === "1-0:71.7.0") {
                    _1_0_71_7_0_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    _1_0_71_7_0_dataset.data.push(parseFloat(report[i].value));
                    if (_1_0_71_7_0_dataset.highest === null || (parseFloat(_1_0_71_7_0_dataset.highest) < parseFloat(report[i].value))) {
                        _1_0_71_7_0_dataset.highest = parseFloat(report[i].value);
                    }
                    if (_1_0_71_7_0_dataset.lowest === null || (parseFloat(_1_0_71_7_0_dataset.lowest) > parseFloat(report[i].value))) {
                        _1_0_71_7_0_dataset.lowest = parseFloat(report[i].value);
                    }
                    _1_0_71_7_0_dataset.total_sum += parseFloat(report[i].value);
                    _1_0_71_7_0_dataset.pcs += 1;
                    this.setState({
                        _1_0_71_7_0_text: t(report[i].display_name)
                    });
                // Temperature
                } else if (report[i].name === "Temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;
                    this.setState({
                        temperature_text: t(report[i].display_name)
                    });
                // Humidity
                } else if (report[i].name === "Humidity") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;
                    this.setState({
                        humidity_text: t(report[i].display_name)
                    });
                }

            }

            if (_1_0_1_8_0_dataset.pcs > 0) {
                _1_0_1_8_0_dataset.average = parseFloat((_1_0_1_8_0_dataset.total_sum / _1_0_1_8_0_dataset.pcs).toFixed(2));
            }
            _1_0_1_8_0_dataset.label = 
                `${_1_0_1_8_0_dataset.label} (${t("avg")}: ${_1_0_1_8_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_1_8_0_dataset.highest ? _1_0_1_8_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_1_8_0_dataset.lowest ? _1_0_1_8_0_dataset.lowest.toString() : 0})`; 

            _1_0_1_8_0_data.datasets.push(_1_0_1_8_0_dataset);

            if (_1_0_2_8_0_dataset.pcs > 0) {
                _1_0_2_8_0_dataset.average = parseFloat((_1_0_2_8_0_dataset.total_sum / _1_0_2_8_0_dataset.pcs).toFixed(2));
            }
            _1_0_2_8_0_dataset.label = 
                `${_1_0_2_8_0_dataset.label} (${t("avg")}: ${_1_0_2_8_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_2_8_0_dataset.highest ? _1_0_2_8_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_2_8_0_dataset.lowest ? _1_0_2_8_0_dataset.lowest.toString() : 0})`; 

            _1_0_2_8_0_data.datasets.push(_1_0_2_8_0_dataset);

            if (_1_0_3_8_0_dataset.pcs > 0) {
                _1_0_3_8_0_dataset.average = parseFloat((_1_0_3_8_0_dataset.total_sum / _1_0_3_8_0_dataset.pcs).toFixed(2));
            }
            _1_0_3_8_0_dataset.label = 
                `${_1_0_3_8_0_dataset.label} (${t("avg")}: ${_1_0_3_8_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_3_8_0_dataset.highest ? _1_0_3_8_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_3_8_0_dataset.lowest ? _1_0_3_8_0_dataset.lowest.toString() : 0})`; 

            _1_0_3_8_0_data.datasets.push(_1_0_3_8_0_dataset);

            if (_1_0_4_8_0_dataset.pcs > 0) {
                _1_0_4_8_0_dataset.average = parseFloat((_1_0_4_8_0_dataset.total_sum / _1_0_4_8_0_dataset.pcs).toFixed(2));
            }
            _1_0_4_8_0_dataset.label = 
                `${_1_0_4_8_0_dataset.label} (${t("avg")}: ${_1_0_4_8_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_4_8_0_dataset.highest ? _1_0_4_8_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_4_8_0_dataset.lowest ? _1_0_4_8_0_dataset.lowest.toString() : 0})`; 

            _1_0_4_8_0_data.datasets.push(_1_0_4_8_0_dataset);

            if (_1_0_1_7_0_dataset.pcs > 0) {
                _1_0_1_7_0_dataset.average = parseFloat((_1_0_1_7_0_dataset.total_sum / _1_0_1_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_1_7_0_dataset.label = 
                `${_1_0_1_7_0_dataset.label} (${t("avg")}: ${_1_0_1_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_1_7_0_dataset.highest ? _1_0_1_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_1_7_0_dataset.lowest ? _1_0_1_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_1_7_0_data.datasets.push(_1_0_1_7_0_dataset);

            if (_1_0_2_7_0_dataset.pcs > 0) {
                _1_0_2_7_0_dataset.average = parseFloat((_1_0_2_7_0_dataset.total_sum / _1_0_2_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_2_7_0_dataset.label = 
                `${_1_0_2_7_0_dataset.label} (${t("avg")}: ${_1_0_2_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_2_7_0_dataset.highest ? _1_0_2_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_2_7_0_dataset.lowest ? _1_0_2_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_2_7_0_data.datasets.push(_1_0_2_7_0_dataset);

            if (_1_0_3_7_0_dataset.pcs > 0) {
                _1_0_3_7_0_dataset.average = parseFloat((_1_0_3_7_0_dataset.total_sum / _1_0_3_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_3_7_0_dataset.label = 
                `${_1_0_3_7_0_dataset.label} (${t("avg")}: ${_1_0_3_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_3_7_0_dataset.highest ? _1_0_3_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_3_7_0_dataset.lowest ? _1_0_3_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_3_7_0_data.datasets.push(_1_0_3_7_0_dataset);

            if (_1_0_4_7_0_dataset.pcs > 0) {
                _1_0_4_7_0_dataset.average = parseFloat((_1_0_4_7_0_dataset.total_sum / _1_0_4_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_4_7_0_dataset.label = 
                `${_1_0_4_7_0_dataset.label} (${t("avg")}: ${_1_0_4_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_4_7_0_dataset.highest ? _1_0_4_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_4_7_0_dataset.lowest ? _1_0_4_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_4_7_0_data.datasets.push(_1_0_4_7_0_dataset);

            if (_1_0_21_7_0_dataset.pcs > 0) {
                _1_0_21_7_0_dataset.average = parseFloat((_1_0_21_7_0_dataset.total_sum / _1_0_21_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_21_7_0_dataset.label = 
                `${_1_0_21_7_0_dataset.label} (${t("avg")}: ${_1_0_21_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_21_7_0_dataset.highest ? _1_0_21_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_21_7_0_dataset.lowest ? _1_0_21_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_21_7_0_data.datasets.push(_1_0_21_7_0_dataset);

            if (_1_0_22_7_0_dataset.pcs > 0) {
                _1_0_22_7_0_dataset.average = parseFloat((_1_0_22_7_0_dataset.total_sum / _1_0_22_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_22_7_0_dataset.label = 
                `${_1_0_22_7_0_dataset.label} (${t("avg")}: ${_1_0_22_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_22_7_0_dataset.highest ? _1_0_22_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_22_7_0_dataset.lowest ? _1_0_22_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_22_7_0_data.datasets.push(_1_0_22_7_0_dataset);

            if (_1_0_41_7_0_dataset.pcs > 0) {
                _1_0_41_7_0_dataset.average = parseFloat((_1_0_41_7_0_dataset.total_sum / _1_0_41_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_41_7_0_dataset.label = 
                `${_1_0_41_7_0_dataset.label} (${t("avg")}: ${_1_0_41_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_41_7_0_dataset.highest ? _1_0_41_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_41_7_0_dataset.lowest ? _1_0_41_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_41_7_0_data.datasets.push(_1_0_41_7_0_dataset);

            if (_1_0_42_7_0_dataset.pcs > 0) {
                _1_0_42_7_0_dataset.average = parseFloat((_1_0_42_7_0_dataset.total_sum / _1_0_42_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_42_7_0_dataset.label = 
                `${_1_0_42_7_0_dataset.label} (${t("avg")}: ${_1_0_42_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_42_7_0_dataset.highest ? _1_0_42_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_42_7_0_dataset.lowest ? _1_0_42_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_42_7_0_data.datasets.push(_1_0_42_7_0_dataset);

            if (_1_0_61_7_0_dataset.pcs > 0) {
                _1_0_61_7_0_dataset.average = parseFloat((_1_0_61_7_0_dataset.total_sum / _1_0_61_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_61_7_0_dataset.label = 
                `${_1_0_61_7_0_dataset.label} (${t("avg")}: ${_1_0_61_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_61_7_0_dataset.highest ? _1_0_61_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_61_7_0_dataset.lowest ? _1_0_61_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_61_7_0_data.datasets.push(_1_0_61_7_0_dataset);

            if (_1_0_62_7_0_dataset.pcs > 0) {
                _1_0_62_7_0_dataset.average = parseFloat((_1_0_62_7_0_dataset.total_sum / _1_0_62_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_62_7_0_dataset.label = 
                `${_1_0_62_7_0_dataset.label} (${t("avg")}: ${_1_0_62_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_62_7_0_dataset.highest ? _1_0_62_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_62_7_0_dataset.lowest ? _1_0_62_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_62_7_0_data.datasets.push(_1_0_62_7_0_dataset);

            if (_1_0_23_7_0_dataset.pcs > 0) {
                _1_0_23_7_0_dataset.average = parseFloat((_1_0_23_7_0_dataset.total_sum / _1_0_23_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_23_7_0_dataset.label = 
                `${_1_0_23_7_0_dataset.label} (${t("avg")}: ${_1_0_23_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_23_7_0_dataset.highest ? _1_0_23_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_23_7_0_dataset.lowest ? _1_0_23_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_23_7_0_data.datasets.push(_1_0_23_7_0_dataset);

            if (_1_0_24_7_0_dataset.pcs > 0) {
                _1_0_24_7_0_dataset.average = parseFloat((_1_0_24_7_0_dataset.total_sum / _1_0_24_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_24_7_0_dataset.label = 
                `${_1_0_24_7_0_dataset.label} (${t("avg")}: ${_1_0_24_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_24_7_0_dataset.highest ? _1_0_24_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_24_7_0_dataset.lowest ? _1_0_24_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_24_7_0_data.datasets.push(_1_0_24_7_0_dataset);

            if (_1_0_43_7_0_dataset.pcs > 0) {
                _1_0_43_7_0_dataset.average = parseFloat((_1_0_43_7_0_dataset.total_sum / _1_0_43_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_43_7_0_dataset.label = 
                `${_1_0_43_7_0_dataset.label} (${t("avg")}: ${_1_0_43_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_43_7_0_dataset.highest ? _1_0_43_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_43_7_0_dataset.lowest ? _1_0_43_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_43_7_0_data.datasets.push(_1_0_43_7_0_dataset);

            if (_1_0_44_7_0_dataset.pcs > 0) {
                _1_0_44_7_0_dataset.average = parseFloat((_1_0_44_7_0_dataset.total_sum / _1_0_44_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_44_7_0_dataset.label = 
                `${_1_0_44_7_0_dataset.label} (${t("avg")}: ${_1_0_44_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_44_7_0_dataset.highest ? _1_0_44_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_44_7_0_dataset.lowest ? _1_0_44_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_44_7_0_data.datasets.push(_1_0_44_7_0_dataset);

            if (_1_0_63_7_0_dataset.pcs > 0) {
                _1_0_63_7_0_dataset.average = parseFloat((_1_0_63_7_0_dataset.total_sum / _1_0_63_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_63_7_0_dataset.label = 
                `${_1_0_63_7_0_dataset.label} (${t("avg")}: ${_1_0_63_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_63_7_0_dataset.highest ? _1_0_63_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_63_7_0_dataset.lowest ? _1_0_63_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_63_7_0_data.datasets.push(_1_0_63_7_0_dataset);

            if (_1_0_64_7_0_dataset.pcs > 0) {
                _1_0_64_7_0_dataset.average = parseFloat((_1_0_64_7_0_dataset.total_sum / _1_0_64_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_64_7_0_dataset.label = 
                `${_1_0_64_7_0_dataset.label} (${t("avg")}: ${_1_0_64_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_64_7_0_dataset.highest ? _1_0_64_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_64_7_0_dataset.lowest ? _1_0_64_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_64_7_0_data.datasets.push(_1_0_64_7_0_dataset);

            if (_1_0_32_7_0_dataset.pcs > 0) {
                _1_0_32_7_0_dataset.average = parseFloat((_1_0_32_7_0_dataset.total_sum / _1_0_32_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_32_7_0_dataset.label = 
                `${_1_0_32_7_0_dataset.label} (${t("avg")}: ${_1_0_32_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_32_7_0_dataset.highest ? _1_0_32_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_32_7_0_dataset.lowest ? _1_0_32_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_32_7_0_data.datasets.push(_1_0_32_7_0_dataset);

            if (_1_0_52_7_0_dataset.pcs > 0) {
                _1_0_52_7_0_dataset.average = parseFloat((_1_0_52_7_0_dataset.total_sum / _1_0_52_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_52_7_0_dataset.label = 
                `${_1_0_52_7_0_dataset.label} (${t("avg")}: ${_1_0_52_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_52_7_0_dataset.highest ? _1_0_52_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_52_7_0_dataset.lowest ? _1_0_52_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_52_7_0_data.datasets.push(_1_0_52_7_0_dataset);

            if (_1_0_72_7_0_dataset.pcs > 0) {
                _1_0_72_7_0_dataset.average = parseFloat((_1_0_72_7_0_dataset.total_sum / _1_0_72_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_72_7_0_dataset.label = 
                `${_1_0_72_7_0_dataset.label} (${t("avg")}: ${_1_0_72_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_72_7_0_dataset.highest ? _1_0_72_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_72_7_0_dataset.lowest ? _1_0_72_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_72_7_0_data.datasets.push(_1_0_72_7_0_dataset);

            if (_1_0_31_7_0_dataset.pcs > 0) {
                _1_0_31_7_0_dataset.average = parseFloat((_1_0_31_7_0_dataset.total_sum / _1_0_31_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_31_7_0_dataset.label = 
                `${_1_0_31_7_0_dataset.label} (${t("avg")}: ${_1_0_31_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_31_7_0_dataset.highest ? _1_0_31_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_31_7_0_dataset.lowest ? _1_0_31_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_31_7_0_data.datasets.push(_1_0_31_7_0_dataset);

            if (_1_0_51_7_0_dataset.pcs > 0) {
                _1_0_51_7_0_dataset.average = parseFloat((_1_0_51_7_0_dataset.total_sum / _1_0_51_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_51_7_0_dataset.label = 
                `${_1_0_51_7_0_dataset.label} (${t("avg")}: ${_1_0_51_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_51_7_0_dataset.highest ? _1_0_51_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_51_7_0_dataset.lowest ? _1_0_51_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_51_7_0_data.datasets.push(_1_0_51_7_0_dataset);

            if (_1_0_71_7_0_dataset.pcs > 0) {
                _1_0_71_7_0_dataset.average = parseFloat((_1_0_71_7_0_dataset.total_sum / _1_0_71_7_0_dataset.pcs).toFixed(2));
            }
            _1_0_71_7_0_dataset.label = 
                `${_1_0_71_7_0_dataset.label} (${t("avg")}: ${_1_0_71_7_0_dataset.average.toString()} ${t("Highest")}: ${_1_0_71_7_0_dataset.highest ? _1_0_71_7_0_dataset.highest.toString() : 0} ${t("Lowest")}: ${_1_0_71_7_0_dataset.lowest ? _1_0_71_7_0_dataset.lowest.toString() : 0})`; 

            _1_0_71_7_0_data.datasets.push(_1_0_71_7_0_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);

            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);
    
            this.setState({
                _1_0_1_8_0_data: _1_0_1_8_0_data,
                _1_0_2_8_0_data: _1_0_2_8_0_data,
                _1_0_3_8_0_data: _1_0_3_8_0_data,
                _1_0_4_8_0_data: _1_0_4_8_0_data,
                _1_0_1_7_0_data: _1_0_1_7_0_data,
                _1_0_2_7_0_data: _1_0_2_7_0_data,
                _1_0_3_7_0_data: _1_0_3_7_0_data,
                _1_0_4_7_0_data: _1_0_4_7_0_data,
                _1_0_21_7_0_data: _1_0_21_7_0_data,
                _1_0_22_7_0_data: _1_0_22_7_0_data,
                _1_0_41_7_0_data: _1_0_41_7_0_data,
                _1_0_42_7_0_data: _1_0_42_7_0_data,
                _1_0_61_7_0_data: _1_0_61_7_0_data,
                _1_0_62_7_0_data: _1_0_62_7_0_data,
                _1_0_23_7_0_data: _1_0_23_7_0_data,
                _1_0_24_7_0_data: _1_0_24_7_0_data,
                _1_0_43_7_0_data: _1_0_43_7_0_data,
                _1_0_44_7_0_data: _1_0_44_7_0_data,
                _1_0_63_7_0_data: _1_0_63_7_0_data,
                _1_0_64_7_0_data: _1_0_64_7_0_data,
                _1_0_32_7_0_data: _1_0_32_7_0_data,
                _1_0_52_7_0_data: _1_0_52_7_0_data,
                _1_0_72_7_0_data: _1_0_72_7_0_data,
                _1_0_31_7_0_data: _1_0_31_7_0_data,
                _1_0_51_7_0_data: _1_0_51_7_0_data,
                _1_0_71_7_0_data: _1_0_71_7_0_data,
                temperature_data: temperature_data,
                humidity_data: humidity_data,
                loading: false
            })
        } else if (sensor.model_name === "DIGITAL-MATTER-YABBY") {
            let { battery_data, in_trip_data, speed_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            in_trip_data.labels = [];
            in_trip_data.datasets = [];

            speed_data.labels = [];
            speed_data.datasets = [];

            // in_trip
            let in_trip_dataset = {
                ...default_dataset_blue,
                label: t("In trip"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Distance deviation
            let speed_dataset = {
                ...default_dataset_red,
                label: t("Speed (km/h)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // In trip
                if (report[i].name === "inTrip") {
                    in_trip_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    in_trip_dataset.data.push(report[i].value === "True" ? 1 : 0);
                    this.setState({
                        in_trip_text: t(report[i].display_name),
                    });
                // Speed
                } else if (report[i].name === "speedKmph") {
                    speed_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    speed_dataset.data.push(parseFloat(report[i].value));
                    if (speed_dataset.highest === null || (parseFloat(speed_dataset.highest) < parseFloat(report[i].value))) {
                        speed_dataset.highest = parseFloat(report[i].value);
                    }
                    if (speed_dataset.lowest === null || (parseFloat(speed_dataset.lowest) > parseFloat(report[i].value))) {
                        speed_dataset.lowest = parseFloat(report[i].value);
                    }
                    speed_dataset.total_sum += parseFloat(report[i].value);
                    speed_dataset.pcs += 1;

                    this.setState({
                        speed_text: t(report[i].display_name),
                    });
                
                // Battery Voltage
                } else if (report[i].name === "batV") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            in_trip_data.datasets.push(in_trip_dataset);

            if (speed_dataset.pcs > 0) {
                speed_dataset.average = parseFloat((speed_dataset.total_sum / speed_dataset.pcs).toFixed(2));
            }
            speed_dataset.label = 
                `${speed_dataset.label} (${t("avg")}: ${speed_dataset.average.toString()} ${t("Highest")}: ${speed_dataset.highest ? speed_dataset.highest.toString() : 0} ${t("Lowest")}: ${speed_dataset.lowest ? speed_dataset.lowest.toString() : 0})`; 

            speed_data.datasets.push(speed_dataset);
            this.setState({
                battery_data: battery_data,
                speed_data: speed_data,
                in_trip_data: in_trip_data,
                loading: false
            })
        } else if (sensor.model_name === "ELSYS-ERS") {
            let { battery_data, humidity_data, temperature_data, light_data, motion_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            humidity_data.labels = [];
            humidity_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            light_data.labels = [];
            light_data.datasets = [];

            motion_data.labels = [];
            motion_data.datasets = [];


            // Humidity
            let humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity (%Rh)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Light
            let light_dataset = {
                ...default_dataset_blue,
                label: t("Light (Lux)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Motion
            let motion_dataset = {
                ...default_dataset_red,
                label: t("Motion (pcs)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature (°C)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                // Humidity
                } else if (report[i].name === "humidity") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;

                    this.setState({
                        humidity_text: t(report[i].display_name),
                    });
                // Light
                } else if (report[i].name === "light") {
                        light_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        light_dataset.data.push(parseFloat(report[i].value));
                        if (light_dataset.highest === null || (parseFloat(light_dataset.highest) < parseFloat(report[i].value))) {
                            light_dataset.highest = parseFloat(report[i].value);
                        }
                        if (light_dataset.lowest === null || (parseFloat(light_dataset.lowest) > parseFloat(report[i].value))) {
                            light_dataset.lowest = parseFloat(report[i].value);
                        }
                        light_dataset.total_sum += parseFloat(report[i].value);
                        light_dataset.pcs += 1;
    
                        this.setState({
                            light_text: t(report[i].display_name),
                        });
                // Motion
                } else if (report[i].name === "motion") {
                    motion_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    motion_dataset.data.push(parseFloat(report[i].value));
                    if (motion_dataset.highest === null || (parseFloat(motion_dataset.highest) < parseFloat(report[i].value))) {
                        motion_dataset.highest = parseFloat(report[i].value);
                    }
                    if (motion_dataset.lowest === null || (parseFloat(motion_dataset.lowest) > parseFloat(report[i].value))) {
                        motion_dataset.lowest = parseFloat(report[i].value);
                    }
                    motion_dataset.total_sum += parseFloat(report[i].value);
                    motion_dataset.pcs += 1;

                    this.setState({
                        motion_text: t(report[i].display_name),
                    });
                // Battery Voltage
                } else if (report[i].name === "vdd") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);

            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);

            if (light_dataset.pcs > 0) {
                light_dataset.average = parseFloat((light_dataset.total_sum / light_dataset.pcs).toFixed(2));
            }
            light_dataset.label = 
                `${light_dataset.label} (${t("avg")}: ${light_dataset.average.toString()} ${t("Highest")}: ${light_dataset.highest ? light_dataset.highest.toString() : 0} ${t("Lowest")}: ${light_dataset.lowest ? light_dataset.lowest.toString() : 0})`; 

            light_data.datasets.push(light_dataset);

            if (motion_dataset.pcs > 0) {
                motion_dataset.average = parseFloat((motion_dataset.total_sum / motion_dataset.pcs).toFixed(2));
            }
            motion_dataset.label = 
                `${motion_dataset.label} (${t("avg")}: ${motion_dataset.average.toString()} ${t("Highest")}: ${motion_dataset.highest ? motion_dataset.highest.toString() : 0} ${t("Lowest")}: ${motion_dataset.lowest ? motion_dataset.lowest.toString() : 0})`; 

            motion_data.datasets.push(motion_dataset);

            this.setState({
                battery_data: battery_data,
                humidity_data: humidity_data,
                temperature_data: temperature_data,
                light_data: light_data,
                motion_data: motion_data,
                loading: false
            })
        } else if (sensor.model_name === "ELSYS-EMS") {
            let { battery_data, humidity_data, temperature_data, door_closed_data, motion_data, waterleak_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            humidity_data.labels = [];
            humidity_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            door_closed_data.labels = [];
            door_closed_data.datasets = [];

            motion_data.labels = [];
            motion_data.datasets = [];

            waterleak_data.labels = [];
            waterleak_data.datasets = [];

            // Humidity
            let humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity (%Rh)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Door closed
            let door_closed_dataset = {
                ...default_dataset_blue,
                label: t("Door closed"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Waterleak
            let waterleak_dataset = {
                ...default_dataset_blue,
                label: t("Waterleak"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Motion
            let motion_dataset = {
                ...default_dataset_red,
                label: t("Motion (Acceleration movements)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature (°C)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
            // for (let i = report.length - 1; i > -1; i--) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                // Humidity
                } else if (report[i].name === "humidity") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;

                    this.setState({
                        humidity_text: t(report[i].display_name),
                    });
                // Door closed
                } else if (report[i].name === "digital") {
                    door_closed_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    door_closed_dataset.data.push(report[i].value === "True" ? 1 : 0);
                    this.setState({
                        in_trip_text: t(report[i].display_name),
                    });
                // Waterleak
                } else if (report[i].name === "waterleak") {
                    waterleak_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    waterleak_dataset.data.push(report[i].value === "True" ? 1 : 0);
                    this.setState({
                        waterleak_text: t(report[i].display_name),
                    });
                // Motion
                } else if (report[i].name === "accMotion") {
                    motion_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    motion_dataset.data.push(parseFloat(report[i].value));
                    if (motion_dataset.highest === null || (parseFloat(motion_dataset.highest) < parseFloat(report[i].value))) {
                        motion_dataset.highest = parseFloat(report[i].value);
                    }
                    if (motion_dataset.lowest === null || (parseFloat(motion_dataset.lowest) > parseFloat(report[i].value))) {
                        motion_dataset.lowest = parseFloat(report[i].value);
                    }
                    motion_dataset.total_sum += parseFloat(report[i].value);
                    motion_dataset.pcs += 1;

                    this.setState({
                        motion_text: t(report[i].display_name),
                    });
                // Battery Voltage
                } else if (report[i].name === "vdd") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);

            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);

            if (motion_dataset.pcs > 0) {
                motion_dataset.average = parseFloat((motion_dataset.total_sum / motion_dataset.pcs).toFixed(2));
            }
            motion_dataset.label = 
                `${motion_dataset.label} (${t("avg")}: ${motion_dataset.average.toString()} ${t("Highest")}: ${motion_dataset.highest ? motion_dataset.highest.toString() : 0} ${t("Lowest")}: ${motion_dataset.lowest ? motion_dataset.lowest.toString() : 0})`; 

            motion_data.datasets.push(motion_dataset);

            door_closed_data.datasets.push(door_closed_dataset);

            waterleak_data.datasets.push(waterleak_dataset);

            this.setState({
                battery_data: battery_data,
                humidity_data: humidity_data,
                temperature_data: temperature_data,
                door_closed_data: door_closed_data,
                waterleak_data: waterleak_data,
                motion_data: motion_data,
                loading: false
            })
        } else if (sensor.model_name === "ELSYS-ELT-2") {
            let { battery_data, humidity_data, temperature_data, temperature_external_data, pressure_data, waterleak_data, distance_data, filling_volume_data, filling_percentage_data,  distance_deviation_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            humidity_data.labels = [];
            humidity_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            temperature_external_data.labels = [];
            temperature_external_data.datasets = [];

            pressure_data.labels = [];
            pressure_data.datasets = [];

            waterleak_data.labels = [];
            waterleak_data.datasets = [];

            distance_data.labels = [];
            distance_data.datasets = [];

            distance_deviation_data.labels = [];
            distance_deviation_data.datasets = [];

            filling_volume_data.labels = [];
            filling_volume_data.datasets = [];

            filling_percentage_data.labels = [];
            filling_percentage_data.datasets = [];

            // Distance
            let distance_dataset = {
                ...default_dataset_blue,
                label: t("Distance (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling volume
            let filling_volume_dataset = {
                ...default_dataset_blue,
                label: t("Filling volume (l)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling percentage
            let filling_percentage_dataset = {
                ...default_dataset_blue,
                label: t("Filling percentage (%)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Distance deviation
            let distance_deviation_dataset = {
                ...default_dataset_blue,
                label: t("Distance deviation (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Humidity
            let humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity (%Rh)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Pressure
            let pressure_dataset = {
                ...default_dataset_blue,
                label: t("Air pressure (hPa)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Waterleak
            let waterleak_dataset = {
                ...default_dataset_blue,
                label: t("Waterleak"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature (°C)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature (external probe)
            let temperature_external_dataset = {
                ...default_dataset_green,
                label: t("Temperature (°C) (External probe)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                // Temperature (external probe)
                } else if (report[i].name === "externalTemperature") {
                    temperature_external_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_external_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_external_dataset.highest === null || (parseFloat(temperature_external_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_external_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_external_dataset.lowest === null || (parseFloat(temperature_external_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_external_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_external_dataset.total_sum += parseFloat(report[i].value);
                    temperature_external_dataset.pcs += 1;

                    this.setState({
                        temperature_external_text: t(report[i].display_name),
                    });
                
                // Humidity
                } else if (report[i].name === "humidity") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;

                    this.setState({
                        humidity_text: t(report[i].display_name),
                    });
                // Pressure
                } else if (report[i].name === "pressure") {
                    pressure_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pressure_dataset.data.push(parseFloat(report[i].value));
                    if (pressure_dataset.highest === null || (parseFloat(pressure_dataset.highest) < parseFloat(report[i].value))) {
                        pressure_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pressure_dataset.lowest === null || (parseFloat(pressure_dataset.lowest) > parseFloat(report[i].value))) {
                        pressure_dataset.lowest = parseFloat(report[i].value);
                    }
                    pressure_dataset.total_sum += parseFloat(report[i].value);
                    pressure_dataset.pcs += 1;

                    this.setState({
                        pressure_text: t(report[i].display_name),
                    });
                // Waterleak
                } else if (report[i].name === "waterleak") {
                    waterleak_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    waterleak_dataset.data.push(report[i].value === "True" ? 1 : 0);
                    this.setState({
                        waterleak_text: t(report[i].display_name),
                    });

                // Distance
                } else if (report[i].name === "distance") {
                    distance_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_dataset.data.push(parseFloat(report[i].value));
                    if (distance_dataset.highest === null || (parseFloat(distance_dataset.highest) < parseFloat(report[i].value))) {
                        distance_dataset.highest = parseFloat(report[i].value);
                    }
                    if (distance_dataset.lowest === null || (parseFloat(distance_dataset.lowest) > parseFloat(report[i].value))) {
                        distance_dataset.lowest = parseFloat(report[i].value);
                    }
                    distance_dataset.total_sum += parseFloat(report[i].value);
                    distance_dataset.pcs += 1;

                // Filling volume
                } else if (report[i].group === "Report" && report[i].name === "fillingVolume") {
                    filling_volume_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_volume_dataset.data.push(parseFloat(report[i].value));
                    if (filling_volume_dataset.highest === null || (parseFloat(filling_volume_dataset.highest) < parseFloat(report[i].value))) {
                        filling_volume_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_volume_dataset.lowest === null || (parseFloat(filling_volume_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_volume_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_volume_dataset.total_sum += parseFloat(report[i].value);
                    filling_volume_dataset.pcs += 1;
                    this.setState({
                        filling_volume_text: t(report[i].display_name)
                    });
                // Filling percentage
                } else if (report[i].group === "Report" && report[i].name === "fillingPercentage") {
                    filling_percentage_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_percentage_dataset.data.push(parseFloat(report[i].value));
                    if (filling_percentage_dataset.highest === null || (parseFloat(filling_percentage_dataset.highest) < parseFloat(report[i].value))) {
                        filling_percentage_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_percentage_dataset.lowest === null || (parseFloat(filling_percentage_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_percentage_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_percentage_dataset.total_sum += parseFloat(report[i].value);
                    filling_percentage_dataset.pcs += 1;
                    this.setState({
                        filling_percentage_text: t(report[i].display_name)
                    });
                // Deviation
                } else if (report[i].group === "Report" && report[i].name === "deviation") {
                    distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_deviation_dataset.data.push(parseFloat(report[i].value));
                    if (distance_deviation_dataset.highest === null || (parseFloat(distance_deviation_dataset.highest) < parseFloat(report[i].value))) {
                        distance_deviation_dataset.highest = parseFloat(report[i].value);
                    }
                    if (distance_deviation_dataset.lowest === null || (parseFloat(distance_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                        distance_deviation_dataset.lowest = parseFloat(report[i].value);
                    }
                    distance_deviation_dataset.total_sum += parseFloat(report[i].value);
                    distance_deviation_dataset.pcs += 1;
                    this.setState({
                        distance_deviation_text: t(report[i].display_name)
                    });
                // Battery Voltage
                } else if (report[i].name === "vdd") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);

            if (temperature_external_dataset.pcs > 0) {
                temperature_external_dataset.average = parseFloat((temperature_external_dataset.total_sum / temperature_external_dataset.pcs).toFixed(2));
            }
            temperature_external_dataset.label = 
                `${temperature_external_dataset.label} (${t("avg")}: ${temperature_external_dataset.average.toString()} ${t("Highest")}: ${temperature_external_dataset.highest ? temperature_external_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_external_dataset.lowest ? temperature_external_dataset.lowest.toString() : 0})`; 

            temperature_external_data.datasets.push(temperature_external_dataset);


            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);

            if (pressure_dataset.pcs > 0) {
                pressure_dataset.average = parseFloat((pressure_dataset.total_sum / pressure_dataset.pcs).toFixed(2));
            }
            pressure_dataset.label = 
                `${pressure_dataset.label} (${t("avg")}: ${pressure_dataset.average.toString()} ${t("Highest")}: ${pressure_dataset.highest ? pressure_dataset.highest.toString() : 0} ${t("Lowest")}: ${pressure_dataset.lowest ? pressure_dataset.lowest.toString() : 0})`; 

            pressure_data.datasets.push(pressure_dataset);

            waterleak_data.datasets.push(waterleak_dataset);

            if (distance_dataset.pcs > 0) {
                distance_dataset.average = parseFloat((distance_dataset.total_sum / distance_dataset.pcs).toFixed(2));
            }
            distance_dataset.label = 
                `${distance_dataset.label} (${t("avg")}: ${distance_dataset.average.toString()} ${t("Highest")}: ${distance_dataset.highest ? distance_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_dataset.lowest ? distance_dataset.lowest.toString() : 0})`; 

            distance_data.datasets.push(distance_dataset);

            if (filling_volume_dataset.pcs > 0) {
                filling_volume_dataset.average = parseFloat((filling_volume_dataset.total_sum / filling_volume_dataset.pcs).toFixed(2));
            }
            filling_volume_dataset.label = 
                `${filling_volume_dataset.label} (${t("avg")}: ${filling_volume_dataset.average.toString()} ${t("Highest")}: ${filling_volume_dataset.highest ? filling_volume_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_volume_dataset.lowest ? filling_volume_dataset.lowest.toString() : 0})`; 

            filling_volume_data.datasets.push(filling_volume_dataset);

            if (filling_percentage_dataset.pcs > 0) {
                filling_percentage_dataset.average = parseFloat((filling_percentage_dataset.total_sum / filling_percentage_dataset.pcs).toFixed(2));
            }
            filling_percentage_dataset.label = 
                `${filling_percentage_dataset.label} (${t("avg")}: ${filling_percentage_dataset.average.toString()} ${t("Highest")}: ${filling_percentage_dataset.highest ? filling_percentage_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_percentage_dataset.lowest ? filling_percentage_dataset.lowest.toString() : 0})`; 

            filling_percentage_data.datasets.push(filling_percentage_dataset);

            if (distance_deviation_dataset.pcs > 0) {
                distance_deviation_dataset.average = parseFloat((distance_deviation_dataset.total_sum / distance_deviation_dataset.pcs).toFixed(2));
            }
            distance_deviation_dataset.label = 
                `${distance_deviation_dataset.label} (${t("avg")}: ${distance_deviation_dataset.average.toString()} ${t("Highest")}: ${distance_deviation_dataset.highest ? distance_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_deviation_dataset.lowest ? distance_deviation_dataset.lowest.toString() : 0})`; 

            distance_deviation_data.datasets.push(distance_deviation_dataset);


            this.setState({
                battery_data: battery_data,
                humidity_data: humidity_data,
                temperature_data: temperature_data,
                temperature_external_data: temperature_external_data,
                pressure_data: pressure_data,
                waterleak_data: waterleak_data,
                distance_data: distance_data,
                filling_volume_data: filling_volume_data,
                filling_percentage_data: filling_percentage_data,
                distance_deviation_data: distance_deviation_data,
                loading: false
            })
        } else if (sensor.model_name.startsWith("WASTECONTROL-VU2")) {
            let {filling_volume_data, filling_percentage_data } = this.state;

            filling_volume_data.labels = [];
            filling_volume_data.datasets = [];

            filling_percentage_data.labels = [];
            filling_percentage_data.datasets = [];

            // Filling volume
            let filling_volume_dataset = {
                ...default_dataset_blue,
                label: t("Filling volume (l)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling percentage
            let filling_percentage_dataset = {
                ...default_dataset_blue,
                label: t("Filling percentage (%)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Filling percentage
                if (report[i].name === "waste_distance") {
                    filling_percentage_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_percentage_dataset.data.push(parseFloat(report[i].value));
                    if (filling_percentage_dataset.highest === null || (parseFloat(filling_percentage_dataset.highest) < parseFloat(report[i].value))) {
                        filling_percentage_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_percentage_dataset.lowest === null || (parseFloat(filling_percentage_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_percentage_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_percentage_dataset.total_sum += parseFloat(report[i].value);
                    filling_percentage_dataset.pcs += 1;

                    this.setState({
                        filling_percentage_text: t(report[i].display_name),
                    });
                // Filling volume
                } else if (report[i].name === "fillingVolume") {
                    filling_volume_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_volume_dataset.data.push(parseFloat(report[i].value));
                    if (filling_volume_dataset.highest === null || (parseFloat(filling_volume_dataset.highest) < parseFloat(report[i].value))) {
                        filling_volume_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_volume_dataset.lowest === null || (parseFloat(filling_volume_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_volume_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_volume_dataset.total_sum += parseFloat(report[i].value);
                    filling_volume_dataset.pcs += 1;

                    this.setState({
                        filling_volume_text: t(report[i].display_name),
                    });
                } 
            }
            if (filling_percentage_dataset.pcs > 0) {
                filling_percentage_dataset.average = parseFloat((filling_percentage_dataset.total_sum / filling_percentage_dataset.pcs).toFixed(2));
            }
            filling_percentage_dataset.label = 
                `${filling_percentage_dataset.label} (${t("avg")}: ${filling_percentage_dataset.average.toString()} ${t("Highest")}: ${filling_percentage_dataset.highest ? filling_percentage_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_percentage_dataset.lowest ? filling_percentage_dataset.lowest.toString() : 0})`; 

                filling_percentage_data.datasets.push(filling_percentage_dataset);

            if (filling_volume_dataset.pcs > 0) {
                filling_volume_dataset.average = parseFloat((filling_volume_dataset.total_sum / filling_volume_dataset.pcs).toFixed(2));
            }
            filling_volume_dataset.label = 
                `${filling_volume_dataset.label} (${t("avg")}: ${filling_volume_dataset.average.toString()} ${t("Highest")}: ${filling_volume_dataset.highest ? filling_volume_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_volume_dataset.lowest ? filling_volume_dataset.lowest.toString() : 0})`; 

                filling_volume_data.datasets.push(filling_volume_dataset);

            this.setState({
                filling_percentage_data: filling_percentage_data,
                filling_volume_data: filling_volume_data,
                loading: false
            })
        } else if (sensor.model_name === "NETVOX-RB11E") {
            let { battery_data, temperature_data, light_data, motion_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            light_data.labels = [];
            light_data.datasets = [];

            motion_data.labels = [];
            motion_data.datasets = [];

            // Light
            let light_dataset = {
                ...default_dataset_blue,
                label: t("Light (Lux)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Motion
            let motion_dataset = {
                ...default_dataset_red,
                label: t("Motion"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature (°C)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                // Light
                } else if (report[i].name === "illuminance") {
                        light_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        light_dataset.data.push(parseFloat(report[i].value));
                        if (light_dataset.highest === null || (parseFloat(light_dataset.highest) < parseFloat(report[i].value))) {
                            light_dataset.highest = parseFloat(report[i].value);
                        }
                        if (light_dataset.lowest === null || (parseFloat(light_dataset.lowest) > parseFloat(report[i].value))) {
                            light_dataset.lowest = parseFloat(report[i].value);
                        }
                        light_dataset.total_sum += parseFloat(report[i].value);
                        light_dataset.pcs += 1;
    
                        this.setState({
                            light_text: t(report[i].display_name),
                        });
                // Motion
                } else if (report[i].name === "occupied") {
                    motion_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    motion_dataset.data.push(report[i].value === "True" ? 1 : 0);
                    this.setState({
                        motion_text: t(report[i].display_name),
                    });
                // Battery Voltage
                } else if (report[i].name === "battery") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);

            if (light_dataset.pcs > 0) {
                light_dataset.average = parseFloat((light_dataset.total_sum / light_dataset.pcs).toFixed(2));
            }
            light_dataset.label = 
                `${light_dataset.label} (${t("avg")}: ${light_dataset.average.toString()} ${t("Highest")}: ${light_dataset.highest ? light_dataset.highest.toString() : 0} ${t("Lowest")}: ${light_dataset.lowest ? light_dataset.lowest.toString() : 0})`; 

            light_data.datasets.push(light_dataset);

            if (motion_dataset.pcs > 0) {
                motion_dataset.average = parseFloat((motion_dataset.total_sum / motion_dataset.pcs).toFixed(2));
            }
            motion_dataset.label = 
                `${motion_dataset.label} (${t("avg")}: ${motion_dataset.average.toString()} ${t("Highest")}: ${motion_dataset.highest ? motion_dataset.highest.toString() : 0} ${t("Lowest")}: ${motion_dataset.lowest ? motion_dataset.lowest.toString() : 0})`; 

            motion_data.datasets.push(motion_dataset);

            this.setState({
                battery_data: battery_data,
                temperature_data: temperature_data,
                light_data: light_data,
                motion_data: motion_data,
                loading: false
            })
        } else if (sensor.model_name === "NETVOX-R313A") {
            let { battery_data, door_closed_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            door_closed_data.labels = [];
            door_closed_data.datasets = [];


            // Door closed
            let door_closed_dataset = {
                ...default_dataset_blue,
                label: t("Door open"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
            // for (let i = report.length - 1; i > -1; i--) {
                let event_time = new Date(report[i].event_time);

                // Door open
                if (report[i].name === "contact") {
                    door_closed_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    door_closed_dataset.data.push(report[i].value === "True" ? 1 : 0);
                    this.setState({
                        door_closed_text: t(report[i].display_name),
                    });
                // Battery Voltage
                } else if (report[i].name === "battery") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            door_closed_data.datasets.push(door_closed_dataset);

            this.setState({
                battery_data: battery_data,
                door_closed_data: door_closed_data,
                loading: false
            })
        }

        this.setState({
            loading: false
        });
    };

    handleStartDateChange = value => {
        if (value !== null) {
            value.setHours(0);
            value.setMinutes(0);
            value.setSeconds(0);
            this.setState({
                search_start_date: getISOFormattedDate(value, "Date"),
                // fetch: true
            });
        }
    };

    handleEndDateChange = value => {
        if (value !== null) {
            // Get also values from current search end date, so move end date timestamp to next mid night.
            value.setDate(value.getDate() + 1);
            value.setHours(0);
            value.setMinutes(0);
            value.setSeconds(0);
            this.setState({
                search_end_date: getISOFormattedDate(value, "Date"),
                // fetch: true
            });
        }
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            // fetch: true
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        const { sensor } = this.state;

        if (items !== null) {
            const titles = [
                // t("Seq"),
                t("Time"),
                // t("Group"),
                t("Sub Group"),
                // t("Variable name"),
                t("Name"),
                t("Value")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    // sequence_number: items[i].sequence_number,
                    event_time: new Date(items[i].event_time).toLocaleString('fi-FI'),
                    // group: items[i].group,
                    sub_group: items[i].sub_group,
                    // name: items[i].name,
                    display_name: t(items[i].display_name),
                    value: items[i].value.replace(".", ",")
                });
            }

            let file_name = "export";
            if (sensor !== null) {
                if (sensor.device_eui === sensor.device_serial_number) {
                    file_name = sensor.device_eui;
                } else {
                    file_name = sensor.device_serial_number + "_" + sensor.device_eui;
                }
            }
            exportToCsv(this.props.activeCompanyId, file_name, titles, items_to_csv);
        }
    };

    handleRefresh = () => {
        this.setState({
            fetch: true
        });
    };

    getFillingVolume = (sensor) => {
        let i = 0;
        for (i in sensor.sensorstatusvariables) {
            if (sensor.model_name.startsWith("WASTECONTROL-VU2")) {
                if (sensor.sensorstatusvariables[i].name === "size") {
                    return parseInt(sensor.sensorstatusvariables[i].last_value);
                }
            } else if (sensor.sensorstatusvariables[i].name === "fillingVolume") {
                let x = 0;
                for (x in sensor.sensorstatusvariables[i].sensorstatusvariableparameters) {
                    if (sensor.sensorstatusvariables[i].sensorstatusvariableparameters[x].name === "volume-full") {
                        return parseInt(sensor.sensorstatusvariables[i].sensorstatusvariableparameters[x].value);
                    }
                }
            }

            // if ((sensor.model_name === "DECENTLAB-DL-MBX" || sensor.model_name === "ELSYS-ELT-2" || sensor.model_name.startsWith("WASTECONTROL-VU3")) && sensor.sensorstatusvariables[i].name === "fillingVolume") {
            //     let x = 0;
            //     for (x in sensor.sensorstatusvariables[i].sensorstatusvariableparameters) {
            //         if (sensor.sensorstatusvariables[i].sensorstatusvariableparameters[x].name === "volume-full") {
            //             return parseInt(sensor.sensorstatusvariables[i].sensorstatusvariableparameters[x].value);
            //         }
            //     }
            // } else if (sensor.model_name.startsWith("WASTECONTROL-VU2") && sensor.sensorstatusvariables[i].name === "size") {
            //     return parseInt(sensor.sensorstatusvariables[i].last_value);
            // }
        }
    };

    getFillingVolumeMeasureUnit = (sensor) => {
        let i = 0;
        for (i in sensor.sensorstatusvariables) {
            if (sensor.sensorstatusvariables[i].name === "fillingVolume") {
                return sensor.sensorstatusvariables[i].measurement_unit;
            }
            // if ((sensor.model_name === "DECENTLAB-DL-MBX" || sensor.model_name === "ELSYS-ELT-2" || sensor.model_name.startsWith("WASTECONTROL-VU3")) && sensor.sensorstatusvariables[i].name === "fillingVolume") {
            //     return sensor.sensorstatusvariables[i].measurement_unit;
            // } else if (sensor.model_name.startsWith("WASTECONTROL-VU2") && sensor.sensorstatusvariables[i].name === "size") {
            //     return sensor.sensorstatusvariables[i].measurement_unit;
            // }
        }
    };

    handleSort = (clickedColumn) => () => {
        const { column, report, direction } = this.state

        if (column !== clickedColumn) {
            let sortedArray;
            if (clickedColumn === "display_name" || clickedColumn === "group" || clickedColumn === "sub_group") {
                sortedArray = _.sortBy(report, [report => report[clickedColumn].toLowerCase()]);
            } else if (clickedColumn === "value") {
                sortedArray = _.sortBy(report, [report => (report["value"] !== "True" && report["value"] !== "False") ? parseInt(report[clickedColumn]) : report[clickedColumn]]);
            } else {
                sortedArray = _.sortBy(report, [clickedColumn]);
            }

            this.setState({
                column: clickedColumn,
                report: sortedArray,
                direction: 'ascending',
            });
            return;
        }

        this.setState({
            report: report.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleDeleteValue = value_id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(deleteSensorStatusValueURL(activeCompanyId, value_id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleRefresh();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    main = () => {
        const { error, loading, report, search, search_end_date, search_start_date, variable_choices, variable_options, sensor,
            activeMenuItem, offset, limit, column, direction, delete_confirm_open, delete_confirm_text, delete_item } = this.state;
        const { t } = this.props;
        const options_velocity = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "mm/s"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_acceleration = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "g"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_no_measure_unit = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_displacement = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "μm"
                        } 
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_temperature = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: this.state.temperature_text === null ? "°C" : this.state.temperature_text
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }

        const options_battery = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "V"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }

        const options_distance = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "mm"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_speed = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "km/h"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_pcs = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "kpl"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_light = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "lux"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_humidity = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "%RH"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_volume_litre = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "l"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_percent = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "%"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_filling_percent = {
            scales: {
                yAxes: [
                    {                        
                        ticks: {
                            min: 0,
                            max: 100
                        },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "%"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_filling_volume = {
            scales: {
                yAxes: [
                    {                        
                        ticks: {
                            min: 0,
                            max: sensor ? this.getFillingVolume(sensor) : 0
                        },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: sensor ? this.getFillingVolumeMeasureUnit(sensor) : ""
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_air_pressure = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "hPa"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_water_pressure = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "kPa"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_tilt_angle = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "deg"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_electrical_conductivity = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "μS/cm"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_meter = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "m"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_oxygen_percent = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "%Sat"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_oxygen_mgl = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "mg/L"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_pressure_kpa = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "kPa"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_pressure_pa = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "Pa"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_ppt = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "ppt"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_ppm = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "ppm"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_ppb = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "ppb"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_index = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "index"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_1_m = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "1/m"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_mgl = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "mg/L"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_ntu = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "NTU"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_fnu = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "FNU"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_ph = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "pH"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_mv = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "mV"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_weight_kilograms = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "kg"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_weight_grams = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "g"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_par_radiation = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "μmol/m2/s"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_kvar = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "kVAr"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_kvarh = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "kVArh"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_kw = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "kW"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_kwh = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "kWh"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_current = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "A"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_minutes = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "minutes"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_mm = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "mm"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }

        const options_g_per_m3 = {
            scales: {
                yAxes: [
                    {                        
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "g/m3"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;

        let filtered_items = [];

        // Filter data for search
        if (report && report.length > 0) {
            let cleaned_report = report.filter(value => Object.keys(value).length !== 0);
            filtered_items = cleaned_report.filter((data) => {
                return data.group.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.sub_group.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.display_name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.sequence_number.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
        }

        return (
            <React.Fragment>
                {is_desktop && (
                    
                    <Button.Group floated="right" basic size='small'>
                    <>
                        {/* <Button icon="download" content={t("Full report to CSV 2")} onClick={() => this.handleFetchFullCSVReport2()} /> */}
                        <Popup content={t("Select report start and end date. Select variables. Select result accuracy. Press Full report to CSV-button. Do not press Refresh button in between to avoid accuracy change.")}
                                                    trigger={<Button icon="download" content={t("Full report to CSV")} onClick={() => this.handleFetchFullCSVReport()} />} />
                        {/* <Button icon="download" content={t("Full report to CSV")} onClick={() => this.handleFetchFullCSVReport()} /> */}
                        <Button icon="download" content={t("Display values to CSV")} onClick={() => this.handleExportToCSV(filtered_items)} />
                    </>
                </Button.Group>
                )}
                
                <Header as="h3">{t("Sensor report")}</Header>
                {is_desktop ? (
                    <Grid columns="2">
                        <Grid.Column width={9}>
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                label="->"
                                locale={fiLocale}
                                placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleEndDateChange(selected)}
                                format='DD.MM.YYYY' />

                        </Grid.Column>
                        {/* {(activeMenuItem === t("Table")) ? ( */}
                            {/* <Grid.Column floated="right" width={6} textAlign="right">
                                <Input
                                    size="mini"
                                    icon='search'
                                    value={search}
                                    name="search"
                                    onChange={this.handleChange} />
                                
                            </Grid.Column> */}
                        {/* ) : ( */}
                            <Grid.Column floated="right" width={6} textAlign="right">
                                <label>{t("Variables")}: </label>
                                <Select
                                    multiple
                                    search
                                    name="variable_choices"
                                    options={this.state.variable_options}
                                    value={this.state.variable_choices}
                                    placeholder={this.state.variable_choices.length === 0 ? (t("All")) : (this.state.variable_choices)}
                                    onChange={this.handleSelectChange} />
                            </Grid.Column>
                            <Grid.Column floated="right" textAlign="right">
                            <label>{t("Result accuracy")}: </label>
                            <Select
                                search
                                name="accuracy_choice"
                                options={this.state.accuracy_options}
                                value={this.state.accuracy_choice}
                                placeholder={t(this.state.accuracy_choice)}
                                onChange={this.handleSelectChange} />
                            </Grid.Column>
                        {/* )} */}
                    </Grid>
                ) : (
                    <React.Fragment>
                        <SemanticDatepicker
                                firstDayOfWeek="1"
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleEndDateChange(selected)}
                            format='DD.MM.YYYY' />
                        <br/>
                        {(activeMenuItem === t("Table")) ? (
                            <>
                                <br/>
                                {/* <Input
                                    fluid
                                    icon='search'
                                    value={search}
                                    name="search"
                                    onChange={this.handleChange} /> */}
                            </>
                        ) : (
                            <>
                                <label>{t("Chart choice")}: </label>
                                <Select
                                    fluid
                                    multiple
                                    search
                                    name="variable_choices"
                                    options={this.state.variable_options}
                                    value={this.state.variable_choices}
                                    placeholder={this.state.variable_choices.length === 0 ? (t("All")) : (this.state.variable_choices)}
                                    onChange={this.handleSelectChange} />

                                <label>{t("Result accuracy")}: </label>
                                <Select
                                    fluid
                                    multiple
                                    search
                                    name="accuracy_choice"
                                    options={this.state.accuracy_options}
                                    value={this.state.accuracy_choice}
                                    placeholder={t(this.state.accuracy_choice)}
                                    onChange={this.handleSelectChange} />

                            </>
                        )}
                    </React.Fragment>
                )}
                <br/>
                <Button icon='refresh' floated="right" color="green" label={t("Refresh")} onClick={() => this.handleRefresh()}/>
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {(report && sensor) && (
                    <React.Fragment>
                        <Menu attached="top" tabular>
                            <Menu.Item
                                name={t("Table")}
                                active={activeMenuItem === t("Table")}
                                onClick={this.handleMenuItemClick} />
                            <Menu.Item
                                name={t("Chart")}
                                active={activeMenuItem === t("Chart")}
                                onClick={this.handleMenuItemClick} />
                        </Menu>
                        {(activeMenuItem === t("Table")) ? (
                            <Table striped selectable celled sortable>
                                {is_desktop && (
                                    <Table.Header >
                                        <Table.Row textAlign="right">
                                            {sensor.model_name === "WISE-2410-EA" && (
                                                <Table.HeaderCell textAlign="left"
                                                sorted={column === 'sequence_number' ? direction : null}
                                                onClick={this.handleSort('sequence_number')}>{t("Seq")}</Table.HeaderCell>
                                            )}
                                            <Table.HeaderCell
                                                sorted={column === 'event_time' ? direction : null}
                                                onClick={this.handleSort('event_time')}>
                                                {t("Time")}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={column === 'group' ? direction : null}
                                                onClick={this.handleSort('group')}>
                                                    {t("Group")}
                                            </Table.HeaderCell>
                                            {sensor.model_name === "WISE-2410-EA" && (
                                                <Table.HeaderCell
                                                    sorted={column === 'sub_group' ? direction : null}
                                                    onClick={this.handleSort('sub_group')}>
                                                        {t("Sub Group")}
                                                </Table.HeaderCell>
                                            )}
                                            <Table.HeaderCell 
                                                sorted={column === 'display_name' ? direction : null}
                                                onClick={this.handleSort('display_name')}>
                                                    {t("Name")}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={column === 'value' ? direction : null}
                                                onClick={this.handleSort('value')}>
                                                    {t("Value")}
                                            </Table.HeaderCell>
                                            {((this.props.activeRoles && (this.props.activeRoles.includes("A") || this.props.activeRoles.includes("S"))) && this.state.value_delete_ok) && (
                                                <Table.HeaderCell />
                                            )}
                                        </Table.Row>
                                    </Table.Header>
                                )}
                                <Table.Body>
                                    {/* {filtered_items.slice(0, offset + limit).map((data, key) => { */}
                                    {filtered_items.map((data, key) => {
                                        return (
                                            <Table.Row key={key} textAlign="right" >
                                                {sensor.model_name === "WISE-2410-EA" && (
                                                    <Table.Cell>
                                                        {!is_desktop && (<React.Fragment><b>{t("Seq")}</b><br/></React.Fragment>)}
                                                        {data.sequence_number}
                                                    </Table.Cell>
                                                )}
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Time")}</b><br/></React.Fragment>)}
                                                    {new Date(data.event_time).toLocaleString('fi-FI')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Group")}</b><br/></React.Fragment>)}
                                                    {t(data.group)}
                                                </Table.Cell>
                                                {sensor.model_name === "WISE-2410-EA" && (
                                                    <Table.Cell>
                                                        {!is_desktop && (<React.Fragment><b>{t("Sub Group")}</b><br/></React.Fragment>)}
                                                        {t(data.sub_group)}
                                                    </Table.Cell>
                                                )}
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Name")}</b><br/></React.Fragment>)}
                                                    {t(data.display_name)}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Value")}</b><br/></React.Fragment>)}
                                                    {(data.value === "True" || data.value === "False") ? (data.value === "True" ? t("Yes") : t("No")) : data.value}
                                                </Table.Cell>
                                                {((this.props.activeRoles && (this.props.activeRoles.includes("A") || this.props.activeRoles.includes("S"))) && this.state.value_delete_ok) && (
                                                    <Table.Cell  textAlign='center'><Icon link color="red"  name='trash alternate outline'
                                                        onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { data_name: t(data.display_name) }), data.id)} />
                                                    </Table.Cell>
                                                )}
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        ) : (
                            <React.Fragment>
                                {(sensor.model_name === "WISE-2410-EA" && (variable_choices.length === 0 || variable_choices.includes("OAVelocity")) && variable_options.findIndex((variable) => variable.key === "OAVelocity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Velocity RMS (mm/s)")}</Header>
                                        <Line data={this.state.all_velocity_RMS_data} options={options_velocity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && (variable_choices.length === 0 || variable_choices.includes("RMSmg")) && variable_options.findIndex((variable) => variable.key === "RMSmg") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Acceleration RMS (g)")}</Header>
                                        <Line data={this.state.all_acceleration_RMS_data} options={options_acceleration}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && (variable_choices.length === 0 || variable_choices.includes("Peakmg")) && variable_options.findIndex((variable) => variable.key === "Peakmg") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Acceleration Peak (g)")}</Header>
                                        <Line data={this.state.all_acceleration_peak_data} options={options_acceleration}/>
                                    </React.Fragment>
                                )}

                                {(sensor.model_name === "WISE-2410-EA" && ((variable_choices.length === 0 && this.state.all_kurtosis_data.labels && this.state.all_kurtosis_data.labels.length > 0) || variable_choices.includes("Kurtosis")) && variable_options.findIndex((variable) => variable.key === "Kurtosis") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Kurtosis")}</Header>
                                        <Line data={this.state.all_kurtosis_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && ((variable_choices.length === 0 && this.state.all_crest_factor_data.labels && this.state.all_crest_factor_data.labels.length > 0) || variable_choices.includes("CrestFactor")) && variable_options.findIndex((variable) => variable.key === "CrestFactor") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Crest factor")}</Header>
                                        <Line data={this.state.all_crest_factor_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && ((variable_choices.length === 0 && this.state.all_skewness_data.labels && this.state.all_skewness_data.labels.length > 0) || variable_choices.includes("Skewness")) && variable_options.findIndex((variable) => variable.key === "Skewness") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Skewness")}</Header>
                                        <Line data={this.state.all_skewness_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && ((variable_choices.length === 0 && this.state.all_deviation_data.labels && this.state.all_deviation_data.labels.length > 0) || variable_choices.includes("Deviation")) && variable_options.findIndex((variable) => variable.key === "Deviation") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Standard Deviation")}</Header>
                                        <Line data={this.state.all_deviation_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && ((variable_choices.length === 0 && this.state.all_displacement_data.labels && this.state.all_displacement_data.labels.length > 0) || variable_choices.includes("Displacement")) && variable_options.findIndex((variable) => variable.key === "Displacement") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Displacement (μm)")}</Header>
                                        <Line data={this.state.all_displacement_data} options={options_displacement}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && (variable_choices.length === 0 || variable_choices.includes("SenVal")) && variable_options.findIndex((variable) => variable.key === "SenVal") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DECENTLAB-DL-MBX" && (variable_choices.length === 0 || variable_choices.includes("Distance")) && variable_options.findIndex((variable) => variable.key === "Distance") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_text}</Header>
                                        <Line data={this.state.distance_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DECENTLAB-DL-MBX" && (variable_choices.length === 0 || variable_choices.includes("fillingVolume")) && variable_options.findIndex((variable) => variable.key === "fillingVolume") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_volume_text}</Header>
                                        <Line data={this.state.filling_volume_data} options={options_filling_volume}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DECENTLAB-DL-MBX" && (variable_choices.length === 0 || variable_choices.includes("fillingPercentage")) && variable_options.findIndex((variable) => variable.key === "fillingPercentage") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_percentage_text}</Header>
                                        <Line data={this.state.filling_percentage_data} options={options_filling_percent}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DECENTLAB-DL-MBX" && (variable_choices.length === 0 || variable_choices.includes("deviation")) && variable_options.findIndex((variable) => variable.key === "deviation") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_deviation_text}</Header>
                                        <Line data={this.state.distance_deviation_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DIGITAL-MATTER-YABBY" && (variable_choices.length === 0 || variable_choices.includes("inTrip")) && variable_options.findIndex((variable) => variable.key === "inTrip") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.in_trip_text}</Header>
                                        <Line data={this.state.in_trip_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DIGITAL-MATTER-YABBY" && (variable_choices.length === 0 || variable_choices.includes("speedKmph")) && variable_options.findIndex((variable) => variable.key === "speedKmph") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.speed_text}</Header>
                                        <Line data={this.state.speed_data} options={options_speed}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ERS" && (variable_choices.length === 0 || variable_choices.includes("temperature")) && variable_options.findIndex((variable) => variable.key === "temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ERS" && (variable_choices.length === 0 || variable_choices.includes("humidity")) && variable_options.findIndex((variable) => variable.key === "humidity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ERS" && (variable_choices.length === 0 || variable_choices.includes("light")) && variable_options.findIndex((variable) => variable.key === "light") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.light_text}</Header>
                                        <Line data={this.state.light_data} options={options_light}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ERS" && (variable_choices.length === 0 || variable_choices.includes("motion")) && variable_options.findIndex((variable) => variable.key === "motion") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.motion_text}</Header>
                                        <Line data={this.state.motion_data} options={options_pcs}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-EMS" && (variable_choices.length === 0 || variable_choices.includes("temperature")) && variable_options.findIndex((variable) => variable.key === "temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-EMS" && (variable_choices.length === 0 || variable_choices.includes("humidity")) && variable_options.findIndex((variable) => variable.key === "humidity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-EMS" && (variable_choices.length === 0 || variable_choices.includes("digital")) && variable_options.findIndex((variable) => variable.key === "digital") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.door_open_text}</Header>
                                        <Line data={this.state.door_closed_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-EMS" && (variable_choices.length === 0 || variable_choices.includes("accMotion")) && variable_options.findIndex((variable) => variable.key === "accMotion") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.motion_text}</Header>
                                        <Line data={this.state.motion_data} options={options_pcs}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-EMS" && (variable_choices.length === 0 || variable_choices.includes("waterleak"))  && variable_options.findIndex((variable) => variable.key === "waterleak") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.waterleak_text}</Header>
                                        <Line data={this.state.waterleak_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("temperature")) && variable_options.findIndex((variable) => variable.key === "temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("externalTemperature")) && variable_options.findIndex((variable) => variable.key === "externalTemperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_external_text}</Header>
                                        <Line data={this.state.temperature_external_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("humidity")) && variable_options.findIndex((variable) => variable.key === "humidity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("pressure"))  && variable_options.findIndex((variable) => variable.key === "pressure") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pressure_text}</Header>
                                        <Line data={this.state.pressure_data} options={options_air_pressure}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("waterleak"))  && variable_options.findIndex((variable) => variable.key === "waterleak") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.waterleak_text}</Header>
                                        <Line data={this.state.waterleak_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("distance")) && variable_options.findIndex((variable) => variable.key === "distance") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_text}</Header>
                                        <Line data={this.state.distance_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("fillingVolume")) && variable_options.findIndex((variable) => variable.key === "fillingVolume") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_volume_text}</Header>
                                        <Line data={this.state.filling_volume_data} options={options_filling_volume}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("fillingPercentage")) && variable_options.findIndex((variable) => variable.key === "fillingPercentage") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_percentage_text}</Header>
                                        <Line data={this.state.filling_percentage_data} options={options_filling_percent}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("deviation")) && variable_options.findIndex((variable) => variable.key === "deviation") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_deviation_text}</Header>
                                        <Line data={this.state.distance_deviation_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("WASTECONTROL") && (variable_choices.length === 0 || variable_choices.includes("Distance"))  && variable_options.findIndex((variable) => variable.key === "Distance") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_text}</Header>
                                        <Line data={this.state.distance_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("WASTECONTROL") && (variable_choices.length === 0 || variable_choices.includes("waste_distance"))  && variable_options.findIndex((variable) => variable.key === "waste_distance") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_percentage_text}</Header>
                                        <Line data={this.state.filling_percentage_data} options={options_filling_percent}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("WASTECONTROL") && (variable_choices.length === 0 || variable_choices.includes("fillingPercentage"))  && variable_options.findIndex((variable) => variable.key === "fillingPercentage") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_percentage_text}</Header>
                                        <Line data={this.state.filling_percentage_data} options={options_filling_percent}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("WASTECONTROL") && (variable_choices.length === 0 || variable_choices.includes("fillingVolume"))  && variable_options.findIndex((variable) => variable.key === "fillingVolume") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_volume_text}</Header>
                                        <Line data={this.state.filling_volume_data} options={options_filling_volume}/>
                                    </React.Fragment>
                                )}

                                {(!sensor.model_name.startsWith("WASTECONTROL") && (variable_choices.length === 0 || variable_choices.includes("BatteryVolt") || variable_choices.includes("Battery voltage") || variable_choices.includes("batV") || variable_choices.includes("vdd") || variable_choices.includes("battery")) && (variable_options.findIndex((variable) => variable.key === "BatteryVolt") !== -1 || variable_options.findIndex((variable) => variable.key === "Battery voltage") !== -1 || variable_options.findIndex((variable) => variable.key === "batV") !== -1 || variable_options.findIndex((variable) => variable.key === "vdd") !== -1 || variable_options.findIndex((variable) => variable.key === "battery") !== -1)) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Battery Voltage (V)")}</Header>
                                        <Line data={this.state.battery_data} options={options_battery}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "NETVOX-RB11E" && (variable_choices.length === 0 || variable_choices.includes("temperature")) && variable_options.findIndex((variable) => variable.key === "temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "NETVOX-RB11E" && (variable_choices.length === 0 || variable_choices.includes("illuminance")) && variable_options.findIndex((variable) => variable.key === "illuminance") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.light_text}</Header>
                                        <Line data={this.state.light_data} options={options_light}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "NETVOX-RB11E" && (variable_choices.length === 0 || variable_choices.includes("occupied")) && variable_options.findIndex((variable) => variable.key === "occupied") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.motion_text}</Header>
                                        <Line data={this.state.motion_data} options={options_pcs}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "NETVOX-R313A" && (variable_choices.length === 0 || variable_choices.includes("contact")) && variable_options.findIndex((variable) => variable.key === "contact") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.door_closed_text}</Header>
                                        <Line data={this.state.door_closed_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-WL") && (variable_choices.length === 0 || variable_choices.includes("Water level"))  && variable_options.findIndex((variable) => variable.key === "Water level") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_text}</Header>
                                        <Line data={this.state.distance_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-WL") && (variable_choices.length === 0 || variable_choices.includes("Water pressure"))  && variable_options.findIndex((variable) => variable.key === "Water pressure") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pressure_text}</Header>
                                        <Line data={this.state.pressure_data} options={options_water_pressure}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-WL") && (variable_choices.length === 0 || variable_choices.includes("Water temperature")) && variable_options.findIndex((variable) => variable.key === "Water temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-WL") && (variable_choices.length === 0 || variable_choices.includes("fillingVolume")) && variable_options.findIndex((variable) => variable.key === "fillingVolume") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_volume_text}</Header>
                                        <Line data={this.state.filling_volume_data} options={options_filling_volume}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-WL") && (variable_choices.length === 0 || variable_choices.includes("fillingPercentage")) && variable_options.findIndex((variable) => variable.key === "fillingPercentage") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_percentage_text}</Header>
                                        <Line data={this.state.filling_percentage_data} options={options_filling_percent}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-WL") && (variable_choices.length === 0 || variable_choices.includes("deviation")) && variable_options.findIndex((variable) => variable.key === "deviation") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_deviation_text}</Header>
                                        <Line data={this.state.distance_deviation_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DINGTEK" && (variable_choices.length === 0 || variable_choices.includes("Distance")) && variable_options.findIndex((variable) => variable.key === "Distance") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_text}</Header>
                                        <Line data={this.state.distance_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DINGTEK" && (variable_choices.length === 0 || variable_choices.includes("Temperature")) && variable_options.findIndex((variable) => variable.key === "Temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state._temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DINGTEK" && (variable_choices.length === 0 || variable_choices.includes("Tilt angle")) && variable_options.findIndex((variable) => variable.key === "Tilt angle") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.angle_text}</Header>
                                        <Line data={this.state.distance_data} options={options_tilt_angle}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DINGTEK" && (variable_choices.length === 0 || variable_choices.includes("fillingVolume")) && variable_options.findIndex((variable) => variable.key === "fillingVolume") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_volume_text}</Header>
                                        <Line data={this.state.filling_volume_data} options={options_filling_volume}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DINGTEK" && (variable_choices.length === 0 || variable_choices.includes("fillingPercentage")) && variable_options.findIndex((variable) => variable.key === "fillingPercentage") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_percentage_text}</Header>
                                        <Line data={this.state.filling_percentage_data} options={options_filling_percent}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DINGTEK" && (variable_choices.length === 0 || variable_choices.includes("deviation")) && variable_options.findIndex((variable) => variable.key === "deviation") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_deviation_text}</Header>
                                        <Line data={this.state.distance_deviation_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-CMF-SHT31") && (variable_choices.length === 0 || variable_choices.includes("External temperature SHT31")) && variable_options.findIndex((variable) => variable.key === "External temperature SHT31") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_external_text}</Header>
                                        <Line data={this.state.temperature_external_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-CMF-SHT31") && (variable_choices.length === 0 || variable_choices.includes("External humidity SHT31")) && variable_options.findIndex((variable) => variable.key === "External humidity SHT31") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_external_text}</Header>
                                        <Line data={this.state.humidity_external_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}

                                {((sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-H21")) && (variable_choices.length === 0 || variable_choices.includes("Water level")) && variable_options.findIndex((variable) => variable.key === "Water level") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.water_level_text}</Header>
                                        <Line data={this.state.water_level_data} options={options_meter}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-H21")) && (variable_choices.length === 0 || variable_choices.includes("External depth H21")) && variable_options.findIndex((variable) => variable.key === "External depth H21") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.h21_sensor_depth_text}</Header>
                                        <Line data={this.state.h21_sensor_depth_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-H21")) && (variable_choices.length === 0 || variable_choices.includes("External electrical conductivity H21")) && variable_options.findIndex((variable) => variable.key === "External electrical conductivity H21") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.h21_conductivity_text}</Header>
                                        <Line data={this.state.h21_conductivity_data} options={options_electrical_conductivity}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-H21")) && (variable_choices.length === 0 || variable_choices.includes("External temperature H21")) && variable_options.findIndex((variable) => variable.key === "External temperature H21") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.h21_temp_text}</Header>
                                        <Line data={this.state.h21_temp_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-OPTOD")) && (variable_choices.length === 0 || variable_choices.includes("External Sat percent OPTOD")) && variable_options.findIndex((variable) => variable.key === "External Sat percent OPTOD") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.optod_o2_sat_text}</Header>
                                        <Line data={this.state.optod_o2_sat_data} options={options_oxygen_percent}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-OPTOD")) && (variable_choices.length === 0 || variable_choices.includes("External mgL OPTOD")) && variable_options.findIndex((variable) => variable.key === "External mgL OPTOD") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.optod_o2_mgl_text}</Header>
                                        <Line data={this.state.optod_o2_mgl_data} options={options_oxygen_mgl}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-OPTOD")) && (variable_choices.length === 0 || variable_choices.includes("External temperature OPTOD")) && variable_options.findIndex((variable) => variable.key === "External temperature OPTOD") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.optod_temp_text}</Header>
                                        <Line data={this.state.optod_temp_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-H21")) && (variable_choices.length === 0 || variable_choices.includes("Temperature")) && variable_options.findIndex((variable) => variable.key === "Temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-26") || sensor.model_name.startsWith("DECENTLAB-DL-PR26") || sensor.model_name.startsWith("CAPROC-AQUA-8010") || sensor.model_name.startsWith("CAPROC-AQUA-36")) && (variable_choices.length === 0 || variable_choices.includes("Water level")) && variable_options.findIndex((variable) => variable.key === "Water level") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.water_level_text}</Header>
                                        <Line data={this.state.water_level_data} options={options_meter}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-26") || sensor.model_name.startsWith("DECENTLAB-DL-PR26") || sensor.model_name.startsWith("CAPROC-AQUA-8010") || sensor.model_name.startsWith("CAPROC-AQUA-36")) && (variable_choices.length === 0 || (variable_choices.includes("External water temperature PR26D") || variable_choices.includes("External water temperature PAA26X") || variable_choices.includes("External water temperature 8010") || variable_choices.includes("External water temperature 36X"))) && variable_options.findIndex((variable) => variable.key.startsWith("External water temperature")) !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.water_temp_text}</Header>
                                        <Line data={this.state.water_temp_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-26") || sensor.model_name.startsWith("DECENTLAB-DL-PR26") || sensor.model_name.startsWith("CAPROC-AQUA-8010") || sensor.model_name.startsWith("CAPROC-AQUA-36")) && (variable_choices.length === 0 || (variable_choices.includes("External sensor depth PR26D") || variable_choices.includes("External sensor depth PAA26X") || variable_choices.includes("External sensor depth 8010") || variable_choices.includes("External sensor depth 36X"))) && variable_options.findIndex((variable) => variable.key.startsWith("External sensor depth")) !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.sensor_depth_text}</Header>
                                        <Line data={this.state.sensor_depth_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-26") || sensor.model_name.startsWith("DECENTLAB-DL-PR26") || sensor.model_name.startsWith("CAPROC-AQUA-8010") || sensor.model_name.startsWith("CAPROC-AQUA-36")) && (variable_choices.length === 0 || (variable_choices.includes("External water pressure PR26D") || variable_choices.includes("External water pressure PAA26X") || variable_choices.includes("External water pressure 8010") || variable_choices.includes("External water pressure 36X"))) && variable_options.findIndex((variable) => variable.key.startsWith("External water pressure")) !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.water_pressure_text}</Header>
                                        <Line data={this.state.water_pressure_data} options={options_pressure_kpa}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-26X") || sensor.model_name.startsWith("CAPROC-AQUA-36X")) && (variable_choices.length === 0 || (variable_choices.includes("External absolute water pressure PAA26X") || variable_choices.includes("External absolute water pressure 36X"))) && variable_options.findIndex((variable) => variable.key.startsWith("External absolute water pressure")) !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.water_pressure_abs_text}</Header>
                                        <Line data={this.state.water_pressure_abs_data} options={options_pressure_kpa}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-AQUA-36X-CTD") && (variable_choices.length === 0 || variable_choices.includes("External water temp compensated conductivity 36X")) && variable_options.findIndex((variable) => variable.key.startsWith("External water temp compensated conductivity")) !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.water_comp_conductivity_text}</Header>
                                        <Line data={this.state.water_comp_conductivity_data} options={options_electrical_conductivity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-AQUA-36X-CTD") && (variable_choices.length === 0 || variable_choices.includes("External raw water conductivity 36X")) && variable_options.findIndex((variable) => variable.key.startsWith("External raw water conductivity")) !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.water_raw_conductivity_text}</Header>
                                        <Line data={this.state.water_raw_conductivity_data} options={options_electrical_conductivity}/>
                                    </React.Fragment>
                                )}

                                {((sensor.model_name.startsWith("CAPROC-AQUA-26") || sensor.model_name.startsWith("DECENTLAB-DL-PR26") || sensor.model_name.startsWith("CAPROC-AQUA-8010") || sensor.model_name.startsWith("CAPROC-AQUA-36")) && (variable_choices.length === 0 || (variable_choices.includes("Air pressure BMP3XX") || variable_choices.includes("Air pressure MS5XX"))) && variable_options.findIndex((variable) => variable.key.startsWith("Air pressure")) !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pressure_text}</Header>
                                        <Line data={this.state.pressure_data} options={options_pressure_kpa}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-26") || sensor.model_name.startsWith("DECENTLAB-DL-PR26") || sensor.model_name.startsWith("CAPROC-AQUA-8010") || sensor.model_name.startsWith("CAPROC-AQUA-36")) && (variable_choices.length === 0 || (variable_choices.includes("Temperature") || variable_choices.includes("Temperature BMP3XX") || variable_choices.includes("Temperature MS5XX"))) && variable_options.findIndex((variable) => variable.key.startsWith("Temperature")) !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-RANGE") || sensor.model_name.startsWith("CAPROC-RADAR")) && (variable_choices.length === 0 || variable_choices.includes("Distance")) && variable_options.findIndex((variable) => variable.key === "Distance") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_text}</Header>
                                        <Line data={this.state.distance_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-RANGE") || sensor.model_name.startsWith("CAPROC-RADAR")) && (variable_choices.length === 0 || variable_choices.includes("Temperature")) && variable_options.findIndex((variable) => variable.key === "Temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-RANGE") || sensor.model_name.startsWith("CAPROC-RADAR")) && (variable_choices.length === 0 || variable_choices.includes("Tilt angle")) && variable_options.findIndex((variable) => variable.key === "Tilt angle") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.angle_text}</Header>
                                        <Line data={this.state.distance_data} options={options_tilt_angle}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-RANGE") || sensor.model_name.startsWith("CAPROC-RADAR")) && (variable_choices.length === 0 || variable_choices.includes("fillingVolume")) && variable_options.findIndex((variable) => variable.key === "fillingVolume") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_volume_text}</Header>
                                        <Line data={this.state.filling_volume_data} options={options_filling_volume}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-RANGE") || sensor.model_name.startsWith("CAPROC-RADAR")) && (variable_choices.length === 0 || variable_choices.includes("fillingPercentage")) && variable_options.findIndex((variable) => variable.key === "fillingPercentage") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_percentage_text}</Header>
                                        <Line data={this.state.filling_percentage_data} options={options_filling_percent}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-RANGE") || sensor.model_name.startsWith("CAPROC-RADAR")) && (variable_choices.length === 0 || variable_choices.includes("deviation")) && variable_options.findIndex((variable) => variable.key === "deviation") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_deviation_text}</Header>
                                        <Line data={this.state.distance_deviation_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-RANGE") || sensor.model_name.startsWith("CAPROC-RADAR")) && (variable_choices.length === 0 || variable_choices.includes("V-notch water level")) && variable_options.findIndex((variable) => variable.key === "V-notch water level") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.v_notch_water_level_text}</Header>
                                        <Line data={this.state.v_notch_water_level_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-RANGE") || sensor.model_name.startsWith("CAPROC-RADAR")) && (variable_choices.length === 0 || variable_choices.includes("V-notch water flow")) && variable_options.findIndex((variable) => variable.key === "V-notch water flow") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.v_notch_water_flow_text}</Header>
                                        <Line data={this.state.v_notch_water_flow_data} />
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-CMF-DS18B20") && (variable_choices.length === 0 || variable_choices.includes("External temperature DS18B20")) && variable_options.findIndex((variable) => variable.key === "External temperature DS18B20") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_external_text}</Header>
                                        <Line data={this.state.temperature_external_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-CMF-DS18B20") && (variable_choices.length === 0 || variable_choices.includes("Temperature")) && variable_options.findIndex((variable) => variable.key === "Temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-CMF-DS18B20") && (variable_choices.length === 0 || variable_choices.includes("Humidity")) && variable_options.findIndex((variable) => variable.key === "Humidity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("Temperature")) && variable_options.findIndex((variable) => variable.key === "Temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("Humidity")) && variable_options.findIndex((variable) => variable.key === "Humidity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("WCIA")) && variable_options.findIndex((variable) => variable.key === "WCIA") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.wcia_text}</Header>
                                        <Line data={this.state.wcia_data} options={options_g_per_m3}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("Temperature SCD4x")) && variable_options.findIndex((variable) => variable.key === "Temperature SCD4x") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.scd4x_temperature_text}</Header>
                                        <Line data={this.state.scd4x_temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("Humidity SCD4x")) && variable_options.findIndex((variable) => variable.key === "Humidity SCD4x") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.scd4x_humidity_text}</Header>
                                        <Line data={this.state.scd4x_humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("Temperature SFA30")) && variable_options.findIndex((variable) => variable.key === "Temperature SFA30") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.sfa30_temperature_text}</Header>
                                        <Line data={this.state.sfa30_temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("Humidity SFA30")) && variable_options.findIndex((variable) => variable.key === "Humidity SFA30") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.sfa30_humidity_text}</Header>
                                        <Line data={this.state.sfa30_humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("VOC index SGP4x")) && variable_options.findIndex((variable) => variable.key === "VOC index SGP4x") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.voc_index_text}</Header>
                                        <Line data={this.state.voc_index_data} options={options_index}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("CO2 SCD4x")) && variable_options.findIndex((variable) => variable.key === "CO2 SCD4x") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.co2_text}</Header>
                                        <Line data={this.state.co2_data} options={options_ppm}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("HCHO SFA30")) && variable_options.findIndex((variable) => variable.key === "HCHO SFA30") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.hcho_text}</Header>
                                        <Line data={this.state.hcho_data} options={options_ppb}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("Differential pressure SDP")) && variable_options.findIndex((variable) => variable.key === "Differential pressure SDP") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.dp_pressure_text}</Header>
                                        <Line data={this.state.dp_pressure_data} options={options_pressure_pa}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("Differential pressure SDP (mass flow)")) && variable_options.findIndex((variable) => variable.key === "Differential pressure SDP (mass flow)") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.dp_pressure_mass_flow_data_text}</Header>
                                        <Line data={this.state.dp_pressure_mass_flow_data} options={options_pressure_pa}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("Temperature SDP")) && variable_options.findIndex((variable) => variable.key === "Temperature SDP") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.dp_temp_text}</Header>
                                        <Line data={this.state.dp_temp_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-IC") && (variable_choices.length === 0 || variable_choices.includes("Air pressure MS5XX")) && variable_options.findIndex((variable) => variable.key === "Air pressure MS5XX") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pressure_text}</Header>
                                        <Line data={this.state.pressure_data} options={options_air_pressure}/>
                                    </React.Fragment>
                                )}

                                {((sensor.model_name.startsWith("CAPROC-TH-PROBE") || sensor.model_name.startsWith("CAPROC-OC-TH")) && (variable_choices.length === 0 || variable_choices.includes("Temperature TH Probe")) && variable_options.findIndex((variable) => variable.key === "Temperature TH Probe") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-TH-PROBE") || sensor.model_name.startsWith("CAPROC-OC-TH")) && (variable_choices.length === 0 || variable_choices.includes("Humidity TH Probe")) && variable_options.findIndex((variable) => variable.key === "Humidity TH Probe") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-TH-PROBE") || sensor.model_name.startsWith("CAPROC-OC-TH")) && (variable_choices.length === 0 || variable_choices.includes("WCIA TH Probe")) && variable_options.findIndex((variable) => variable.key === "WCIA TH Probe") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.wcia_text}</Header>
                                        <Line data={this.state.wcia_data} options={options_g_per_m3}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Temperature TH Probe 2")) && variable_options.findIndex((variable) => variable.key === "Temperature TH Probe 2") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text_2}</Header>
                                        <Line data={this.state.temperature_data_2} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Humidity TH Probe 2")) && variable_options.findIndex((variable) => variable.key === "Humidity TH Probe 2") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text_2}</Header>
                                        <Line data={this.state.humidity_data_2} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("WCIA TH Probe 2")) && variable_options.findIndex((variable) => variable.key === "WCIA TH Probe 2") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.wcia_text_2}</Header>
                                        <Line data={this.state.wcia_data_2} options={options_g_per_m3}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Temperature TH Probe 3")) && variable_options.findIndex((variable) => variable.key === "Temperature TH Probe 3") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text_3}</Header>
                                        <Line data={this.state.temperature_data_3} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Humidity TH Probe 3")) && variable_options.findIndex((variable) => variable.key === "Humidity TH Probe 3") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text_3}</Header>
                                        <Line data={this.state.humidity_data_3} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("WCIA TH Probe 3")) && variable_options.findIndex((variable) => variable.key === "WCIA TH Probe 3") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.wcia_text_3}</Header>
                                        <Line data={this.state.wcia_data_3} options={options_g_per_m3}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Corrected temperature TH probe")) && variable_options.findIndex((variable) => variable.key === "Corrected temperature TH probe") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.corrected_temperature_text}</Header>
                                        <Line data={this.state.corrected_temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Corrected humidity TH probe")) && variable_options.findIndex((variable) => variable.key === "Corrected humidity TH probe") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.corrected_humidity_text}</Header>
                                        <Line data={this.state.corrected_humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Corrected WCIA TH probe")) && variable_options.findIndex((variable) => variable.key === "Corrected WCIA TH probe") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.corrected_wcia_text}</Header>
                                        <Line data={this.state.corrected_wcia_data} options={options_g_per_m3}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Corrected temperature TH probe 2")) && variable_options.findIndex((variable) => variable.key === "Corrected temperature TH probe 2") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.corrected_temperature_text_2}</Header>
                                        <Line data={this.state.corrected_temperature_data_2} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Corrected humidity TH probe 2")) && variable_options.findIndex((variable) => variable.key === "Corrected humidity TH probe 2") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.corrected_humidity_text_2}</Header>
                                        <Line data={this.state.corrected_humidity_data_2} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Corrected WCIA TH probe 2")) && variable_options.findIndex((variable) => variable.key === "Corrected WCIA TH probe 2") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.corrected_wcia_text_2}</Header>
                                        <Line data={this.state.corrected_wcia_data_2} options={options_g_per_m3}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Corrected temperature TH probe 3")) && variable_options.findIndex((variable) => variable.key === "Corrected temperature TH probe 3") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.corrected_temperature_text_3}</Header>
                                        <Line data={this.state.corrected_temperature_data_3} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Corrected humidity TH probe 3")) && variable_options.findIndex((variable) => variable.key === "Corrected humidity TH probe 3") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.corrected_humidity_text_3}</Header>
                                        <Line data={this.state.corrected_humidity_data_3} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-TH-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Corrected WCIA TH probe 3")) && variable_options.findIndex((variable) => variable.key === "Corrected WCIA TH probe 3") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.corrected_wcia_text_3}</Header>
                                        <Line data={this.state.corrected_wcia_data_3} options={options_weight_grams}/>
                                    </React.Fragment>
                                )}

                                {(sensor.model_name.startsWith("CAPROC-T-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Temperature T Probe")) && variable_options.findIndex((variable) => variable.key === "Temperature T Probe") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-T-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Temperature T Probe 2")) && variable_options.findIndex((variable) => variable.key === "Temperature T Probe 2") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text_2}</Header>
                                        <Line data={this.state.temperature_data_2} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-T-PROBE") && (variable_choices.length === 0 || variable_choices.includes("Temperature T Probe 3")) && variable_options.findIndex((variable) => variable.key === "Temperature T Probe 3") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text_3}</Header>
                                        <Line data={this.state.temperature_data_3} options={options_temperature}/>
                                    </React.Fragment>
                                )}

                                {(sensor.model_name.includes("PHEHT") && (variable_choices.length === 0 || variable_choices.includes("External temperature PHEHT")) && variable_options.findIndex((variable) => variable.key === "External temperature PHEHT") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pheht_temp_text}</Header>
                                        <Line data={this.state.pheht_temp_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("PHEHT") && (variable_choices.length === 0 || variable_choices.includes("External pH PHEHT")) && variable_options.findIndex((variable) => variable.key === "External pH PHEHT") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pheht_ph_text}</Header>
                                        <Line data={this.state.pheht_ph_data} options={options_ph}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("PHEHT") && (variable_choices.length === 0 || variable_choices.includes("External redox PHEHT")) && variable_options.findIndex((variable) => variable.key === "External redox PHEHT") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pheht_redox_text}</Header>
                                        <Line data={this.state.pheht_redox_data} options={options_mv}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("PHEHT") && (variable_choices.length === 0 || variable_choices.includes("External pH mV PHEHT")) && variable_options.findIndex((variable) => variable.key === "External pH mV PHEHT") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pheht_ph_mv_text}</Header>
                                        <Line data={this.state.pheht_ph_mv_data} options={options_mv}/>
                                    </React.Fragment>
                                )}

                                {(sensor.model_name.includes("C4E") && (variable_choices.length === 0 || variable_choices.includes("External temperature C4E")) && variable_options.findIndex((variable) => variable.key === "External temperature C4E") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.c4e_temp_text}</Header>
                                        <Line data={this.state.c4e_temp_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("C4E") && (variable_choices.length === 0 || variable_choices.includes("External conductivity C4E")) && variable_options.findIndex((variable) => variable.key === "External conductivity C4E") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.c4e_conductivity_text}</Header>
                                        <Line data={this.state.c4e_conductivity_data} options={options_electrical_conductivity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("C4E") && (variable_choices.length === 0 || variable_choices.includes("External salinity C4E")) && variable_options.findIndex((variable) => variable.key === "External salinity C4E") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.c4e_salinity_text}</Header>
                                        <Line data={this.state.c4e_salinity_data} options={options_ppt}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("C4E") && (variable_choices.length === 0 || variable_choices.includes("External tot dissolved solids C4E")) && variable_options.findIndex((variable) => variable.key === "External tot dissolved solids C4E") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.c4e_tot_dissolved_solids_text}</Header>
                                        <Line data={this.state.c4e_tot_dissolved_solids_data} options={options_ppm}/>
                                    </React.Fragment>
                                )}

                                {(sensor.model_name.includes("NTU") && (variable_choices.length === 0 || variable_choices.includes("External temperature NTU")) && variable_options.findIndex((variable) => variable.key === "External temperature NTU") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.ntu_temp_text}</Header>
                                        <Line data={this.state.ntu_temp_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("NTU") && (variable_choices.length === 0 || variable_choices.includes("External turbidity ntu NTU")) && variable_options.findIndex((variable) => variable.key === "External turbidity ntu NTU") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.ntu_turbidity_ntu_text}</Header>
                                        <Line data={this.state.ntu_turbidity_ntu_data} options={options_ntu}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("NTU") && (variable_choices.length === 0 || variable_choices.includes("External turbidity fnu NTU")) && variable_options.findIndex((variable) => variable.key === "External turbidity fnu NTU") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.ntu_turbidity_fnu_text}</Header>
                                        <Line data={this.state.ntu_turbidity_fnu_data} options={options_fnu}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("NTU") && (variable_choices.length === 0 || variable_choices.includes("External tu turbidity NTU")) && variable_options.findIndex((variable) => variable.key === "External tu turbidity NTU") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.ntu_tu_turbidity_text}</Header>
                                        <Line data={this.state.ntu_tu_turbidity_data} options={options_mgl}/>
                                    </React.Fragment>
                                )}

                                {(sensor.model_name.includes("STACSENSE") && (variable_choices.length === 0 || variable_choices.includes("External temperature STACSENSE")) && variable_options.findIndex((variable) => variable.key === "External temperature STACSENSE") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.stacsense_temp_text}</Header>
                                        <Line data={this.state.stacsense_temp_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("STACSENSE") && (variable_choices.length === 0 || variable_choices.includes("External CAS254 STACSENSE")) && variable_options.findIndex((variable) => variable.key === "External CAS254 STACSENSE") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.stacsense_cas254_text}</Header>
                                        <Line data={this.state.stacsense_cas254_data} options={options_1_m}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("STACSENSE") && (variable_choices.length === 0 || variable_choices.includes("External COD STACSENSE")) && variable_options.findIndex((variable) => variable.key === "External COD STACSENSE") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.stacsense_cod_text}</Header>
                                        <Line data={this.state.stacsense_cod_data} options={options_mgl}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("STACSENSE") && (variable_choices.length === 0 || variable_choices.includes("External BOD STACSENSE")) && variable_options.findIndex((variable) => variable.key === "External BOD STACSENSE") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.stacsense_bod_text}</Header>
                                        <Line data={this.state.stacsense_bod_data} options={options_mgl}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.includes("STACSENSE") && (variable_choices.length === 0 || variable_choices.includes("External TOC STACSENSE")) && variable_options.findIndex((variable) => variable.key === "External TOC STACSENSE") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.stacsense_toc_text}</Header>
                                        <Line data={this.state.stacsense_toc_data} options={options_mgl}/>
                                    </React.Fragment>
                                )}

                                {((sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-H21") || sensor.model_name.startsWith("CAPROC-AQUA-PHEHT") || sensor.model_name.startsWith("CAPROC-AQUA-C4E") || sensor.model_name.startsWith("CAPROC-AQUA-NTU") || sensor.model_name.startsWith("CAPROC-AQUA-STACSENSE")) && (variable_choices.length === 0 || (variable_choices.includes("Temperature") || variable_choices.includes("Temperature MS5XX"))) && variable_options.findIndex((variable) => variable.key.startsWith("Temperature")) !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {((sensor.model_name.startsWith("CAPROC-AQUA-H21-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-H21") || sensor.model_name.startsWith("CAPROC-AQUA-PHEHT") || sensor.model_name.startsWith("CAPROC-AQUA-C4E") || sensor.model_name.startsWith("CAPROC-AQUA-NTU") || sensor.model_name.startsWith("CAPROC-AQUA-STACSENSE")) && (variable_choices.length === 0 || (variable_choices.includes("Air pressure") || variable_choices.includes("Air pressure MS5XX"))) && variable_options.findIndex((variable) => variable.key.startsWith("Air pressure")) !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pressure_text}</Header>
                                        <Line data={this.state.pressure_data} options={options_pressure_kpa}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-SCALE") && (variable_choices.length === 0 || variable_choices.includes("Scale weight")) && variable_options.findIndex((variable) => variable.key === "Scale weight") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.scale_weight_text}</Header>
                                        <Line data={this.state.scale_weight_data} options={options_weight_grams}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-SCALE") && (variable_choices.length === 0 || variable_choices.includes("Amount")) && variable_options.findIndex((variable) => variable.key === "Amount") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.scale_amount_text}</Header>
                                        <Line data={this.state.scale_amount_data} options={options_pcs}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-SCALE") && (variable_choices.length === 0 || variable_choices.includes("Filling percentage")) && variable_options.findIndex((variable) => variable.key === "Filling percentage") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.scale_filling_percentage_text}</Header>
                                        <Line data={this.state.scale_filling_percentage_data} options={options_percent}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-SCALE") && (variable_choices.length === 0 || variable_choices.includes("Temperature")) && variable_options.findIndex((variable) => variable.key === "Temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DECENTLAB-DL-PAR" && (variable_choices.length === 0 || variable_choices.includes("External photosynthetically active radiation PAR")) && variable_options.findIndex((variable) => variable.key === "External photosynthetically active radiation PAR") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.radiation_text}</Header>
                                        <Line data={this.state.radiation_data} options={options_par_radiation}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("Temperature")) && variable_options.findIndex((variable) => variable.key === "Temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("Humidity")) && variable_options.findIndex((variable) => variable.key === "Humidity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:1.8.0")) && variable_options.findIndex((variable) => variable.key === "1-0:1.8.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_1_8_0_text}</Header>
                                        <Line data={this.state._1_0_1_8_0_data} options={options_kwh}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:2.8.0")) && variable_options.findIndex((variable) => variable.key === "1-0:2.8.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_2_8_0_text}</Header>
                                        <Line data={this.state._1_0_2_8_0_data} options={options_kwh}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:3.8.0")) && variable_options.findIndex((variable) => variable.key === "1-0:3.8.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_3_8_0_text}</Header>
                                        <Line data={this.state._1_0_3_8_0_data} options={options_kvarh}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:4.8.0")) && variable_options.findIndex((variable) => variable.key === "1-0:4.8.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_4_8_0_text}</Header>
                                        <Line data={this.state._1_0_4_8_0_data} options={options_kvarh}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:1.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:1.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_1_7_0_text}</Header>
                                        <Line data={this.state._1_0_1_7_0_data} options={options_kw}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:2.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:2.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_2_7_0_text}</Header>
                                        <Line data={this.state._1_0_2_7_0_data} options={options_kw}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:3.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:3.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_3_7_0_text}</Header>
                                        <Line data={this.state._1_0_3_7_0_data} options={options_kvar}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:4.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:4.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_4_7_0_text}</Header>
                                        <Line data={this.state._1_0_4_7_0_data} options={options_kvar}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:21.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:21.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_21_7_0_text}</Header>
                                        <Line data={this.state._1_0_21_7_0_data} options={options_kw}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:22.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:22.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_22_7_0_text}</Header>
                                        <Line data={this.state._1_0_22_7_0_data} options={options_kw}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:41.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:41.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_41_7_0_text}</Header>
                                        <Line data={this.state._1_0_41_7_0_data} options={options_kw}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:42.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:42.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_42_7_0_text}</Header>
                                        <Line data={this.state._1_0_42_7_0_data} options={options_kw}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:61.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:61.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_61_7_0_text}</Header>
                                        <Line data={this.state._1_0_61_7_0_data} options={options_kw}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:62.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:62.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_62_7_0_text}</Header>
                                        <Line data={this.state._1_0_62_7_0_data} options={options_kw}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:23.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:23.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_23_7_0_text}</Header>
                                        <Line data={this.state._1_0_23_7_0_data} options={options_kvar}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:24.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:24.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_24_7_0_text}</Header>
                                        <Line data={this.state._1_0_24_7_0_data} options={options_kvar}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:43.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:43.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_43_7_0_text}</Header>
                                        <Line data={this.state._1_0_43_7_0_data} options={options_kvar}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:44.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:44.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_44_7_0_text}</Header>
                                        <Line data={this.state._1_0_44_7_0_data} options={options_kvar}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:63.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:63.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_63_7_0_text}</Header>
                                        <Line data={this.state._1_0_63_7_0_data} options={options_kvar}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:64.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:64.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_64_7_0_text}</Header>
                                        <Line data={this.state._1_0_64_7_0_data} options={options_kvar}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:32.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:32.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_32_7_0_text}</Header>
                                        <Line data={this.state._1_0_32_7_0_data} options={options_battery}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:52.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:52.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_52_7_0_text}</Header>
                                        <Line data={this.state._1_0_52_7_0_data} options={options_battery}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:72.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:72.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_72_7_0_text}</Header>
                                        <Line data={this.state._1_0_72_7_0_data} options={options_battery}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:31.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:31.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_31_7_0_text}</Header>
                                        <Line data={this.state._1_0_31_7_0_data} options={options_current}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:51.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:51.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_51_7_0_text}</Header>
                                        <Line data={this.state._1_0_51_7_0_data} options={options_current}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-HAN") && (variable_choices.length === 0 || variable_choices.includes("1-0:71.7.0")) && variable_options.findIndex((variable) => variable.key === "1-0:71.7.0") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state._1_0_71_7_0_text}</Header>
                                        <Line data={this.state._1_0_71_7_0_data} options={options_current}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-RAIN") && (variable_choices.length === 0 || variable_choices.includes("Temperature")) && variable_options.findIndex((variable) => variable.key === "Temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-RAIN") && (variable_choices.length === 0 || variable_choices.includes("Humidity")) && variable_options.findIndex((variable) => variable.key === "Humidity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-RAIN") && (variable_choices.length === 0 || variable_choices.includes("Air pressure MS5XX")) && variable_options.findIndex((variable) => variable.key === "Air pressure MS5XX") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pressure_text}</Header>
                                        <Line data={this.state.pressure_data} options={options_air_pressure}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-RAIN") && (variable_choices.length === 0 || variable_choices.includes("Rain tip counter")) && variable_options.findIndex((variable) => variable.key === "Rain tip counter") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.rain_tip_counter_text}</Header>
                                        <Bar data={this.state.rain_tip_counter_data} options={options_pcs}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-RAIN") && (variable_choices.length === 0 || variable_choices.includes("Rain tip cumulative counter")) && variable_options.findIndex((variable) => variable.key === "Rain tip cumulative counter") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.rain_tip_counter_cumulative_text}</Header>
                                        <Bar data={this.state.rain_tip_counter_cumulative_data} options={options_pcs}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-RAIN") && (variable_choices.length === 0 || variable_choices.includes("Precipitation")) && variable_options.findIndex((variable) => variable.key === "Precipitation") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.rain_precipitation_text}</Header>
                                        <Bar data={this.state.rain_precipitation_data} options={options_mm}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("CAPROC-RAIN") && (variable_choices.length === 0 || variable_choices.includes("Cumulative precipitation")) && variable_options.findIndex((variable) => variable.key === "Cumulative precipitation") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.rain_precipitation_cumulative_text}</Header>
                                        <Bar data={this.state.rain_precipitation_cumulative_data} options={options_mm}/>
                                    </React.Fragment>
                                )}

                            </React.Fragment>
                        )}
                    
                        <Confirm
                            content={delete_confirm_text}
                            open={delete_confirm_open}
                            onCancel={() => this.closeDeleteConfirm()}
                            onConfirm={() => this.handleDeleteValue(delete_item)}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    render() {
        const {  search_end_date, search_start_date, fetch, sensor, variable_options  } = this.state;
        const { isAuthenticated } = this.props;

        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (fetch) {
            if (search_start_date !== null && search_end_date !== null && sensor !== null && variable_options.length > 0) {
                this.handleFetchReport();
                this.setState({ fetch: false }); 
            } 
        }
        
        //console.log(this.state);
        return (
            <React.Fragment>
                {this.state.screen_size === "normal" && (
                    <Shell active_sensor_id={sensor && (sensor.id)} active_sensor_name={sensor && (sensor.local_name)} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>
                        <this.radioButtons/>
                        <this.main />
                    </Shell>
                )}
                {this.state.screen_size === "wide" && (
                    <Container>
                        <Segment vertical>
                            <this.radioButtons/>    
                            <this.main/>
                        </Segment>
                    </Container>
                )}{this.state.screen_size === "full" && (
                    <React.Fragment>
                        <this.radioButtons/>
                        <this.main />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRoles: state.company.activeRoles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensorreports')(SensorReportsV2))
);