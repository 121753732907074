// Copyright Caproc Oy
// System name: IoT
// System module: Sensor
// Author: TS
//
// 6.3.2022 TS Initial version


import React from "react";
import { Form, Message, Header, Button, Select, Checkbox, Dimmer, Loader, Segment, Image } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { sendDownlinkSettingsURL, sensorDownlinkDetailsURL, sensorDetailsURL } from "../../constants";


class SensorDownlinkSettingsRelay extends React.Component {
    state = {
        sensor: null,
        error: null,
        success_mgs: null,
        loading: false,
        component_loading: false,
        activeIndex: 0,
        sensor_downlink_settings: null,
        selected_sensor_downlink_settings: [],
        sensor_relay_settings: null,
        closed_relays: null
    };

    componentDidMount() {
        this.props.setActivity();
        this.setState({
            component_loading: true
        });
        this.handleDetails();
        this.handleGetRelaySettings();
        this.setState({
            component_loading: false
        });

    }

    getActiveSettings = (settings) => {
        let new_settings = [];
        let i = 0;
        for (i in settings) {
            if (settings[i].is_active) {
                new_settings.push(settings[i]);
            }
        }
        return new_settings;
    };

    getRelaySettings = (settings) => {
        let new_settings = [];
        let i = 0;
        for (i in settings) {
            if (settings[i].is_active && settings[i].name.startsWith("Relay ch")) {
                new_settings.push(settings[i]);
            }
        }
        return new_settings;
    };
    
    getInitialRelayStates = (settings) => {
        let new_settings = [];
        let i = 0;
        for (i in settings) {
            // Add only closed relays into the state
            if (settings[i].is_active && settings[i].name.startsWith("Relay ch") && settings[i].last_value === "1") {
                new_settings.push(settings[i]);
            }
        }
        return new_settings;
    };
    
    handleGetRelaySettings = () => {
        this.setState({
            loading: true
        });

        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor_relay_settings: this.getRelaySettings(res.data.sensorstatusvariables),
                    closed_relays: this.getInitialRelayStates(res.data.sensorstatusvariables),
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
        });
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDownlinkDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    sensor_downlink_settings: this.getActiveSettings(res.data.sensor_downlink_settings),
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
        
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            sensor, sensor_downlink_settings, closed_relays
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (sensor.id === null) return;

        let downlink_settings = [];
        // Specific settings for DNIL relays. Commands are always for both channels
        if (sensor.sensor_model.model_name.startsWith("DNIL"))
        {
        let command = null;
        if (closed_relays.length > 0)
        {
            if (closed_relays.length === 2) 
            {
                // Both channels closed
                command = "Both channels closed";
            }
            else
            {
            if (closed_relays[0].name === "Relay ch1 status")
            {
                // Ch1 closed Ch2 open
                command = "Ch1 closed Ch2 open";
            }
            else if (closed_relays[0].name === "Relay ch2 status")
            {
                // Ch1 open Ch2 closed
                command = "Ch1 open Ch2 closed";
            }
            else
            {
                // Raise error
                command = "";
            }
            }
        }
        else 
        {
            // Both relays open
            command = "Both channels open";
        }
        
        if (command !== null && command !== "") {
            
            let setting_item = {};
            let i = 0;
            let x = 0;
            for (i in sensor_downlink_settings) {
                if (sensor_downlink_settings[i].sensor_model_downlink_setting.name === "Downlink setting")
                {
                    x = 0;
                    for (x in sensor_downlink_settings[i].sensor_model_downlink_setting.sensormodeldownlinksettingoptions)
                    if (sensor_downlink_settings[i].sensor_model_downlink_setting.sensormodeldownlinksettingoptions[x].name === command)
                    {
                        setting_item = {
                        company: sensor_downlink_settings[i].company,
                        id: sensor_downlink_settings[i].id,
                        sensor: sensor_downlink_settings[i].sensor,
                        current_value: sensor_downlink_settings[i].sensor_model_downlink_setting.sensormodeldownlinksettingoptions[x].option_value
                    }
                    downlink_settings = [...downlink_settings, setting_item];
                    }
                }
            }
        }
        }

        if (downlink_settings.length > 0) {
            try {
                const response = await authAxios.post(sendDownlinkSettingsURL(activeCompanyId, sensor.id), 
                    {
                        downlink_settings: downlink_settings
                    });
                this.setState({
                    loading: false,
                    success_mgs: t("Relay control sent"),
                    error: null
                });
            } catch (error) {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: error
                });
                return;
            }

        } else {
            this.setState({
                loading: false
            });
        }
    };

    handleSettingCheckboxChange = (e, { value, checked }) => {
        let { closed_relays } = this.state;
        if (checked) {
            if (!closed_relays.includes(value)) {
                closed_relays.push(value);
            }
        } else {
            if (closed_relays.includes(value)) {
                closed_relays.splice(closed_relays.indexOf(value), 1);
            }
        }
        this.setState({ closed_relays });
    };

    is_checked = (id) => {
        let { closed_relays } = this.state;
        let i = 0;
        for (i in closed_relays) {
            if (closed_relays[i].name === id.name)
            return true;
        }
        return false;
    };    

    render() {
        const {
            sensor,
            error,
            success_mgs,
            loading,
            component_loading,
            sensor_relay_settings
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell active_sensor_id={sensor && sensor.id} active_sensor_name={sensor && sensor.local_name} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>
                {(sensor && sensor_relay_settings) && (
                    <>
                        {component_loading ? (
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>
                                <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                            </Segment>

                        ) : (
                            <React.Fragment>
                                {loading && (
                                    <Segment>
                                        <Dimmer active inverted>
                                            <Loader inverted>Loading</Loader>
                                        </Dimmer>
                                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                    </Segment>
                                )}

                                <Header as="h3">{t("Sensor downlink settings")}</Header>
                                <br/>
                                <Form onSubmit={this.handleSubmit} error={error !== null}>
                                    {sensor.sensor_model.sensor_class === "A" && (
                                        <>
                                            <Header as="h5">{t("A class device")}</Header>
                                            {t("Settings will be sent to the sensor after next scheduled uplink message")}
                                        </>
                                    )}
                                    {sensor.sensor_model.sensor_class === "C" && (
                                        <>
                                            <Header as="h5">{t("C class device")}</Header>
                                            {t("Settings will be sent to the sensor immediately")}
                                        </>
                                    )}
                                    <Form.Field width="6"><hr/></Form.Field>
                                    
                                    <Form.Field width="6">
                                    
                                    <b >{t("Sensor")}: </b>{sensor.local_unit}/{sensor.local_line}/{sensor.local_machine}/{sensor.local_name}<br/>
                                    <b >{t("Sensor model")}: </b> {sensor.device_name} <br/>
                                    <b >{t("Device EUI")}: </b> {sensor.device_eui} <br/>

                                    </Form.Field>
                                    {sensor_relay_settings && (
                                        sensor_relay_settings.map((setting, key) => {
                                            if (setting.is_active) {
                                                return (
                                                    <>
                                                        <Form.Field width="4">
                                                            <Checkbox
                                                                label={<label>{this.is_checked(setting) ? (t(setting.display_name) + " (" + t("Turn on") + ")") : (t(setting.display_name) + " (" + t("Turn off") + ")")}</label>}
                                                                onChange={this.handleSettingCheckboxChange}
                                                                checked={this.is_checked(setting)}
                                                                value={setting}
                                                            />
                                                        </Form.Field>
                                                        {setting.last_sent_timestamp && (
                                                            <>
                                                                <label>{t("Last modified")}</label>:&nbsp;
                                                                {setting.last_sent_timestamp ? new Date(setting.last_sent_timestamp).toLocaleString('fi-FI') : ""}&nbsp;{setting.author && (setting.author.username)}<br/>
                                                            </>
                                                        )}
                                                        <Form.Field width="6">
                                                            <hr/>
                                                        </Form.Field>
                                                    </>
                                                );
                                            }
                                        })
                                    )}
                                    
                                    {error && <Message error header={t("Error")} content={error.response.data} />}
                                    {success_mgs && ( 
                                        <Form.Field width="8">
                                            <Message positive header={success_mgs} />
                                        </Form.Field>
                                    )}
                                    
                                    <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleSubmit(e)}>
                                        {t("Send")}
                                    </Button>
                                </Form>
                            </React.Fragment>
                        )}
                    </>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensordownlinksettingsrelay')(SensorDownlinkSettingsRelay))
);