// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 19.3.2020 TS Initial version
// 7.1.2024 1.0.8 TS Polygon texts and titles


import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Confirm, Input } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { fetchSensorGroupsURL, deleteSensorGroupDetailsURL } from "../../constants";


class SensorGroups extends React.Component {
    state = {
        data: null,
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        error: null,
        loading: false,
        search: ""
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchList();
    }

    handleFetchList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchSensorGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    data: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFormatList = data => {
        let i = 0;
        let newlist = [];
        for (i in data) {
            newlist.push(data[i]);
        }
        return newlist;
    };

    groupDetails = id => {
        this.props.history.push(`/sensor/sensor-group-details/${id}/`)
    };

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleDelete = id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(deleteSensorGroupDetailsURL(activeCompanyId, id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchList();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        const { data, error, loading, delete_confirm_open, delete_confirm_text, delete_item, search } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        let filter_data = [];

        // Filter data for search
        if (data !== null) {
            filter_data = data.filter((group) => {
                return group.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 || group.description.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
        }
        
        const logo = localStorage.getItem("Logo");
        return (
            <Shell>
                {logo && (
                    <>
                        {logo === "Polygon" ? (
                            <Header as="h3">{t("Luo / Poista kohteita")}</Header>
                        ) : (
                            <Header as="h3">{t("Sensor Groups")}</Header>
                        )}
                        
                        <Link to="/sensor/sensor-group-details/create/">
                            <Button floated="right" color="green">
                                {logo === "Polygon" ? (
                                    t("+ Luo kohde")
                                ) : (
                                    t("+ Sensor Group")
                                )}
                            </Button>
                        </Link>

                        {error && (
                            <Message
                                error
                                header={t("There was an error")}
                                content={JSON.stringify(error)}
                            />
                        )}
                        {loading && (
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>
                                <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                            </Segment>
                        )}
                        {data && (
                            <React.Fragment>
                                <Input
                                    icon='search'
                                    value={search}
                                    name="search"
                                    onChange={this.handleChange} />
                                <Table striped selectable celled>
                                    <Table.Header>
                                        <Table.Row>
                                            {logo === "Polygon" ? (
                                                <>
                                                    <Table.HeaderCell>{t("Kohteet")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Antureita kohteessa (kpl)")}</Table.HeaderCell>
                                                    <Table.HeaderCell />
                                                </>
                                            ) : (
                                                <>
                                                    <Table.HeaderCell>{t("Group name")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Sensors in group (pcs)")}</Table.HeaderCell>
                                                    <Table.HeaderCell />
                                                </>
                                            )}
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {filter_data.map((group, key) => {
                                            return (
                                                <Table.Row key={key} >
                                                    {(group.name === "Default group" && logo === "Polygon") ? (
                                                        <Table.Cell onClick={() => this.groupDetails(group.id)}>{t("TMP VARASTO")}</Table.Cell>
                                                    ) : (
                                                        <Table.Cell onClick={() => this.groupDetails(group.id)}>{t(group.name)}</Table.Cell>
                                                    )}
                                                    <Table.Cell onClick={() => this.groupDetails(group.id)}>{group.sensors.length}</Table.Cell>
                                                    {/* Default group should not be deleted */}
                                                    {group.name !== "Default group" && (
                                                        <>
                                                            {logo === "Polygon" ? (
                                                                <Table.Cell collapsing textAlign='center'><Button compact negative size='mini' icon='remove'
                                                                    onClick={() => this.openDeleteConfirm(t("Haluatko poistaa kohteen? Poistetun kohteen sensorit siirtyvät varastoon."), group.id)} /></Table.Cell>
                                                            ) : (
                                                                <Table.Cell collapsing textAlign='center'><Button compact negative size='mini' icon='remove'
                                                                    onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { name: group.name }), group.id)} /></Table.Cell>
                                                            )}
                                                        </>
                                                    )}
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            </React.Fragment>
                        )}
                        <Confirm
                            content={delete_confirm_text}
                            open={delete_confirm_open}
                            onCancel={() => this.closeDeleteConfirm()}
                            onConfirm={() => this.handleDelete(delete_item)}
                        />
                    </>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensorgroups')(SensorGroups))
);