// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 21.3.2020 TS Initial version
// 25.3.2020 TS Changed Chart as default
// 15.6.2020 TS Mobile mode titles. Export to CSV.
// 16.6.2020 TS Added charts for Battery Voltage and "extra" WISE-2410 variables. Also chart choice select now added.
// 24.6.2020 TS Added Decentlab
// 27.6.2020 TS Added avg, highest and lowest values to each dataset in charts
// 15.9.2020 TS Added Elsys ERS
// 19.1.2021 TS Added Elsys ELT 2
// 26.1.2021 TS Added Wastecontrol

import React from "react";
import { Message, Header, Table, Segment, Dimmer, Loader, Image,  Grid, Menu, Radio, Container, Button, Responsive, Select } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate, exportToCsv, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { sensorDetailsURL, fetchSensorValuesForVariablesURL } from "../../constants";
import {  Line  } from 'react-chartjs-2';



class SensorReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            report: null,
            sensor: null,
            activeMenuItem: this.props.t("Chart"),
            error: null,
            loading: false,
            search_start_date: null,
            search_end_date: null,
            search: "",
            fetch: false,
            all_velocity_RMS_data: {},
            all_acceleration_peak_data: {},
            all_acceleration_RMS_data: {},
            all_kurtosis_data: {},
            all_skewness_data: {},
            all_crest_factor_data: {},
            all_deviation_data: {},
            all_displacement_data: {},
            temperature_data: {},
            battery_data: {},
            distance_data: {},
            distance_deviation_data: {},
            filling_percentage_data: {},
            filling_volume_data: {},
            x_axis_velocity_RMS_data: {},
            y_axis_velocity_RMS_data: {},
            z_axis_velocity_RMS_data: {},
            humidity_data: {},
            pressure_data: {},
            light_data: {},
            waterleak_data: {},
            motion_data: {},
            door_closed_data: {},
            temperature_text: null,
            distance_text: null,
            filling_percentage_text: null,
            filling_volume_text: null,
            distance_deviation_text: null,
            in_trip_data: {},
            speed_data: {},
            screen_size: "normal",
            variable_options: [],
            variable_choices: [],
            sensor: null,
            tableData: [],
            offset: 0,
            limit: 30,
            startup_counter: 0,
            is_mounted: false
        };
        window.onscroll = () => {
            if (this.state.activeMenuItem === this.props.t("Table")) {
                if (this.state.error || this.state.loading || (this.state.report && this.state.report.length < this.state.offset)) return;
                if (
                    document.documentElement.scrollHeight -
                    document.documentElement.scrollTop ===
                    document.documentElement.clientHeight
                ) {
                    this.setState({
                        offset: this.state.offset + this.state.limit
                    });
                }
            }
        };
    }

    componentDidMount() {
        this.props.setActivity();
        let init_start_date = new Date();
        // init_start_date.setDate(init_start_date.getDate() - 1);
        this.handleStartDateChange(init_start_date);
        this.handleFetchSensor();
        this.handleEndDateChange(new Date());
        this.setState({ fetch: true});
    }

    handleVariableOptions = sensor => {
        const { t } = this.props;
        let options = [];

        if (sensor !== null) {
            let options_value = null;
            let i = 0;
            for (i in sensor.sensorstatusvariables) {
                if (!sensor.sensorstatusvariables[i].is_active) continue;
                if (sensor.model_name === "WISE-2410-EA" && sensor.sensorstatusvariables[i].name !== "PowerSrc" && sensor.sensorstatusvariables[i].name !== "Time") {
                    options_value = null;
                    // Get options only once. Not for each axis
                    if (sensor.sensorstatusvariables[i].group === "Accelerometer" && sensor.sensorstatusvariables[i].sub_group === "X-Axis") {
                        options_value = {
                            key: sensor.sensorstatusvariables[i].name, 
                            text: t(sensor.sensorstatusvariables[i].display_name), 
                            value: sensor.sensorstatusvariables[i].name
                        };
                    } else if (sensor.sensorstatusvariables[i].group !== "Accelerometer") {
                        options_value = {
                            key: sensor.sensorstatusvariables[i].name, 
                            text: t(sensor.sensorstatusvariables[i].display_name), 
                            value: sensor.sensorstatusvariables[i].name
                        };
                    }
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                } else if (sensor.model_name === "DECENTLAB-DL-MBX") {
                    options_value = null;
                    if (sensor.sensorstatusvariables[i].group === "Report") {
                        options_value = {
                            key: sensor.sensorstatusvariables[i].name, 
                            text: t(sensor.sensorstatusvariables[i].display_name), 
                            value: sensor.sensorstatusvariables[i].name
                        };    
                    } else {
                        options_value = {
                            key: sensor.sensorstatusvariables[i].group, 
                            text: t(sensor.sensorstatusvariables[i].display_name), 
                            value: sensor.sensorstatusvariables[i].group
                        };
                    }
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                } else if (sensor.model_name === "DIGITAL-MATTER-YABBY" && sensor.sensorstatusvariables[i].group === "position" ) {
                    options_value = null;
                    options_value = {
                        key: sensor.sensorstatusvariables[i].name, 
                        text: t(sensor.sensorstatusvariables[i].display_name), 
                        value: sensor.sensorstatusvariables[i].name
                    };
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                    let init_start_date = new Date();
                    init_start_date.setDate(init_start_date.getDate() - 7);
                    this.handleStartDateChange(init_start_date);
                } else if ((sensor.model_name === "ELSYS-ERS" || sensor.model_name === "ELSYS-EMS" || sensor.model_name === "ELSYS-ELT-2") && sensor.sensorstatusvariables[i].group === "status" ) {
                    options_value = null;
                    options_value = {
                        key: sensor.sensorstatusvariables[i].name, 
                        text: t(sensor.sensorstatusvariables[i].display_name), 
                        value: sensor.sensorstatusvariables[i].name
                    };
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                } else if (sensor.model_name.startsWith("WASTECONTROL") && sensor.sensorstatusvariables[i].is_report_value) {
                    options_value = null;
                    options_value = {
                        key: sensor.sensorstatusvariables[i].name, 
                        text: t(sensor.sensorstatusvariables[i].display_name), 
                        value: sensor.sensorstatusvariables[i].name
                    };
                    if (options_value !== null && !options.includes(options_value)) {
                        options.push(options_value);
                    }
                    let init_start_date = new Date();
                    init_start_date.setDate(init_start_date.getDate() - 7);
                    this.handleStartDateChange(init_start_date);
                }
            }  
            // Add also distance deviation
            // if (sensor.model_name === "DECENTLAB-DL-MBX") {
                // options_value = null;
                // options_value = {
                //     key: "Distance deviation", 
                //     text: t("Distance deviation (mm)"), 
                //     value: "Distance deviation"
                // };
                // if (options_value !== null && !options.includes(options_value)) {
                //     options.push(options_value);
                // }
            // }
            if (sensor.model_name === "WISE-2410-EA") {
                // Set default chart to start with
                let default_choices = [];
                default_choices.push("OAVelocity");
                default_choices.push("Peakmg");
                default_choices.push("RMSmg");
                default_choices.push("SenVal");
                this.setState({
                    variable_choices: default_choices
                });
            }
        }
        this.setState({
            variable_options: options
        });
    };

    handleRadioChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    radioButtons = () => {
        return (
            <React.Fragment>
                <Radio
                    name="screen_size"
                    label={this.props.t("Normal")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "normal"}
                    value="normal"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Wide")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "wide"}
                    value="wide"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Full screen")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "full"}
                    value="full"
                />  
            </React.Fragment>
        );
    };

    handleMenuItemClick = (e, { name }) => {
        this.setState({ activeMenuItem: name });
        const { t } = this.props;
        if (name == t("Chart")) {
            this.handleLineChartData(this.state.report);
        } else if (name == t("Table")) {
            this.setState({
                offset: 0
            });
        }
    };

    handleFetchReport = () => {
        this.setState({
            loading: true
        });
        const { search_start_date, search_end_date, variable_choices, variable_options } = this.state;
        const { activeCompanyId, t } = this.props;
        const { sensor_id } = this.props.match.params;
        let search_variables = [];
        if (variable_choices.length === 0) {
            if (variable_options.length > 0) {
                let i = 0;
                for (i in variable_options) {
                    search_variables.push(variable_options[i].value);
                }
            } 
        } else {
            search_variables = variable_choices;
        }
        if (search_start_date === null || search_end_date === null || search_variables.length === 0 ) {
            this.setState({
                loading: false
            });
            return;
        } 
        authAxios
            .get(fetchSensorValuesForVariablesURL(activeCompanyId, sensor_id, search_start_date, search_end_date, search_variables))
            .then(res => {
                this.setState({
                    report: res.data,
                    error: null,
                    loading: false
                });
                if (this.state.activeMenuItem == t("Chart")) {
                    this.handleLineChartData(res.data);
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFetchSensor = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    loading: false,
                    error: null
                });
                this.handleVariableOptions(res.data);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleLineChartData = report => {
        this.setState({
            loading: true
        });
        const { t } = this.props;
        const { sensor } = this.state;
        const default_dataset_red = {
            label: "mm/s",
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            data: [],
            fill: false
        };
        const default_dataset_blue = {
            label: "",
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            data: [],
            fill: false
        };
        const default_dataset_green = {
            label: "",
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            data: [],
            fill: false
        };

        if (sensor.model_name === "WISE-2410-EA") {
            // let {
            //     all_velocity_RMS_data,
            //     all_acceleration_RMS_data,
            //     all_acceleration_peak_data,
            //     temperature_data,
            //     x_axis_velocity_RMS_data,
            //     y_axis_velocity_RMS_data,
            //     z_axis_velocity_RMS_data,
            //     all_kurtosis_data,
            //     all_skewness_data,
            //     all_crest_factor_data,
            //     all_deviation_data,
            //     all_displacement_data,
            //     battery_data
            // } = this.state;

            let _all_velocity_RMS_data = {};
            _all_velocity_RMS_data.labels = [];
            _all_velocity_RMS_data.datasets = [];

            let _all_acceleration_RMS_data = {};
            _all_acceleration_RMS_data.labels = [];
            _all_acceleration_RMS_data.datasets = [];

            let _all_acceleration_peak_data = {};
            _all_acceleration_peak_data.labels = [];
            _all_acceleration_peak_data.datasets = [];

            let _battery_data = {};
            _battery_data.labels = [];
            _battery_data.datasets = [];

            let _temperature_data = {};
            _temperature_data.labels = [];
            _temperature_data.datasets = [];

            let _all_kurtosis_data = {};
            _all_kurtosis_data.labels = [];
            _all_kurtosis_data.datasets = [];

            let _all_crest_factor_data = {};
            _all_crest_factor_data.labels = [];
            _all_crest_factor_data.datasets = [];

            let _all_skewness_data = {};
            _all_skewness_data.labels = [];
            _all_skewness_data.datasets = [];

            let _all_deviation_data = {};
            _all_deviation_data.labels = [];
            _all_deviation_data.datasets = [];

            let _all_displacement_data = {};
            _all_displacement_data.labels = [];
            _all_displacement_data.datasets = [];

            // x_axis_velocity_RMS_data.labels = [];
            // x_axis_velocity_RMS_data.datasets = [];
            // y_axis_velocity_RMS_data.labels = [];
            // y_axis_velocity_RMS_data.datasets = [];
            // z_axis_velocity_RMS_data.labels = [];
            // z_axis_velocity_RMS_data.datasets = [];

            // Velocity RMS
            let x_axis_velocity_RMS_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Velocity RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_velocity_RMS_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Velocity RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_velocity_RMS_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Velocity RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Acceleration RMS
            let x_axis_acceleration_RMS_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Acceleration RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_acceleration_RMS_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Acceleration RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_acceleration_RMS_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Acceleration RMS"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Acceleration Peak
            let x_axis_acceleration_peak_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Acceleration Peak"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_acceleration_peak_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Acceleration Peak"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_acceleration_peak_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Acceleration Peak"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Kurtosis
            let x_axis_kurtosis_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Kurtosis"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_kurtosis_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Kurtosis"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_kurtosis_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Kurtosis"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Skewness
            let x_axis_skewness_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Skewness"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_skewness_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Skewness"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_skewness_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Skewness"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Crest factor
            let x_axis_crest_factor_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Crest factor"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_crest_factor_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Crest factor"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_crest_factor_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Crest factor"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Deviation
            let x_axis_deviation_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Deviation"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_deviation_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Deviation"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_deviation_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Deviation"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            // Displacement
            let x_axis_displacement_dataset = {
                ...default_dataset_blue,
                label: t("X-Axis Displacement"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let y_axis_displacement_dataset = {
                ...default_dataset_red,
                label: t("Y-Axis Displacement"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            let z_axis_displacement_dataset = {
                ...default_dataset_green,
                label: t("Z-Axis Displacement"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
        
            // Temperature
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Surface temperature"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            
            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);
                // Velocity RMS
                if (report[i].name === "OAVelocity") {
                    if (!_all_velocity_RMS_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_velocity_RMS_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        // x_axis_velocity_RMS_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        x_axis_velocity_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_velocity_RMS_dataset.highest === null || (parseFloat(x_axis_velocity_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_velocity_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_velocity_RMS_dataset.lowest === null || (parseFloat(x_axis_velocity_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_velocity_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_velocity_RMS_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_velocity_RMS_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        // y_axis_velocity_RMS_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        y_axis_velocity_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_velocity_RMS_dataset.highest === null || (parseFloat(y_axis_velocity_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_velocity_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_velocity_RMS_dataset.lowest === null || (parseFloat(y_axis_velocity_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_velocity_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_velocity_RMS_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_velocity_RMS_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        // z_axis_velocity_RMS_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        z_axis_velocity_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_velocity_RMS_dataset.highest === null || (parseFloat(z_axis_velocity_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_velocity_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_velocity_RMS_dataset.lowest === null || (parseFloat(z_axis_velocity_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_velocity_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_velocity_RMS_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_velocity_RMS_dataset.pcs += 1;
                    }
                // Acceleration Peak
                } else if (report[i].name === "Peakmg") {
                    if (!_all_acceleration_peak_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_acceleration_peak_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_acceleration_peak_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_acceleration_peak_dataset.highest === null || (parseFloat(x_axis_acceleration_peak_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_acceleration_peak_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_acceleration_peak_dataset.lowest === null || (parseFloat(x_axis_acceleration_peak_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_acceleration_peak_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_acceleration_peak_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_acceleration_peak_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_acceleration_peak_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_acceleration_peak_dataset.highest === null || (parseFloat(y_axis_acceleration_peak_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_acceleration_peak_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_acceleration_peak_dataset.lowest === null || (parseFloat(y_axis_acceleration_peak_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_acceleration_peak_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_acceleration_peak_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_acceleration_peak_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_acceleration_peak_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_acceleration_peak_dataset.highest === null || (parseFloat(z_axis_acceleration_peak_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_acceleration_peak_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_acceleration_peak_dataset.lowest === null || (parseFloat(z_axis_acceleration_peak_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_acceleration_peak_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_acceleration_peak_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_acceleration_peak_dataset.pcs += 1;
                    }
                // Acceleration RMS
                } else if (report[i].name === "RMSmg") {
                    if (!_all_acceleration_RMS_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_acceleration_RMS_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_acceleration_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_acceleration_RMS_dataset.highest === null || (parseFloat(x_axis_acceleration_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_acceleration_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_acceleration_RMS_dataset.lowest === null || (parseFloat(x_axis_acceleration_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_acceleration_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_acceleration_RMS_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_acceleration_RMS_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_acceleration_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_acceleration_RMS_dataset.highest === null || (parseFloat(y_axis_acceleration_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_acceleration_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_acceleration_RMS_dataset.lowest === null || (parseFloat(y_axis_acceleration_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_acceleration_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_acceleration_RMS_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_acceleration_RMS_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_acceleration_RMS_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_acceleration_RMS_dataset.highest === null || (parseFloat(z_axis_acceleration_RMS_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_acceleration_RMS_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_acceleration_RMS_dataset.lowest === null || (parseFloat(z_axis_acceleration_RMS_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_acceleration_RMS_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_acceleration_RMS_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_acceleration_RMS_dataset.pcs += 1;
                    }
                // Kurtosis
                } else if (report[i].name === "Kurtosis") {
                    if (!_all_kurtosis_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_kurtosis_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_kurtosis_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_kurtosis_dataset.highest === null || (parseFloat(x_axis_kurtosis_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_kurtosis_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_kurtosis_dataset.lowest === null || (parseFloat(x_axis_kurtosis_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_kurtosis_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_kurtosis_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_kurtosis_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_kurtosis_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_kurtosis_dataset.highest === null || (parseFloat(y_axis_kurtosis_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_kurtosis_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_kurtosis_dataset.lowest === null || (parseFloat(y_axis_kurtosis_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_kurtosis_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_kurtosis_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_kurtosis_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_kurtosis_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_kurtosis_dataset.highest === null || (parseFloat(z_axis_kurtosis_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_kurtosis_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_kurtosis_dataset.lowest === null || (parseFloat(z_axis_kurtosis_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_kurtosis_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_kurtosis_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_kurtosis_dataset.pcs += 1;
                    }
                // Skewness
                } else if (report[i].name === "Skewness") {
                    if (!_all_skewness_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_skewness_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_skewness_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_skewness_dataset.highest === null || (parseFloat(x_axis_skewness_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_skewness_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_skewness_dataset.lowest === null || (parseFloat(x_axis_skewness_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_skewness_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_skewness_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_skewness_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_skewness_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_skewness_dataset.highest === null || (parseFloat(y_axis_skewness_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_skewness_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_skewness_dataset.lowest === null || (parseFloat(y_axis_skewness_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_skewness_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_skewness_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_skewness_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_skewness_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_skewness_dataset.highest === null || (parseFloat(z_axis_skewness_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_skewness_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_skewness_dataset.lowest === null || (parseFloat(z_axis_skewness_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_skewness_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_skewness_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_skewness_dataset.pcs += 1;
                    }
                // Crest factor
                } else if (report[i].name === "CrestFactor") {
                    if (!_all_crest_factor_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_crest_factor_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_crest_factor_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_crest_factor_dataset.highest === null || (parseFloat(x_axis_crest_factor_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_crest_factor_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_crest_factor_dataset.lowest === null || (parseFloat(x_axis_crest_factor_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_crest_factor_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_crest_factor_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_crest_factor_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_crest_factor_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_crest_factor_dataset.highest === null || (parseFloat(y_axis_crest_factor_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_crest_factor_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_crest_factor_dataset.lowest === null || (parseFloat(y_axis_crest_factor_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_crest_factor_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_crest_factor_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_crest_factor_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_crest_factor_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_crest_factor_dataset.highest === null || (parseFloat(z_axis_crest_factor_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_crest_factor_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_crest_factor_dataset.lowest === null || (parseFloat(z_axis_crest_factor_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_crest_factor_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_crest_factor_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_crest_factor_dataset.pcs += 1;
                    }
                // Standard deviation
                } else if (report[i].name === "Deviation") {
                    if (!_all_deviation_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_deviation_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_deviation_dataset.highest === null || (parseFloat(x_axis_deviation_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_deviation_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_deviation_dataset.lowest === null || (parseFloat(x_axis_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_deviation_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_deviation_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_deviation_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_deviation_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_deviation_dataset.highest === null || (parseFloat(y_axis_deviation_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_deviation_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_deviation_dataset.lowest === null || (parseFloat(y_axis_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_deviation_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_deviation_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_deviation_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_deviation_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_deviation_dataset.highest === null || (parseFloat(z_axis_deviation_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_deviation_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_deviation_dataset.lowest === null || (parseFloat(z_axis_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_deviation_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_deviation_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_deviation_dataset.pcs += 1;
                    }
                // Displacement
                } else if (report[i].name === "Displacement") {
                    if (!_all_displacement_data.labels.includes(new Date(event_time).toLocaleString('fi-FI')))
                        _all_displacement_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    if (report[i].sub_group === "X-Axis") {
                        x_axis_displacement_dataset.data.push(parseFloat(report[i].value));
                        if (x_axis_displacement_dataset.highest === null || (parseFloat(x_axis_displacement_dataset.highest) < parseFloat(report[i].value))) {
                            x_axis_displacement_dataset.highest = parseFloat(report[i].value);
                        }
                        if (x_axis_displacement_dataset.lowest === null || (parseFloat(x_axis_displacement_dataset.lowest) > parseFloat(report[i].value))) {
                            x_axis_displacement_dataset.lowest = parseFloat(report[i].value);
                        }
                        x_axis_displacement_dataset.total_sum += parseFloat(report[i].value);
                        x_axis_displacement_dataset.pcs += 1;
                    } else if (report[i].sub_group === "Y-Axis") {
                        y_axis_displacement_dataset.data.push(parseFloat(report[i].value));
                        if (y_axis_displacement_dataset.highest === null || (parseFloat(y_axis_displacement_dataset.highest) < parseFloat(report[i].value))) {
                            y_axis_displacement_dataset.highest = parseFloat(report[i].value);
                        }
                        if (y_axis_displacement_dataset.lowest === null || (parseFloat(y_axis_displacement_dataset.lowest) > parseFloat(report[i].value))) {
                            y_axis_displacement_dataset.lowest = parseFloat(report[i].value);
                        }
                        y_axis_displacement_dataset.total_sum += parseFloat(report[i].value);
                        y_axis_displacement_dataset.pcs += 1;
                    }  else if (report[i].sub_group === "Z-Axis") {
                        z_axis_displacement_dataset.data.push(parseFloat(report[i].value));
                        if (z_axis_displacement_dataset.highest === null || (parseFloat(z_axis_displacement_dataset.highest) < parseFloat(report[i].value))) {
                            z_axis_displacement_dataset.highest = parseFloat(report[i].value);
                        }
                        if (z_axis_displacement_dataset.lowest === null || (parseFloat(z_axis_displacement_dataset.lowest) > parseFloat(report[i].value))) {
                            z_axis_displacement_dataset.lowest = parseFloat(report[i].value);
                        }
                        z_axis_displacement_dataset.total_sum += parseFloat(report[i].value);
                        z_axis_displacement_dataset.pcs += 1;
                    }
                // Temperature
                } else if (report[i].group === "TempHumi" && report[i].name === "SenVal") {
                    _temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value))
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;
                    
                    if (this.state.temperature_text === null) {
                        this.setState({
                            temperature_text: t(report[i].display_name)
                        });
                    }
                // Battery Voltage
                } else if (report[i].group === "Device" && report[i].name === "BatteryVolt") {
                    _battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value))
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    
                    if (this.state.battery_text === null) {
                        this.setState({
                            battery_text: t(report[i].display_name)
                        });
                    }
                }
            }
            if (x_axis_velocity_RMS_dataset.pcs > 0) {
                x_axis_velocity_RMS_dataset.average = parseFloat((x_axis_velocity_RMS_dataset.total_sum / x_axis_velocity_RMS_dataset.pcs).toFixed(2));
            }
            x_axis_velocity_RMS_dataset.label = 
                `${x_axis_velocity_RMS_dataset.label} (${t("avg")}: ${x_axis_velocity_RMS_dataset.average.toString()} ${t("Highest")}: ${x_axis_velocity_RMS_dataset.highest ? x_axis_velocity_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_velocity_RMS_dataset.lowest ? x_axis_velocity_RMS_dataset.lowest.toString() : 0})`; 

            _all_velocity_RMS_data.datasets.push(x_axis_velocity_RMS_dataset);

            if (y_axis_velocity_RMS_dataset.pcs > 0) {
                y_axis_velocity_RMS_dataset.average = parseFloat((y_axis_velocity_RMS_dataset.total_sum / y_axis_velocity_RMS_dataset.pcs).toFixed(2));
            }
            y_axis_velocity_RMS_dataset.label = 
                `${y_axis_velocity_RMS_dataset.label} (${t("avg")}: ${y_axis_velocity_RMS_dataset.average.toString()} ${t("Highest")}: ${y_axis_velocity_RMS_dataset.highest ? y_axis_velocity_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_velocity_RMS_dataset.lowest ? y_axis_velocity_RMS_dataset.lowest.toString() : 0})`; 

            _all_velocity_RMS_data.datasets.push(y_axis_velocity_RMS_dataset);

            if (z_axis_velocity_RMS_dataset.pcs > 0) {
                z_axis_velocity_RMS_dataset.average = parseFloat((z_axis_velocity_RMS_dataset.total_sum / z_axis_velocity_RMS_dataset.pcs).toFixed(2));
            }
            z_axis_velocity_RMS_dataset.label = 
                `${z_axis_velocity_RMS_dataset.label} (${t("avg")}: ${z_axis_velocity_RMS_dataset.average.toString()} ${t("Highest")}: ${z_axis_velocity_RMS_dataset.highest ? z_axis_velocity_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_velocity_RMS_dataset.lowest ? z_axis_velocity_RMS_dataset.lowest.toString() : 0})`; 

            _all_velocity_RMS_data.datasets.push(z_axis_velocity_RMS_dataset);

            if (x_axis_acceleration_RMS_dataset.pcs > 0) {
                x_axis_acceleration_RMS_dataset.average = parseFloat((x_axis_acceleration_RMS_dataset.total_sum / x_axis_acceleration_RMS_dataset.pcs).toFixed(2));
            }
            x_axis_acceleration_RMS_dataset.label = 
                `${x_axis_acceleration_RMS_dataset.label} (${t("avg")}: ${x_axis_acceleration_RMS_dataset.average.toString()} ${t("Highest")}: ${x_axis_acceleration_RMS_dataset.highest ? x_axis_acceleration_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_acceleration_RMS_dataset.lowest ? x_axis_acceleration_RMS_dataset.lowest.toString() : 0})`; 

            _all_acceleration_RMS_data.datasets.push(x_axis_acceleration_RMS_dataset);

            if (y_axis_acceleration_RMS_dataset.pcs > 0) {
                y_axis_acceleration_RMS_dataset.average = parseFloat((y_axis_acceleration_RMS_dataset.total_sum / y_axis_acceleration_RMS_dataset.pcs).toFixed(2));
            }
            y_axis_acceleration_RMS_dataset.label = 
                `${y_axis_acceleration_RMS_dataset.label} (${t("avg")}: ${y_axis_acceleration_RMS_dataset.average.toString()} ${t("Highest")}: ${y_axis_acceleration_RMS_dataset.highest ? y_axis_acceleration_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_acceleration_RMS_dataset.lowest ? y_axis_acceleration_RMS_dataset.lowest.toString() : 0})`; 

            _all_acceleration_RMS_data.datasets.push(y_axis_acceleration_RMS_dataset);

            if (z_axis_acceleration_RMS_dataset.pcs > 0) {
                z_axis_acceleration_RMS_dataset.average = parseFloat((z_axis_acceleration_RMS_dataset.total_sum / z_axis_acceleration_RMS_dataset.pcs).toFixed(2));
            }
            z_axis_acceleration_RMS_dataset.label = 
                `${z_axis_acceleration_RMS_dataset.label} (${t("avg")}: ${z_axis_acceleration_RMS_dataset.average.toString()} ${t("Highest")}: ${z_axis_acceleration_RMS_dataset.highest ? z_axis_acceleration_RMS_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_acceleration_RMS_dataset.lowest ? z_axis_acceleration_RMS_dataset.lowest.toString() : 0})`; 

            _all_acceleration_RMS_data.datasets.push(z_axis_acceleration_RMS_dataset);

            if (x_axis_acceleration_peak_dataset.pcs > 0) {
                x_axis_acceleration_peak_dataset.average = parseFloat((x_axis_acceleration_peak_dataset.total_sum / x_axis_acceleration_peak_dataset.pcs).toFixed(2));
            }
            x_axis_acceleration_peak_dataset.label = 
                `${x_axis_acceleration_peak_dataset.label} (${t("avg")}: ${x_axis_acceleration_peak_dataset.average.toString()} ${t("Highest")}: ${x_axis_acceleration_peak_dataset.highest ? x_axis_acceleration_peak_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_acceleration_peak_dataset.lowest ? x_axis_acceleration_peak_dataset.lowest.toString() : 0})`; 

            _all_acceleration_peak_data.datasets.push(x_axis_acceleration_peak_dataset);

            if (y_axis_acceleration_peak_dataset.pcs > 0) {
                y_axis_acceleration_peak_dataset.average = parseFloat((y_axis_acceleration_peak_dataset.total_sum / y_axis_acceleration_peak_dataset.pcs).toFixed(2));
            }
            y_axis_acceleration_peak_dataset.label = 
                `${y_axis_acceleration_peak_dataset.label} (${t("avg")}: ${y_axis_acceleration_peak_dataset.average.toString()} ${t("Highest")}: ${y_axis_acceleration_peak_dataset.highest ? y_axis_acceleration_peak_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_acceleration_peak_dataset.lowest ? y_axis_acceleration_peak_dataset.lowest.toString() : 0})`; 

            _all_acceleration_peak_data.datasets.push(y_axis_acceleration_peak_dataset);

            if (z_axis_acceleration_peak_dataset.pcs > 0) {
                z_axis_acceleration_peak_dataset.average = parseFloat((z_axis_acceleration_peak_dataset.total_sum / z_axis_acceleration_peak_dataset.pcs).toFixed(2));
            }
            z_axis_acceleration_peak_dataset.label = 
                `${z_axis_acceleration_peak_dataset.label} (${t("avg")}: ${z_axis_acceleration_peak_dataset.average.toString()} ${t("Highest")}: ${z_axis_acceleration_peak_dataset.highest ? z_axis_acceleration_peak_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_acceleration_peak_dataset.lowest ? z_axis_acceleration_peak_dataset.lowest.toString() : 0})`; 

            _all_acceleration_peak_data.datasets.push(z_axis_acceleration_peak_dataset);

            if (x_axis_kurtosis_dataset.pcs > 0) {
                x_axis_kurtosis_dataset.average = parseFloat((x_axis_kurtosis_dataset.total_sum / x_axis_kurtosis_dataset.pcs).toFixed(2));
            }
            x_axis_kurtosis_dataset.label = 
                `${x_axis_kurtosis_dataset.label} (${t("avg")}: ${x_axis_kurtosis_dataset.average.toString()} ${t("Highest")}: ${x_axis_kurtosis_dataset.highest ? x_axis_kurtosis_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_kurtosis_dataset.lowest ? x_axis_kurtosis_dataset.lowest.toString() : 0})`; 

            _all_kurtosis_data.datasets.push(x_axis_kurtosis_dataset);

            if (y_axis_kurtosis_dataset.pcs > 0) {
                y_axis_kurtosis_dataset.average = parseFloat((y_axis_kurtosis_dataset.total_sum / y_axis_kurtosis_dataset.pcs).toFixed(2));
            }
            y_axis_kurtosis_dataset.label = 
                `${y_axis_kurtosis_dataset.label} (${t("avg")}: ${y_axis_kurtosis_dataset.average.toString()} ${t("Highest")}: ${y_axis_kurtosis_dataset.highest ? y_axis_kurtosis_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_kurtosis_dataset.lowest ? y_axis_kurtosis_dataset.lowest.toString() : 0})`; 

            _all_kurtosis_data.datasets.push(y_axis_kurtosis_dataset);

            if (z_axis_kurtosis_dataset.pcs > 0) {
                z_axis_kurtosis_dataset.average = parseFloat((z_axis_kurtosis_dataset.total_sum / z_axis_kurtosis_dataset.pcs).toFixed(2));
            }
            z_axis_kurtosis_dataset.label = 
                `${z_axis_kurtosis_dataset.label} (${t("avg")}: ${z_axis_kurtosis_dataset.average.toString()} ${t("Highest")}: ${z_axis_kurtosis_dataset.highest ? z_axis_kurtosis_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_kurtosis_dataset.lowest ? z_axis_kurtosis_dataset.lowest.toString() : 0})`; 

            _all_kurtosis_data.datasets.push(z_axis_kurtosis_dataset);

            if (x_axis_skewness_dataset.pcs > 0) {
                x_axis_skewness_dataset.average = parseFloat((x_axis_skewness_dataset.total_sum / x_axis_skewness_dataset.pcs).toFixed(2));
            }
            x_axis_skewness_dataset.label = 
                `${x_axis_skewness_dataset.label} (${t("avg")}: ${x_axis_skewness_dataset.average.toString()} ${t("Highest")}: ${x_axis_skewness_dataset.highest ? x_axis_skewness_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_skewness_dataset.lowest ? x_axis_skewness_dataset.lowest.toString() : 0})`; 

            _all_skewness_data.datasets.push(x_axis_skewness_dataset);

            if (y_axis_skewness_dataset.pcs > 0) {
                y_axis_skewness_dataset.average = parseFloat((y_axis_skewness_dataset.total_sum / y_axis_skewness_dataset.pcs).toFixed(2));
            }
            y_axis_skewness_dataset.label = 
                `${y_axis_skewness_dataset.label} (${t("avg")}: ${y_axis_skewness_dataset.average.toString()} ${t("Highest")}: ${y_axis_skewness_dataset.highest ? y_axis_skewness_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_skewness_dataset.lowest ? y_axis_skewness_dataset.lowest.toString() : 0})`; 

            _all_skewness_data.datasets.push(y_axis_skewness_dataset);

            if (z_axis_skewness_dataset.pcs > 0) {
                z_axis_skewness_dataset.average = parseFloat((z_axis_skewness_dataset.total_sum / z_axis_skewness_dataset.pcs).toFixed(2));
            }
            z_axis_skewness_dataset.label = 
                `${z_axis_skewness_dataset.label} (${t("avg")}: ${z_axis_skewness_dataset.average.toString()} ${t("Highest")}: ${z_axis_skewness_dataset.highest ? z_axis_skewness_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_skewness_dataset.lowest ? z_axis_skewness_dataset.lowest.toString() : 0})`; 

            _all_skewness_data.datasets.push(z_axis_skewness_dataset);

            if (x_axis_crest_factor_dataset.pcs > 0) {
                x_axis_crest_factor_dataset.average = parseFloat((x_axis_crest_factor_dataset.total_sum / x_axis_crest_factor_dataset.pcs).toFixed(2));
            }
            x_axis_crest_factor_dataset.label = 
                `${x_axis_crest_factor_dataset.label} (${t("avg")}: ${x_axis_crest_factor_dataset.average.toString()} ${t("Highest")}: ${x_axis_crest_factor_dataset.highest ? x_axis_crest_factor_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_crest_factor_dataset.lowest ? x_axis_crest_factor_dataset.lowest.toString() : 0})`; 

            _all_crest_factor_data.datasets.push(x_axis_crest_factor_dataset);

            if (y_axis_crest_factor_dataset.pcs > 0) {
                y_axis_crest_factor_dataset.average = parseFloat((y_axis_crest_factor_dataset.total_sum / y_axis_crest_factor_dataset.pcs).toFixed(2));
            }
            y_axis_crest_factor_dataset.label = 
                `${y_axis_crest_factor_dataset.label} (${t("avg")}: ${y_axis_crest_factor_dataset.average.toString()} ${t("Highest")}: ${y_axis_crest_factor_dataset.highest ? y_axis_crest_factor_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_crest_factor_dataset.lowest ? y_axis_crest_factor_dataset.lowest.toString() : 0})`; 

            _all_crest_factor_data.datasets.push(y_axis_crest_factor_dataset);

            if (z_axis_crest_factor_dataset.pcs > 0) {
                z_axis_crest_factor_dataset.average = parseFloat((z_axis_crest_factor_dataset.total_sum / z_axis_crest_factor_dataset.pcs).toFixed(2));
            }
            z_axis_crest_factor_dataset.label = 
                `${z_axis_crest_factor_dataset.label} (${t("avg")}: ${z_axis_crest_factor_dataset.average.toString()} ${t("Highest")}: ${z_axis_crest_factor_dataset.highest ? z_axis_crest_factor_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_crest_factor_dataset.lowest ? z_axis_crest_factor_dataset.lowest.toString() : 0})`; 

            _all_crest_factor_data.datasets.push(z_axis_crest_factor_dataset);

            if (x_axis_deviation_dataset.pcs > 0) {
                x_axis_deviation_dataset.average = parseFloat((x_axis_deviation_dataset.total_sum / x_axis_deviation_dataset.pcs).toFixed(2));
            }
            x_axis_deviation_dataset.label = 
                `${x_axis_deviation_dataset.label} (${t("avg")}: ${x_axis_deviation_dataset.average.toString()} ${t("Highest")}: ${x_axis_deviation_dataset.highest ? x_axis_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_deviation_dataset.lowest ? x_axis_deviation_dataset.lowest.toString() : 0})`; 

            _all_deviation_data.datasets.push(x_axis_deviation_dataset);

            if (y_axis_deviation_dataset.pcs > 0) {
                y_axis_deviation_dataset.average = parseFloat((y_axis_deviation_dataset.total_sum / y_axis_deviation_dataset.pcs).toFixed(2));
            }
            y_axis_deviation_dataset.label = 
                `${y_axis_deviation_dataset.label} (${t("avg")}: ${y_axis_deviation_dataset.average.toString()} ${t("Highest")}: ${y_axis_deviation_dataset.highest ? y_axis_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_deviation_dataset.lowest ? y_axis_deviation_dataset.lowest.toString() : 0})`; 

            _all_deviation_data.datasets.push(y_axis_deviation_dataset);

            if (z_axis_deviation_dataset.pcs > 0) {
                z_axis_deviation_dataset.average = parseFloat((z_axis_deviation_dataset.total_sum / z_axis_deviation_dataset.pcs).toFixed(2));
            }
            z_axis_deviation_dataset.label = 
                `${z_axis_deviation_dataset.label} (${t("avg")}: ${z_axis_deviation_dataset.average.toString()} ${t("Highest")}: ${z_axis_deviation_dataset.highest ? z_axis_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_deviation_dataset.lowest ? z_axis_deviation_dataset.lowest.toString() : 0})`; 

            _all_deviation_data.datasets.push(z_axis_deviation_dataset);

            if (x_axis_displacement_dataset.pcs > 0) {
                x_axis_displacement_dataset.average = parseFloat((x_axis_displacement_dataset.total_sum / x_axis_displacement_dataset.pcs).toFixed(2));
            }
            x_axis_displacement_dataset.label = 
                `${x_axis_displacement_dataset.label} (${t("avg")}: ${x_axis_displacement_dataset.average.toString()} ${t("Highest")}: ${x_axis_displacement_dataset.highest ? x_axis_displacement_dataset.highest.toString() : 0} ${t("Lowest")}: ${x_axis_displacement_dataset.lowest ? x_axis_displacement_dataset.lowest.toString() : 0})`; 

            _all_displacement_data.datasets.push(x_axis_displacement_dataset);

            if (y_axis_displacement_dataset.pcs > 0) {
                y_axis_displacement_dataset.average = parseFloat((y_axis_displacement_dataset.total_sum / y_axis_displacement_dataset.pcs).toFixed(2));
            }
            y_axis_displacement_dataset.label = 
                `${y_axis_displacement_dataset.label} (${t("avg")}: ${y_axis_displacement_dataset.average.toString()} ${t("Highest")}: ${y_axis_displacement_dataset.highest ? y_axis_displacement_dataset.highest.toString() : 0} ${t("Lowest")}: ${y_axis_displacement_dataset.lowest ? y_axis_displacement_dataset.lowest.toString() : 0})`; 

            _all_displacement_data.datasets.push(y_axis_displacement_dataset);

            if (z_axis_displacement_dataset.pcs > 0) {
                z_axis_displacement_dataset.average = parseFloat((z_axis_displacement_dataset.total_sum / z_axis_displacement_dataset.pcs).toFixed(2));
            }
            z_axis_displacement_dataset.label = 
                `${z_axis_displacement_dataset.label} (${t("avg")}: ${z_axis_displacement_dataset.average.toString()} ${t("Highest")}: ${z_axis_displacement_dataset.highest ? z_axis_displacement_dataset.highest.toString() : 0} ${t("Lowest")}: ${z_axis_displacement_dataset.lowest ? z_axis_displacement_dataset.lowest.toString() : 0})`; 

            _all_displacement_data.datasets.push(z_axis_displacement_dataset);

            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 
            _battery_data.datasets.push(battery_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 
            _temperature_data.datasets.push(temperature_dataset);

            // x_axis_velocity_RMS_data.datasets.push(x_axis_velocity_RMS_dataset);
            // y_axis_velocity_RMS_data.datasets.push(y_axis_velocity_RMS_dataset);
            // z_axis_velocity_RMS_data.datasets.push(z_axis_velocity_RMS_dataset);

            this.setState({
                // x_axis_velocity_RMS_data: x_axis_velocity_RMS_data,
                // y_axis_velocity_RMS_data: y_axis_velocity_RMS_data,
                // z_axis_velocity_RMS_data: z_axis_velocity_RMS_data,
                all_velocity_RMS_data: _all_velocity_RMS_data,
                all_acceleration_RMS_data: _all_acceleration_RMS_data,
                all_acceleration_peak_data: _all_acceleration_peak_data,
                all_kurtosis_data: _all_kurtosis_data,
                all_skewness_data: _all_skewness_data,
                all_crest_factor_data: _all_crest_factor_data,
                all_deviation_data: _all_deviation_data,
                all_displacement_data: _all_displacement_data,
                temperature_data: _temperature_data,
                battery_data: _battery_data,
                loading: false
            })
        } else if (sensor.model_name === "DECENTLAB-DL-MBX") {
            let { battery_data, distance_data, filling_volume_data, filling_percentage_data,  distance_deviation_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            distance_data.labels = [];
            distance_data.datasets = [];

            distance_deviation_data.labels = [];
            distance_deviation_data.datasets = [];

            filling_volume_data.labels = [];
            filling_volume_data.datasets = [];

            filling_percentage_data.labels = [];
            filling_percentage_data.datasets = [];

            // Distance
            let distance_dataset = {
                ...default_dataset_blue,
                label: t("Distance (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling volume
            let filling_volume_dataset = {
                ...default_dataset_blue,
                label: t("Filling volume (l)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling percentage
            let filling_percentage_dataset = {
                ...default_dataset_blue,
                label: t("Filling percentage (%)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Distance deviation
            let distance_deviation_dataset = {
                ...default_dataset_blue,
                label: t("Distance deviation (mm)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Distance
                if (report[i].group === "Distance") {
                    distance_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_dataset.data.push(parseFloat(report[i].value));
                    if (distance_dataset.highest === null || (parseFloat(distance_dataset.highest) < parseFloat(report[i].value))) {
                        distance_dataset.highest = parseFloat(report[i].value);
                    }
                    if (distance_dataset.lowest === null || (parseFloat(distance_dataset.lowest) > parseFloat(report[i].value))) {
                        distance_dataset.lowest = parseFloat(report[i].value);
                    }
                    distance_dataset.total_sum += parseFloat(report[i].value);
                    distance_dataset.pcs += 1;

                    // distance_deviation_dataset.data.push(parseFloat(report[i].deviation));
                    // if (distance_deviation_dataset.highest === null || (parseFloat(distance_deviation_dataset.highest) < parseFloat(report[i].deviation))) {
                    //     distance_deviation_dataset.highest = parseFloat(report[i].deviation);
                    // }
                    // if (distance_deviation_dataset.lowest === null || (parseFloat(distance_deviation_dataset.lowest) > parseFloat(report[i].deviation))) {
                    //     distance_deviation_dataset.lowest = parseFloat(report[i].deviation);
                    // }
                    // distance_deviation_dataset.total_sum += parseFloat(report[i].deviation);
                    // distance_deviation_dataset.pcs += 1;
                    // this.setState({
                    //     distance_text: t(report[i].display_name),
                    //     distance_deviation_text: t("Distance deviation (mm)")
                    // });
                // Filling volume
                } else if (report[i].group === "Report" && report[i].name === "fillingVolume") {
                    filling_volume_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_volume_dataset.data.push(parseFloat(report[i].value));
                    if (filling_volume_dataset.highest === null || (parseFloat(filling_volume_dataset.highest) < parseFloat(report[i].value))) {
                        filling_volume_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_volume_dataset.lowest === null || (parseFloat(filling_volume_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_volume_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_volume_dataset.total_sum += parseFloat(report[i].value);
                    filling_volume_dataset.pcs += 1;
                    this.setState({
                        filling_volume_text: t(report[i].display_name)
                    });
                // Filling percentage
                } else if (report[i].group === "Report" && report[i].name === "fillingPercentage") {
                    filling_percentage_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_percentage_dataset.data.push(parseFloat(report[i].value));
                    if (filling_percentage_dataset.highest === null || (parseFloat(filling_percentage_dataset.highest) < parseFloat(report[i].value))) {
                        filling_percentage_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_percentage_dataset.lowest === null || (parseFloat(filling_percentage_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_percentage_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_percentage_dataset.total_sum += parseFloat(report[i].value);
                    filling_percentage_dataset.pcs += 1;
                    this.setState({
                        filling_percentage_text: t(report[i].display_name)
                    });
                // Deviation
                } else if (report[i].group === "Report" && report[i].name === "deviation") {
                    distance_deviation_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    distance_deviation_dataset.data.push(parseFloat(report[i].value));
                    if (distance_deviation_dataset.highest === null || (parseFloat(distance_deviation_dataset.highest) < parseFloat(report[i].value))) {
                        distance_deviation_dataset.highest = parseFloat(report[i].value);
                    }
                    if (distance_deviation_dataset.lowest === null || (parseFloat(distance_deviation_dataset.lowest) > parseFloat(report[i].value))) {
                        distance_deviation_dataset.lowest = parseFloat(report[i].value);
                    }
                    distance_deviation_dataset.total_sum += parseFloat(report[i].value);
                    distance_deviation_dataset.pcs += 1;
                    this.setState({
                        distance_deviation_text: t(report[i].display_name)
                    });
                // Battery Voltage
                } else if (report[i].group === "Battery voltage") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            if (distance_dataset.pcs > 0) {
                distance_dataset.average = parseFloat((distance_dataset.total_sum / distance_dataset.pcs).toFixed(2));
            }
            distance_dataset.label = 
                `${distance_dataset.label} (${t("avg")}: ${distance_dataset.average.toString()} ${t("Highest")}: ${distance_dataset.highest ? distance_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_dataset.lowest ? distance_dataset.lowest.toString() : 0})`; 

            distance_data.datasets.push(distance_dataset);

            if (filling_volume_dataset.pcs > 0) {
                filling_volume_dataset.average = parseFloat((filling_volume_dataset.total_sum / filling_volume_dataset.pcs).toFixed(2));
            }
            filling_volume_dataset.label = 
                `${filling_volume_dataset.label} (${t("avg")}: ${filling_volume_dataset.average.toString()} ${t("Highest")}: ${filling_volume_dataset.highest ? filling_volume_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_volume_dataset.lowest ? filling_volume_dataset.lowest.toString() : 0})`; 

            filling_volume_data.datasets.push(filling_volume_dataset);

            if (filling_percentage_dataset.pcs > 0) {
                filling_percentage_dataset.average = parseFloat((filling_percentage_dataset.total_sum / filling_percentage_dataset.pcs).toFixed(2));
            }
            filling_percentage_dataset.label = 
                `${filling_percentage_dataset.label} (${t("avg")}: ${filling_percentage_dataset.average.toString()} ${t("Highest")}: ${filling_percentage_dataset.highest ? filling_percentage_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_percentage_dataset.lowest ? filling_percentage_dataset.lowest.toString() : 0})`; 

            filling_percentage_data.datasets.push(filling_percentage_dataset);

            if (distance_deviation_dataset.pcs > 0) {
                distance_deviation_dataset.average = parseFloat((distance_deviation_dataset.total_sum / distance_deviation_dataset.pcs).toFixed(2));
            }
            distance_deviation_dataset.label = 
                `${distance_deviation_dataset.label} (${t("avg")}: ${distance_deviation_dataset.average.toString()} ${t("Highest")}: ${distance_deviation_dataset.highest ? distance_deviation_dataset.highest.toString() : 0} ${t("Lowest")}: ${distance_deviation_dataset.lowest ? distance_deviation_dataset.lowest.toString() : 0})`; 

            distance_deviation_data.datasets.push(distance_deviation_dataset);

            this.setState({
                battery_data: battery_data,
                distance_data: distance_data,
                distance_deviation_data: distance_deviation_data,
                filling_volume_data: filling_volume_data,
                filling_percentage_data: filling_percentage_data,
                loading: false
            })
        } else if (sensor.model_name === "DIGITAL-MATTER-YABBY") {
            let { battery_data, in_trip_data, speed_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            in_trip_data.labels = [];
            in_trip_data.datasets = [];

            speed_data.labels = [];
            speed_data.datasets = [];

            // in_trip
            let in_trip_dataset = {
                ...default_dataset_blue,
                label: t("In trip"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Distance deviation
            let speed_dataset = {
                ...default_dataset_red,
                label: t("Speed (km/h)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // In trip
                if (report[i].name === "inTrip") {
                    in_trip_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    in_trip_dataset.data.push(report[i].value === "True" ? 1 : 0);
                    this.setState({
                        in_trip_text: t(report[i].display_name),
                    });
                // Speed
                } else if (report[i].name === "speedKmph") {
                    speed_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    speed_dataset.data.push(parseFloat(report[i].value));
                    if (speed_dataset.highest === null || (parseFloat(speed_dataset.highest) < parseFloat(report[i].value))) {
                        speed_dataset.highest = parseFloat(report[i].value);
                    }
                    if (speed_dataset.lowest === null || (parseFloat(speed_dataset.lowest) > parseFloat(report[i].value))) {
                        speed_dataset.lowest = parseFloat(report[i].value);
                    }
                    speed_dataset.total_sum += parseFloat(report[i].value);
                    speed_dataset.pcs += 1;

                    this.setState({
                        speed_text: t(report[i].display_name),
                    });
                
                // Battery Voltage
                } else if (report[i].name === "batV") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            in_trip_data.datasets.push(in_trip_dataset);

            if (speed_dataset.pcs > 0) {
                speed_dataset.average = parseFloat((speed_dataset.total_sum / speed_dataset.pcs).toFixed(2));
            }
            speed_dataset.label = 
                `${speed_dataset.label} (${t("avg")}: ${speed_dataset.average.toString()} ${t("Highest")}: ${speed_dataset.highest ? speed_dataset.highest.toString() : 0} ${t("Lowest")}: ${speed_dataset.lowest ? speed_dataset.lowest.toString() : 0})`; 

            speed_data.datasets.push(speed_dataset);
            this.setState({
                battery_data: battery_data,
                speed_data: speed_data,
                in_trip_data: in_trip_data,
                loading: false
            })
        } else if (sensor.model_name === "ELSYS-ERS") {
            let { battery_data, humidity_data, temperature_data, light_data, motion_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            humidity_data.labels = [];
            humidity_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            light_data.labels = [];
            light_data.datasets = [];

            motion_data.labels = [];
            motion_data.datasets = [];


            // Humidity
            let humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity (%Rh)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Light
            let light_dataset = {
                ...default_dataset_blue,
                label: t("Light (Lux)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Motion
            let motion_dataset = {
                ...default_dataset_red,
                label: t("Motion (pcs)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature (°C)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                // Humidity
                } else if (report[i].name === "humidity") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;

                    this.setState({
                        humidity_text: t(report[i].display_name),
                    });
                // Light
                } else if (report[i].name === "light") {
                        light_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                        light_dataset.data.push(parseFloat(report[i].value));
                        if (light_dataset.highest === null || (parseFloat(light_dataset.highest) < parseFloat(report[i].value))) {
                            light_dataset.highest = parseFloat(report[i].value);
                        }
                        if (light_dataset.lowest === null || (parseFloat(light_dataset.lowest) > parseFloat(report[i].value))) {
                            light_dataset.lowest = parseFloat(report[i].value);
                        }
                        light_dataset.total_sum += parseFloat(report[i].value);
                        light_dataset.pcs += 1;
    
                        this.setState({
                            light_text: t(report[i].display_name),
                        });
                // Motion
                } else if (report[i].name === "motion") {
                    motion_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    motion_dataset.data.push(parseFloat(report[i].value));
                    if (motion_dataset.highest === null || (parseFloat(motion_dataset.highest) < parseFloat(report[i].value))) {
                        motion_dataset.highest = parseFloat(report[i].value);
                    }
                    if (motion_dataset.lowest === null || (parseFloat(motion_dataset.lowest) > parseFloat(report[i].value))) {
                        motion_dataset.lowest = parseFloat(report[i].value);
                    }
                    motion_dataset.total_sum += parseFloat(report[i].value);
                    motion_dataset.pcs += 1;

                    this.setState({
                        motion_text: t(report[i].display_name),
                    });
                // Battery Voltage
                } else if (report[i].name === "vdd") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);

            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);

            if (light_dataset.pcs > 0) {
                light_dataset.average = parseFloat((light_dataset.total_sum / light_dataset.pcs).toFixed(2));
            }
            light_dataset.label = 
                `${light_dataset.label} (${t("avg")}: ${light_dataset.average.toString()} ${t("Highest")}: ${light_dataset.highest ? light_dataset.highest.toString() : 0} ${t("Lowest")}: ${light_dataset.lowest ? light_dataset.lowest.toString() : 0})`; 

            light_data.datasets.push(light_dataset);

            if (motion_dataset.pcs > 0) {
                motion_dataset.average = parseFloat((motion_dataset.total_sum / motion_dataset.pcs).toFixed(2));
            }
            motion_dataset.label = 
                `${motion_dataset.label} (${t("avg")}: ${motion_dataset.average.toString()} ${t("Highest")}: ${motion_dataset.highest ? motion_dataset.highest.toString() : 0} ${t("Lowest")}: ${motion_dataset.lowest ? motion_dataset.lowest.toString() : 0})`; 

            motion_data.datasets.push(motion_dataset);

            this.setState({
                battery_data: battery_data,
                humidity_data: humidity_data,
                temperature_data: temperature_data,
                light_data: light_data,
                motion_data: motion_data,
                loading: false
            })
        } else if (sensor.model_name === "ELSYS-EMS") {
            let { battery_data, humidity_data, temperature_data, door_closed_data, motion_data, waterleak_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            humidity_data.labels = [];
            humidity_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            door_closed_data.labels = [];
            door_closed_data.datasets = [];

            motion_data.labels = [];
            motion_data.datasets = [];

            waterleak_data.labels = [];
            waterleak_data.datasets = [];

            // Humidity
            let humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity (%Rh)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Door closed
            let door_closed_dataset = {
                ...default_dataset_blue,
                label: t("Door closed"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Waterleak
            let waterleak_dataset = {
                ...default_dataset_blue,
                label: t("Waterleak"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Motion
            let motion_dataset = {
                ...default_dataset_red,
                label: t("Motion (Acceleration movements)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature (°C)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                // Humidity
                } else if (report[i].name === "humidity") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;

                    this.setState({
                        humidity_text: t(report[i].display_name),
                    });
                // Door closed
                } else if (report[i].name === "digital") {
                    door_closed_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    door_closed_dataset.data.push(report[i].value === "True" ? 1 : 0);
                    this.setState({
                        in_trip_text: t(report[i].display_name),
                    });
                // Waterleak
                } else if (report[i].name === "waterleak") {
                    waterleak_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    waterleak_dataset.data.push(report[i].value === "True" ? 1 : 0);
                    this.setState({
                        waterleak_text: t(report[i].display_name),
                    });
                // Motion
                } else if (report[i].name === "accMotion") {
                    motion_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    motion_dataset.data.push(parseFloat(report[i].value));
                    if (motion_dataset.highest === null || (parseFloat(motion_dataset.highest) < parseFloat(report[i].value))) {
                        motion_dataset.highest = parseFloat(report[i].value);
                    }
                    if (motion_dataset.lowest === null || (parseFloat(motion_dataset.lowest) > parseFloat(report[i].value))) {
                        motion_dataset.lowest = parseFloat(report[i].value);
                    }
                    motion_dataset.total_sum += parseFloat(report[i].value);
                    motion_dataset.pcs += 1;

                    this.setState({
                        motion_text: t(report[i].display_name),
                    });
                // Battery Voltage
                } else if (report[i].name === "vdd") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);

            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);

            if (motion_dataset.pcs > 0) {
                motion_dataset.average = parseFloat((motion_dataset.total_sum / motion_dataset.pcs).toFixed(2));
            }
            motion_dataset.label = 
                `${motion_dataset.label} (${t("avg")}: ${motion_dataset.average.toString()} ${t("Highest")}: ${motion_dataset.highest ? motion_dataset.highest.toString() : 0} ${t("Lowest")}: ${motion_dataset.lowest ? motion_dataset.lowest.toString() : 0})`; 

            motion_data.datasets.push(motion_dataset);

            door_closed_data.datasets.push(door_closed_dataset);

            waterleak_data.datasets.push(waterleak_dataset);

            this.setState({
                battery_data: battery_data,
                humidity_data: humidity_data,
                temperature_data: temperature_data,
                door_closed_data: door_closed_data,
                waterleak_data: waterleak_data,
                motion_data: motion_data,
                loading: false
            })
        } else if (sensor.model_name === "ELSYS-ELT-2") {
            let { battery_data, humidity_data, temperature_data, pressure_data, waterleak_data } = this.state;

            battery_data.labels = [];
            battery_data.datasets = [];

            humidity_data.labels = [];
            humidity_data.datasets = [];

            temperature_data.labels = [];
            temperature_data.datasets = [];

            pressure_data.labels = [];
            pressure_data.datasets = [];

            waterleak_data.labels = [];
            waterleak_data.datasets = [];

            // Humidity
            let humidity_dataset = {
                ...default_dataset_blue,
                label: t("Humidity (%Rh)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Pressure
            let pressure_dataset = {
                ...default_dataset_blue,
                label: t("Air pressure (hPa)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Waterleak
            let waterleak_dataset = {
                ...default_dataset_blue,
                label: t("Waterleak"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Temperature
            let temperature_dataset = {
                ...default_dataset_green,
                label: t("Temperature (°C)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Battery
            let battery_dataset = {
                ...default_dataset_green,
                label: t("Battery Voltage (V)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Temperature
                if (report[i].name === "temperature") {
                    temperature_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    temperature_dataset.data.push(parseFloat(report[i].value));
                    if (temperature_dataset.highest === null || (parseFloat(temperature_dataset.highest) < parseFloat(report[i].value))) {
                        temperature_dataset.highest = parseFloat(report[i].value);
                    }
                    if (temperature_dataset.lowest === null || (parseFloat(temperature_dataset.lowest) > parseFloat(report[i].value))) {
                        temperature_dataset.lowest = parseFloat(report[i].value);
                    }
                    temperature_dataset.total_sum += parseFloat(report[i].value);
                    temperature_dataset.pcs += 1;

                    this.setState({
                        temperature_text: t(report[i].display_name),
                    });
                // Humidity
                } else if (report[i].name === "humidity") {
                    humidity_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    humidity_dataset.data.push(parseFloat(report[i].value));
                    if (humidity_dataset.highest === null || (parseFloat(humidity_dataset.highest) < parseFloat(report[i].value))) {
                        humidity_dataset.highest = parseFloat(report[i].value);
                    }
                    if (humidity_dataset.lowest === null || (parseFloat(humidity_dataset.lowest) > parseFloat(report[i].value))) {
                        humidity_dataset.lowest = parseFloat(report[i].value);
                    }
                    humidity_dataset.total_sum += parseFloat(report[i].value);
                    humidity_dataset.pcs += 1;

                    this.setState({
                        humidity_text: t(report[i].display_name),
                    });
                // Pressure
                } else if (report[i].name === "pressure") {
                    pressure_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    pressure_dataset.data.push(parseFloat(report[i].value));
                    if (pressure_dataset.highest === null || (parseFloat(pressure_dataset.highest) < parseFloat(report[i].value))) {
                        pressure_dataset.highest = parseFloat(report[i].value);
                    }
                    if (pressure_dataset.lowest === null || (parseFloat(pressure_dataset.lowest) > parseFloat(report[i].value))) {
                        pressure_dataset.lowest = parseFloat(report[i].value);
                    }
                    pressure_dataset.total_sum += parseFloat(report[i].value);
                    pressure_dataset.pcs += 1;

                    this.setState({
                        pressure_text: t(report[i].display_name),
                    });
                // Waterleak
                } else if (report[i].name === "waterleak") {
                    waterleak_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    waterleak_dataset.data.push(report[i].value === "True" ? 1 : 0);
                    this.setState({
                        waterleak_text: t(report[i].display_name),
                    });
                // Battery Voltage
                } else if (report[i].name === "vdd") {
                    battery_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    battery_dataset.data.push(parseFloat(report[i].value));
                    if (battery_dataset.highest === null || (parseFloat(battery_dataset.highest) < parseFloat(report[i].value))) {
                        battery_dataset.highest = parseFloat(report[i].value);
                    }
                    if (battery_dataset.lowest === null || (parseFloat(battery_dataset.lowest) > parseFloat(report[i].value))) {
                        battery_dataset.lowest = parseFloat(report[i].value);
                    }
                    battery_dataset.total_sum += parseFloat(report[i].value);
                    battery_dataset.pcs += 1;
                    this.setState({
                        battery_text: t(report[i].display_name)
                    });
                }
            }
            if (battery_dataset.pcs > 0) {
                battery_dataset.average = parseFloat((battery_dataset.total_sum / battery_dataset.pcs).toFixed(2));
            }
            battery_dataset.label = 
                `${battery_dataset.label} (${t("avg")}: ${battery_dataset.average.toString()} ${t("Highest")}: ${battery_dataset.highest ? battery_dataset.highest.toString() : 0} ${t("Lowest")}: ${battery_dataset.lowest ? battery_dataset.lowest.toString() : 0})`; 

            battery_data.datasets.push(battery_dataset);

            if (temperature_dataset.pcs > 0) {
                temperature_dataset.average = parseFloat((temperature_dataset.total_sum / temperature_dataset.pcs).toFixed(2));
            }
            temperature_dataset.label = 
                `${temperature_dataset.label} (${t("avg")}: ${temperature_dataset.average.toString()} ${t("Highest")}: ${temperature_dataset.highest ? temperature_dataset.highest.toString() : 0} ${t("Lowest")}: ${temperature_dataset.lowest ? temperature_dataset.lowest.toString() : 0})`; 

            temperature_data.datasets.push(temperature_dataset);

            if (humidity_dataset.pcs > 0) {
                humidity_dataset.average = parseFloat((humidity_dataset.total_sum / humidity_dataset.pcs).toFixed(2));
            }
            humidity_dataset.label = 
                `${humidity_dataset.label} (${t("avg")}: ${humidity_dataset.average.toString()} ${t("Highest")}: ${humidity_dataset.highest ? humidity_dataset.highest.toString() : 0} ${t("Lowest")}: ${humidity_dataset.lowest ? humidity_dataset.lowest.toString() : 0})`; 

            humidity_data.datasets.push(humidity_dataset);

            if (pressure_dataset.pcs > 0) {
                pressure_dataset.average = parseFloat((pressure_dataset.total_sum / pressure_dataset.pcs).toFixed(2));
            }
            pressure_dataset.label = 
                `${pressure_dataset.label} (${t("avg")}: ${pressure_dataset.average.toString()} ${t("Highest")}: ${pressure_dataset.highest ? pressure_dataset.highest.toString() : 0} ${t("Lowest")}: ${pressure_dataset.lowest ? pressure_dataset.lowest.toString() : 0})`; 

            pressure_data.datasets.push(pressure_dataset);

            waterleak_data.datasets.push(waterleak_dataset);

            this.setState({
                battery_data: battery_data,
                humidity_data: humidity_data,
                temperature_data: temperature_data,
                pressure_data: pressure_data,
                waterleak_data: waterleak_data,
                loading: false
            })
        } else if (sensor.model_name.startsWith("WASTECONTROL")) {
            let {filling_volume_data, filling_percentage_data } = this.state;

            filling_volume_data.labels = [];
            filling_volume_data.datasets = [];

            filling_percentage_data.labels = [];
            filling_percentage_data.datasets = [];

            // Filling volume
            let filling_volume_dataset = {
                ...default_dataset_blue,
                label: t("Filling volume (l)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };
            // Filling percentage
            let filling_percentage_dataset = {
                ...default_dataset_blue,
                label: t("Filling percentage (%)"),
                average: 0,
                total_sum: 0,
                pcs: 0,
                highest: null,
                lowest: null,
                data: []
            };

            for (let i = 0; i < report.length; i++) {
                let event_time = new Date(report[i].event_time);

                // Filling percentage
                if (report[i].name === "waste_distance") {
                    filling_percentage_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_percentage_dataset.data.push(parseFloat(report[i].value));
                    if (filling_percentage_dataset.highest === null || (parseFloat(filling_percentage_dataset.highest) < parseFloat(report[i].value))) {
                        filling_percentage_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_percentage_dataset.lowest === null || (parseFloat(filling_percentage_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_percentage_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_percentage_dataset.total_sum += parseFloat(report[i].value);
                    filling_percentage_dataset.pcs += 1;

                    this.setState({
                        filling_percentage_text: t(report[i].display_name),
                    });
                // Filling volume
                } else if (report[i].name === "fillingVolume") {
                    filling_volume_data.labels.push(new Date(event_time).toLocaleString('fi-FI'))
                    filling_volume_dataset.data.push(parseFloat(report[i].value));
                    if (filling_volume_dataset.highest === null || (parseFloat(filling_volume_dataset.highest) < parseFloat(report[i].value))) {
                        filling_volume_dataset.highest = parseFloat(report[i].value);
                    }
                    if (filling_volume_dataset.lowest === null || (parseFloat(filling_volume_dataset.lowest) > parseFloat(report[i].value))) {
                        filling_volume_dataset.lowest = parseFloat(report[i].value);
                    }
                    filling_volume_dataset.total_sum += parseFloat(report[i].value);
                    filling_volume_dataset.pcs += 1;

                    this.setState({
                        filling_volume_text: t(report[i].display_name),
                    });
                } 
            }
            if (filling_percentage_dataset.pcs > 0) {
                filling_percentage_dataset.average = parseFloat((filling_percentage_dataset.total_sum / filling_percentage_dataset.pcs).toFixed(2));
            }
            filling_percentage_dataset.label = 
                `${filling_percentage_dataset.label} (${t("avg")}: ${filling_percentage_dataset.average.toString()} ${t("Highest")}: ${filling_percentage_dataset.highest ? filling_percentage_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_percentage_dataset.lowest ? filling_percentage_dataset.lowest.toString() : 0})`; 

                filling_percentage_data.datasets.push(filling_percentage_dataset);

            if (filling_volume_dataset.pcs > 0) {
                filling_volume_dataset.average = parseFloat((filling_volume_dataset.total_sum / filling_volume_dataset.pcs).toFixed(2));
            }
            filling_volume_dataset.label = 
                `${filling_volume_dataset.label} (${t("avg")}: ${filling_volume_dataset.average.toString()} ${t("Highest")}: ${filling_volume_dataset.highest ? filling_volume_dataset.highest.toString() : 0} ${t("Lowest")}: ${filling_volume_dataset.lowest ? filling_volume_dataset.lowest.toString() : 0})`; 

                filling_volume_data.datasets.push(filling_volume_dataset);

            this.setState({
                filling_percentage_data: filling_percentage_data,
                filling_volume_data: filling_volume_data,
                loading: false
            })
        }

        this.setState({
            loading: false
        });
    };

    handleStartDateChange = value => {
        if (value !== null) {
            value.setHours(0);
            value.setMinutes(0);
            value.setSeconds(0);
            this.setState({
                search_start_date: getISOFormattedDate(value, "Date"),
                // fetch: true
            });
        }
    };

    handleEndDateChange = value => {
        if (value !== null) {
            // Get also values from current search end date, so move end date timestamp to next mid night.
            value.setDate(value.getDate() + 1);
            value.setHours(0);
            value.setMinutes(0);
            value.setSeconds(0);
            this.setState({
                search_end_date: getISOFormattedDate(value, "Date"),
                // fetch: true
            });
        }
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            // fetch: true
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Seq"),
                t("Time"),
                t("Group"),
                t("Sub Group"),
                t("Variable name"),
                t("Name"),
                t("Value")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    sequence_number: items[i].sequence_number,
                    event_time: new Date(items[i].event_time).toLocaleString('fi-FI'),
                    group: items[i].group,
                    sub_group: items[i].sub_group,
                    name: items[i].name,
                    display_name: items[i].display_name,
                    value: items[i].value.replace(".", ",")
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    handleRefresh = () => {
        this.setState({
            fetch: true
        });
    };

    getFillingVolume = (sensor) => {
        let i = 0;
        for (i in sensor.sensorstatusvariables) {
            if (sensor.model_name === "DECENTLAB-DL-MBX" && sensor.sensorstatusvariables[i].name === "fillingVolume") {
                let x = 0;
                for (x in sensor.sensorstatusvariables[i].sensorstatusvariableparameters) {
                    if (sensor.sensorstatusvariables[i].sensorstatusvariableparameters[x].name === "volume-full") {
                        return parseInt(sensor.sensorstatusvariables[i].sensorstatusvariableparameters[x].value);
                    }
                }
            } else if (sensor.model_name.startsWith("WASTECONTROL") && sensor.sensorstatusvariables[i].name === "size") {
                return parseInt(sensor.sensorstatusvariables[i].last_value);
            }
        }
    };

    getFillingVolumeMeasureUnit = (sensor) => {
        let i = 0;
        for (i in sensor.sensorstatusvariables) {
            if (sensor.model_name === "DECENTLAB-DL-MBX" && sensor.sensorstatusvariables[i].name === "fillingVolume") {
                return sensor.sensorstatusvariables[i].measurement_unit;
            } else if (sensor.model_name.startsWith("WASTECONTROL") && sensor.sensorstatusvariables[i].name === "size") {
                return sensor.sensorstatusvariables[i].measurement_unit;
            }
        }
    };

    main = () => {
        const { error, loading, report, search, search_end_date, search_start_date, variable_choices, variable_options, sensor,
            activeMenuItem, offset, limit } = this.state;
        const { t } = this.props;
        const options_velocity = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "mm/s"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_acceleration = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "g"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_no_measure_unit = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_displacement = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "μm"
                        } 
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_temperature = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: this.state.temperature_text
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }

        const options_battery = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "V"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }

        const options_distance = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "mm"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_speed = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "km/h"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_pcs = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "kpl"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_light = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "lux"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_humidity = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "%RH"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_volume_litre = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "l"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_percent = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "%"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_filling_percent = {
            scales: {
                yAxes: [
                    {                        
                        ticks: {
                            min: 0,
                            max: 100
                        },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "%"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_filling_volume = {
            scales: {
                yAxes: [
                    {                        
                        ticks: {
                            min: 0,
                            max: sensor ? this.getFillingVolume(sensor) : 0
                        },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: sensor ? this.getFillingVolumeMeasureUnit(sensor) : ""
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        const options_air_pressure = {
            scales: {
                yAxes: [
                    {                        
                        // ticks: {
                        //     min: 0
                        // },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: "hPa"
                        }            
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }

        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;

        let filtered_items = [];

        // Filter data for search
        if (report && report.length > 0) {
            filtered_items = report.filter((data) => {
                return data.group.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.sub_group.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.display_name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.sequence_number.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
        }

        return (
            <React.Fragment>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(filtered_items)} />
                    </Button.Group>
                )}
                
                <Header as="h3">{t("Sensor report")}</Header>
                {is_desktop ? (
                    <Grid columns="2">
                        <Grid.Column width={9}>
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                label="->"
                                locale={fiLocale}
                                placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleEndDateChange(selected)}
                                format='DD.MM.YYYY' />

                        </Grid.Column>
                        {/* {(activeMenuItem === t("Table")) ? ( */}
                            {/* <Grid.Column floated="right" width={6} textAlign="right">
                                <Input
                                    size="mini"
                                    icon='search'
                                    value={search}
                                    name="search"
                                    onChange={this.handleChange} />
                                
                            </Grid.Column> */}
                        {/* ) : ( */}
                            <Grid.Column floated="right" width={6} textAlign="right">
                                <label>{t("Variables")}: </label>
                                <Select
                                    multiple
                                    search
                                    name="variable_choices"
                                    options={this.state.variable_options}
                                    value={this.state.variable_choices}
                                    placeholder={this.state.variable_choices.length === 0 ? (t("All")) : (this.state.variable_choices)}
                                    onChange={this.handleSelectChange} />
                            </Grid.Column>
                        {/* )} */}
                    </Grid>
                ) : (
                    <React.Fragment>
                        <SemanticDatepicker
                                firstDayOfWeek="1"
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleEndDateChange(selected)}
                            format='DD.MM.YYYY' />
                        <br/>
                        {(activeMenuItem === t("Table")) ? (
                            <>
                                <br/>
                                {/* <Input
                                    fluid
                                    icon='search'
                                    value={search}
                                    name="search"
                                    onChange={this.handleChange} /> */}
                            </>
                        ) : (
                            <>
                                <label>{t("Chart choice")}: </label>
                                <Select
                                    fluid
                                    multiple
                                    search
                                    name="variable_choices"
                                    options={this.state.variable_options}
                                    value={this.state.variable_choices}
                                    placeholder={this.state.variable_choices.length === 0 ? (t("All")) : (this.state.variable_choices)}
                                    onChange={this.handleSelectChange} />
                            </>
                        )}
                    </React.Fragment>
                )}
                <br/>
                <Button icon='refresh' floated="right" color="green" label={t("Refresh")} onClick={() => this.handleRefresh()}/>
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {(report && sensor) && (
                    <React.Fragment>
                        <Menu attached="top" tabular>
                            <Menu.Item
                                name={t("Table")}
                                active={activeMenuItem === t("Table")}
                                onClick={this.handleMenuItemClick} />
                            <Menu.Item
                                name={t("Chart")}
                                active={activeMenuItem === t("Chart")}
                                onClick={this.handleMenuItemClick} />
                        </Menu>
                        {(activeMenuItem === t("Table")) ? (
                            <Table striped selectable celled>
                                {is_desktop && (
                                    <Table.Header >
                                        <Table.Row textAlign="right">
                                            {sensor.model_name === "WISE-2410-EA" && (
                                                <Table.HeaderCell textAlign="left">{t("Seq")}</Table.HeaderCell>
                                            )}
                                            <Table.HeaderCell>{t("Time")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Group")}</Table.HeaderCell>
                                            {sensor.model_name === "WISE-2410-EA" && (
                                                <Table.HeaderCell>{t("Sub Group")}</Table.HeaderCell>
                                            )}
                                            <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Value")}</Table.HeaderCell>
                                            {sensor.model_name === "DECENTLAB-DL-MBX" && (
                                                <Table.HeaderCell>{t("Deviation")}</Table.HeaderCell>
                                            )}
                                        </Table.Row>
                                    </Table.Header>
                                )}
                                <Table.Body>
                                    {filtered_items.slice(0, offset + limit).map((data, key) => {
                                        return (
                                            <Table.Row key={key} textAlign="right" >
                                                {sensor.model_name === "WISE-2410-EA" && (
                                                    <Table.Cell>
                                                        {!is_desktop && (<React.Fragment><b>{t("Seq")}</b><br/></React.Fragment>)}
                                                        {data.sequence_number}
                                                    </Table.Cell>
                                                )}
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Time")}</b><br/></React.Fragment>)}
                                                    {new Date(data.event_time).toLocaleString('fi-FI')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Group")}</b><br/></React.Fragment>)}
                                                    {t(data.group)}
                                                </Table.Cell>
                                                {sensor.model_name === "WISE-2410-EA" && (
                                                    <Table.Cell>
                                                        {!is_desktop && (<React.Fragment><b>{t("Sub Group")}</b><br/></React.Fragment>)}
                                                        {t(data.sub_group)}
                                                    </Table.Cell>
                                                )}
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Name")}</b><br/></React.Fragment>)}
                                                    {t(data.display_name)}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Value")}</b><br/></React.Fragment>)}
                                                    {(data.value === "True" || data.value === "False") ? (data.value === "True" ? t("Yes") : t("No")) : data.value}
                                                </Table.Cell>
                                                {sensor.model_name === "DECENTLAB-DL-MBX" && (
                                                    <Table.Cell>
                                                        {!is_desktop && (<React.Fragment><b>{t("Deviation")}</b><br/></React.Fragment>)}
                                                        {data.deviation}
                                                    </Table.Cell>
                                                )}
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        ) : (
                            <React.Fragment>
                                {(sensor.model_name === "WISE-2410-EA" && (variable_choices.length === 0 || variable_choices.includes("OAVelocity")) && variable_options.findIndex((variable) => variable.key === "OAVelocity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Velocity RMS (mm/s)")}</Header>
                                        <Line data={this.state.all_velocity_RMS_data} options={options_velocity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && (variable_choices.length === 0 || variable_choices.includes("RMSmg")) && variable_options.findIndex((variable) => variable.key === "RMSmg") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Acceleration RMS (g)")}</Header>
                                        <Line data={this.state.all_acceleration_RMS_data} options={options_acceleration}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && (variable_choices.length === 0 || variable_choices.includes("Peakmg")) && variable_options.findIndex((variable) => variable.key === "Peakmg") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Acceleration Peak (g)")}</Header>
                                        <Line data={this.state.all_acceleration_peak_data} options={options_acceleration}/>
                                    </React.Fragment>
                                )}

                                {(sensor.model_name === "WISE-2410-EA" && ((variable_choices.length === 0 && this.state.all_kurtosis_data.labels && this.state.all_kurtosis_data.labels.length > 0) || variable_choices.includes("Kurtosis")) && variable_options.findIndex((variable) => variable.key === "Kurtosis") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Kurtosis")}</Header>
                                        <Line data={this.state.all_kurtosis_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && ((variable_choices.length === 0 && this.state.all_crest_factor_data.labels && this.state.all_crest_factor_data.labels.length > 0) || variable_choices.includes("CrestFactor")) && variable_options.findIndex((variable) => variable.key === "CrestFactor") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Crest factor")}</Header>
                                        <Line data={this.state.all_crest_factor_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && ((variable_choices.length === 0 && this.state.all_skewness_data.labels && this.state.all_skewness_data.labels.length > 0) || variable_choices.includes("Skewness")) && variable_options.findIndex((variable) => variable.key === "Skewness") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Skewness")}</Header>
                                        <Line data={this.state.all_skewness_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && ((variable_choices.length === 0 && this.state.all_deviation_data.labels && this.state.all_deviation_data.labels.length > 0) || variable_choices.includes("Deviation")) && variable_options.findIndex((variable) => variable.key === "Deviation") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Standard Deviation")}</Header>
                                        <Line data={this.state.all_deviation_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && ((variable_choices.length === 0 && this.state.all_displacement_data.labels && this.state.all_displacement_data.labels.length > 0) || variable_choices.includes("Displacement")) && variable_options.findIndex((variable) => variable.key === "Displacement") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Displacement (μm)")}</Header>
                                        <Line data={this.state.all_displacement_data} options={options_displacement}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "WISE-2410-EA" && (variable_choices.length === 0 || variable_choices.includes("SenVal")) && variable_options.findIndex((variable) => variable.key === "SenVal") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DECENTLAB-DL-MBX" && (variable_choices.length === 0 || variable_choices.includes("Distance")) && variable_options.findIndex((variable) => variable.key === "Distance") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_text}</Header>
                                        <Line data={this.state.distance_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DECENTLAB-DL-MBX" && (variable_choices.length === 0 || variable_choices.includes("fillingVolume")) && variable_options.findIndex((variable) => variable.key === "fillingVolume") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_volume_text}</Header>
                                        <Line data={this.state.filling_volume_data} options={options_filling_volume}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DECENTLAB-DL-MBX" && (variable_choices.length === 0 || variable_choices.includes("fillingPercentage")) && variable_options.findIndex((variable) => variable.key === "fillingPercentage") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_percentage_text}</Header>
                                        <Line data={this.state.filling_percentage_data} options={options_filling_percent}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DECENTLAB-DL-MBX" && (variable_choices.length === 0 || variable_choices.includes("deviation")) && variable_options.findIndex((variable) => variable.key === "deviation") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.distance_deviation_text}</Header>
                                        <Line data={this.state.distance_deviation_data} options={options_distance}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DIGITAL-MATTER-YABBY" && (variable_choices.length === 0 || variable_choices.includes("inTrip")) && variable_options.findIndex((variable) => variable.key === "inTrip") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.in_trip_text}</Header>
                                        <Line data={this.state.in_trip_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "DIGITAL-MATTER-YABBY" && (variable_choices.length === 0 || variable_choices.includes("speedKmph")) && variable_options.findIndex((variable) => variable.key === "speedKmph") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.speed_text}</Header>
                                        <Line data={this.state.speed_data} options={options_speed}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ERS" && (variable_choices.length === 0 || variable_choices.includes("temperature")) && variable_options.findIndex((variable) => variable.key === "temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ERS" && (variable_choices.length === 0 || variable_choices.includes("humidity")) && variable_options.findIndex((variable) => variable.key === "humidity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ERS" && (variable_choices.length === 0 || variable_choices.includes("light")) && variable_options.findIndex((variable) => variable.key === "light") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.light_text}</Header>
                                        <Line data={this.state.light_data} options={options_light}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ERS" && (variable_choices.length === 0 || variable_choices.includes("motion")) && variable_options.findIndex((variable) => variable.key === "motion") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.motion_text}</Header>
                                        <Line data={this.state.motion_data} options={options_pcs}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-EMS" && (variable_choices.length === 0 || variable_choices.includes("temperature")) && variable_options.findIndex((variable) => variable.key === "temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-EMS" && (variable_choices.length === 0 || variable_choices.includes("humidity")) && variable_options.findIndex((variable) => variable.key === "humidity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-EMS" && (variable_choices.length === 0 || variable_choices.includes("digital")) && variable_options.findIndex((variable) => variable.key === "digital") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.door_open_text}</Header>
                                        <Line data={this.state.door_closed_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-EMS" && (variable_choices.length === 0 || variable_choices.includes("accMotion")) && variable_options.findIndex((variable) => variable.key === "accMotion") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.motion_text}</Header>
                                        <Line data={this.state.motion_data} options={options_pcs}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-EMS" && (variable_choices.length === 0 || variable_choices.includes("waterleak"))  && variable_options.findIndex((variable) => variable.key === "waterleak") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.waterleak_text}</Header>
                                        <Line data={this.state.waterleak_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("temperature")) && variable_options.findIndex((variable) => variable.key === "temperature") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.temperature_text}</Header>
                                        <Line data={this.state.temperature_data} options={options_temperature}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("humidity")) && variable_options.findIndex((variable) => variable.key === "humidity") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.humidity_text}</Header>
                                        <Line data={this.state.humidity_data} options={options_humidity}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("pressure"))  && variable_options.findIndex((variable) => variable.key === "pressure") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.pressure_text}</Header>
                                        <Line data={this.state.pressure_data} options={options_air_pressure}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name === "ELSYS-ELT-2" && (variable_choices.length === 0 || variable_choices.includes("waterleak"))  && variable_options.findIndex((variable) => variable.key === "waterleak") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.waterleak_text}</Header>
                                        <Line data={this.state.waterleak_data} options={options_no_measure_unit}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("WASTECONTROL") && (variable_choices.length === 0 || variable_choices.includes("waste_distance"))  && variable_options.findIndex((variable) => variable.key === "waste_distance") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_percentage_text}</Header>
                                        <Line data={this.state.filling_percentage_data} options={options_filling_percent}/>
                                    </React.Fragment>
                                )}
                                {(sensor.model_name.startsWith("WASTECONTROL") && (variable_choices.length === 0 || variable_choices.includes("fillingVolume"))  && variable_options.findIndex((variable) => variable.key === "fillingVolume") !== -1) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{this.state.filling_volume_text}</Header>
                                        <Line data={this.state.filling_volume_data} options={options_filling_volume}/>
                                    </React.Fragment>
                                )}

                                {(!sensor.model_name.startsWith("WASTECONTROL") && (variable_choices.length === 0 || variable_choices.includes("BatteryVolt") || variable_choices.includes("Battery voltage") || variable_choices.includes("batV") || variable_choices.includes("vdd"))) && (
                                    <React.Fragment>
                                        <Header textAlign="center" as="h3">{t("Battery Voltage (V)")}</Header>
                                        <Line data={this.state.battery_data} options={options_battery}/>
                                    </React.Fragment>
                                )}

                                {/* <Line data={this.state.x_axis_velocity_RMS_data} options={options}/>
                                <Line data={this.state.y_axis_velocity_RMS_data} options={options}/>
                                <Line data={this.state.z_axis_velocity_RMS_data} options={options}/>  */}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    render() {
        const {  search_end_date, search_start_date, fetch, sensor, variable_options  } = this.state;
        const { isAuthenticated } = this.props;

        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (fetch) {
            if (search_start_date !== null && search_end_date !== null && sensor !== null && variable_options.length > 0) {
                this.handleFetchReport();
                this.setState({ fetch: false }); 
            } 
        }
        
        // console.log(this.state);
        return (
            <React.Fragment>
                {this.state.screen_size === "normal" && (
                    <Shell active_sensor_id={sensor && (sensor.id)} active_sensor_name={sensor && (sensor.local_name)} active_sensor_model={sensor && (sensor.model_name)}>
                        <this.radioButtons/>
                        <this.main />
                    </Shell>
                )}
                {this.state.screen_size === "wide" && (
                    <Container>
                        <Segment vertical>
                            <this.radioButtons/>    
                            <this.main/>
                        </Segment>
                    </Container>
                )}{this.state.screen_size === "full" && (
                    <React.Fragment>
                        <this.radioButtons/>
                        <this.main />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensorreports')(SensorReports))
);