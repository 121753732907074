// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Project
// Author: TS
//
// 1.2.2020 TS Initial version
// 13.2.2020 TS Added project routes
// 16.3.2020 TS Added project task routes
// 6.3.2023 TS Added Sensor downlink settings
// 17.2.2024 TS Added move sensor to other company 

import React from "react";
import { Route, Redirect } from "react-router-dom";
import Hoc from "./hoc/hoc";

import Login from "./containers/Login";
//import Signup from "./containers/Signup";
import PasswordReset from "./containers/PasswordReset";
import LandingPage from "./containers/LandingPage";
import PasswordResetRequest from "./containers/PasswordResetEmail";
import AccountDetails from "./containers/Account/AccountDetails";
import ChangePassword from "./containers/Account/ChangePassword";
import CompanyDetails from "./containers/Company/CompanyDetails";
import CompanyUsers from "./containers/Company/CompanyUsers";
import CompanyUserDetails from "./containers/Company/CompanyUserDetails";
import CompanyContacts from "./containers/Company/CompanyContacts";
import CompanyContactDetails from "./containers/Company/CompanyContactDetails";
import CompanyFiles from "./containers/Company/CompanyFiles";
import CompanyFileDetails from "./containers/Company/CompanyFileDetails";
import CompanyTasks from "./containers/Company/CompanyTasks";
import CompanyTaskDetails from "./containers/Company/CompanyTaskDetails";
import SensorGroups from "./containers/Sensor/SensorGroups";
import SensorGroupDetails from "./containers/Sensor/SensorGroupDetails";
import SensorDetails from "./containers/Sensor/SensorDetails";
import SensorDownlinkSettings from "./containers/Sensor/SensorDownlinkSettings";
import SensorDownlinkSettingsRelay from "./containers/Sensor/SensorDownlinkSettingsRelay";
import Sensors from "./containers/Sensor/Sensors";
import SensorStatus from "./containers/Sensor/SensorStatus";
import SensorReport from "./containers/Sensor/SensorReport";
import SensorReportV2 from "./containers/Sensor/SensorReportV2";
import SensorStatusVariables from "./containers/Sensor/SensorStatusVariables";
import SensorStatusVariableDetails from "./containers/Sensor/SensorStatusVariableDetails";
import SensorBatteryChanges from "./containers/Sensor/SensorBatteryChanges";
import SensorAlarms from "./containers/Sensor/SensorAlarms";
import SensorMap from "./containers/Sensor/SensorMap";
import SensorConsumapleReport from "./containers/Sensor/SensorConsumapleReport";
import SensorMoveToOtherCompany from "./containers/Sensor/SensorMoveToOtherCompany";

import LoraTest from "./containers/Sensor/LoraTest";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authenticated = localStorage.getItem("token") !== null;
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
};

const BaseRouter = () => (
  <Hoc>
    <Route exact path="/" component={LandingPage} />
    <Route path="/login/" component={Login} />
    {/* <Route path="/signup/" component={Signup} /> */}
    <Route path="/password-reset-email/:email/" component={PasswordResetRequest} />
    <Route path="/password-reset-email/" component={PasswordResetRequest} />
    <Route path="/password-reset-confirm/:uid/:token/" component={PasswordReset} />
    <PrivateRoute path="/account/change-password/" component={ChangePassword} />
    <PrivateRoute path="/account/account-details/" component={AccountDetails} />
    <PrivateRoute path="/company/company-details/" component={CompanyDetails} />
    <PrivateRoute path="/company/users/" component={CompanyUsers} />
    <PrivateRoute path="/company/user-details/:user_id/" component={CompanyUserDetails} />
    <PrivateRoute path="/company/contacts/" component={CompanyContacts} />
    <PrivateRoute path="/company/contact-details/:contact_id/" component={CompanyContactDetails} />
    <PrivateRoute path="/company/files/" component={CompanyFiles} />
    <PrivateRoute path="/company/file-details/:file_id/" component={CompanyFileDetails} />
    <PrivateRoute path="/company/tasks/" component={CompanyTasks} />
    <PrivateRoute path="/company/task-details/:task_id/" component={CompanyTaskDetails} />
    <PrivateRoute path="/sensors/" component={Sensors} />
    <PrivateRoute path="/sensor/sensor-details/:sensor_id/" component={SensorDetails} />
    <PrivateRoute path="/sensor/sensor-groups/" component={SensorGroups} />
    <PrivateRoute path="/sensor/sensor-group-details/:sensor_group_id/" component={SensorGroupDetails} />
    <PrivateRoute path="/sensor/downlink-settings/:sensor_id/" component={SensorDownlinkSettings} />
    <PrivateRoute path="/sensor/downlink-settings-relay/:sensor_id/" component={SensorDownlinkSettingsRelay} />
    <PrivateRoute path="/sensor/status-variables/:sensor_id/" component={SensorStatus} />
    <PrivateRoute path="/sensor/status-history/:sensor_id/" component={SensorReport} />
    <PrivateRoute path="/sensor/status-history-v2/:sensor_id/" component={SensorReportV2} />
    <PrivateRoute path="/sensor/status-variable-settings/:sensor_id/" component={SensorStatusVariables} />
    <PrivateRoute path="/sensor/:sensor_id/status-variable-details/:status_variable_id/" component={SensorStatusVariableDetails} />
    <PrivateRoute path="/sensor/battery-changes/:sensor_id/" component={SensorBatteryChanges} />
    <PrivateRoute path="/sensor/alarms/:sensor_id/" component={SensorAlarms} />
    <PrivateRoute path="/sensor/map/:sensor_id/" component={SensorMap} />
    <PrivateRoute path="/sensor/consumaple-history/:sensor_id/:measurement_unit/:report_type/" component={SensorConsumapleReport} />
    <PrivateRoute path="/sensor/move-to-other-company/:sensor_id/" component={SensorMoveToOtherCompany} />

    <PrivateRoute path="/loratest/" component={LoraTest} />
    
  </Hoc>
);

export default BaseRouter;
