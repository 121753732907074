// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 19.3.2020 TS Initial version

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Confirm, Input } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { postLoraTestURL } from "../../constants";


class LoraTest extends React.Component {
    state = {
        data: {
            // DevEUI_uplink: {
            //     DevEUI: "00070900004F2B08",
            //     DevAddr: "004F2B08",
            //     CustomerID: "111111",
            //     payload_hex: "598ffe5f10d40d0000f029fe5fd40d00000000000000000000000000000000", 
            //     FCntUp: 26,
            //     FPort: 100,
            //     Lrcid: '00000201', 
            //     LrrRSSI: -111.0,
            //     LrrSNR: 2.0,
            //     SpFact: 12,
            //     LrrLAT: 60.935123,
            //     LrrLON: 25.677097
            // }
            // DevEUI_uplink: {
            //     DevEUI: "A81758FFFE03507C",
            //     DevAddr: "FE03507C",
            //     CustomerID: "111111",
            //     payload_hex: "0100eb02240400010500070db9", 
            //     FCntUp: 26,
            //     FPort: 5,
            //     Lrcid: '00000201', 
            //     LrrRSSI: -111.0,
            //     LrrSNR: 2.0,
            //     SpFact: 12,
            //     LrrLAT: 60.935123,
            //     LrrLON: 25.677097
            // }
            // DevEUI_uplink: {
            //     DevEUI: "A81758FFFE049472",
            //     DevAddr: "FE049472",
            //     CustomerID: "111111",
            //     payload_hex: "0100a2022803c103fe070e100b00000be80d010f001200", 
            //     FCntUp: 26,
            //     FPort: 5,
            //     Lrcid: '00000201', 
            //     LrrRSSI: -111.0,
            //     LrrSNR: 2.0,
            //     SpFact: 12,
            //     LrrLAT: 60.935123,
            //     LrrLON: 25.677097
            // }
            // DevEUI_uplink: {
            //     DevEUI: "70B3D57BA00018D8",
            //     DevAddr: "A00018D8",
            //     CustomerID: "111111",
            //     payload_hex: "0218d800030d3c000f0bc4", 
            //     FCntUp: 26,
            //     FPort: 5,
            //     Lrcid: '00000201', 
            //     LrrRSSI: -111.0,
            //     LrrSNR: 2.0,
            //     SpFact: 12,
            //     LrrLAT: 60.935123,
            //     LrrLON: 25.677097
            // }
            DevEUI_uplink: {
                DevEUI: "1515151515151",
                DevAddr: "1515151515151",
                CustomerID: "111111",
                payload_hex: "641280df6d0dbba0", 
                FCntUp: 18,
                FPort: 1,
                Lrcid: '00000201', 
                LrrRSSI: -111.0,
                LrrSNR: 2.0,
                SpFact: 12,
                LrrLAT: 60.935123,
                LrrLON: 25.677097
            }


        },
        error: null,
        loading: false,
    };

    componentDidMount() {
        this.props.setActivity();
    }


    handlePost = id => {
        this.setState({
            loading: true
        });
        authAxios
            .post(postLoraTestURL(), {
                ...this.state.data
            })
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };


    render() {
        const { data, error, loading } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        console.log(this.state);

        return (
            <Shell>
                <Header as="h3">{t("LoRa Test")}</Header>
                <b>{data.toString()}</b>
                <Button onClick={() => this.handlePost()}>Send</Button>

                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('loratest')(LoraTest))
);