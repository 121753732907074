import React from "react";
import { Form, Input, Message, Header, Button, Item, Icon } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, authFileAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyFileDetailsURL, companyFileUploadURL, companyFileDetailsUpdateURL, companyFileDownloadURL, companyFileDeleteURL } from "../../constants";

class CompanyFileDetails extends React.Component {
    state = {
        file: {
            group: "",
            category: "",
            name: "",
            added: "",
            file: "",
            author: null,
            date_created: null,
            selected_file: null
        },
        error: null,
        success_mgs: null,
        loading: false,
        create: null
    };

    componentDidMount() {
        this.props.setActivity();
        // this.fileSelector = buildFileSelector();
        if (this.props.match.params.file_id === "upload") {
            this.setState({
                create: true,
                is_active: true
            })
        } else {
            this.handleDetails();
        }
        // this.fileInput = React.createRef();
    }


    handleFileSelect = (e) => {
        // e.preventDefault();
        // this.fileSelector.click();
        const { file } = this.state;
        const updatedFile = {
            ...file,
            selected_file: e.target.files[0]
        }
        this.setState({
            file: updatedFile,
            success_mgs: null,
            error: null
        });
    }


    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { file_id } = this.props.match.params;
        authAxios
            .get(companyFileDetailsURL(activeCompanyId, file_id))
            .then(res => {
                this.setState({
                    file: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleChange = e => {
        const { file } = this.state;
        const updatedFile = {
            ...file,
            [e.target.name]: e.target.value
        }
        this.setState({
            file: updatedFile,
            success_mgs: null,
            error: null
        });
    };


    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            file,
            create
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (file.name === "") {
            this.setState({
                error: t("Please fill in Name"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (create) {
                if (file.selected_file === null) {
                    this.setState({
                        error: t("Please select file to upload"),
                        success_mgs: null,
                        loading: false
                    });
                } else {
                    let form_data = new FormData();
                    form_data.append('file', file.selected_file, file.selected_file.name);
                    form_data.append('group', file.group);
                    form_data.append('category', file.category);
                    form_data.append('name', file.name);
                    form_data.append('company', activeCompanyId);
                    form_data.append('author', localStorage.getItem("UserID"));

                    authFileAxios
                        .post(companyFileUploadURL(activeCompanyId), form_data)
                        .then(res => {
                            this.props.history.push("/company/files/");
                        })
                        .catch(err => {
                            this.setState({
                                loading: false,
                                success_mgs: null,
                                error: err
                            });
                        });
                }
            } else {
                const { file_id } = this.props.match.params;
                const updatedFile = {
                    ...file,
                    author: localStorage.getItem("UserID")
                }
                authAxios
                    .put(companyFileDetailsUpdateURL(activeCompanyId, file_id), {
                        ...updatedFile
                    })
                    .then(res => {
                        this.props.history.push("/company/files/");
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };
    downloadFile = (file) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(companyFileDownloadURL(activeCompanyId, file.id), { responseType: 'blob' })
            .then(res => {
                //Create a Blob from the data stream
                const res_file = new Blob(
                    [res.data]);
                //Build a URL from the file
                const fileURL = URL.createObjectURL(res_file);
                // Get the file name from the URL
                const filename = file.file.substring(file.file.lastIndexOf("/") + 1);
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();

                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    deleteFile = file => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(companyFileDeleteURL(activeCompanyId, file.id))
            .then(res => {
                this.props.history.push("/company/files/");
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    render() {
        const {
            file,
            error,
            success_mgs,
            loading,
            create
        } = this.state;
        const { isAuthenticated, t } = this.props;
        // console.log(this.state);
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        return (
            <Shell >
                <Header as="h4">{t("Company file details")}</Header>
                <Form onSubmit={this.handleSubmit} error={error !== null}>
                    <Form.Field>
                        <label>{t("File group")}</label>
                        <Input
                            value={file.group}
                            name="group"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("File category")}</label>
                        <Input
                            value={file.category}
                            name="category"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("File name")}</label>
                        <Input
                            value={file.name}
                            name="name"
                            onChange={this.handleChange} required />
                    </Form.Field>
                    <Form.Field>
                        {create ? (
                            <Input type="file" id="file" onChange={this.handleFileSelect} />
                        ) : (
                                // <Link onClick={() => this.downloadFile(file)} >
                                //     <Header as="h5" color="blue"> {file.file.substring(file.file.lastIndexOf("/") + 1)}</Header>
                                // </Link>
                                <Link onClick={() => this.downloadFile(file)} >
                                    <Item>
                                        <Icon name="file alternate outline" size="big" />
                                        <Item.Header>
                                            <Header as="h5" color="blue"> {file.file.substring(file.file.lastIndexOf("/") + 1)}</Header>
                                        </Item.Header>
                                    </Item>
                                </Link>
                            )}
                    </Form.Field>
                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Button primary type="submit" loading={loading} disabled={loading}>
                        {create ? (t("Upload")) : (t("Save"))}
                    </Button>
                    <Button negative loading={loading} disabled={loading} onClick={() => this.deleteFile(file)} floated="right">
                        {t("Delete file")}
                    </Button>
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companyfiledetails')(CompanyFileDetails))
);