// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 19.3.2020 TS Initial version
// 28.7.2020 TS Added alarm_notification_status
// 28.4.2021 TS Added email and sms alarm settings
// 7.1.2024 1.0.8 TS Polygon texts and titles

import React from "react";
import { Form, Input, Message, Header, Button, Checkbox } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { sensorGroupDetailsURL, updateSensorGroupDetailsURL, createSensorGroupDetailsURL } from "../../constants";


class SensorGroupDetails extends React.Component {
    state = {
        company: "",
        name: "",
        description: "",
        alarm_notification_status: "A",
        sensor_group_id: null,
        is_email_notifications_enabled: true,
        is_sms_notifications_enabled: false,
        error: null,
        success_mgs: null,
        loading: false,
        create: null,
        default_group: false
    };

    componentDidMount() {
        this.props.setActivity();
        if (this.props.match.params.sensor_group_id === "create") {
            this.setState({
                create: true,
                is_active: true
            })
        } else {
            this.handleDetails();
        }
    }

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_group_id } = this.props.match.params;
        authAxios
            .get(sensorGroupDetailsURL(activeCompanyId, sensor_group_id))
            .then(res => {
                this.setState({
                    sensor_group_id: res.data.id,
                    company: res.data.company,
                    name: res.data.name,
                    description: res.data.description,
                    alarm_notification_status: res.data.alarm_notification_status,
                    is_email_notifications_enabled: res.data.is_email_notifications_enabled,
                    is_sms_notifications_enabled: res.data.is_sms_notifications_enabled,
                    error: null,
                    success_mgs: null,
                    loading: false,
                    default_group: res.data.name === "Default group" ? true : false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleCheckboxChange = (e, { name, checked }) => {
        
        this.setState({
            [name]: checked,
            success_mgs: null,
            error: null,
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            company,
            name,
            description,
            alarm_notification_status,
            is_email_notifications_enabled,
            is_sms_notifications_enabled, 
            sensor_group_id,
            create
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (name === "") {
            this.setState({
                error: t("Please fill in name"),
                success_mgs: null,
                loading: false
            });
        } else if (name.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Name"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (create) {
                authAxios
                    .post(createSensorGroupDetailsURL(activeCompanyId), {
                        company: activeCompanyId,
                        name,
                        description,
                        alarm_notification_status,
                        is_email_notifications_enabled,
                        is_sms_notifications_enabled,    
                        author: localStorage.getItem("UserID")
                    })
                    .then(res => {
                        this.props.history.push(`/sensor/sensor-groups`)
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            } else {
                authAxios
                    .put(updateSensorGroupDetailsURL(activeCompanyId, sensor_group_id), {
                        company,
                        name,
                        description,
                        alarm_notification_status,
                        is_email_notifications_enabled,
                        is_sms_notifications_enabled,    
                    })
                    .then(res => {
                        this.props.history.push(`/sensor/sensor-groups`)
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    render() {
        const {
            name,
            description,
            is_email_notifications_enabled,
            is_sms_notifications_enabled,  
            error,
            success_mgs,
            loading,
            create
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        const logo = localStorage.getItem("Logo");
        return (
            <Shell>
                {logo && (
                    <>
                        {logo === "Polygon" ? (
                            <Header as="h4">{t("Kohteen tiedot")}</Header>
                        ) : (
                            <Header as="h4">{t("Sensor group details")}</Header>
                        )}
                        <Form onSubmit={this.handleSubmit} error={error !== null}>
                            <Form.Field>
                                <label>{t("Name")}</label>
                                {logo === "Polygon" ? (
                                    <Input
                                        disabled={this.state.default_group}
                                        value={name === "Default group" ? "TMP VARASTO" : name}
                                        name="name"
                                        onChange={this.handleChange} />
                                ) : (
                                    <Input
                                        disabled={this.state.default_group}
                                        value={name}
                                        name="name"
                                        onChange={this.handleChange} />
                                )}
                            </Form.Field>
                            <Form.Field>
                                <label>{t("Description")}</label>
                                <Input
                                    value={description}
                                    name="description"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    label={<label>{t("Email alarms enabled")}</label>}
                                    name="is_email_notifications_enabled"
                                    onChange={this.handleCheckboxChange}
                                    checked={is_email_notifications_enabled}
                                />
                            </Form.Field>
                            {/* <Form.Field>
                                <Checkbox
                                    disabled={true}
                                    label={<label>{t("SMS alarms enabled")}</label>}
                                    
                                    name="is_sms_notifications_enabled"
                                    onChange={this.handleCheckboxChange}
                                    checked={is_sms_notifications_enabled}
                                />
                            </Form.Field> */}

                            {error && <Message error header={t("Error")} content={error} />}
                            {success_mgs && <Message positive header={success_mgs} />}
                            <Button primary type="submit" loading={loading} disabled={loading}>
                                {create ? (t("Create")) : (t("Save"))}
                            </Button>
                        </Form>
                    </>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensorgroupdetails')(SensorGroupDetails))
);