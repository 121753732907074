// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 20.3.2020 TS Initial version
// 1.7.2020 TS Added menu version for Mobile
// 7.1.2024 1.0.8 TS Polygon texts and titles

import React, { useState } from "react";
import { Grid, Container, Segment, Header, Menu,  Accordion,  Responsive } from "semantic-ui-react";
import {  getWidth} from "../../utils";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

const Shell = props => {
    const [activeMenuIndex, setActiveMenuIndex] = useState(0);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        
        const newIndex = activeMenuIndex === index ? -1 : index

        setActiveMenuIndex( newIndex )
    };

    const checkIfOnlyCustomerRole = () => {
        // Only Client role activated to limit access
        if (props.activeRoles && props.activeRoles.length === 1 && props.activeRoles.includes("C")) {
            return true;
        }
        return false;
    };

    const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
    //console.log(localStorage.getItem("Logo"));
    const logo = localStorage.getItem("Logo");
    const activeCompanyName = localStorage.getItem("activeCompanyName");
    return (
    < Segment vertical>
        <Container>
            <Grid container stackable verticalAlign="top" divided columns={2}>
                {(logo && activeCompanyName) && (
                <Grid.Row>
                    <Grid.Column width={4}>
                        
                        {is_desktop ? (
                            <>
                            {logo === "Polygon" ? (
                                <Header as="h3">{activeCompanyName}</Header>
                            ) : (
                                <Header as="h3">{props.t("Sensors")}</Header>
                            )}
                            <Menu vertical fluid>
                                {logo !== "Polygon" && (
                                    <Menu.Item>
                                        <Header as="h4">{props.t("Sensors")}</Header>
                                    </Menu.Item>
                                )}
                                <Menu.Item
                                    active={props.location.pathname === "/sesors/"}
                                    onClick={() => props.history.push("/sensors/")}
                                    name="sensors"
                                >
                                    {logo === "Polygon" ? (
                                        props.t("Kohteet/Anturit")
                                    ) : (
                                        props.t("Sensors")
                                    )}
                                    {/* {props.t("Sensors")} */}
                                </Menu.Item>
                                {!checkIfOnlyCustomerRole() && (
                                    <Menu.Item
                                        active={props.location.pathname === "/sensor/sensor-groups/"}
                                        onClick={() => props.history.push("/sensor/sensor-groups/")}
                                        name="sensor-groups"
                                    >
                                        {logo === "Polygon" ? (
                                            props.t("Luo/Poista kohteita")
                                        ) : (
                                            props.t("Sensor Groups")
                                        )}
                                        {/* {props.t("Sensor Groups")} */}
                                    </Menu.Item>
                                )}
                                <Menu.Item
                                    active={props.location.pathname === `/sensor/map/all/`}
                                    onClick={() => props.history.push(`/sensor/map/all/`)}
                                    name="sensors-map"
                                >
                                    {props.t("Map")}
                                </Menu.Item>
                                {props.active_sensor_id && (
                                    <React.Fragment>
                                        <Menu.Item>
                                            {logo === "Polygon" ? (
                                                <Header as="h4">{props.active_sensor_device_name}</Header>
                                            ) : (
                                                <Header as="h4">{props.active_sensor_name}</Header>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item
                                            active={props.location.pathname === `/sensor/status-variables/${props.active_sensor_id}/`}
                                            onClick={() => props.history.push(`/sensor/status-variables/${props.active_sensor_id}/`)}
                                            name="sensor-status-variables"
                                        >
                                            {props.t("Status")}
                                        </Menu.Item>
                                        {!checkIfOnlyCustomerRole() && (
                                            <Menu.Item
                                                active={props.location.pathname === `/sensor/sensor-details/${props.active_sensor_id}/`}
                                                onClick={() => props.history.push(`/sensor/sensor-details/${props.active_sensor_id}/`)}
                                                name="sensor-details"
                                            >
                                                {props.t("Sensor details")}
                                            </Menu.Item>
                                        )}
                                        {(props.activeRoles && (props.activeRoles.includes("A") || props.activeRoles.includes("S"))) && (
                                            <Menu.Item
                                                active={props.location.pathname === `/sensor/move-to-other-company/${props.active_sensor_id}/`}
                                                onClick={() => props.history.push(`/sensor/move-to-other-company/${props.active_sensor_id}/`)}
                                                name="sensor-move-to-other-company"
                                            >
                                                {props.t("Move to other company")}
                                            </Menu.Item>
                                        )}
                                        {!checkIfOnlyCustomerRole() && (
                                            <>
                                                {logo === "Polygon" ? (
                                                    <>
                                                        {props.active_sensor_type === "relay" && (
                                                            <>
                                                                <Menu.Item
                                                                    active={props.location.pathname === `/sensor/downlink-settings-relay/${props.active_sensor_id}/`}
                                                                    onClick={() => props.history.push(`/sensor/downlink-settings-relay/${props.active_sensor_id}/`)}
                                                                    name="sensor-downlink-settings-relay"
                                                                >
                                                                    {props.t("Relay control")}
                                                                </Menu.Item>
                                                            </>
                                                        )}
                                                        {(props.activeRoles && (props.activeRoles.includes("A") || props.activeRoles.includes("S"))) && (
                                                            <>
                                                                <Menu.Item
                                                                    active={props.location.pathname === `/sensor/downlink-settings/${props.active_sensor_id}/`}
                                                                    onClick={() => props.history.push(`/sensor/downlink-settings/${props.active_sensor_id}/`)}
                                                                    name="sensor-downlink-settings"
                                                                >
                                                                    {props.t("Sensor downlink settings")}
                                                                </Menu.Item>
                                                            
                                                                <Menu.Item
                                                                    active={props.location.pathname === `/sensor/status-variable-settings/${props.active_sensor_id}/`}
                                                                    onClick={() => props.history.push(`/sensor/status-variable-settings/${props.active_sensor_id}/`)}
                                                                    name="sensor-status-variable-settings"
                                                                >
                                                                    {props.t("Sensor variable settings")}
                                                                </Menu.Item>
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Menu.Item
                                                            active={props.location.pathname === `/sensor/downlink-settings/${props.active_sensor_id}/`}
                                                            onClick={() => props.history.push(`/sensor/downlink-settings/${props.active_sensor_id}/`)}
                                                            name="sensor-downlink-settings"
                                                        >
                                                            {props.t("Sensor downlink settings")}
                                                        </Menu.Item>
                                                    
                                                        <Menu.Item
                                                            active={props.location.pathname === `/sensor/status-variable-settings/${props.active_sensor_id}/`}
                                                            onClick={() => props.history.push(`/sensor/status-variable-settings/${props.active_sensor_id}/`)}
                                                            name="sensor-status-variable-settings"
                                                        >
                                                            {props.t("Sensor variable settings")}
                                                        </Menu.Item>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <Menu.Item
                                            active={props.location.pathname === `/sensor/battery-changes/${props.active_sensor_id}/`}
                                            onClick={() => props.history.push(`/sensor/battery-changes/${props.active_sensor_id}/`)}
                                            name="sensor-battery-changes"
                                        >
                                            {props.t("Sensor battery changes")}
                                        </Menu.Item>
                                        <Menu.Item
                                            active={props.location.pathname === `/sensor/alarms/${props.active_sensor_id}/`}
                                            onClick={() => props.history.push(`/sensor/alarms/${props.active_sensor_id}/`)}
                                            name="sensor-alarms"
                                        >
                                            {props.t("Sensor alarms")}
                                        </Menu.Item>
                                        <Menu.Item
                                            active={props.location.pathname === `/sensor/map/${props.active_sensor_id}/`}
                                            onClick={() => props.history.push(`/sensor/map/${props.active_sensor_id}/`)}
                                            name="sensor-on-map"
                                        >
                                            {props.t("Sensor on map")}
                                        </Menu.Item>
                                        {(props.active_sensor_model === "AXIOMA-W1" || props.active_sensor_model === "BMETERS-LW3") ? (
                                            <Menu.Item
                                                active={props.location.pathname === `/sensor/consumaple-history/${props.active_sensor_id}/m3/day/`}
                                                onClick={() => props.history.push(`/sensor/consumaple-history/${props.active_sensor_id}/m3/day/`)}
                                                name="sensor-consumaple-history"
                                            >
                                                {props.t("Status history")}
                                            </Menu.Item>
                                        ) : (
                                            <>
                                            {/* <Menu.Item
                                                active={props.location.pathname === `/sensor/status-history/${props.active_sensor_id}/`}
                                                onClick={() => props.history.push(`/sensor/status-history/${props.active_sensor_id}/`)}
                                                name="sensor-status-history"
                                            >
                                                {props.t("Status history")}
                                            </Menu.Item> */}
                                            <Menu.Item
                                                active={props.location.pathname === `/sensor/status-history-v2/${props.active_sensor_id}/`}
                                                onClick={() => props.history.push(`/sensor/status-history-v2/${props.active_sensor_id}/`)}
                                                name="sensor-status-history"
                                            >
                                                {props.t("Status history")}
                                            </Menu.Item>
                                            </>
                                        )}
                                    </React.Fragment>
                                )}
                            </Menu>
                            </>
                        ) : (
                            <Segment>
                            <Accordion>
                                <Accordion.Title
                                    active={activeMenuIndex === 1}
                                    index={1}
                                    onClick={handleClick}>
                                        <Header as="h3">{props.t("Menu")}</Header>
                                </Accordion.Title>
                                <Accordion.Content active={activeMenuIndex === 1}>
                                    <Menu vertical fluid size="large">
                                        <Menu.Item>
                                            <Header as="h4">{props.t("Sensors")}</Header>
                                        </Menu.Item>
                                        <Menu.Item
                                            active={props.location.pathname === "/sesors/"}
                                            onClick={() => props.history.push("/sensors/")}
                                            name="sensors"
                                        >
                                            {logo === "Polygon" ? (
                                                props.t("Kohteet/Anturit")
                                            ) : (
                                                props.t("Sensors")
                                            )}
                                            {/* {props.t("Sensors")} */}
                                        </Menu.Item>
                                        {!checkIfOnlyCustomerRole() && (
                                            <Menu.Item
                                                active={props.location.pathname === "/sensor/sensor-groups/"}
                                                onClick={() => props.history.push("/sensor/sensor-groups/")}
                                                name="sensor-groups"
                                            >
                                                {logo === "Polygon" ? (
                                                    props.t("Luo/Poista kohteita")
                                                ) : (
                                                    props.t("Sensor Groups")
                                                )}
                                                {/* {props.t("Sensor Groups")} */}
                                            </Menu.Item>
                                        )}
                                        <Menu.Item
                                            active={props.location.pathname === `/sensor/map/all/`}
                                            onClick={() => props.history.push(`/sensor/map/all/`)}
                                            name="sensors-map"
                                        >
                                            {props.t("Map")}
                                        </Menu.Item>
                                        {props.active_sensor_id && (
                                            <React.Fragment>
                                                <Menu.Item>
                                                    {logo === "Polygon" ? (
                                                        <Header as="h4">{props.active_sensor_device_name}</Header>
                                                    ) : (
                                                        <Header as="h4">{props.active_sensor_name}</Header>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item
                                                    active={props.location.pathname === `/sensor/status-variables/${props.active_sensor_id}/`}
                                                    onClick={() => props.history.push(`/sensor/status-variables/${props.active_sensor_id}/`)}
                                                    name="sensor-status-variables"
                                                >
                                                    {props.t("Status")}
                                                </Menu.Item>
                                                {!checkIfOnlyCustomerRole() && (
                                                    <>
                                                        <Menu.Item
                                                            active={props.location.pathname === `/sensor/sensor-details/${props.active_sensor_id}/`}
                                                            onClick={() => props.history.push(`/sensor/sensor-details/${props.active_sensor_id}/`)}
                                                            name="sensor-details"
                                                        >
                                                            {props.t("Sensor details")}
                                                        </Menu.Item>
                                                    
                                                        <Menu.Item
                                                            active={props.location.pathname === `/sensor/status-variable-settings/${props.active_sensor_id}/`}
                                                            onClick={() => props.history.push(`/sensor/status-variable-settings/${props.active_sensor_id}/`)}
                                                            name="sensor-status-variable-settings"
                                                        >
                                                            {props.t("Sensor variable settings")}
                                                        </Menu.Item>
                                                    </>
                                                )}
                                                <Menu.Item
                                                    active={props.location.pathname === `/sensor/battery-changes/${props.active_sensor_id}/`}
                                                    onClick={() => props.history.push(`/sensor/battery-changes/${props.active_sensor_id}/`)}
                                                    name="sensor-battery-changes"
                                                >
                                                    {props.t("Sensor battery changes")}
                                                </Menu.Item>
                                                <Menu.Item
                                                    active={props.location.pathname === `/sensor/alarms/${props.active_sensor_id}/`}
                                                    onClick={() => props.history.push(`/sensor/alarms/${props.active_sensor_id}/`)}
                                                    name="sensor-alarms"
                                                >
                                                    {props.t("Sensor alarms")}
                                                </Menu.Item>
                                                <Menu.Item
                                                    active={props.location.pathname === `/sensor/map/${props.active_sensor_id}/`}
                                                    onClick={() => props.history.push(`/sensor/map/${props.active_sensor_id}/`)}
                                                    name="sensor-on-map"
                                                >
                                                    {props.t("Sensor on map")}
                                                </Menu.Item>
                                                <Menu.Item
                                                    active={props.location.pathname === `/sensor/status-history-v2/${props.active_sensor_id}/`}
                                                    onClick={() => props.history.push(`/sensor/status-history-v2/${props.active_sensor_id}/`)}
                                                    name="sensor-status-history"
                                                >
                                                    {props.t("Status history")}
                                                </Menu.Item>

                                            </React.Fragment>
                                        )}
                                    </Menu>
                                </Accordion.Content>
                            </Accordion>
                            </Segment>
                        )}
                    </Grid.Column>
                    <Grid.Column width={12}>{props.children}</Grid.Column>
                </Grid.Row>
                )}
            </Grid>
        </Container>
    </Segment >
);
}
const mapDispatchToProps = dispatch => {
    return {
        // logout: () => dispatch(logout())
    };
};

const mapStateToProps = state => {
    return {
        activeRoles: state.company.activeRoles
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensorshell')(Shell))
);