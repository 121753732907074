// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 6.3.2022 TS Initial version
// 27.7.2022 TS Change sensor_model === "RADAR" ro startsWith("RADAR")
// 20.6.2023 TS Downlink options are now stored in database.

import React from "react";
import { Form, Message, Header, Button, Select, Checkbox, Dimmer, Loader, Segment, Image } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { sendDownlinkSettingsURL, sensorDownlinkDetailsURL } from "../../constants";


class SensorDownlinkSettings extends React.Component {
    state = {
        sensor: null,
        error: null,
        success_mgs: null,
        loading: false,
        component_loading: false,
        roles: null,
        activeIndex: 0,
        measure_interval_options: [],
        country_options: null,
        is_measure_interval: false,
        is_xm132_range_start: false,
        xm132_range_start: 250,
        is_xm132_range_length: false,
        xm132_range_length: 1200,
        is_xm132_threshold: false,
        xm132_threshold: 500,
        is_xm132_downsampling_factor: false,
        xm132_downsampling_factor: 1,
        is_xm132_profile_selection: false,
        is_xm132_hw_acc_avg_samples: false,
        is_xm132_mur: false,
        is_LW13IO_downlink_request: false,
        LW13IO_downlink_request: "open relay",
        is_LR2x0_downlink_request: false,
        LR2x0_downlink_request: "both channels open",
        is_TH_probe_1_heating_mode: false,
        TH_probe_1_heating_mode: 0,
        is_TH_probe_2_heating_mode: false,
        TH_probe_2_heating_mode: 0,
        is_TH_probe_3_heating_mode: false,
        TH_probe_3_heating_mode: 0,
        sensor_downlink_settings: null,
        selected_sensor_downlink_settings: []
    };

    componentDidMount() {
        this.props.setActivity();
        this.setState({
            component_loading: true
        });
        this.handleDetails();
        this.setState({
            component_loading: false
        });
        // this.handleSetMeasureIntervalOptions();

    }

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDownlinkDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    sensor_downlink_settings: res.data.sensor_downlink_settings,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            sensor, selected_sensor_downlink_settings
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (sensor.id === null) return;

        if (selected_sensor_downlink_settings.length > 0) {
            let downlink_settings = [];
            let setting_item = {};
            let i = 0;
            for (i in selected_sensor_downlink_settings) {
                setting_item = {
                    company: selected_sensor_downlink_settings[i].company,
                    id: selected_sensor_downlink_settings[i].id,
                    sensor: selected_sensor_downlink_settings[i].sensor,
                    current_value: selected_sensor_downlink_settings[i].current_value
                }
                downlink_settings.push(setting_item);
            }
            if (downlink_settings.length > 0) {
                try {
                    const response = await authAxios.post(sendDownlinkSettingsURL(activeCompanyId, sensor.id), 
                        {
                            downlink_settings: downlink_settings
                        });
                    this.setState({
                        loading: false,
                        success_mgs: t("Settings downlink sent"),
                        error: null
                    });
                } catch (error) {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: error
                    });
                    return;
                }

            } else {
                this.setState({
                    loading: false
                });
            }
        } else {
            this.setState({
                loading: false
            });
        }

        // let is_data_to_send = false;

        // let downlink_settings = {};
        // if (this.state.is_measure_interval) {
        //     is_data_to_send = true;
        //     downlink_settings = {
        //         ...downlink_settings,
        //         measure_interval_sec: sensor.measure_interval
        //     }
        // }
        // if (this.state.is_LW13IO_downlink_request) {
        //     is_data_to_send = true;
        //     downlink_settings = {
        //         ...downlink_settings,
        //         LW13IO_downlink_request: this.state.LW13IO_downlink_request
        //     }
        // }
        // if (this.state.is_LR2x0_downlink_request) {
        //     is_data_to_send = true;
        //     downlink_settings = {
        //         ...downlink_settings,
        //         LR2x0_downlink_request: this.state.LR2x0_downlink_request
        //     }
        // }
        // if (this.state.is_TH_probe_1_heating_mode) {
        //     is_data_to_send = true;
        //     downlink_settings = {
        //         ...downlink_settings,
        //         Caproc_TH_probe_1_heating_mode: this.state.TH_probe_1_heating_mode
        //     }
        // }
        // if (this.state.is_TH_probe_2_heating_mode) {
        //     is_data_to_send = true;
        //     downlink_settings = {
        //         ...downlink_settings,
        //         Caproc_TH_probe_2_heating_mode: this.state.TH_probe_2_heating_mode
        //     }
        // }
        // if (this.state.is_TH_probe_3_heating_mode) {
        //     is_data_to_send = true;
        //     downlink_settings = {
        //         ...downlink_settings,
        //         Caproc_TH_probe_3_heating_mode: this.state.TH_probe_3_heating_mode
        //     }
        // }

        // if (this.state.is_xm132_range_start) {
        //     is_data_to_send = true;
        //     downlink_settings = {
        //         ...downlink_settings,
        //         Caproc_xm132_range_start: this.state.xm132_range_start
        //     }
        // }

        // if (this.state.is_xm132_range_length) {
        //     is_data_to_send = true;
        //     downlink_settings = {
        //         ...downlink_settings,
        //         Caproc_xm132_range_length: this.state.xm132_range_length
        //     }
        // }

        // if (this.state.is_xm132_threshold) {
        //     is_data_to_send = true;
        //     downlink_settings = {
        //         ...downlink_settings,
        //         Caproc_xm132_threshold: this.state.xm132_threshold
        //     }
        // }

        // if (is_data_to_send) {
        //     authAxios
        //         .post(sendDownlinkSettingsURL(activeCompanyId, sensor.id), {
        //             sensor_id: sensor.id,
        //             downlink_settings
        //         })
        //         .then(res => {
        //             this.setState({
        //                 loading: false,
        //                 success_mgs: t("Settings downlink sent"),
        //                 error: null
        //             });
        //         })
        //         .catch(err => {
        //             this.setState({
        //                 loading: false,
        //                 success_mgs: null,
        //                 error: err
        //             });
        //         });
        // } else {
        //     this.setState({
        //         loading: false
        //     });
        // }
    };

    handleSetMeasureIntervalPlaceholder = (measure_interval_sec) => {
        const { measure_interval_options } = this.state;
        const { t } = this.props;
        let i = 0;
        for (i in measure_interval_options) {
            if (measure_interval_options[i].value === measure_interval_sec) {
                return t(measure_interval_options[i].text);
            }
        }
        return measure_interval_sec + " " + t("seconds");
    };

    handleSetOptions = (model_options) => {
        const { t } = this.props;
        let options = [];
    
        let i = 0;
        for (i in model_options) {
            if (model_options[i].option_type === "N") {
                options.push({
                    key: model_options[i].option_key,
                    text: t(model_options[i].option_text),
                    value: model_options[i].option_value
                });
            } else { // R=Range
                let x = parseInt(model_options[i].range_min_value);
                const max = parseInt(model_options[i].range_max_value);
                const increment = parseInt(model_options[i].range_increment);
                while (x <= max) {
                    options.push({
                        key: x,
                        text: x.toString(),
                        value: x
                    })
                    x += increment;
                }
            }
        }
        return options;
    };

    // handleSetMeasureIntervalOptions = () => {
    //     const { t } = this.props;
    //     const { sensor } = this.state;
    //     if (sensor.model_name === "") return;
    //     let model_name = "";
    //     model_name = sensor.model_name;

    //     let options = [];
    //     if (!model_name.startsWith("DINGTEK")) {
    //         options.push({
    //             key: 600,
    //             text: "10 " + t("minutes"),
    //             value: 600
    //         });
    //         options.push({
    //             key: 900,
    //             text: "15 " + t("minutes"),
    //             value: 900
    //         });
    //         options.push({
    //             key: 1800,
    //             text: "30 " + t("minutes"),
    //             value: 1800
    //         });
    //     }
    //     options.push({
    //         key: 3600,
    //         text: "60 " + t("minutes"),
    //         value: 3600
    //     });
    //     options.push({
    //         key: 10800,
    //         text: "3 " + t("hours"),
    //         value: 10800
    //     });
    //     options.push({
    //         key: 21600,
    //         text: "6 " + t("hours"),
    //         value: 21600
    //     });
    //     options.push({
    //         key: 42300,
    //         text: "12 " + t("hours"),
    //         value: 42300
    //     });
    //     options.push({
    //         key: 86400,
    //         text: "24 " + t("hours"),
    //         value: 86400
    //     });
    //     return options;
    //     // this.setState({
    //     //     measure_interval_options: options
    //     // })
    // };

    // handleLW13IOOptions = () => {
    //     const { t } = this.props;
    //     let options = [];
    //     options.push({
    //         key: "open relay",
    //         text: t("Open relay"),
    //         value: "open relay"
    //     })
    //     options.push({
    //         key: "close relay",
    //         text: t("Close relay"),
    //         value: "close relay"
    //     })
    //     options.push({
    //         key: "status request",
    //         text: t("Request status"),
    //         value: "status request"
    //     })
    //     options.push({
    //         key: "restart",
    //         text: t("Restart device"),
    //         value: "restart"
    //     })
    //     return options;
    // };

    // handleLR2x0Options = () => {
    //     const { t } = this.props;
    //     let options = [];
    //     options.push({
    //         key: "both channels open",
    //         text: t("Both channels open"),
    //         value: "both channels open"
    //     })
    //     options.push({
    //         key: "both channels closed",
    //         text: t("Both channels closed"),
    //         value: "both channels closed"
    //     })
    //     options.push({
    //         key: "ch1 open ch2 closed",
    //         text: t("Ch1 open Ch2 closed"),
    //         value: "ch1 open ch2 closed"
    //     })
    //     options.push({
    //         key: "ch1 closed ch2 open",
    //         text: t("Ch1 closed Ch2 open"),
    //         value: "ch1 closed ch2 open"
    //     })
    //     options.push({
    //         key: "relay state request",
    //         text: t("Relay state request"),
    //         value: "relay state request"
    //     })
    //     options.push({
    //         key: "status request",
    //         text: t("Status request"),
    //         value: "status request"
    //     })
    //     options.push({
    //         key: "device reset",
    //         text: t("Reset device"),
    //         value: "device reset"
    //     })
    //     return options;
    // };

    // handleTHProbeHeatingModeOptions = () => {
    //     const { t } = this.props;
    //     let options = [];
    //     options.push({
    //         key: 0,
    //         text: t("0 = No heating"),
    //         value: 0
    //     })
    //     options.push({
    //         key: 1,
    //         text: t("1 = Low heating, short measurement"),
    //         value: 1
    //     })
    //     options.push({
    //         key: 2,
    //         text: t("2 = Low heating, long measurement"),
    //         value: 2
    //     })
    //     options.push({
    //         key: 3,
    //         text: t("3 = Medium heating, short measurement"),
    //         value: 3
    //     })
    //     options.push({
    //         key: 4,
    //         text: t("4 = Medium heating, long measurement"),
    //         value: 4
    //     })
    //     options.push({
    //         key: 5,
    //         text: t("5 = High heating, short measurement"),
    //         value: 5
    //     })
    //     options.push({
    //         key: 6,
    //         text: t("6 = High heating, long measurement"),
    //         value: 6
    //     })
    //     return options;
    // };

    // handleRangeOptions = () => {
    //     const { t } = this.props;
    //     let options = [];
    //     let i = 200;
    //     while (i < 12050) {
    //         options.push({
    //             key: i,
    //             text: i.toString(),
    //             value: i
    //         })
    //         i += 50;
    //     }
    //     return options;
    // };

    // handleThresholdOptions = () => {
    //     const { t } = this.props;
    //     let options = [];
    //     let i = 300;
    //     while (i < 6100) {
    //         options.push({
    //             key: i,
    //             text: i.toString(),
    //             value: i
    //         })
    //         i += 100;
    //     }
    //     return options;
    // };

    handleChange = e => {
        const { sensor } = this.state;
        const updatedDetails = {
            ...sensor,
            [e.target.name]: e.target.value,
        };
        this.setState({
            sensor: updatedDetails,
            success_mgs: null,
            error: null,
        });
    };

    handleMeasureIntervalSelectChange = (e, { name, value }) => {
        const { sensor } = this.state;
        let updatedDetails = {
            ...sensor,
            [name]: value
        };
        this.setState({
            sensor: updatedDetails,
            success_mgs: null,
            error: null,
        });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            success_mgs: null,
            error: null,
        });
    };


    handleCheckboxChange = (e, { name, checked }) => {
        this.setState({
            [name]: checked,
            success_mgs: null,
            error: null,
        });
    };

    handleSettingCheckboxChange = (e, { value, checked }) => {
        let { selected_sensor_downlink_settings } = this.state;
        if (checked) {
            if (!selected_sensor_downlink_settings.includes(value)) {
                selected_sensor_downlink_settings.push(value);
            }
        } else {
            if (selected_sensor_downlink_settings.includes(value)) {
                selected_sensor_downlink_settings.splice(selected_sensor_downlink_settings.indexOf(value), 1);
            }
        }
        this.setState({ selected_sensor_downlink_settings });
    };

    handleCheckIfSelectedSetting = value => {
        const { selected_sensor_downlink_settings } = this.state;
        let i = 0;
        let return_value = false;
        for (i in selected_sensor_downlink_settings) {
            if (value === selected_sensor_downlink_settings[i]) {
                return_value = true;
            }
        }
        return return_value;
    };

    handleSettingSelectChange = (e, { name, value }) => {
        const { selected_sensor_downlink_settings, sensor_downlink_settings } = this.state;
        let updatedSetting = {
            ...name,
            current_value: value
        }
        if (selected_sensor_downlink_settings.includes(name)) {
            selected_sensor_downlink_settings.splice(selected_sensor_downlink_settings.indexOf(name), 1, updatedSetting);
        }        

        if (sensor_downlink_settings.includes(name)) {
            sensor_downlink_settings.splice(sensor_downlink_settings.indexOf(name), 1, updatedSetting);
        }  
        this.setState({ selected_sensor_downlink_settings, sensor_downlink_settings });
    };


    render() {
        const {
            sensor,
            error,
            success_mgs,
            loading,
            component_loading,
            sensor_downlink_settings
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell active_sensor_id={sensor && sensor.id} active_sensor_name={sensor && sensor.local_name} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>
                {sensor && (
                    <>
                        {component_loading ? (
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>
                                <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                            </Segment>

                        ) : (
                            <React.Fragment>
                                {loading && (
                                    <Segment>
                                        <Dimmer active inverted>
                                            <Loader inverted>Loading</Loader>
                                        </Dimmer>
                                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                    </Segment>
                                )}

                                <Header as="h3">{t("Sensor downlink settings")}</Header>
                                <br/>
                                <Form onSubmit={this.handleSubmit} error={error !== null}>
                                    {sensor.sensor_model.sensor_class === "A" && (
                                        <>
                                            <Header as="h5">{t("A class device")}</Header>
                                            {t("Settings will be sent to the sensor after next scheduled uplink message")}
                                        </>
                                    )}
                                    {sensor.sensor_model.sensor_class === "C" && (
                                        <>
                                            <Header as="h5">{t("C class device")}</Header>
                                            {t("Settings will be sent to the sensor immediately")}
                                        </>
                                    )}
                                    <Form.Field width="6"><hr/></Form.Field>
                                    
                                    <Form.Field width="6">
                                    
                                    <b >{t("Sensor")}: </b>{sensor.local_unit}/{sensor.local_line}/{sensor.local_machine}/{sensor.local_name}<br/>
                                    <b >{t("Sensor model")}: </b> {sensor.device_name} <br/>
                                    <b >{t("Device EUI")}: </b> {sensor.device_eui} <br/>

                                    {/* <label>{t("Sensor model")}</label>
                                    <label>{sensor.model_name}</label> */}
                                    <hr/>
                                    {/* <Input
                                        required
                                        value={sensor.model_name}
                                        name="model_name" /> */}
                                    </Form.Field>
                                    {sensor_downlink_settings && (
                                        sensor_downlink_settings.map((setting, key) => {
                                            if (setting.is_active) {
                                                return (
                                                    <>
                                                        <Form.Field width="4">
                                                            <Checkbox
                                                                label={<label>{t(setting.sensor_model_downlink_setting.display_name)}</label>}
                                                                onChange={this.handleSettingCheckboxChange}
                                                                checked={this.handleCheckIfSelectedSetting(setting) === true}
                                                                value={setting}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field width={4}>
                                                            <Select
                                                                search
                                                                disabled={!this.handleCheckIfSelectedSetting(setting)}
                                                                name={setting}
                                                                options={this.handleSetOptions(setting.sensor_model_downlink_setting.sensormodeldownlinksettingoptions)}
                                                                placeholder={setting.current_value}
                                                                onChange={this.handleSettingSelectChange}
                                                                value={setting.current_value}
                                                            />
                                                        </Form.Field>
                                                        {setting.sensor_model_downlink_setting.display_comment && (
                                                            <>
                                                            {t(setting.sensor_model_downlink_setting.display_comment)}<br/>
                                                            </>
                                                        )}
                                                        {setting.last_sent_timestamp && (
                                                            <>
                                                                <label>{t("Last modified")}</label>:&nbsp;
                                                                {setting.last_sent_timestamp ? new Date(setting.last_sent_timestamp).toLocaleString('fi-FI') : ""}&nbsp;{setting.author && (setting.author.username)}<br/>
                                                            </>
                                                        )}
                                                        <Form.Field width="6">
                                                            <hr/>
                                                        </Form.Field>
                                                    </>
                                                );
                                            }
                                        })
                                    )}
                                    {/* {(sensor.sensor_model.sensor_class === "A" && sensor.sensor_model.sensor_type !== "water") && (
                                        <>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("Measure interval (sec)")}</label>}
                                                    name="is_measure_interval"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_measure_interval}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_measure_interval}
                                                    name='measure_interval'
                                                    options={this.handleSetMeasureIntervalOptions()}
                                                    placeholder={this.handleSetMeasureIntervalPlaceholder(sensor.measure_interval)}
                                                    onChange={this.handleMeasureIntervalSelectChange}
                                                    value={sensor.measure_interval}
                                                />
                                            </Form.Field>
                                        </>
                                    )} */}
                                    {/* {sensor.sensor_model.model_name === "MCF88-LW13IO" && (
                                        <>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("Downlink setting")}</label>}
                                                    name="is_LW13IO_downlink_request"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_LW13IO_downlink_request}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_LW13IO_downlink_request}
                                                    name='LW13IO_downlink_request'
                                                    options={this.handleLW13IOOptions()}
                                                    placeholder={this.state.LW13IO_downlink_request}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.LW13IO_downlink_request}
                                                />
                                            </Form.Field>
                                        </>
                                    )} */}
                                    {/* {sensor.sensor_model.model_name.startsWith("DNIL-LR2") && (
                                        <>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("Downlink setting")}</label>}
                                                    name="is_LR2x0_downlink_request"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_LR2x0_downlink_request}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_LR2x0_downlink_request}
                                                    name='LR2x0_downlink_request'
                                                    options={this.handleLR2x0Options()}
                                                    placeholder={this.state.LR2x0_downlink_request}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.LR2x0_downlink_request}
                                                />
                                            </Form.Field>
                                        </>
                                    )} */}
                                    {/* {sensor.sensor_model.model_name === "CAPROC-TH-PROBE" && (
                                        <>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("TH probe heating mode")}</label>}
                                                    name="is_TH_probe_1_heating_mode"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_TH_probe_1_heating_mode}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_TH_probe_1_heating_mode}
                                                    name='TH_probe_1_heating_mode'
                                                    options={this.handleTHProbeHeatingModeOptions()}
                                                    placeholder={this.state.TH_probe_1_heating_mode}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.TH_probe_1_heating_mode}
                                                />
                                            </Form.Field>
                                        </>
                                    )} */}
                                    {/* {sensor.sensor_model.model_name === "CAPROC-TH-PROBE-2" && (
                                        <>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("TH probe heating mode")}</label>}
                                                    name="is_TH_probe_1_heating_mode"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_TH_probe_1_heating_mode}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_TH_probe_1_heating_mode}
                                                    name='TH_probe_1_heating_mode'
                                                    options={this.handleTHProbeHeatingModeOptions()}
                                                    placeholder={this.state.TH_probe_1_heating_mode}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.TH_probe_1_heating_mode}
                                                />
                                            </Form.Field>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("TH probe 2 heating mode")}</label>}
                                                    name="is_TH_probe_2_heating_mode"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_TH_probe_2_heating_mode}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_TH_probe_2_heating_mode}
                                                    name='TH_probe_2_heating_mode'
                                                    options={this.handleTHProbeHeatingModeOptions()}
                                                    placeholder={this.state.TH_probe_2_heating_mode}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.TH_probe_2_heating_mode}
                                                />
                                            </Form.Field>
                                        </>
                                    )}
                                    {sensor.sensor_model.model_name === "CAPROC-TH-PROBE-3" && (
                                        <>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("TH probe heating mode")}</label>}
                                                    name="is_TH_probe_1_heating_mode"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_TH_probe_1_heating_mode}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_TH_probe_1_heating_mode}
                                                    name='TH_probe_1_heating_mode'
                                                    options={this.handleTHProbeHeatingModeOptions()}
                                                    placeholder={this.state.TH_probe_1_heating_mode}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.TH_probe_1_heating_mode}
                                                />
                                            </Form.Field>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("TH probe 2 heating mode")}</label>}
                                                    name="is_TH_probe_2_heating_mode"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_TH_probe_2_heating_mode}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_TH_probe_2_heating_mode}
                                                    name='TH_probe_2_heating_mode'
                                                    options={this.handleTHProbeHeatingModeOptions()}
                                                    placeholder={this.state.TH_probe_2_heating_mode}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.TH_probe_2_heating_mode}
                                                />
                                            </Form.Field>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("TH probe 3 heating mode")}</label>}
                                                    name="is_TH_probe_3_heating_mode"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_TH_probe_3_heating_mode}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_TH_probe_3_heating_mode}
                                                    name='TH_probe_3_heating_mode'
                                                    options={this.handleTHProbeHeatingModeOptions()}
                                                    placeholder={this.state.TH_probe_3_heating_mode}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.TH_probe_3_heating_mode}
                                                />
                                            </Form.Field>
                                        </>
                                    )} */}
                                    {/* {sensor.sensor_model.model_name.startsWith("CAPROC-RADAR") && (
                                        <>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("Range start")}</label>}
                                                    name="is_xm132_range_start"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_xm132_range_start}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_xm132_range_start}
                                                    name='xm132_range_start'
                                                    options={this.handleRangeOptions()}
                                                    placeholder={this.state.xm132_range_start.toString()}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.xm132_range_start}
                                                />
                                            </Form.Field>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("Range length")}</label>}
                                                    name="is_xm132_range_length"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_xm132_range_length}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_xm132_range_length}
                                                    name='xm132_range_length'
                                                    options={this.handleRangeOptions()}
                                                    placeholder={this.state.xm132_range_length.toString()}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.xm132_range_length}
                                                />
                                            </Form.Field>
                                            <Form.Field width="4">
                                                <Checkbox
                                                    label={<label>{t("Amplitude threshold")}</label>}
                                                    name="is_xm132_threshold"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.is_xm132_threshold}
                                                />
                                            </Form.Field>
                                            <Form.Field width={4}>
                                                <Select
                                                    search
                                                    disabled={!this.state.is_xm132_threshold}
                                                    name='xm132_threshold'
                                                    options={this.handleThresholdOptions()}
                                                    placeholder={this.state.xm132_threshold.toString()}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.xm132_threshold}
                                                />
                                            </Form.Field>
                                            {t("250-500 = Soft material (plastic, clothes, general waste)")} <br/>
                                            {t("500-1000=Medium soft material")} <br/>
                                            {t(">1000=Hard material (liquid, steel)")}
                                        </>
                                    )} */}
                                    {/* <Form.Field width="6">
                                        <hr/>
                                    </Form.Field> */}
                                    
                                    {error && <Message error header={t("Error")} content={error.response.data} />}
                                    {success_mgs && ( 
                                        <Form.Field width="8">
                                            <Message positive header={success_mgs} />
                                        </Form.Field>
                                    )}
                                    
                                    <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleSubmit(e)}>
                                        {t("Send")}
                                    </Button>
                                </Form>
                            </React.Fragment>
                        )}
                    </>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensordownlinksettings')(SensorDownlinkSettings))
);