import { Responsive } from "semantic-ui-react";
import axios from "axios";
import { endpoint, host, exportToCsvURL } from "./constants";


export const authAxios = axios.create({
    baseURL: endpoint,
    headers: {
        Authorization: {
            toString() {
                return `Token ${localStorage.getItem("token")}`;
            }
        }
    }
});

export const authFileAxios = axios.create({
    baseURL: endpoint,
    headers: {
        Authorization: {
            toString() {
                return `Token ${localStorage.getItem("token")}`;
            }
        },
        'content-type': 'multipart/form-data'
    }
});

export const restAuthAxios = axios.create({
    baseURL: host,
    headers: {
        Authorization: {
            toString() {
                return `Token ${localStorage.getItem("token")}`;
            }
        }
    }
});

export const getWidth = () => {
    const isSSR = typeof window === "undefined";
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

export const getMobileWidth = () => {
    const isSSR = typeof window === "undefined";
    return isSSR ? Responsive.onlyMobile.minWidth : window.innerWidth;
};


// Format given date to format defined in the "format input parameter". Possible formats are "Date", "Time", "TimeSeconds", "DateTime", "DateTimeSeconds"
export const getISOFormattedDate = (input_date, format) => {
    if (input_date === null) return null;
    // Format to "YYYY-MM-DD HH:mm:ss". getMonth() January is 0, so add + 1 to get proper calendar month number
    let formatted_date = "";
    if (format.includes("Date")) {
        formatted_date = input_date.getFullYear() + "-";
        // Add 0 if value less than 10
        if ((input_date.getMonth() + 1) < 10) {
            formatted_date = formatted_date + "0" + (input_date.getMonth() + 1) + "-";
        } else formatted_date = formatted_date + (input_date.getMonth() + 1) + "-";
        // Add 0 if value less than 10
        if (input_date.getDate() < 10) {
            formatted_date = formatted_date + "0" + input_date.getDate();
        } else formatted_date = formatted_date + input_date.getDate();
    }
    if (format.includes("Time")) {
        if (formatted_date !== "") {
            formatted_date = formatted_date + " ";
        }
        // Add 0 if value less than 10
        if (input_date.getHours() < 10) {
            formatted_date = formatted_date + "0" + input_date.getHours();
        } else formatted_date = formatted_date + input_date.getHours();
        // Add 0 if value less than 10
        if (input_date.getMinutes() < 10) {
            formatted_date = formatted_date + ":0" + input_date.getMinutes();
        } else formatted_date = formatted_date + ":" + input_date.getMinutes();
        if (format.includes("Seconds")) {
            // Add 0 if value less than 10
            if (input_date.getSeconds() < 10) {
                formatted_date = formatted_date + ":0" + input_date.getSeconds();
            } else formatted_date = formatted_date + ":" + input_date.getSeconds();
        }
    }
    return formatted_date;
};
// Calculate Net price for set of items
export const getTotalNetPrice = items => {
    let i = 0;
    let net = 0;
    for (i in items) {
        net += getItemNetPrice(items[i])
    }
    return net;
}
// Calculate Gross price for set of items
export const getTotalGrossPrice = items => {
    let i = 0;
    let gross = 0;
    // Go through all items in invoice and add them together multiplied with VAT%
    for (i in items) {
        gross += getItemGrossPrice(items[i]);
    }
    return gross;
}
// Calculate Net price for single item
export const getItemNetPrice = item => {
    let discount_multiplier = 1;
    // e.g. 10% discount => 0.90 as multiplier
    if (item.discount > 0) {
        discount_multiplier = (100 - item.discount) / 100;
    }
    return (item.net_price * item.amount * discount_multiplier);
}
// Calculate Gross price for single item
export const getItemGrossPrice = item => {
    let vat_multiplier = 1;
    // If item vat value is 0, use just net_price => vat_multiplier = 1
    // Otherwise change e.g. 24% VAT value into 1.24 and use as a mulplier for gross price
    if (item.vat_value > 0) {
        vat_multiplier = (item.vat_value / 100) + 1;
    }
    return getItemNetPrice(item) * vat_multiplier;
}
// Validate new passwords
export const validatePasswords = (password1, password2) => {
    if (password1 !== password2) {
        return "Passwords do not match";
    } else if (password1.length < 8) {
        // return "Please check passwords. Password length needs to be more than 8 characters and include numbers and alphabets"
        return "Please check passwords. Password length needs to be more than 8 characters and include numbers, alphabets and special characters [$@$!%*#?&]"
    } else {
        // Create an array and push all possible values that is needed in password
        let matchedCase = [];
        var ctr = 0;
        var i = 0;
        
        matchedCase.push("[0-9]");      // Numbers

        // First check numbers
        for (i = 0; i < matchedCase.length; i++) {
            if (new RegExp(matchedCase[i]).test(password1)) {
                ctr++;
            }
        }
        if (ctr > 0) {
            matchedCase = [];
            matchedCase.push("[$@$!%*#?&]"); // Special Character
            for (i = 0; i < matchedCase.length; i++) {
                if (new RegExp(matchedCase[i]).test(password1)) {
                    ctr++;
                }
            }
        }

        if (ctr > 1) {
            matchedCase = [];
            matchedCase.push("[A-Z]");      // Uppercase Alpabets
            matchedCase.push("[a-z]");     // Lowercase Alpabets    
            // Then Alpabets
            for (i = 0; i < matchedCase.length; i++) {
                if (new RegExp(matchedCase[i]).test(password1)) {
                    ctr++;
                }
            }
        }
        if (ctr < 3) {
            return "Please check passwords. Password needs to include numbers, alphabets and special characters [$@$!%*#?&]"
        } else {
            return "ok";
        }
    }
}

export const exportToCsv = (company, file_name, titles, data) => {
    if (titles === "") {
        titles = null;
    }
    authAxios
        .put(exportToCsvURL(company), { 
            responseType: 'blob',
            file_name: file_name,
            titles: titles,
            data: data
        })
        .then(res => {
            //Create a Blob from the data stream
            const res_file = new Blob(
                ["\uFEFF"+res.data]); // Add extra characters for CSV
            //Build a URL from the file
            const fileURL = URL.createObjectURL(res_file);
            // Get the file name from the URL
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', file_name + ".csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
            return "ok";
        })
        .catch(err => {
            return err;
        });
}

export const roundFloat = (number, decimalPlaces) => {
    const factorOfTen = Math.pow(10, decimalPlaces);
    return Math.round(number * factorOfTen) / factorOfTen;
}