import React from "react";
import { Form, Input, Message, Header, Button, Select } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyContactDetailsURL, updateCompanyContactURL, createCompanyContactURL, companyContactRoleOptionsURL } from "../../constants";


class CompanyContactDetails extends React.Component {
    state = {
        company: "",
        first_name: "",
        last_name: "",
        title: "",
        email: "",
        phone: "",
        role: "",
        role_options: null,
        contact_id: null,
        error: null,
        success_mgs: null,
        loading: false,
        create: null
    };

    componentDidMount() {
        this.props.setActivity();
        this.fetctRoleOptions();

        if (this.props.match.params.contact_id === "create") {
            this.setState({
                create: true,
                is_active: true
            })
        } else {
            this.handleDetails();
        }
    }

    fetctRoleOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(companyContactRoleOptionsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    role_options: this.handleFormatRoleOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatRoleOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: options[k].name,
                value: options[k].value
            };
        });
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { contact_id } = this.props.match.params;
        authAxios
            .get(companyContactDetailsURL(activeCompanyId, contact_id))
            .then(res => {
                this.setState({
                    contact_id: res.data.id,
                    company: res.data.company,
                    first_name: res.data.first_name,
                    last_name: res.data.last_name,
                    title: res.data.title,
                    email: res.data.email,
                    phone: res.data.phone,
                    role: res.data.role,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            first_name,
            last_name,
            title,
            email,
            phone,
            role,
            contact_id,
            create
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (first_name === "") {
            this.setState({
                error: t("Please fill in first name"),
                success_mgs: null,
                loading: false
            });
        } else if (last_name === "") {
            this.setState({
                error: t("Please fill in last name"),
                success_mgs: null,
                loading: false
            });
        } else if (first_name.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for First name"),
                success_mgs: null,
                loading: false
            });
        } else if (last_name.length > 30) {
            this.setState({
                error: t("Max amount of characters is 30 for Last name"),
                success_mgs: null,
                loading: false
            });
        } else if (title.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for Title"),
                success_mgs: null,
                loading: false
            });
        } else if (phone.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for Phone"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (create) {
                authAxios
                    .post(createCompanyContactURL(activeCompanyId), {
                        company: activeCompanyId,
                        first_name,
                        last_name,
                        title,
                        email,
                        phone,
                        role,
                        author: localStorage.getItem("UserID")
                    })
                    .then(res => {
                        this.props.history.push(`/company/contacts/`);
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });

            } else {
                authAxios
                    .put(updateCompanyContactURL(activeCompanyId, contact_id), {
                        company: activeCompanyId,
                        first_name,
                        last_name,
                        title,
                        email,
                        phone,
                        role
                    })
                    .then(res => {
                        this.props.history.push(`/company/contacts/`);
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    render() {
        const {
            first_name,
            last_name,
            title,
            email,
            phone,
            role,
            role_options,
            error,
            success_mgs,
            loading,
            create
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        return (
            <Shell>
                <Header as="h4">{t("Company contact")}</Header>
                <Form onSubmit={this.handleSubmit} error={error !== null}>
                    <Form.Field required>
                        <label>{t("First name")}</label>
                        <Input
                            required
                            value={first_name}
                            name="first_name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Last name")}</label>
                        <Input
                            required
                            value={last_name}
                            name="last_name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Title")}</label>
                        <Input
                            value={title}
                            name="title"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Email")}</label>
                        <Input
                            required
                            value={email}
                            name="email"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Phone")}</label>
                        <Input
                            value={phone}
                            name="phone"
                            onChange={this.handleChange} />
                    </Form.Field>
                    {role_options && (
                        <Form.Field required>
                            <label>{t("Role")}</label>
                            <Select
                                required
                                search
                                fluid
                                name='role'
                                options={role_options}
                                placeholder={role.toString()}
                                onChange={this.handleSelectChange}
                                value={role}
                            />
                        </Form.Field>
                    )}

                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Button primary type="submit" loading={loading} disabled={loading}>
                        {create ? (t("Create")) : (t("Save"))}
                    </Button>
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companycontactdetails')(CompanyContactDetails))
);