// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 19.3.2020 TS Initial version
// 26.2.2021 TS Added options to adjust measurement interval and send values to selected sensors. Now Elsys, Wise and Decentlat are enabled.
// 12.3.2021 TS Added Wastecontrol LoRa to measure intervals.
// 25.3.2021 TS Added alarms controls
// 14.5.2021 TS Added Dingek
// 2.7.2021 TS Added settings_last_modified_time, settings_last_modified_author. Measure interval update only for active sensors.
// 7.3.2022 TS Disabled measure interval setting. It is now in "Sensor downlink settings" tab.

import React from "react";
import { Form, Input, Message, Header, Button, Select, Checkbox, Dimmer, Loader, Segment, Image, TextArea, Accordion, Icon } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { createSensorURL, sensorDetailsURL, updateSensorDetailsURL, fetchSensorGroupsURL, companyAccountRolesURL, fetchSensorModels, updateSensorMeasureIntervalURL, countriesURL } from "../../constants";


class SensorDetails extends React.Component {
    state = {
        sensor: {
            id: null,
            company: "",
            sensor_group: "",
            model_name: "",
            device_name: "",
            uuid: "",
            device_description: "",
            device_address: "",
            device_eui: "",
            device_serial_number: "",
            device_location_address: "",
            device_location_postal_code: "",
            device_location_city: "",
            device_location_country: "fi",
            device_location_longitude: "0",
            device_location_latitude: "0",
            is_active: true,
            is_deleted: false,
            local_name: "",
            local_unit: "",
            local_line: "",
            local_machine: "",
            local_description: "",
            measure_interval: null,
            date_created: "",
            author: null,
            sensor_model: null,
            is_notifications_enabled: true,
            is_email_notifications_enabled: true,
            is_sms_notifications_enabled: false,
            settings_last_modified_time: null,
            settings_last_modified_author: null
        },

        sensor_group_options: null,
        sensor_model_options: null,
        error: null,
        success_mgs: null,
        loading: false,
        component_loading: false,
        create: false,
        roles: null,
        activeIndex: 0,
        measure_interval_options: [],
        country_options: null,
        sensor_type: null
    };

    componentDidMount() {
        this.props.setActivity();
        this.setState({
            component_loading: true
        });
        this.handleGroups();
        this.handleFetchSensorModels();
        this.fetchCountryOptions();
        if (this.props.match.params.sensor_id === "create") {
            this.setState({
                create: true
            })
        } else {
            this.handleDetails();
        }
        this.setState({
            component_loading: false,
        });
        authAxios
        .get(companyAccountRolesURL(this.props.activeCompanyId))
        .then(res => {
            this.setState({
                roles: res.data
            });
    
        })
        .catch(err => {
            // console.log(err);
        });
        this.handleSetMeasureIntervalOptions();

    }

    fetchCountryOptions = () => {
        this.setState({
            loading: true,
        });
        authAxios
            .get(countriesURL)
            .then(res => {
                this.setState({
                    country_options: this.handleFormatCountries(res.data),
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatCountries = countries => {
        const keys = Object.keys(countries);
        return keys.map(k => {
            return {
                key: k,
                text: countries[k],
                value: k
            };
        });
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    };

    handleGroups = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchSensorGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    sensor_group_options: this.handleFormatOptions(res.data),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    customer_group: null,
                    loading: false,
                    error: err
                });
            });
    };

    handleFormatOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: options[k].name,
                value: options[k].id
            };
        });
    };


    handleFetchSensorModels = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchSensorModels(activeCompanyId))
            .then(res => {
                this.setState({
                    sensor_model_options: this.handleModelFormatOptions(res.data),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    customer_group: null,
                    loading: false,
                    error: err
                });
            });
    };

    handleModelFormatOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: options[k].model_name,
                value: options[k],
                image: { src: options[k].thumbnail_image_url, size:"mini" }
            };
        });
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    sensor_type: res.data.sensor_model.sensor_type
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            sensor,
            create
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (sensor.model_name === "") {
            this.setState({
                error: t("Please select Model"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor.sensor_group === "") {
            this.setState({
                error: t("Please select Sensor group"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor.local_unit.length > 100) {
            this.setState({
                error: t("Max amount of characters for Local unit is 100"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor.local_line.length > 100) {
            this.setState({
                error: t("Max amount of characters for Local line is 100"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor.local_machine.length > 100) {
            this.setState({
                error: t("Max amount of characters for Local machine is 100"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor.local_name.length > 100) {
            this.setState({
                error: t("Max amount of characters for Local name is 100"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor.device_location_address.length > 150) {
            this.setState({
                error: t("Max amount of characters is 150 for Address"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor.device_location_postal_code.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Postal code"),
                success_mgs: null,
                loading: false
            });
        } else if (sensor.device_location_city.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for City"),
                success_mgs: null,
                loading: false
            });

        } else {
            if (create) {
                const updatedDetails = {
                    ...sensor,
                    company: activeCompanyId,
                    model_name: sensor.model_name.model_name,
                    author: localStorage.getItem("UserID"),
                    uuid: `${sensor.device_name}_${sensor.device_address}`,
                    settings_last_modified_author: localStorage.getItem("UserID"),
                    settings_last_modified_time: getISOFormattedDate(new Date(), "DateTime")
                }
                await authAxios
                    .post(createSensorURL(activeCompanyId), {
                        ...updatedDetails
                    })
                    .then(res => {
                        // Goto Sensors page 
                        this.props.history.push("/sensors/");

                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });

            } else {
                let updatedSensor = {
                    ...sensor,
                    settings_last_modified_author: localStorage.getItem("UserID"),
                    settings_last_modified_time: getISOFormattedDate(new Date(), "DateTime")
                };
                if (sensor.device_location_country === "") {
                    updatedSensor = {
                        ...sensor,
                        device_location_country: "fi"
                    }
                }
                this.setState({
                    sensor: updatedSensor
                });
                await authAxios
                    .put(updateSensorDetailsURL(activeCompanyId, sensor.id), {
                        ...updatedSensor
                    })
                    .then(res => {
                        this.setState({
                            loading: false,
                            success_mgs: t("Sensor updated successfully"),
                            error: null
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
            // if (this.handleIsMeasureIntervalUpdateEnabled && sensor.is_active) {
            //     this.handleUpdateMeasureInterval();
            // }
        }
    };

    handleIsMeasureIntervalUpdateEnabled = () => {
        const { create, sensor } = this.state;
        if (sensor.model_name === "") return;
        let model_name = "";
        if (create) {
            model_name = sensor.model_name.model_name;
        } else {
            model_name = sensor.model_name;
        }
        if (model_name.startsWith("DECENTLAB") || model_name.startsWith("ELSYS") || model_name.startsWith("WISE") || model_name.startsWith("WASTECONTROL-VU3") || model_name.startsWith("CAPROC") || model_name.startsWith("DINGTEK")) {
            return true;
        } else {
            return false;
        }
    };

    handleSetMeasureIntervalPlaceholder = (measure_interval_sec) => {
        const { measure_interval_options } = this.state;
        const { t } = this.props;
        let i = 0;
        for (i in measure_interval_options) {
            if (measure_interval_options[i].value === measure_interval_sec) {
                return t(measure_interval_options[i].text);
            }
        }
        return measure_interval_sec + " " + t("seconds");
    };

    handleUpdateMeasureInterval = () => {
        const { sensor } = this.state;
        const { activeCompanyId } = this.props;
        if (sensor.id === null) return;
        authAxios
            .post(updateSensorMeasureIntervalURL(activeCompanyId, sensor.id), {
                sensor_id: sensor.id,
                measure_interval_sec: sensor.measure_interval
            })
            .then(res => {
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleSetMeasureIntervalOptions = () => {
        const { t } = this.props;
        const { create, sensor } = this.state;
        if (sensor.model_name === "") return;
        let model_name = "";
        if (create) {
            model_name = sensor.model_name.model_name;
        } else {
            model_name = sensor.model_name;
        }

        let options = [];
        if (!model_name.startsWith("DINGTEK")) {
            options.push({
                key: 600,
                text: t("10 minutes"),
                value: 600
            });
            options.push({
                key: 900,
                text: t("15 minutes"),
                value: 900
            });
            options.push({
                key: 1800,
                text: t("30 minutes"),
                value: 1800
            });
        }
        options.push({
            key: 3600,
            text: t("60 minutes"),
            value: 3600
        });
        options.push({
            key: 10800,
            text: t("3 hours"),
            value: 10800
        });
        options.push({
            key: 21600,
            text: t("6 hours"),
            value: 21600
        });
        options.push({
            key: 42300,
            text: t("12 hours"),
            value: 42300
        });
        options.push({
            key: 86400,
            text: t("24 hours"),
            value: 86400
        });
        return options;
        // this.setState({
        //     measure_interval_options: options
        // })
    };

    handleChange = e => {
        const { sensor } = this.state;
        const updatedDetails = {
            ...sensor,
            [e.target.name]: e.target.value,
        };
        this.setState({
            sensor: updatedDetails,
            success_mgs: null,
            error: null,
        });
    };

    handleSelectChange = (e, { name, value }) => {
        const { sensor } = this.state;
        let updatedDetails = {
            ...sensor,
            [name]: value
        };
        if (name === "model_name") {
            updatedDetails = {
                ...sensor,
                sensor_model: value.id,
                model_name: value,
                device_name: value.device_name,
                device_description: value.device_description,
                measure_interval: value.measure_interval
            };
        }
        this.setState({
            sensor: updatedDetails,
            success_mgs: null,
            error: null,
        });
    };

    handleCheckboxChange = (e, { name, checked }) => {
        const { sensor } = this.state;
        
        const updatedDetails = {
            ...sensor,
            [name]: checked
        };
        this.setState({
            sensor: updatedDetails,
            success_mgs: null,
            error: null,
        });
    };

    render() {
        const {
            sensor,
            error,
            success_mgs,
            loading,
            component_loading,
            sensor_group_options,
            sensor_model_options,
            create,
            activeIndex,
            sensor_type

        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        const logo = localStorage.getItem("Logo");
        return (
            <Shell active_sensor_id={sensor.id} active_sensor_name={sensor.local_name} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor_type)}>

                {component_loading ? (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>

                ) : (
                    <>
                        {logo && (
                            <React.Fragment>
                                {/* {error && (
                                    <Message
                                        error
                                        header="There was an error"
                                        content={JSON.stringify(error)}
                                    />
                                )} */}
                                {loading && (
                                    <Segment>
                                        <Dimmer active inverted>
                                            <Loader inverted>Loading</Loader>
                                        </Dimmer>
                                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                    </Segment>
                                )}


                                <Header as="h3">{t("Sensor details")}</Header>
                                <br/>
                                <Form onSubmit={this.handleSubmit} error={error !== null}>
                                    <Header as="h5">{t("Device details")}</Header>
                                    <hr/>
                                    {(sensor_model_options && create) ? (
                                        <Form.Field required width="8">
                                            <label>{t("Sensor model")}</label>
                                            <Select
                                                required
                                                search
                                                name='model_name'
                                                options={sensor_model_options}
                                                placeholder={create ? (t("Select sensor model")) : (sensor.model_name.toString())}
                                                onChange={this.handleSelectChange}
                                                value={sensor.model_name}
                                            />
                                        </Form.Field>
                                    ) : (
                                        <Form.Field required width="8">
                                        <label>{t("Sensor model (read-only)")}</label>
                                        <Input
                                            required
                                            value={sensor.model_name}
                                            name="model_name" />
                                        </Form.Field>
                                    )}
                                    {create ? (
                                        <React.Fragment>
                                            <Form.Field required width="8">
                                                <label>{t("Device name")}</label>
                                                <Input
                                                    required
                                                    value={sensor.device_name}
                                                    name="device_name"
                                                    onChange={this.handleChange} />
                                            </Form.Field>
                                            <Form.Field width="8">
                                                <label>{t("Device description")}</label>
                                                <Input
                                                    value={sensor.device_description}
                                                    name="device_description"
                                                    onChange={this.handleChange} />
                                            </Form.Field>
                                            <Form.Field required width="5">
                                                <label>{t("Serial number")}</label>
                                                <Input
                                                    value={sensor.device_serial_number}
                                                    name="device_serial_number"
                                                    onChange={this.handleChange} />
                                            </Form.Field>
                                            <Form.Field required width="5">
                                                <label>{t("Device address")}</label>
                                                <Input
                                                    required
                                                    value={sensor.device_address}
                                                    name="device_address"
                                                    onChange={this.handleChange} />
                                            </Form.Field>
                                            <Form.Field required width="5">
                                                <label>{t("Device EUI")}</label>
                                                <Input
                                                    required
                                                    value={sensor.device_eui}
                                                    name="device_eui"
                                                    onChange={this.handleChange} />
                                            </Form.Field>
                                        </React.Fragment>
                                    ):(
                                        <>
                                        <Accordion>
                                            <Accordion.Title
                                                active={activeIndex === 1}
                                                index={1}
                                                onClick={this.handleClick}>
                                                <Icon name='dropdown' />
                                                <b>{t("Read only information")}</b> 
                                            </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 1}>
                                                <React.Fragment>
                                                    <Form.Field width="8">
                                                        <label>{t("Device name")}</label>
                                                        <Input
                                                            required
                                                            value={sensor.device_name}
                                                            name="device_name"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                    <Form.Field width="8">
                                                        <label>{t("Device description (read-only)")}</label>
                                                        <Input
                                                            value={sensor.device_description}
                                                            name="device_description" />
                                                    </Form.Field>
                                                    <Form.Field required width="8">
                                                        <label>{t("Serial number (read-only)")}</label>
                                                        <Input
                                                            value={sensor.device_serial_number}
                                                            name="device_serial_number" />
                                                    </Form.Field>
                                                    <Form.Field required width="8">
                                                        <label>{t("Device address (read-only)")}</label>
                                                        <Input
                                                            required
                                                            value={sensor.device_address}
                                                            name="device_address" />
                                                    </Form.Field>
                                                    <Form.Field required width="8">
                                                        <label>{t("Device EUI (read-only)")}</label>
                                                        <Input
                                                            required
                                                            value={sensor.device_eui}
                                                            name="device_eui" />
                                                    </Form.Field>
                                                </React.Fragment>
                                            </Accordion.Content>
                                        </Accordion>
                                        <br/>
                                        </>
                                    )}
                                    {/* <Form.Field required width="4">
                                        <Popup content={t("Updated measure interval will be sent to the sensor when saving the sensor details")} trigger={
                                            <label>{t("Measure interval (sec)")}</label>
                                        } />
                                        <Select
                                            search
                                            disabled={!this.handleIsMeasureIntervalUpdateEnabled()}
                                            name='measure_interval'
                                            options={this.handleSetMeasureIntervalOptions()}
                                            placeholder={this.handleSetMeasureIntervalPlaceholder(sensor.measure_interval)}
                                            onChange={this.handleSelectChange}
                                            value={sensor.measure_interval}
                                        />
                                    </Form.Field> */}
                                    <Header as="h5">{t("Local details")}</Header>
                                    <hr/>

                                    <Form.Field width="8">
                                        {logo === "Polygon" ? (
                                             <label>{t("Sensor location on site")}</label>
                                        ) : (
                                            <label>{t("Name")}</label>
                                        )}
                                        <Input
                                            value={sensor.local_name}
                                            name="local_name"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    {logo !== "Polygon" && (
                                        <>
                                            <Form.Field width="8">
                                                <label>{t("Unit")}</label>
                                                <Input
                                                    value={sensor.local_unit}
                                                    name="local_unit"
                                                    onChange={this.handleChange} />
                                            </Form.Field>
                                            <Form.Field width="8">
                                                <label>{t("Line")}</label>
                                                <Input
                                                    value={sensor.local_line}
                                                    name="local_line"
                                                    onChange={this.handleChange} />
                                            </Form.Field>
                                            <Form.Field width="8">
                                                <label>{t("Machine")}</label>
                                                <Input
                                                    value={sensor.local_machine}
                                                    name="local_machine"
                                                    onChange={this.handleChange} />
                                            </Form.Field>
                                        </>
                                    )}
                                    <Form.Field
                                        control={TextArea}
                                        label={logo === "Polygon" ? t("Additional information") : t("Description")}
                                        value={sensor.local_description}
                                        name="local_description"
                                        onChange={this.handleChange}
                                        width="8" />

                                    <Header as="h5">{t("Sensor location address details")}</Header>
                                    <hr/>
                                    <Form.Field width="8">
                                        <label>{t("Address")}</label>
                                        <Input
                                            value={sensor.device_location_address}
                                            name="device_location_address"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field width="3">
                                        <label>{t("Postal code")}</label>
                                        <Input
                                            value={sensor.device_location_postal_code}
                                            name="device_location_postal_code"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field width="5">
                                        <label>{t("City")}</label>
                                        <Input
                                            value={sensor.device_location_city}
                                            name="device_location_city"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field width="5">
                                        <label>{t("Country")}</label>
                                        <Select
                                            search
                                            name='device_location_country'
                                            options={this.state.country_options}
                                            placeholder={sensor.device_location_country}
                                            onChange={this.handleSelectChange}
                                            value={sensor.device_location_country}
                                        />
                                    </Form.Field>
                                    <Header as="h5">{t("Sensor location details")}</Header>
                                    <hr/>
                                    <Form.Field width="3">
                                        <label>{t("Latitude")}</label>
                                        <Input
                                            value={sensor.device_location_latitude}
                                            name="device_location_latitude"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field width="3">
                                        <label>{t("Longitude")}</label>
                                        <Input
                                            value={sensor.device_location_longitude}
                                            name="device_location_longitude"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <hr/>
                                    {sensor_group_options && (
                                        <Form.Field required width="5">
                                            <label>{t("Sensor group")}</label>
                                            <Select
                                                required
                                                search
                                                fluid
                                                name='sensor_group'
                                                options={sensor_group_options}
                                                placeholder={create ? (t("Select sensor group")) : (sensor.sensor_group.toString())}
                                                onChange={this.handleSelectChange}
                                                value={sensor.sensor_group}
                                            />
                                        </Form.Field>
                                    )}
                                    {!create && (
                                        <>
                                            <hr/>
                                            <Form.Field>
                                                <Checkbox
                                                    label={<label>{t("Alarms enabled")}</label>}
                                                    name="is_notifications_enabled"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={sensor.is_notifications_enabled}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    disabled={!sensor.is_notifications_enabled}
                                                    label={<label>{t("Email alarms enabled")}</label>}
                                                    name="is_email_notifications_enabled"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={sensor.is_email_notifications_enabled}
                                                />
                                            </Form.Field>
                                            {/* <Form.Field>
                                                <Checkbox
                                                    disabled
                                                    // disabled={!sensor.is_notifications_enabled}
                                                    label={<label>{t("SMS alarms enabled")}</label>}
                                                    name="is_sms_notifications_enabled"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={sensor.is_sms_notifications_enabled}
                                                />
                                            </Form.Field> */}
                                            <hr/>
                                        </>
                                    )}
                                    <Form.Field>
                                        <Checkbox
                                            label={<label>{t("Is active")}</label>}
                                            name="is_active"
                                            onChange={this.handleCheckboxChange}
                                            checked={sensor.is_active}
                                        />
                                    </Form.Field>
                                    <label>{t("Created")}</label>:&nbsp;
                                    {new Date(sensor.date_created).toLocaleString('fi-FI')}<br/>
                                    <label>{t("Last modified")}</label>:&nbsp;
                                    {sensor.settings_last_modified_time ? new Date(sensor.settings_last_modified_time).toLocaleString('fi-FI') : ""}&nbsp;{sensor.settings_last_modified_author && (sensor.settings_last_modified_author.username)}<br/><br/>
                                    

                                    {error && <Message error header={t("Error")} content={error} />}
                                    {success_mgs && <Message positive header={success_mgs} />}
                                    <Button primary type="submit" loading={loading} disabled={loading}>
                                        {create ? (t("Create")) : (t("Save"))}
                                    </Button>
                                </Form>
                            </React.Fragment>
                        )}
                    </>
                )}
            </Shell>

        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensordetails')(SensorDetails))
);