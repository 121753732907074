import axios from "axios";
import * as actionTypes from "./actionTypes";
import { authAxios, restAuthAxios } from "../../utils";
import { fetchUserCompanies, resetUserCompanies } from "./company";
import { accountDetailsURL, loginURL, logoutURL, signupURL, resetPasswordURL } from "../../constants";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  };
};

export const authLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
    token: null
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};


export const logout = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      dispatch(authStart());
      restAuthAxios
        .post(logoutURL)
        .then(res => {
          // console.log(res);
          localStorage.removeItem("token");
          localStorage.removeItem("expirationDate");
          localStorage.removeItem("userName");
          localStorage.removeItem("userFullName");
          localStorage.removeItem("UserID");
          localStorage.removeItem("Logo");
          dispatch(authLogout());
          dispatch(resetUserCompanies());
        })
        .catch(err => {
          dispatch(authFail(err));
          localStorage.removeItem("token");
          localStorage.removeItem("expirationDate");
          localStorage.removeItem("userName");
          localStorage.removeItem("userFullName");
          localStorage.removeItem("UserID");
          localStorage.removeItem("Logo");
          dispatch(authLogout());
          dispatch(resetUserCompanies());
          // console.log(err);
        });
    }
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(authCheckState());
    }, expirationTime * 1000);
  };
};

export const authLogin = (username, password) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(loginURL, {
        username: username,
        password: password
      })
      .then(res => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 86400 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("userName", username);
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(86400));
        dispatch(fetchUserCompanies());
        authAxios
          .get(accountDetailsURL)
          .then(res => {
            localStorage.setItem("userFullName", res.data.first_name + " " + res.data.last_name);
            localStorage.setItem("UserID", res.data.id);
            localStorage.setItem("Logo", res.data.logo);
          })
          .catch(err => {
            // console.log(err);
          });
      })
      .catch(err => {
        dispatch(authFail(err));
        // console.log(err);
      });
  };
};

export const authSignup = (username, email, password1, password2) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(signupURL, {
        username: username,
        email: email,
        password1: password1,
        password2: password2
      })
      .then(res => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 86400 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("userName", username);
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(86400));
        authAxios
          .get(accountDetailsURL)
          .then(res => {
            localStorage.setItem("userFullName", res.data.first_name + " " + res.data.last_name);
            localStorage.setItem("UserID", res.data.id);
            localStorage.setItem("Logo", res.data.logo);
          })
          .catch(err => {
            // console.log(err);
          });
      })
      .catch(err => {
        dispatch(authFail(err));
      });
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    if (token === undefined) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        if (token !== undefined)
          dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
        dispatch(fetchUserCompanies());
      }
    }
  };
};

export const authSendResetPwdEmail = email => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(resetPasswordURL, {
        email: email
      })
      .then(res => {
        dispatch(authSuccess(null));
      })
      .catch(err => {
        dispatch(authFail(err));
      });
  };
};

export const setActivity = () => {
  return dispatch => {
    const expirationDate = new Date(new Date().getTime() + 86400 * 1000);
    localStorage.setItem("expirationDate", expirationDate);
  };
};
