// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 20.3.2020 TS Initial version
// 25.3.2020 TS Added colors for error and ok statuses
// 24.6.2020 TS Added Decentlab
// 26.6.2020 TS export to CSV
// 27.6.2020 TS Added link alarms list if alarm activated and to variable details from detail name
// 1.7.2020 TS Fixed loading bug when switching between all sensors and one sensor. Moved Google maps API key in to env variables
// 19.7.2021 TS Added default map marker


import React, { useState } from "react";
import { Message, Header, Table, Segment, Dimmer, Loader, Image, Icon, Popup, Responsive, Progress} from "semantic-ui-react";
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from "react-google-maps"
import Shell from "./Shell";
import { authAxios, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {  sensorDetailsURL, sensorsWithLocationsURL } from "../../constants";
  

const getIcon = sensor => {
    if (sensor.model_name === "WISE-2410-EA")
        return {
            url: sensor.sensor_model.thumbnail_image_url,
            scaledSize: new window.google.maps.Size(60,60)
        };
    else if (sensor.model_name === "DECENTLAB-DL-MBX") {
        return {
            url: sensor.sensor_model.thumbnail_image_url,
            scaledSize: new window.google.maps.Size(60,50)
        };
    } else if (sensor.model_name === "WASTECONTROL-VU250-2W") {
        return {
            url: sensor.sensor_model.thumbnail_image_url,
            scaledSize: new window.google.maps.Size(45,60)
        };
    } else {
        return {
            url: sensor.sensor_model.thumbnail_image_url,
            scaledSize: new window.google.maps.Size(60,50)
        };
    }
};

const WrappedMap = withScriptjs(withGoogleMap(props => {
    const [selectedSensor, setSelectedSensor] = useState(null);

    return (
    <GoogleMap 
        defaultZoom={10}
        defaultCenter={{lat: props.default_lat, lng: props.default_lng}}>
        {}
        {props.sensors.map(sensor => (
            <>
            {(sensor.sensor_model.use_default_map_marker === false && sensor.sensor_model.thumbnail_image_url !== "") ? (
                <Marker 
                    key={sensor.id}
                    position={{
                        lat: sensor.device_location_latitude, 
                        lng: sensor.device_location_longitude
                    }}
                    onClick={() => {
                        setSelectedSensor(sensor);
                    }}
                    
                    icon={getIcon(sensor)}
                />
            ) : (
                <Marker 
                    key={sensor.id}
                    position={{
                        lat: sensor.device_location_latitude, 
                        lng: sensor.device_location_longitude
                    }}
                    onClick={() => {
                        setSelectedSensor(sensor);
                    }}               
                />
            )}
            </>
        ))}
        {selectedSensor && (
            <InfoWindow
                position={{
                    lat: selectedSensor.device_location_latitude, 
                    lng: selectedSensor.device_location_longitude
                }}
                onCloseClick={() => {
                    setSelectedSensor(null);
                }}
            >
                <React.Fragment>
                    <Header as="h3">{selectedSensor.local_unit}/{selectedSensor.local_line}/{selectedSensor.local_machine}/{selectedSensor.local_name}</Header>
                    {selectedSensor.device_location_latitude} {selectedSensor.device_location_longitude}<br/>
                    {/* <b >{props.t("Sensor")}: </b>{selectedSensor.local_unit}/{selectedSensor.local_line}/{selectedSensor.local_machine}/{selectedSensor.local_name}<br/> */}
                    <b >{props.t("Sensor model")}: </b> {selectedSensor.model_name} <br/>
                    <b >{props.t("Sensor status")}: </b> {handleGetStatus(props.t, selectedSensor)} <br/>
                    <b >{props.t("Sensor alarms")}: </b> {handleGetAlarmsStatus(props.t, selectedSensor)} <br/>
                    {selectedSensor.model_name === "DECENTLAB-DL-MBX" && (
                        <>
                        <br/>
                        {selectedSensor.sensorstatusvariables.map(variable => (
                            <>
                            {(variable.group === "Distance" && variable.last_value !== "") && (
                                <>
                                    <b >{props.t("Distance")}: </b> 
                                    {(variable.is_alarm_enabled && variable.last_value !== "") ? (
                                        <Popup trigger={
                                            <Progress percent={getAlarmProgressPercent(variable)} 
                                                size="small" 
                                                color={getAlarmProgressColor(variable)}
                                                label={`${variable.last_value} mm`} />}>
                                                <b>{props.t("Alarm limits")}</b><br/>
                                                {props.t("Critical high limit")}: {variable.alarm_critical_high_limit}<br/>
                                                {props.t("High limit")}: {variable.alarm_high_limit}<br/>
                                                {props.t("Low limit")}: {variable.alarm_low_limit}<br/>
                                                {props.t("Critical low limit")}: {variable.alarm_critical_low_limit}<br/>
                                        </Popup>
                                    ) : (
                                        variable.last_value + "mm"
                                    )}
                                    <br/>
                                    <b >{props.t("Deviation")}: </b> 
                                    {(variable.is_alarm_enabled && variable.last_deviation !== "") ? (
                                        <Popup trigger={
                                            <Progress percent={getAlarmProgressPercent(variable)} 
                                                size="small" 
                                                color={getAlarmProgressColor(variable)}
                                                label={`${variable.last_deviation} mm`} />}>
                                                <b>{props.t("Alarm limits")}</b><br/>
                                                {props.t("Critical high limit")}: {variable.alarm_critical_high_limit - variable.reference_value}<br/>
                                                {props.t("High limit")}: {variable.alarm_high_limit - variable.reference_value}<br/>
                                                {props.t("Low limit")}: {variable.alarm_low_limit - variable.reference_value}<br/>
                                                {props.t("Critical low limit")}: {variable.alarm_critical_low_limit - variable.reference_value}<br/>
                                        </Popup>
                                    ) : (
                                        variable.last_deviation + "mm"
                                    )}
                                    <br/>
                                </>
                            )}
                            {(variable.group === "Battery voltage" && variable.last_value !== "") && (<><b >{props.t("Battery Voltage")}: </b> {variable.last_value} V<br/></>)}
                            </>
                        ))}
                        </>
                    )}
                    <b >{props.t("Last status")}: </b> {selectedSensor.last_status ? new Date(selectedSensor.last_status).toLocaleString('fi-FI') : ""} <br/>
                    <b >{props.t("Last value")}: </b> {selectedSensor.last_display_value} <br/>
                    <br/>
                    <Link to={`/sensor/status-variables/${selectedSensor.id}/`}>{props.t("Sensor details..")}</Link>
                </React.Fragment>
            </InfoWindow>
        )}

    </GoogleMap>
    );
}
));

const getAlarmProgressPercent = variable => {
    if (variable.last_value < variable.alarm_critical_low_limit) {
        return 0;
    } else if (variable.last_value > variable.alarm_critical_high_limit) {
        return 100;
    } else {
        // Math.abs = absolute value
        const range_top_abs = Math.abs(variable.alarm_critical_high_limit - variable.alarm_critical_low_limit);
        const last_value_abs = Math.abs(variable.last_value - variable.alarm_critical_low_limit);
        return Math.round(last_value_abs / range_top_abs * 100);
    }
};

const getAlarmProgressColor = variable => {
    let return_color = "green";
    if (variable.last_value < variable.alarm_critical_low_limit) {
        return_color = "red";
    } else if (variable.last_value > variable.alarm_critical_high_limit) {
        return_color = "red";
    } else {
        if (variable.last_value < variable.alarm_low_limit) {
            return_color =  "yellow";
        } else if (variable.last_value > variable.alarm_high_limit) {
            return_color = "yellow";
        }
    }
    return return_color;
};


const handleGetAlarmsStatus = (t, sensor) => {
    if (sensor.last_status) {
        let i = 0;
        for (i in sensor.sensorstatusvariables) {
            if (sensor.sensorstatusvariables[i].status.includes("Alarm") && sensor.sensorstatusvariables[i].status !== "Alarm on" && sensor.sensorstatusvariables[i].status !== "Alarm off") {
                return <Table.Cell error collapsing onClick={<Redirect to={`/sensor/alarms/${sensor.id}/`} />}><Header as="h5" color="red"><Popup content={t(sensor.sensorstatusvariables[i].status)} trigger={<Icon name="attention" />} /></Header></Table.Cell>
            }
        }
        return <font color="green "><strong >{t("No alarms")}</strong></font>
    } else {
        return <font color="red"><strong >{t("Offline")}</strong></font>
    }
};


const handleGetStatus = (t, sensor) => {
    if (sensor.last_status) {
        const sensor_last_status_sec = new Date(sensor.last_status).getTime() / 1000;
        const current_time_sec = new Date().getTime() / 1000;
        if ((current_time_sec - sensor_last_status_sec) > sensor.measure_interval * 2) {
            return <font color="red"><strong >{t("Offline")}</strong></font>
        } else {
            return <font color="green"><strong >{t("Online")}</strong></font>
        }
    } else {
        return <font color="red"><strong >{t("Offline")}</strong></font>
    }
};


class SensorMap extends React.Component {
    state = {
        sensor: null,
        sensors: null,
        status_variables: null,
        error: null,
        loading: false,
        success_mgs: null,
        group: "",
        default_lat: 0,
        default_lng: 0,
        all_sensors: false,
        sensor_id_param: ""
    };

    componentDidMount() {
        this.props.setActivity();
        
        if (this.props.match.params.sensor_id === "all") {
            this.setState({
                all_sensors: true,
                sensor_id_param: "all"
            });
            this.handleFetchSensorList();
        } else {
            this.handleFetchSensor();
            this.setState({
                all_sensors: false,
                sensor_id_param: this.props.match.params.sensor_id
            });
        }
    }

    handleFetchSensorList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(sensorsWithLocationsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    sensors: res.data,
                    loading: false,
                    error: null
                });
                if (this.props.match.params.sensor_id === "all" && res.data.length > 0) {
                    this.setState({
                        default_lat: res.data[0].company_location_lat,
                        default_lng: res.data[0].company_location_lng
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    handleFetchSensor = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    loading: false,
                    error: null
                });
                if (res.data.device_location_latitude !== 0.0 && res.data.device_location_longitude !== 0.0) {
                    this.setState({
                        default_lat: res.data.device_location_latitude,
                        default_lng: res.data.device_location_longitude
                    });
                } else {
                    this.setState({
                        default_lat: res.data.company_location_lat,
                        default_lng: res.data.company_location_lng
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleGoToVariableDetails = variable => {
        this.props.history.push(`/sensor/${variable.sensor}/status-variable-details/${variable.id}/`)
    };

    handleGotToAlarms = variable => {
        this.props.history.push(`/sensor/alarms/${variable.sensor}/`)
    };

    handleGetSensorCategories = sensor => {
        let return_string = sensor.local_name;
        if (sensor.local_machine !== null && sensor.local_machine !== "")
        {
            return_string = return_string + "/" + sensor.local_machine;
        }
        if (sensor.local_line !== null && sensor.local_line !== "")
        {
            return_string = return_string + "/" + sensor.local_line;
        }
        if (sensor.local_unit !== null && sensor.local_unit !== "")
        {
            return_string = return_string + "/" + sensor.local_unit;
        }
        return return_string;
    };


    render() {
        const { error, loading,  sensor, sensors, all_sensors, sensor_id_param } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }

        if (this.props.match.params.sensor_id !== sensor_id_param) {

            if (this.props.match.params.sensor_id === "all") {
                if (sensors && sensors.length > 0) {
                    this.setState({
                        default_lat: sensors[0].company_location_lat,
                        default_lng: sensors[0].company_location_lng,
                        all_sensors: true,
                        sensor: null,
                        sensor_id_param: this.props.match.params.sensor_id
                    });
                }
            } else {
                this.setState({
                    all_sensors: false,
                    sensor_id_param: this.props.match.params.sensor_id
                });
                this.handleFetchSensor();
            }
        }

        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;

        // console.log(this.state);
        return (
            <Shell active_sensor_id={sensor && (sensor.id)} active_sensor_name={sensor && (sensor.local_name)} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {((sensor_id_param === "all" && sensors) || (sensor_id_param !== "all" && sensor)) && (
                    <React.Fragment>
                        {(!all_sensors && sensor) && (
                            <React.Fragment>
                                <Header as="h3">{t("Sensor on map")}</Header>
                                <b >{t("Sensor")}: </b>{this.handleGetSensorCategories(sensor)}<br/>
                                <b >{t("Sensor model")}: </b> {sensor.device_name} <br/>
                                <b >{t("Sensor status")}: </b> {handleGetStatus(t, sensor)} <br/>
                                {(sensor.device_location_latitude !== 0 && sensor.device_location_longitude !== 0) ? (
                                    <>
                                        (<b>{t("Lat")}:</b> {sensor.device_location_latitude !== 0 ? sensor.device_location_latitude : ""} <b>{t("Lon")}:</b> {sensor.device_location_longitude !== 0 ? sensor.device_location_longitude : ""}) <br/>
                                    </>
                                ) : (
                                    t("Location not defined")
                                )}
                            </React.Fragment>
                        )}
                        {all_sensors && (
                            <Header as="h3">{t("Sensors on map")}</Header>
                        )}
                        {/* <Button icon='refresh' floated="right" color="green" label={t("Refresh")} onClick={() => this.handleRefresh()}/> */}
                        <br/>
                        <div style={{ width: is_desktop ? "50vw" : window.innerWidth - 10,  height: is_desktop ? "80vh" : "70vh"}}>
                            <WrappedMap 
                                // googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                                googleMapURL = {`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&callback=initMap`}
                                loadingElement = {<div style={{  height: `100%` }} />}
                                containerElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                //containerElement = {is_desktop ? (<div style={{ width: `100%`, height: `100%` }} />) : (<div style={{ width: getWidth(), height: `800px` }} />)} 
                                mapElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                default_lat = {this.state.default_lat}
                                default_lng = {this.state.default_lng}
                                sensors={sensor_id_param === "all" ? sensors : [sensor]}
                                t={t}
                            />
                        </div>
                    </React.Fragment>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensormap')(SensorMap))
);