// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Sensor
// Author: TS
//
// 20.3.2020 TS Initial version
// 25.3.2020 TS Added colors for error and ok statuses
// 24.6.2020 TS Added Decentlab
// 26.6.2020 TS export to CSV
// 27.6.2020 TS Added link alarms list if alarm activated and to variable details from detail name
// 25.7.2020 TS Added Digital Matter Yabby
// 29.7.2020 TS Fixded map centering after refresh of moving device
// 15.9.2020 TS Added Elsys-ERS and Elsys-EMS
// 23.11.2020 TS Added Axioma W1
// 26.11.2020 TS Links from Axioma status to reports
// 19.1.2021 TS Added ELSYS-ELT-2
// 06.02.2021 TS Measure interval * 2 in check if online
// 1.3.2021 TS Elsys ELT-2 ultrasonic for waste bin monitoring
// 3.3.2021 TS Netvox R313A and RB11E
// 19.3.2021 TS Water level probe
// 14.4.2021 TS Dingtek
// 7.5.2021 TS Caproc CMF
// 18.7.2021 TS Added AQUA-H21-OPTOD
// 19.7.2021 TS Added default map marker
// 4.8.2021 TS Increased sensor "Offline" limit to 3 x measure interval
// 20.9.2021 TS Changed sensor_type check for "waste" type to startsWith
// 27.9.2021 TS CAPROC-IC
// 15.10.2021 TS Added internal temp and humi for CMF
// 17.12.2021 TS Get translation instean of last value if the variable.name is "fraction". Only valid for waste sensor type.
// 25.2.2022 TS TH-PROBE
// 10.7.2022 TS T-PROBE and OC-TH
// 14.7.2022 TS Added admin info to status
// 24.7.2022 TS Added AQUALABO sensors
// 25.7.2022 TS water flow
// 23.8.2022 TS Scale
// 26.5.2023 TS Decentlab DL-PAR
// 18.8.2023 TS Added Cellular signal strength
// 04.10.2023 TS Changed Decentlab-MBX status to match normal device view.
// 27.10.2023 TS Added HAN reader
// 22.11.2023 TS Added RAIN


import React, { useState } from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Icon, Popup, Responsive, Progress, Grid, Card, Statistic} from "semantic-ui-react";
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from "react-google-maps"
import Shell from "./Shell";
import { authAxios, exportToCsv, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fi';
import {  sensorDetailsURL, sensorConsumptionStatsURL } from "../../constants";

const getIcon = sensor => {
    if (sensor.model_name === "WISE-2410-EA")
        return {
            url: sensor.sensor_model.thumbnail_image_url,
            scaledSize: new window.google.maps.Size(60,60)
        };
    else if (sensor.model_name === "DECENTLAB-DL-MBX") {
        return {
            url: sensor.sensor_model.thumbnail_image_url,
            scaledSize: new window.google.maps.Size(60,50)
        };
    } else if (sensor.model_name === "WASTECONTROL-VU250-2W") {
        return {
            url: sensor.sensor_model.thumbnail_image_url,
            scaledSize: new window.google.maps.Size(45,60)
        };
    } else {
        return {
            url: sensor.sensor_model.thumbnail_image_url,
            scaledSize: new window.google.maps.Size(60,50)
        };
    }
};

const WrappedMap = withScriptjs(withGoogleMap(props => {
    const [selectedSensor, setSelectedSensor] = useState(null);

    return (
        <GoogleMap 
            defaultZoom={15}
            defaultCenter={{lat: props.sensor.device_location_latitude, lng: props.sensor.device_location_longitude}}>
            {(props.sensor.sensor_model.use_default_map_marker === false && props.sensor.sensor_model.thumbnail_image_url !== "") ? (
                <Marker 
                    position={{
                        lat: props.sensor.device_location_latitude, 
                        lng: props.sensor.device_location_longitude
                    }}
                    onClick={() => {
                        setSelectedSensor(props.sensor);
                    }}
                    icon={getIcon(props.sensor)}
                />
            ) : (
                <Marker 
                    position={{
                        lat: props.sensor.device_location_latitude, 
                        lng: props.sensor.device_location_longitude
                    }}
                    onClick={() => {
                        setSelectedSensor(props.sensor);
                    }}
                />
            )}
            {selectedSensor && (
                <InfoWindow
                    position={{
                        lat: selectedSensor.device_location_latitude, 
                        lng: selectedSensor.device_location_longitude
                    }}
                    onCloseClick={() => {
                        setSelectedSensor(null);
                    }}
                >
                    <React.Fragment>
                        <Header as="h3">{selectedSensor.local_unit}/{selectedSensor.local_line}/{selectedSensor.local_machine}/{selectedSensor.local_name}</Header>
                        {selectedSensor.device_location_latitude} {selectedSensor.device_location_longitude}<br/>
                        {/* <b >{props.t("Sensor")}: </b>{selectedSensor.local_unit}/{selectedSensor.local_line}/{selectedSensor.local_machine}/{selectedSensor.local_name}<br/> */}
                        <b >{props.t("Sensor model")}: </b> {selectedSensor.model_name} <br/>
                        <b >{props.t("Sensor status")}: </b> {handleGetStatus(props.t, selectedSensor)} <br/>
                        <b >{props.t("Sensor alarms")}: </b> {handleGetAlarmsStatus(props.t, selectedSensor)} <br/>
                        <b >{props.t("Last status")}: </b> {selectedSensor.last_status ? new Date(selectedSensor.last_status).toLocaleString('fi-FI') : ""} <br/>
                        <b >{props.t("Last value")}: </b> {selectedSensor.last_display_value} <br/>
                        <br/>
                        <Link to={`/sensor/status-variables/${selectedSensor.id}/`}>{props.t("Sensor details..")}</Link>
                    </React.Fragment>
                </InfoWindow>
            )}

        </GoogleMap>
    );
}));  

const handleGetAlarmsStatus = (t, sensor) => {
    if (sensor.last_status) {
        let i = 0;
        for (i in sensor.sensorstatusvariables) {
            if (sensor.sensorstatusvariables[i].status.includes("Alarm") && sensor.sensorstatusvariables[i].status !== "Alarm on" && sensor.sensorstatusvariables[i].status !== "Alarm off") {
                return <Table.Cell error collapsing onClick={<Redirect to={`/sensor/alarms/${sensor.id}/`} />}><Header as="h5" color="red"><Popup content={t(sensor.sensorstatusvariables[i].status)} trigger={<Icon name="attention" />} /></Header></Table.Cell>
            }
        }
        return <font color="green "><strong >{t("No alarms")}</strong></font>
    } else {
        return <font color="red"><strong >{t("Offline")}</strong></font>
    }
};


const handleGetStatus = (t, sensor) => {
    let sensor_status = handleCheckIfOnline(sensor);
    if (sensor_status == "Online") {
        return <font color="green"><strong >{t("Online")}</strong></font>
    } else {
        return <font color="red"><strong >{t("Offline")}</strong></font>
    }
};

const handleCheckIfOnline = sensor => {
    if (sensor.last_status) {
        const sensor_last_status_sec = new Date(sensor.last_status).getTime() / 1000;
        const current_time_sec = new Date().getTime() / 1000;
        if ((current_time_sec - sensor_last_status_sec) > sensor.measure_interval * 3) {
            return "Offline";
        } else {
            return "Online";
        }
    } else {
        return "Offline";
    }
};

class SensorStatus extends React.Component {
    state = {
        sensor: null,
        status_variables: null,
        error: null,
        loading: false,
        success_mgs: null,
        group: "",
        sensor_statistics: null
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchSensor();

    }

    handleFetchSensor = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    loading: false,
                    error: null
                });
                // Get statistics if water meter
                if (res.data.model_name === "AXIOMA-W1" || res.data.model_name === "BMETERS-LW3") {
                    this.handleFetchConsumptionStatistics(res.data.id, "m3");
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFetchConsumptionStatistics = (id, measurement_unit) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(sensorConsumptionStatsURL(activeCompanyId, id, measurement_unit))
            .then(res => {
                this.setState({
                    sensor_statistics: res.data,
                    loading: false,
                    error: null
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    handleRefresh = () => {
        this.handleFetchSensor();
    };

    handleGetStatus = sensor => {
        const { t } = this.props;
        if (sensor.last_status) {
            const sensor_last_status_sec = new Date(sensor.last_status).getTime() / 1000;
            const current_time_sec = new Date().getTime() / 1000;
            if ((current_time_sec - sensor_last_status_sec) > sensor.measure_interval * 3) {
                return <font color="red"><strong >{t("Offline")}</strong></font>
            } else {
                return <font color="green"><strong >{t("Online")}</strong></font>
            }
        } else {
            return <font color="red"><strong >{t("Offline")}</strong></font>
        }
    };

    handleGoToVariableDetails = variable => {
        this.props.history.push(`/sensor/${variable.sensor}/status-variable-details/${variable.id}/`)
    };

    handleGoToAlarms = variable => {
        this.props.history.push(`/sensor/alarms/${variable.sensor}/`)
    };

    handleGoToReport = (report_type, measurement_unit) => {
        this.props.history.push(`/sensor/consumaple-history/${this.state.sensor.id}/${measurement_unit}/${report_type}/`)
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        const { sensor } = this.state;
        if (items !== null) {
            let titles = []
            let items_to_csv = [];
            if (sensor.model_name === "WISE-2410-EA") {
                titles = [
                    t("Variable"),
                    t("Axis"),
                    t("Value"),
                    t("Deviation"),
                    t("Alarm status"),
                    t("Last status")
                ];
                let i = 0;
                for (i in items) {
                    items_to_csv.push({
                        variable: t(items[i].display_name),
                        axis: items[i].sub_group,
                        value: items[i].last_value,
                        deviation: items[i].last_deviation,
                        alarm_status: t(items[i].status),
                        event_time: new Date(items[i].event_time).toLocaleString('fi-FI')
                    });
                }
    
            // } else if (sensor.model_name === "DECENTLAB-DL-MBX") {
            } else {
                titles = [
                    t("Variable"),
                    t("Value"),
                    t("Deviation"),
                    t("Alarm status"),
                    t("Last status")
                ];
                let i = 0;
                for (i in items) {
                    if (sensor.model_name === "DIGITAL-MATTER-YABBY" && items[i].group === "stats") continue;
                    items_to_csv.push({
                        variable: t(items[i].display_name),
                        value: items[i].last_value,
                        deviation: items[i].last_deviation,
                        alarm_status: t(items[i].status),
                        event_time: items[i].event_time ? new Date(items[i].event_time).toLocaleString('fi-FI') : ""
                    });
                }
    
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    getAlarmProgressPercent = variable => {
        if (variable.last_value < variable.alarm_critical_low_limit) {
            return 0;
        } else if (variable.last_value > variable.alarm_critical_high_limit) {
            return 100;
        } else {
            // Math.abs = absolute value
            const range_top_abs = Math.abs(variable.alarm_critical_high_limit - variable.alarm_critical_low_limit);
            const last_value_abs = Math.abs(variable.last_value - variable.alarm_critical_low_limit);
            return Math.round(last_value_abs / range_top_abs * 100);
        }
    };

    getAlarmProgressColor = variable => {
        let return_color = "green";
        if (variable.last_value < variable.alarm_critical_low_limit) {
            return_color = "red";
        } else if (variable.last_value > variable.alarm_critical_high_limit) {
            return_color = "red";
        } else {
            if (variable.last_value < variable.alarm_low_limit) {
                return_color =  "yellow";
            } else if (variable.last_value > variable.alarm_high_limit) {
                return_color = "yellow";
            }
        }
        return return_color;
    };

    handleGetSignalStrength = sensor => {
        const { t } = this.props;
        if (handleCheckIfOnline(sensor) == "Offline") {
            return <font color="red"><strong >{t("Offline")}</strong></font>
        }
        // LoRaWAN
        if (sensor.device_type === "L")
        {
            if (sensor.last_lrc !== "") {
                if (sensor.last_snr > 0) {
                    return <font color="green"><strong >{t("Excellent")}</strong></font>
                } else if (sensor.last_snr <= 0 && sensor.last_snr >= -7) {
                    return <font color="green"><strong >{t("Good")}</strong></font> 
                } else if (sensor.last_snr < -7 && sensor.last_snr >= -15) {
                    return <font color="green"><strong >{t("Moderate")}</strong></font> 
                } else {
                    return <font color="red"><strong >{t("Weak")}</strong></font>
                }
            }
        }
        // Cellular
        else
        {
            if (sensor.cell_id !== "") {
                if (sensor.rsrp >= -80) {
                    return <font color="green"><strong >{t("Excellent")}</strong></font>
                } else if (sensor.rsrp < -80 && sensor.rsrp >= -90) {
                    return <font color="green"><strong >{t("Good")}</strong></font> 
                } else if (sensor.rsrp < -90 && sensor.rsrp >= -100) {
                    return <font color="green"><strong >{t("Moderate")}</strong></font> 
                } else {
                    return <font color="red"><strong >{t("Weak")}</strong></font>
                }
            }
        }
    }

    handleGetNextStatus = sensor => {
        const { t } = this.props;
        if (sensor.last_status === null) {
            return <font color="red"><strong >{t("Offline")}</strong></font>
        }
        moment.locale('fi');
        let next_status_timestamp = moment(sensor.last_status);
        next_status_timestamp = next_status_timestamp.add(sensor.measure_interval, 'seconds');
        return next_status_timestamp.toDate().toLocaleString('fi-FI');
    }

    handleGetSensorCategories = sensor => {
        let return_string = sensor.local_name;
        if (sensor.local_machine !== null && sensor.local_machine !== "")
        {
            return_string = return_string + "/" + sensor.local_machine;
        }
        if (sensor.local_line !== null && sensor.local_line !== "")
        {
            return_string = return_string + "/" + sensor.local_line;
        }
        if (sensor.local_unit !== null && sensor.local_unit !== "")
        {
            return_string = return_string + "/" + sensor.local_unit;
        }
        return return_string;
    };

    render() {
        const { error, loading, sensor, sensor_statistics} = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        return (
            <Shell active_sensor_id={sensor && (sensor.id)} active_sensor_name={sensor && (sensor.local_name)} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>
                {(sensor && is_desktop) && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(sensor.sensorstatusvariables)} />
                    </Button.Group>
                )}
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {sensor && (
                    <React.Fragment>
                        
                        <Header as="h3">{t("Sensor status")}</Header>
                        <Grid>
                            <Grid.Row columns="2">
                                <Grid.Column>
                                {/* <b >{t("Sensor")}: </b>{sensor.local_unit}/{sensor.local_line}/{sensor.local_machine}/{sensor.local_name}<br/> */}
                                <b >{t("Sensor")}: </b>{this.handleGetSensorCategories(sensor)}<br/>
                                <b >{t("Sensor model")}: </b> {sensor.device_name} <br/>
                                <b >{t("Sensor status")}: </b> {this.handleGetStatus(sensor)} <br/>
                                <b >{t("Signal strength")}: </b> 
                                <>
                                    {/* L=LoRaWAN C=Cellular*/}
                                    {sensor.device_type === "L" ? (
                                        <Popup content={"RSSI: " + sensor.last_rssi + "SF: " + sensor.last_sf + " LRC: " + sensor.last_lrc} trigger={this.handleGetSignalStrength(sensor)} />
                                    ) : (
                                        <Popup wide="very" content={"Mode: " + sensor.network_mode + " RSRP: " + sensor.rsrp + " MCCMNC: " + sensor.mccmnc + " CELL_ID: " + sensor.cell_id} trigger={this.handleGetSignalStrength(sensor)} />
                                    )}
                                </>
                                
                                {/* {(this.props.activeRoles && this.props.activeRoles.includes("A")) ? (
                                    <> {this.handleGetSignalStrength(sensor)}  {" RSSI: " + sensor.last_rssi + " SNR: " + sensor.last_snr + " SF" + sensor.last_sf + " LRC: " + sensor.last_lrc} </>
                                ) : (
                                    this.handleGetSignalStrength(sensor)
                                )} */}
                                <br/>
                                <b >{t("Last status")}: </b> {sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""} <br/>
                                <b >{t("Next status")}: </b> {this.handleGetNextStatus(sensor)} <br/>
                                </Grid.Column>
                                <Grid.Column>
                                    {is_desktop ? (
                                        <Image src={sensor.sensor_model.thumbnail_image_url} size="small"/>
                                    ) : (
                                        <Image src={sensor.sensor_model.thumbnail_image_url} floated="right" size="mini"/>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Button icon='refresh' floated="right" color="green" label={t("Refresh")} onClick={() => this.handleRefresh()}/>

                        {sensor.model_name === "WISE-2410-EA" && (
                            <React.Fragment>
                                <Header as="h4" color="blue">{t("Accelerometer")}</Header>
                                <div>
                                    <Table striped selectable celled>
                                        {is_desktop && (
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell collapsing>{t("Variable")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Axis")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Value")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Deviation")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Alarm status")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Last status")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        )}
                                        <Table.Body>
                                            {sensor.sensorstatusvariables.map((variable, key) => {
                                                if (variable.is_active && variable.group === "Accelerometer") {
                                                    return (
                                                        <Table.Row key={key}>
                                                            <Table.Cell onClick={() => this.handleGoToVariableDetails(variable)}>
                                                                {!is_desktop && (<React.Fragment><b>{t("Variable")}</b><br/></React.Fragment>)}
                                                                {t(variable.display_name)}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Axis")}</b><br/></React.Fragment>)}
                                                                {variable.sub_group}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Value")}</b><br/></React.Fragment>)}
                                                                {(variable.is_alarm_enabled && variable.last_value !== "") ? (
                                                                    <Popup trigger={
                                                                        <Progress percent={this.getAlarmProgressPercent(variable)} 
                                                                            size="small" 
                                                                            color={this.getAlarmProgressColor(variable)}
                                                                            label={variable.last_value} />}>
                                                                            <b>{t("Alarm limits")}</b><br/>
                                                                            {t("Critical high limit")}: {variable.alarm_critical_high_limit}<br/>
                                                                            {t("High limit")}: {variable.alarm_high_limit}<br/>
                                                                            {t("Low limit")}: {variable.alarm_low_limit}<br/>
                                                                            {t("Critical low limit")}: {variable.alarm_critical_low_limit}<br/>
                                                                    </Popup>
                                                                ) : (
                                                                    variable.last_value
                                                                )}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Deviation")}</b><br/></React.Fragment>)}
                                                                {(variable.is_alarm_enabled && variable.last_deviation !== "") ? (
                                                                    <Popup trigger={
                                                                        <Progress percent={this.getAlarmProgressPercent(variable)} 
                                                                            size="small" 
                                                                            color={this.getAlarmProgressColor(variable)}
                                                                            label={variable.last_deviation} />}>
                                                                            <b>{t("Alarm limits")}</b><br/>
                                                                            {t("Critical high limit")}: {variable.alarm_critical_high_limit - variable.reference_value}<br/>
                                                                            {t("High limit")}: {variable.alarm_high_limit - variable.reference_value}<br/>
                                                                            {t("Low limit")}: {variable.alarm_low_limit - variable.reference_value}<br/>
                                                                            {t("Critical low limit")}: {variable.alarm_critical_low_limit - variable.reference_value}<br/>
                                                                    </Popup>
                                                                ) : (
                                                                    variable.last_deviation
                                                                )}
                                                            </Table.Cell>
                                                            {(variable.status.includes("High Alarm") || variable.status.includes("Low Alarm")) ? (
                                                                <React.Fragment>
                                                                    {variable.status.includes("CRITICAL") ? (
                                                                        <Table.Cell error onClick={() => this.handleGoToAlarms(variable)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Alarm status")}</b><br/></React.Fragment>)}
                                                                            <Header as="h5" color="red"><Icon name='attention' />{t(variable.status)}</Header> </Table.Cell>
                                                                    ) : (
                                                                        <Table.Cell warning onClick={() => this.handleGoToAlarms(variable)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Alarm status")}</b><br/></React.Fragment>)}
                                                                             <Icon name='attention' />{t(variable.status)}</Table.Cell>
                                                                    )}
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {variable.status === "OK" ? (
                                                                        <Table.Cell positive textAlign="center" onClick={() => this.handleGoToAlarms(variable)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Alarm status")}</b><br/></React.Fragment>)}
                                                                            <Header as="h5" color="green"><Popup content={t(variable.status)} trigger={<Icon name="check circle outline" />} /></Header>
                                                                        </Table.Cell>
                                                                    ) : (
                                                                        <Table.Cell onClick={() => this.handleGoToAlarms(variable)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Alarm status")}</b><br/></React.Fragment>)}
                                                                            {t(variable.status)}
                                                                        </Table.Cell>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Last status")}</b><br/></React.Fragment>)}
                                                                {variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                }
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                                <Header as="h4" color="blue">{t("Device")}</Header>
                                <div>
                                    <Table striped selectable celled>
                                        {is_desktop && (
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell collapsing>{t("Variable")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Value")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Deviation")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Alarm status")}</Table.HeaderCell>
                                                    <Table.HeaderCell collapsing>{t("Last status")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        )}
                                        <Table.Body>
                                            {sensor.sensorstatusvariables.map((variable, key) => {
                                                if (variable.is_active && variable.group !== "Accelerometer") {
                                                    return (
                                                        <Table.Row key={key}>
                                                            <Table.Cell onClick={() => this.handleGoToVariableDetails(variable)}>
                                                                {!is_desktop && (<React.Fragment><b>{t("Variable")}</b><br/></React.Fragment>)}
                                                                {t(variable.display_name)}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Value")}</b><br/></React.Fragment>)}
                                                                {(variable.is_alarm_enabled && variable.last_value !== "") ? (
                                                                    <Popup trigger={
                                                                        <Progress percent={this.getAlarmProgressPercent(variable)} 
                                                                            size="small" 
                                                                            color={this.getAlarmProgressColor(variable)}
                                                                            label={variable.last_value} />}>
                                                                            <b>{t("Alarm limits")}</b><br/>
                                                                            {t("Critical high limit")}: {variable.alarm_critical_high_limit}<br/>
                                                                            {t("High limit")}: {variable.alarm_high_limit}<br/>
                                                                            {t("Low limit")}: {variable.alarm_low_limit}<br/>
                                                                            {t("Critical low limit")}: {variable.alarm_critical_low_limit}<br/>
                                                                    </Popup>
                                                                ) : (
                                                                    variable.last_value
                                                                )}
                                                            </Table.Cell>
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Deviation")}</b><br/></React.Fragment>)}
                                                                {(variable.is_alarm_enabled && variable.last_deviation !== "") ? (
                                                                    <Popup trigger={
                                                                        <Progress percent={this.getAlarmProgressPercent(variable)} 
                                                                            size="small" 
                                                                            color={this.getAlarmProgressColor(variable)}
                                                                            label={variable.last_deviation} />}>
                                                                            <b>{t("Alarm limits")}</b><br/>
                                                                            {t("Critical high limit")}: {variable.alarm_critical_high_limit - variable.reference_value}<br/>
                                                                            {t("High limit")}: {variable.alarm_high_limit - variable.reference_value}<br/>
                                                                            {t("Low limit")}: {variable.alarm_low_limit - variable.reference_value}<br/>
                                                                            {t("Critical low limit")}: {variable.alarm_critical_low_limit - variable.reference_value}<br/>
                                                                    </Popup>
                                                                ) : (
                                                                    variable.last_deviation
                                                                )}
                                                            </Table.Cell>
                                                            {(variable.status.includes("High Alarm") || variable.status.includes("Low Alarm")) ? (
                                                                <React.Fragment>
                                                                    {variable.status.includes("CRITICAL") ? (
                                                                        <Table.Cell error onClick={() => this.handleGoToAlarms(variable)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Alarm status")}</b><br/></React.Fragment>)}
                                                                            <Header as="h5" color="red"><Icon name='attention' />{t(variable.status)}</Header> </Table.Cell>
                                                                    ) : (
                                                                        <Table.Cell warning onClick={() => this.handleGoToAlarms(variable)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Alarm status")}</b><br/></React.Fragment>)}
                                                                             <Icon name='attention' />{t(variable.status)}</Table.Cell>
                                                                    )}
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {variable.status === "OK" ? (
                                                                        <Table.Cell positive textAlign="center" onClick={() => this.handleGoToAlarms(variable)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Alarm status")}</b><br/></React.Fragment>)}
                                                                            <Header as="h5" color="green"><Popup content={t(variable.status)} trigger={<Icon name="check circle outline" />} /></Header>
                                                                        </Table.Cell>
                                                                    ) : (
                                                                        <Table.Cell onClick={() => this.handleGoToAlarms(variable)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Alarm status")}</b><br/></React.Fragment>)}
                                                                            {t(variable.status)}
                                                                        </Table.Cell>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                            <Table.Cell >
                                                                {!is_desktop && (<React.Fragment><b>{t("Last status")}</b><br/></React.Fragment>)}
                                                                {variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                }
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </React.Fragment>
                        )}
                        {(sensor.model_name === "DECENTLAB-DL-MBX" && !sensor.sensor_model.sensor_type.startsWith("waste")) && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.is_display_value) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t(variable.display_name)}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{variable.last_value}</Statistic.Value>
                                                                        <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}  
                                                <>
                                                    <Card >
                                                        <Card.Header textAlign="center">
                                                            <div style={{paddingTop: 5}}>
                                                                <Header as="h3">{t("Battery")}</Header>
                                                                {/* <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta> */}
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            <Statistic size='tiny' >
                                                                {parseInt(sensor.battery_level_percent) > 75 && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery full" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 75 && parseInt(sensor.battery_level_percent) > 50) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery three quarters" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 50 && parseInt(sensor.battery_level_percent) > 25) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery half" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 25 && parseInt(sensor.battery_level_percent) > 10) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery quarter" color="yellow"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {parseInt(sensor.battery_level_percent) < 10 && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery empty" color="red" /></Statistic.Value>
                                                                    </>
                                                                )}
                                                                <Statistic.Label>{sensor.battery_level_percent} %</Statistic.Label>
                                                            </Statistic>
                                                        </Card.Content>
                                                    </Card>
                                                </>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid  columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                <Card fluid>
                                                    <Card.Header textAlign="center">
                                                        <div style={{paddingTop: 5}}>
                                                            <Header as="h3">{t("Position")}</Header>
                                                            {sensor.sensorstatusvariables.map(variable => {
                                                                return (
                                                                    <>
                                                                        {(variable.name === "fixFailed" && variable.last_value === "False") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Icon name="checkmark" color="green"/></Card.Meta>
                                                                        )}
                                                                        {(variable.name === "fixFailed" && variable.last_value === "True") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Popup content={t("GPS fixing failed")} trigger={<Icon name="times" color="red"/>}/></Card.Meta>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                            <Card.Meta>{t("Lat")}: {sensor.device_location_latitude} {t("Lon")}: {sensor.device_location_longitude}</Card.Meta>
                                                            <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                        </div>
                                                    </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            {/* <div style={{ width: is_desktop ? "50vw" : window.innerWidth - 10,  height: is_desktop ? "80vh" : "70vh"}}> */}
                                                            <div style={{ width: is_desktop ? "800px" : window.innerWidth - 40,  height: is_desktop ? "400px" : "70vh"}}>
                                                                <WrappedMap 
                                                                    googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                                                                    loadingElement = {<div style={{  height: `100%` }} />}
                                                                    containerElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    //containerElement = {is_desktop ? (<div style={{ width: `100%`, height: `100%` }} />) : (<div style={{ width: getWidth(), height: `800px` }} />)} 
                                                                    mapElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    default_lat = {sensor.device_location_latitude}
                                                                    default_lng = {sensor.device_location_longitude}
                                                                    sensor={sensor}
                                                                    t={t}
                                                                />
                                                            </div>
                                                        </Card.Content>
                                                </Card>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        )}
                        {sensor.model_name === "DIGITAL-MATTER-YABBY" && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row >
                                        <Grid.Column>
                                            <Card.Group centered >
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "inTrip") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Status")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        {variable.last_value === "False" ? (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="map marker alternate" color="red" /></Statistic.Value>
                                                                                <Statistic.Label>{t("Still")}</Statistic.Label>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="shipping fast" color="green"/></Statistic.Value>
                                                                                <Statistic.Label>{t("In motion")}</Statistic.Label>
                                                                            </>
                                                                        )}
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                            {(variable.is_active && variable.name === "batV") && (
                                                                <Card >
                                                                    <Card.Header textAlign="center">
                                                                        <div style={{paddingTop: 5}}>
                                                                            <Header as="h3">{t("Battery")}</Header>
                                                                            <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                        </div>
                                                                    </Card.Header>
                                                                    <Card.Content textAlign="center">
                                                                        <Statistic size='tiny' >
                                                                            {parseFloat(variable.last_value) > 4.5 && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery full" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 4.5 && parseFloat(variable.last_value) > 4.4) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery three quarters" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 4.4 && parseFloat(variable.last_value) > 4.3) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery half" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 4.3 && parseFloat(variable.last_value) > 4.2) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery quarter" color="yellow"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {parseFloat(variable.last_value) < 4.2 && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery empty" color="red" /></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                        </Statistic>
                                                                    </Card.Content>
                                                                </Card>
                                                            )}
                                                        </>
                                                    );
                                                })}                                               
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "speedKmph") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Speed")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseInt(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("km/h")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                            {(variable.is_active && variable.name === "headingDeg") && (
                                                                <Card >
                                                                    <Card.Header textAlign="center">
                                                                        <div style={{paddingTop: 5}}>
                                                                            <Header as="h3">{t("Direction")}</Header>
                                                                            <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                        </div>
                                                                    </Card.Header>
                                                                    <Card.Content textAlign="center">
                                                                        <Statistic size='tiny' >
                                                                            <Statistic.Value>{parseInt(variable.last_value)}</Statistic.Value>
                                                                            <Statistic.Label>{t("deg")}</Statistic.Label>
                                                                        </Statistic>
                                                                    </Card.Content>
                                                                </Card>
                                                            )}
                                                        </>
                                                    );
                                                })}                                               
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    </Grid>
                                    <Grid  columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                <Card fluid>
                                                    <Card.Header textAlign="center">
                                                        <div style={{paddingTop: 5}}>
                                                            <Header as="h3">{t("Position")}</Header>
                                                            {sensor.sensorstatusvariables.map(variable => {
                                                                return (
                                                                    <>
                                                                        {(variable.name === "fixFailed" && variable.last_value === "False") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Icon name="checkmark" color="green"/></Card.Meta>
                                                                        )}
                                                                        {(variable.name === "fixFailed" && variable.last_value === "True") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Popup content={t("GPS fixing failed")} trigger={<Icon name="times" color="red"/>}/></Card.Meta>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                            <Card.Meta>{t("Lat")}: {sensor.device_location_latitude} {t("Lon")}: {sensor.device_location_longitude}</Card.Meta>
                                                            <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                        </div>
                                                    </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            {/* <div style={{ width: is_desktop ? "50vw" : window.innerWidth - 10,  height: is_desktop ? "80vh" : "70vh"}}> */}
                                                            <div style={{ width: is_desktop ? "800px" : window.innerWidth - 40,  height: is_desktop ? "400px" : "70vh"}}>
                                                                <WrappedMap 
                                                                    googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                                                                    loadingElement = {<div style={{  height: `100%` }} />}
                                                                    containerElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    //containerElement = {is_desktop ? (<div style={{ width: `100%`, height: `100%` }} />) : (<div style={{ width: getWidth(), height: `800px` }} />)} 
                                                                    mapElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    default_lat = {sensor.device_location_latitude}
                                                                    default_lng = {sensor.device_location_longitude}
                                                                    sensor={sensor}
                                                                    t={t}
                                                                />
                                                            </div>
                                                        </Card.Content>
                                                </Card>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    
                                </Grid>
                            </React.Fragment>
                        )}
                        {(sensor.model_name === "ELSYS-ERS" || sensor.model_name === "ELSYS-EMS" || (sensor.model_name === "ELSYS-ELT-2" && !sensor.sensor_model.sensor_type.startsWith("waste")) || sensor.model_name.startsWith("CAPROC-CMF")) && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row >
                                        <Grid.Column>
                                            <Card.Group centered >
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && (variable.name === "temperature" || variable.name === "Temperature")) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Temperature")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseFloat(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("°C")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && (variable.name === "externalTemperature" || variable.name.startsWith("External temperature"))) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Temperature (external probe)")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseFloat(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("°C")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && (variable.name === "humidity" || variable.name === "Humidity")) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Humidity")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseInt(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("%RH")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}           
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name.startsWith("External humidity")) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Humidity (external probe)")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseInt(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("%RH")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}                     
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                            {(variable.is_active && variable.name === "light") && (
                                                                <Card >
                                                                    <Card.Header textAlign="center">
                                                                        <div style={{paddingTop: 5}}>
                                                                            <Header as="h3">{t("Light")}</Header>
                                                                            <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                        </div>
                                                                    </Card.Header>
                                                                    <Card.Content textAlign="center">
                                                                        <Statistic size='tiny' >
                                                                            <Statistic.Value>{parseInt(variable.last_value)}</Statistic.Value>
                                                                            <Statistic.Label>{t("Lux")}</Statistic.Label>
                                                                        </Statistic>
                                                                    </Card.Content>
                                                                </Card>
                                                            )}
                                                        </>
                                                    );
                                                })}     
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "motion") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Motion")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseInt(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("pcs since last status")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}                   
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "digital") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Door status")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        {variable.last_value === "False" ? (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="exclamation circle" color="red" /></Statistic.Value>
                                                                                <Statistic.Label>{t("Open")}</Statistic.Label>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="check" color="green"/></Statistic.Value>
                                                                                <Statistic.Label>{t("Closed")}</Statistic.Label>
                                                                            </>
                                                                        )}
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}          
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "accMotion") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Motion (Acceleration movements)")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseInt(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("pcs since last status")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}                               
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "waterleak") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Waterleak")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        {variable.last_value === "True" ? (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="exclamation circle" color="red" /></Statistic.Value>
                                                                                <Statistic.Label>{t("Yes")}</Statistic.Label>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="check" color="green"/></Statistic.Value>
                                                                                <Statistic.Label>{t("No")}</Statistic.Label>
                                                                            </>
                                                                        )}
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}  
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "pressure") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Air pressure")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseFloat(variable.last_value)}</Statistic.Value>
                                                                        {/* <Statistic.Label>{t("hPa")}</Statistic.Label> */}
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}  
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                            {(variable.is_active && variable.name === "vdd") && (
                                                                <Card >
                                                                    <Card.Header textAlign="center">
                                                                        <div style={{paddingTop: 5}}>
                                                                            <Header as="h3">{t("Battery")}</Header>
                                                                            <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                        </div>
                                                                    </Card.Header>
                                                                    <Card.Content textAlign="center">
                                                                        <Statistic size='tiny' >
                                                                            {parseFloat(variable.last_value) > 3.5 && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery full" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 3.5 && parseFloat(variable.last_value) > 3.4) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery three quarters" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 3.4 && parseFloat(variable.last_value) > 3.3) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery half" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 3.3 && parseFloat(variable.last_value) > 3.2) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery quarter" color="yellow"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {parseFloat(variable.last_value) < 3.2 && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery empty" color="red" /></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                        </Statistic>
                                                                    </Card.Content>
                                                                </Card>
                                                            )}
                                                        </>
                                                    );
                                                })}          
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    </Grid>
                            </React.Fragment>
                        )}
                        {(sensor.model_name === "AXIOMA-W1" || sensor.model_name === "BMETERS-LW3") && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row >
                                        <Grid.Column>
                                            <Card.Group centered >
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "Current volume") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Current volume")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' horizontal>
                                                                        <Statistic.Value>{variable.last_value.toString(3)}</Statistic.Value>
                                                                        <Statistic.Label>{t("M3")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "Status") && (
                                                            <Card onClick={() => this.handleGoToAlarms(variable)}>
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Sensor alarm")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny'>
                                                                        {variable.last_value !== "OK" ? (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="exclamation circle" color="red" /></Statistic.Value>
                                                                                <Statistic.Label>{t(variable.last_value)}</Statistic.Label>
                                                                            </>
                                                                        ) : (
                                                                            <>  
                                                                                <Statistic.Value> <Icon name="check" color="green"/></Statistic.Value>
                                                                                <Statistic.Label>{t("No alarms")}</Statistic.Label>
                                                                            </>
                                                                        )}
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}                
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {sensor_statistics && (
                                    <>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Card.Group centered>
                                                    <Card onClick={() => this.handleGoToReport("day", "m3")}>
                                                        <Card.Header textAlign="center">
                                                            <div style={{paddingTop: 5}}>
                                                                <Header as="h3">{t("Today")}</Header>
                                                                <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            
                                                            <Statistic size='tiny' horizontal>
                                                                <Statistic.Value>{sensor_statistics.current_day_consumption.toFixed(3)}</Statistic.Value>
                                                                <Statistic.Label>{t("M3")}</Statistic.Label>
                                                            </Statistic>
                                                            <Card.Meta>{t("Yesterday")}</Card.Meta>
                                                            <Statistic size='mini' horizontal>
                                                                <Statistic.Value>{sensor_statistics.previous_day_consumption.toFixed(3)}</Statistic.Value>
                                                                <Statistic.Label>{t("M3")}</Statistic.Label>
                                                            </Statistic>
                                                            
                                                        </Card.Content>
                                                    </Card>
                                                    <Card onClick={() => this.handleGoToReport("month", "m3")}>
                                                        <Card.Header textAlign="center">
                                                            <div style={{paddingTop: 5}}>
                                                                <Header as="h3">{t("Current week")}</Header>
                                                                <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            <Statistic size='tiny' horizontal>
                                                                <Statistic.Value>{sensor_statistics.current_week_consumption.toFixed(3)}</Statistic.Value>
                                                                <Statistic.Label>{t("M3")}</Statistic.Label>
                                                            </Statistic>
                                                            <Card.Meta>{t("Last week")}</Card.Meta>
                                                            <Statistic size='mini' horizontal>
                                                                <Statistic.Value>{sensor_statistics.previous_week_consumption.toFixed(3)}</Statistic.Value>
                                                                <Statistic.Label>{t("M3")}</Statistic.Label>
                                                            </Statistic>
                                                        </Card.Content>
                                                    </Card>
                                                </Card.Group>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Card.Group centered>
                                                <Card onClick={() => this.handleGoToReport("month", "m3")}>
                                                        <Card.Header textAlign="center">
                                                            <div style={{paddingTop: 5}}>
                                                                <Header as="h3">{t("Current month")}</Header>
                                                                <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            <Statistic size='tiny' horizontal>
                                                                <Statistic.Value>{sensor_statistics.current_month_consumption.toFixed(3)}</Statistic.Value>
                                                                <Statistic.Label>{t("M3")}</Statistic.Label>
                                                            </Statistic>
                                                            <Card.Meta>{t("Last month")}</Card.Meta>
                                                            <Statistic size='mini' horizontal>
                                                                <Statistic.Value>{sensor_statistics.previous_month_consumption.toFixed(3)}</Statistic.Value>
                                                                <Statistic.Label>{t("M3")}</Statistic.Label>
                                                            </Statistic>
                                                        </Card.Content>
                                                    </Card>
                                                    <Card onClick={() => this.handleGoToReport("year", "m3")}>
                                                        <Card.Header textAlign="center">
                                                            <div style={{paddingTop: 5}}>
                                                                <Header as="h3">{t("Current year")}</Header>
                                                                <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            <Statistic size='tiny' horizontal>
                                                                <Statistic.Value>{sensor_statistics.current_year_consumption.toFixed(3)}</Statistic.Value>
                                                                <Statistic.Label>{t("M3")}</Statistic.Label>
                                                            </Statistic>
                                                            <Card.Meta>{t("Last year")}</Card.Meta>
                                                            <Statistic size='mini' horizontal>
                                                                <Statistic.Value>{sensor_statistics.previous_year_consumption.toFixed(3)}</Statistic.Value>
                                                                <Statistic.Label>{t("M3")}</Statistic.Label>
                                                            </Statistic>
                                                        </Card.Content>
                                                    </Card>
                                                </Card.Group>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>
                                    )}
                                    </Grid>
                                    <Grid  columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                <Card fluid>
                                                    <Card.Header textAlign="center">
                                                        <div style={{paddingTop: 5}}>
                                                            <Header as="h3">{t("Position")}</Header>
                                                            <Card.Meta>{t("Lat")}: {sensor.device_location_latitude} {t("Lon")}: {sensor.device_location_longitude}</Card.Meta>
                                                            <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                        </div>
                                                    </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            {/* <div style={{ width: is_desktop ? "50vw" : window.innerWidth - 10,  height: is_desktop ? "80vh" : "70vh"}}> */}
                                                            <div style={{ width: is_desktop ? "800px" : window.innerWidth - 40,  height: is_desktop ? "400px" : "70vh"}}>
                                                                <WrappedMap 
                                                                    googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                                                                    loadingElement = {<div style={{  height: `100%` }} />}
                                                                    containerElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    //containerElement = {is_desktop ? (<div style={{ width: `100%`, height: `100%` }} />) : (<div style={{ width: getWidth(), height: `800px` }} />)} 
                                                                    mapElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    default_lat = {sensor.device_location_latitude}
                                                                    default_lng = {sensor.device_location_longitude}
                                                                    sensor={sensor}
                                                                    t={t}
                                                                />
                                                            </div>
                                                        </Card.Content>
                                                </Card>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    
                                </Grid>
                            </React.Fragment>
                        )}
                        {(sensor.sensor_model.sensor_type.startsWith("waste")) && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>

                                    {/* <Grid.Row >
                                        <Grid.Column>
                                            <Card.Group centered >
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && (variable.name === "waste_distance" || variable.name === "fillingPercentage")) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Filling rate")}</Header>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                <Statistic size='tiny' color={parseInt(variable.last_value) > 70 ? "red" : "black"}>
                                                                    <Statistic.Value>{parseInt(variable.last_value)} {variable.measurement_unit}</Statistic.Value>
                                                                </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "fillingVolume") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{`${t("Filling volume")}`}</Header>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                <Statistic size='tiny'>
                                                                    <Statistic.Value>{parseInt(variable.last_value)} {variable.measurement_unit}</Statistic.Value>
                                                                </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}                            
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "fraction") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Fraction")}</Header>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Header>{t(variable.last_value)}</Header>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                            {(variable.is_active && variable.name === "container_type") && (
                                                                <Card >
                                                                    <Card.Header textAlign="center">
                                                                        <div style={{paddingTop: 5}}>
                                                                            <Header as="h3">{t("Container type")}</Header>
                                                                        </div>
                                                                    </Card.Header>
                                                                    <Card.Content textAlign="center">
                                                                        <Statistic size='tiny' >
                                                                            <Header>{t(variable.last_value)}</Header>
                                                                        </Statistic>
                                                                    </Card.Content>
                                                                </Card>
                                                            )}
                                                        </>
                                                    );
                                                })}             
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row> */}

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered >

                                                {/* {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && (variable.name === "temperature" || variable.name === "Temperature")) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Temperature")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseFloat(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("°C")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "externalTemperature") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Temperature (external probe)")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseFloat(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("°C")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "humidity") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Humidity")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseInt(variable.last_value)}</Statistic.Value>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}     
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "Tilt angle") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Tilt angle")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseInt(variable.last_value)}</Statistic.Value>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}                 */}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.is_display_value) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t(variable.display_name)}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{variable.name === "fraction" ? t(variable.last_value) : variable.last_value}</Statistic.Value>
                                                                        <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}                

                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                
                                                {/* {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "pressure") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Air pressure")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseFloat(variable.last_value)}</Statistic.Value>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}   */}
                                                <>
                                                    <Card >
                                                        <Card.Header textAlign="center">
                                                            <div style={{paddingTop: 5}}>
                                                                <Header as="h3">{t("Battery")}</Header>
                                                                {/* <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta> */}
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            <Statistic size='tiny' >
                                                                {parseInt(sensor.battery_level_percent) > 75 && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery full" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 75 && parseInt(sensor.battery_level_percent) > 50) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery three quarters" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 50 && parseInt(sensor.battery_level_percent) > 25) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery half" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 25 && parseInt(sensor.battery_level_percent) > 10) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery quarter" color="yellow"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {parseInt(sensor.battery_level_percent) < 10 && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery empty" color="red" /></Statistic.Value>
                                                                    </>
                                                                )}
                                                                <Statistic.Label>{sensor.battery_level_percent} %</Statistic.Label>
                                                            </Statistic>
                                                        </Card.Content>
                                                    </Card>
                                                </>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    </Grid>
                                    <Grid  columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                <Card fluid>
                                                    <Card.Header textAlign="center">
                                                        <div style={{paddingTop: 5}}>
                                                            <Header as="h3">{t("Position")}</Header>
                                                            {sensor.sensorstatusvariables.map(variable => {
                                                                return (
                                                                    <>
                                                                        {(variable.name === "fixFailed" && variable.last_value === "False") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Icon name="checkmark" color="green"/></Card.Meta>
                                                                        )}
                                                                        {(variable.name === "fixFailed" && variable.last_value === "True") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Popup content={t("GPS fixing failed")} trigger={<Icon name="times" color="red"/>}/></Card.Meta>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                            <Card.Meta>{t("Lat")}: {sensor.device_location_latitude} {t("Lon")}: {sensor.device_location_longitude}</Card.Meta>
                                                            <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                        </div>
                                                    </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            {/* <div style={{ width: is_desktop ? "50vw" : window.innerWidth - 10,  height: is_desktop ? "80vh" : "70vh"}}> */}
                                                            <div style={{ width: is_desktop ? "800px" : window.innerWidth - 40,  height: is_desktop ? "400px" : "70vh"}}>
                                                                <WrappedMap 
                                                                    googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                                                                    loadingElement = {<div style={{  height: `100%` }} />}
                                                                    containerElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    //containerElement = {is_desktop ? (<div style={{ width: `100%`, height: `100%` }} />) : (<div style={{ width: getWidth(), height: `800px` }} />)} 
                                                                    mapElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    default_lat = {sensor.device_location_latitude}
                                                                    default_lng = {sensor.device_location_longitude}
                                                                    sensor={sensor}
                                                                    t={t}
                                                                />
                                                            </div>
                                                        </Card.Content>
                                                </Card>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        )}
                        {(sensor.sensor_model.sensor_type === "water level") && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.is_display_value) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t(variable.display_name)}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{variable.last_value}</Statistic.Value>
                                                                        <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}  
                                                <>
                                                    <Card >
                                                        <Card.Header textAlign="center">
                                                            <div style={{paddingTop: 5}}>
                                                                <Header as="h3">{t("Battery")}</Header>
                                                                {/* <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta> */}
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            <Statistic size='tiny' >
                                                                {parseInt(sensor.battery_level_percent) > 75 && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery full" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 75 && parseInt(sensor.battery_level_percent) > 50) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery three quarters" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 50 && parseInt(sensor.battery_level_percent) > 25) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery half" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 25 && parseInt(sensor.battery_level_percent) > 10) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery quarter" color="yellow"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {parseInt(sensor.battery_level_percent) < 10 && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery empty" color="red" /></Statistic.Value>
                                                                    </>
                                                                )}
                                                                <Statistic.Label>{sensor.battery_level_percent} %</Statistic.Label>
                                                            </Statistic>
                                                        </Card.Content>
                                                    </Card>
                                                </>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid  columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                <Card fluid>
                                                    <Card.Header textAlign="center">
                                                        <div style={{paddingTop: 5}}>
                                                            <Header as="h3">{t("Position")}</Header>
                                                            {sensor.sensorstatusvariables.map(variable => {
                                                                return (
                                                                    <>
                                                                        {(variable.name === "fixFailed" && variable.last_value === "False") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Icon name="checkmark" color="green"/></Card.Meta>
                                                                        )}
                                                                        {(variable.name === "fixFailed" && variable.last_value === "True") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Popup content={t("GPS fixing failed")} trigger={<Icon name="times" color="red"/>}/></Card.Meta>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                            <Card.Meta>{t("Lat")}: {sensor.device_location_latitude} {t("Lon")}: {sensor.device_location_longitude}</Card.Meta>
                                                            <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                        </div>
                                                    </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            {/* <div style={{ width: is_desktop ? "50vw" : window.innerWidth - 10,  height: is_desktop ? "80vh" : "70vh"}}> */}
                                                            <div style={{ width: is_desktop ? "800px" : window.innerWidth - 40,  height: is_desktop ? "400px" : "70vh"}}>
                                                                <WrappedMap 
                                                                    googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                                                                    loadingElement = {<div style={{  height: `100%` }} />}
                                                                    containerElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    //containerElement = {is_desktop ? (<div style={{ width: `100%`, height: `100%` }} />) : (<div style={{ width: getWidth(), height: `800px` }} />)} 
                                                                    mapElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    default_lat = {sensor.device_location_latitude}
                                                                    default_lng = {sensor.device_location_longitude}
                                                                    sensor={sensor}
                                                                    t={t}
                                                                />
                                                            </div>
                                                        </Card.Content>
                                                </Card>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        )}
                        {(sensor.model_name.startsWith("NETVOX")) && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row >
                                        <Grid.Column>
                                            <Card.Group centered >
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "temperature") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Temperature")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseFloat(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("°C")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "illuminance") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Light")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{parseInt(variable.last_value)}</Statistic.Value>
                                                                        <Statistic.Label>{t("Lux")}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}                
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "occupied") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Motion")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        {variable.last_value === "True" ? (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="exclamation circle" color="red" /></Statistic.Value>
                                                                                <Statistic.Label>{t("Motion")}</Statistic.Label>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="check" color="green"/></Statistic.Value>
                                                                                <Statistic.Label>{t("No motion")}</Statistic.Label>
                                                                            </>
                                                                        )}
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}         
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.name === "contact") && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("Door status")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        {variable.last_value === "True" ? (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="exclamation circle" color="red" /></Statistic.Value>
                                                                                <Statistic.Label>{t("Open")}</Statistic.Label>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="check" color="green"/></Statistic.Value>
                                                                                <Statistic.Label>{t("Closed")}</Statistic.Label>
                                                                            </>
                                                                        )}
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}         
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                            {(sensor.model_name === "NETVOX-RB11E" && variable.is_active && variable.name === "battery") && (
                                                                <Card >
                                                                    <Card.Header textAlign="center">
                                                                        <div style={{paddingTop: 5}}>
                                                                            <Header as="h3">{t("Battery")}</Header>
                                                                            <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                        </div>
                                                                    </Card.Header>
                                                                    <Card.Content textAlign="center">
                                                                        <Statistic size='tiny' >
                                                                            {parseFloat(variable.last_value) > 3.5 && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery full" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 3.5 && parseFloat(variable.last_value) > 3.4) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery three quarters" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 3.4 && parseFloat(variable.last_value) > 3.3) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery half" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 3.3 && parseFloat(variable.last_value) > 3.2) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery quarter" color="yellow"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {parseFloat(variable.last_value) < 3.2 && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery empty" color="red" /></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                        </Statistic>
                                                                    </Card.Content>
                                                                </Card>
                                                            )}
                                                            {(sensor.model_name === "NETVOX-R313A" && variable.is_active && variable.name === "battery") && (
                                                                <Card >
                                                                    <Card.Header textAlign="center">
                                                                        <div style={{paddingTop: 5}}>
                                                                            <Header as="h3">{t("Battery")}</Header>
                                                                            <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                        </div>
                                                                    </Card.Header>
                                                                    <Card.Content textAlign="center">
                                                                        <Statistic size='tiny' >
                                                                            {parseFloat(variable.last_value) > 2.9 && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery full" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 2.9 && parseFloat(variable.last_value) > 2.8) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery three quarters" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 2.8 && parseFloat(variable.last_value) > 2.6) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery half" color="green"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {(parseFloat(variable.last_value) < 2.6 && parseFloat(variable.last_value) > 2.4) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery quarter" color="yellow"/></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                            {parseFloat(variable.last_value) < 2.4 && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="battery empty" color="red" /></Statistic.Value>
                                                                                    <Statistic.Label>{variable.last_value} V</Statistic.Label>
                                                                                </>
                                                                            )}
                                                                        </Statistic>
                                                                    </Card.Content>
                                                                </Card>
                                                            )}
                                                        </>
                                                    );
                                                })}          
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    </Grid>
                            </React.Fragment>
                        )}
                        {(sensor.model_name.startsWith("CAPROC-IC")) && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.is_display_value) && (
                                                            <>
                                                            {(variable.name === "CO2 SCD4x") ? (
                                                                <Card >
                                                                    <Card.Header textAlign="center">
                                                                        <div style={{paddingTop: 5}}>
                                                                            <Header as="h3">{t(variable.display_name)}</Header>
                                                                            <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                        </div>
                                                                    </Card.Header>
                                                                    <Card.Content textAlign="center">
                                                                        <Statistic  >
                                                                            {parseInt(variable.last_value) <= 1000 && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="smile outline" color="green"/></Statistic.Value>
                                                                                </>
                                                                            )}
                                                                            {(parseInt(variable.last_value) <= 1600 && parseInt(variable.last_value) > 1000) && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="meh outline" color="yellow"/></Statistic.Value>
                                                                                </>
                                                                            )}
                                                                            {parseInt(variable.last_value) > 1600 && (
                                                                                <>
                                                                                    <Statistic.Value> <Icon name="frown outline" color="red" /></Statistic.Value>
                                                                                </>
                                                                            )}
                                                                            <Statistic.Label>{variable.last_value} {t(variable.measurement_unit)}</Statistic.Label>
                                                                        </Statistic>
                                                                    </Card.Content>
                                                                </Card>
                                                            ) : (
                                                                <Card >
                                                                    <Card.Header textAlign="center">
                                                                        <div style={{paddingTop: 5}}>
                                                                            <Header as="h3">{t(variable.display_name)}</Header>
                                                                            <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                        </div>
                                                                    </Card.Header>
                                                                    <Card.Content textAlign="center">
                                                                        <Statistic size='tiny' >
                                                                            <Statistic.Value>{variable.last_value}</Statistic.Value>
                                                                            <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                        </Statistic>
                                                                    </Card.Content>
                                                                </Card>
                                                            )}
                                                            </>
                                                        )}
                                                        </>
                                                    );
                                                })}  
                                                <>
                                                    <Card >
                                                        <Card.Header textAlign="center">
                                                            <div style={{paddingTop: 5}}>
                                                                <Header as="h3">{t("Battery")}</Header>
                                                                {/* <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta> */}
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            <Statistic size='tiny' >
                                                                {parseInt(sensor.battery_level_percent) > 75 && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery full" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 75 && parseInt(sensor.battery_level_percent) > 50) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery three quarters" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 50 && parseInt(sensor.battery_level_percent) > 25) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery half" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 25 && parseInt(sensor.battery_level_percent) > 10) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery quarter" color="yellow"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {parseInt(sensor.battery_level_percent) < 10 && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery empty" color="red" /></Statistic.Value>
                                                                    </>
                                                                )}
                                                                <Statistic.Label>{sensor.battery_level_percent} %</Statistic.Label>
                                                            </Statistic>
                                                        </Card.Content>
                                                    </Card>
                                                </>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        )}
                        {(sensor.sensor_model.sensor_type === "water flow" || sensor.model_name.startsWith("CAPROC-TH-PROBE") || sensor.model_name.startsWith("CAPROC-T-PROBE") || sensor.model_name.startsWith("CAPROC-OC-TH") || sensor.model_name.startsWith("CAPROC-AQUA-OPTOD") || sensor.model_name.startsWith("CAPROC-AQUA-PHEHT") || sensor.model_name.startsWith("CAPROC-AQUA-C4E") || sensor.model_name.startsWith("CAPROC-AQUA-NTU") || sensor.model_name.startsWith("CAPROC-AQUA-STACSENSE") || sensor.model_name.startsWith("CAPROC-AQUA-PHEHT-C4E") || sensor.sensor_model.sensor_type === "scale" || sensor.model_name.startsWith("DECENTLAB-DL-PAR") || sensor.model_name.startsWith("CAPROC-RAIN")) && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.is_display_value) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t(variable.display_name)}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        <Statistic.Value>{variable.last_value}</Statistic.Value>
                                                                        <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}  
                                                <>
                                                    <Card >
                                                        <Card.Header textAlign="center">
                                                            <div style={{paddingTop: 5}}>
                                                                <Header as="h3">{t("Battery")}</Header>
                                                                {/* <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta> */}
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            <Statistic size='tiny' >
                                                                {parseInt(sensor.battery_level_percent) > 75 && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery full" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 75 && parseInt(sensor.battery_level_percent) > 50) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery three quarters" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 50 && parseInt(sensor.battery_level_percent) > 25) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery half" color="green"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {(parseInt(sensor.battery_level_percent) < 25 && parseInt(sensor.battery_level_percent) > 10) && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery quarter" color="yellow"/></Statistic.Value>
                                                                    </>
                                                                )}
                                                                {parseInt(sensor.battery_level_percent) < 10 && (
                                                                    <>
                                                                        <Statistic.Value> <Icon name="battery empty" color="red" /></Statistic.Value>
                                                                    </>
                                                                )}
                                                                <Statistic.Label>{sensor.battery_level_percent} %</Statistic.Label>
                                                            </Statistic>
                                                        </Card.Content>
                                                    </Card>
                                                </>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid  columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                <Card fluid>
                                                    <Card.Header textAlign="center">
                                                        <div style={{paddingTop: 5}}>
                                                            <Header as="h3">{t("Position")}</Header>
                                                            {sensor.sensorstatusvariables.map(variable => {
                                                                return (
                                                                    <>
                                                                        {(variable.name === "fixFailed" && variable.last_value === "False") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Icon name="checkmark" color="green"/></Card.Meta>
                                                                        )}
                                                                        {(variable.name === "fixFailed" && variable.last_value === "True") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Popup content={t("GPS fixing failed")} trigger={<Icon name="times" color="red"/>}/></Card.Meta>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                            <Card.Meta>{t("Lat")}: {sensor.device_location_latitude} {t("Lon")}: {sensor.device_location_longitude}</Card.Meta>
                                                            <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                        </div>
                                                    </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            {/* <div style={{ width: is_desktop ? "50vw" : window.innerWidth - 10,  height: is_desktop ? "80vh" : "70vh"}}> */}
                                                            <div style={{ width: is_desktop ? "800px" : window.innerWidth - 40,  height: is_desktop ? "400px" : "70vh"}}>
                                                                <WrappedMap 
                                                                    googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                                                                    loadingElement = {<div style={{  height: `100%` }} />}
                                                                    containerElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    //containerElement = {is_desktop ? (<div style={{ width: `100%`, height: `100%` }} />) : (<div style={{ width: getWidth(), height: `800px` }} />)} 
                                                                    mapElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    default_lat = {sensor.device_location_latitude}
                                                                    default_lng = {sensor.device_location_longitude}
                                                                    sensor={sensor}
                                                                    t={t}
                                                                />
                                                            </div>
                                                        </Card.Content>
                                                </Card>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        )}
                        {(sensor.model_name.startsWith("CAPROC-HAN")) && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.is_display_value && variable.name === "HAN power status") ? (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t("HAN power status")}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        {variable.last_value === "Fals" ? (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="exclamation circle" color="red" /></Statistic.Value>
                                                                                <Statistic.Label>{t("Off")}</Statistic.Label>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Statistic.Value> <Icon name="check" color="green"/></Statistic.Value>
                                                                                <Statistic.Label>{t("On")}</Statistic.Label>
                                                                            </>
                                                                        )}
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        ) :(
                                                            <>
                                                            {(variable.is_active && variable.is_display_value) && (
                                                                <Card >
                                                                    <Card.Header textAlign="center">
                                                                        <div style={{paddingTop: 5}}>
                                                                            <Header as="h3">{t(variable.display_name)}</Header>
                                                                            <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                        </div>
                                                                    </Card.Header>
                                                                    <Card.Content textAlign="center">
                                                                        <Statistic size='tiny' >
                                                                            <Statistic.Value>{variable.last_value}</Statistic.Value>
                                                                            <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                        </Statistic>
                                                                    </Card.Content>
                                                                </Card>
                                                            )}
                                                            </>
                                                        )}
                                                        </>
                                                    );
                                                })}  
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid  columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                <Card fluid>
                                                    <Card.Header textAlign="center">
                                                        <div style={{paddingTop: 5}}>
                                                            <Header as="h3">{t("Position")}</Header>
                                                            {sensor.sensorstatusvariables.map(variable => {
                                                                return (
                                                                    <>
                                                                        {(variable.name === "fixFailed" && variable.last_value === "False") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Icon name="checkmark" color="green"/></Card.Meta>
                                                                        )}
                                                                        {(variable.name === "fixFailed" && variable.last_value === "True") && (
                                                                            <Card.Meta>{t("GPS fixing")}: <Popup content={t("GPS fixing failed")} trigger={<Icon name="times" color="red"/>}/></Card.Meta>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                            <Card.Meta>{t("Lat")}: {sensor.device_location_latitude} {t("Lon")}: {sensor.device_location_longitude}</Card.Meta>
                                                            <Card.Meta>{sensor.last_status ? new Date(sensor.last_status).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                        </div>
                                                    </Card.Header>
                                                        <Card.Content textAlign="center">
                                                            {/* <div style={{ width: is_desktop ? "50vw" : window.innerWidth - 10,  height: is_desktop ? "80vh" : "70vh"}}> */}
                                                            <div style={{ width: is_desktop ? "800px" : window.innerWidth - 40,  height: is_desktop ? "400px" : "70vh"}}>
                                                                <WrappedMap 
                                                                    googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                                                                    loadingElement = {<div style={{  height: `100%` }} />}
                                                                    containerElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    //containerElement = {is_desktop ? (<div style={{ width: `100%`, height: `100%` }} />) : (<div style={{ width: getWidth(), height: `800px` }} />)} 
                                                                    mapElement = {<div style={{ width: `100%`, height: `100%` }} />}
                                                                    default_lat = {sensor.device_location_latitude}
                                                                    default_lng = {sensor.device_location_longitude}
                                                                    sensor={sensor}
                                                                    t={t}
                                                                />
                                                            </div>
                                                        </Card.Content>
                                                </Card>
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        )}
                        {(sensor.model_name.startsWith("MCF88-LW13IO")) && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.is_display_value) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t(variable.display_name)}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        {variable.name === "Relay status" && (
                                                                            <>
                                                                                <Statistic.Value>{variable.last_value === "1" ? (t("Closed")) : (t("Open"))}</Statistic.Value>
                                                                                <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                            </>
                                                                        )}
                                                                        {variable.name === "Input status" && (
                                                                            <>
                                                                                <Statistic.Value>{variable.last_value === "1" ? (t("On")) : (t("Off"))}</Statistic.Value>
                                                                                <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                            </>
                                                                        )}
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}  
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        )}
                        {(sensor.model_name.startsWith("DNIL-LR2")) && (
                            <React.Fragment>
                                <Grid container stackable verticalAlign="middle" columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Card.Group centered>
                                                {sensor.sensorstatusvariables.map(variable => {
                                                    return(
                                                        <>
                                                        {(variable.is_active && variable.is_display_value) && (
                                                            <Card >
                                                                <Card.Header textAlign="center">
                                                                    <div style={{paddingTop: 5}}>
                                                                        <Header as="h3">{t(variable.display_name)}</Header>
                                                                        <Card.Meta>{variable.event_time ? new Date(variable.event_time).toLocaleString('fi-FI') : ""}</Card.Meta>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Content textAlign="center">
                                                                    <Statistic size='tiny' >
                                                                        {variable.name.startsWith("Relay ch") ? (
                                                                            <>
                                                                                {variable.name === "Relay ch1 status" && (
                                                                                    <>
                                                                                        <Statistic.Value>{variable.last_value === "1" ? (t("On")) : (t("Off"))}</Statistic.Value>
                                                                                        <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                                    </>
                                                                                )}
                                                                                {variable.name === "Relay ch2 status" && (
                                                                                    <>
                                                                                        <Statistic.Value>{variable.last_value === "1" ? (t("On")) : (t("Off"))}</Statistic.Value>
                                                                                        <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Statistic.Value>{variable.last_value}</Statistic.Value>
                                                                                <Statistic.Label>{t(variable.measurement_unit)}</Statistic.Label>
                                                                            </>
                                                                        )}
                                                                    </Statistic>
                                                                </Card.Content>
                                                            </Card>
                                                        )}
                                                        </>
                                                    );
                                                })}  
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRoles: state.company.activeRoles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensorstatus')(SensorStatus))
);