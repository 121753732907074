import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Confirm, Grid, Input } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyTaskListURL, companyTaskDeleteURL } from "../../constants";


class CompanyTasks extends React.Component {
    state = {
        tasks: null,
        error: null,
        loading: false,
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        search_start_date: null,
        search_end_date: null,
        search: ""
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchList();
    }


    handleFetchList = () => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(companyTaskListURL(activeCompanyId))
            .then(res => {
                this.setState({
                    tasks: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    taskDetails = (id) => {
        this.props.history.push(`/company/task-details/${id}/`)
    };

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleDelete = id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(companyTaskDeleteURL(activeCompanyId, id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchList();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date")
        });
    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date")
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        const { error, loading, tasks, delete_confirm_open, delete_confirm_text, delete_item, search, search_end_date, search_start_date } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        let filtered_items = [];

        // Filter data for search
        if (tasks !== null) {
            filtered_items = tasks.filter((task) => {
                return task.location.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.category.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.target.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.name.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.description.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.operator.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.task_date.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
            if (search_start_date !== null) {
                filtered_items = filtered_items.filter((task) => {
                    return (new Date(task.task_date) >= new Date(search_start_date))
                })
            }
            if (search_end_date !== null) {
                filtered_items = filtered_items.filter((task) => {
                    return (new Date(task.task_date) <= new Date(search_end_date).setDate(new Date(search_end_date).getDate()))
                })
            }
        }
        return (
            <Shell>
                <Header as="h3">{t("Company tasks")}</Header>
                <Grid columns="2">
                    <Grid.Column width={9}>
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            size="mini"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleStartDateChange(selected)}
                            format='DD.MM.YYYY' />
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            size="mini"
                            disabled={loading}
                            label="->"
                            locale={fiLocale}
                            placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleEndDateChange(selected)}
                            format='DD.MM.YYYY' />
                    </Grid.Column>
                    <Grid.Column floated="right" width={6}>
                        <Input
                            size="mini"
                            icon='search'
                            value={search}
                            name="search"
                            onChange={this.handleChange} />
                        <Button positive size='tiny' content={t("+ Task")} onClick={() => this.props.history.push(`/company/task-details/create/`)} />
                    </Grid.Column>
                </Grid>

                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {tasks && (
                    <Table striped celled unstackable >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell >{t("Location")}</Table.HeaderCell>
                                <Table.HeaderCell width="4">{t("Category")}</Table.HeaderCell>
                                <Table.HeaderCell width="4">{t("Target")}</Table.HeaderCell>
                                <Table.HeaderCell width="4">{t("Task name")}</Table.HeaderCell>
                                <Table.HeaderCell width="5">{t("Description")}</Table.HeaderCell>
                                <Table.HeaderCell width="4">{t("Operator")}</Table.HeaderCell>
                                <Table.HeaderCell width="4">{t("Date")}</Table.HeaderCell>
                                <Table.HeaderCell width="2" />
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {filtered_items.map((task, key) => {
                                return (
                                    <Table.Row key={key} >
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>{task.location}</Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>{task.category}</Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>{task.target}</Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>
                                            {task.name}
                                            {task.task_files.length > 0 && (<Header as="h6" floated="right" icon="attach" />)}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>{task.description}</Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>{task.operator}</Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>{new Date(task.task_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                        <Table.Cell textAlign='center'><Button compact negative size='mini' icon='remove'
                                            onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { name: task.name }), task.id)} />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                )}
                <Confirm
                    content={delete_confirm_text}
                    open={delete_confirm_open}
                    onCancel={() => this.closeDeleteConfirm()}
                    onConfirm={() => this.handleDelete(delete_item)}
                />
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companytasks')(CompanyTasks))
);