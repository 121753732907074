import React from "react";
import { Form, Input, Message, Header, Button, Select } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyDetailsURL, countriesURL, updateCompanyDetailsURL } from "../../constants";


class CompanyDetails extends React.Component {
    state = {
        company_id: "",
        number: "",
        vat: "",
        name: "",
        description: "",
        address: "",
        postal_code: "",
        country: "",
        phone: "",
        email: "",
        homepage: "",
        bank_account: "",
        bank_name: "",
        bank_BIC: "",
        last_modified: "",
        author: "",
        city: "",
        country_options: null,
        error: null,
        success_mgs: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleCompanyDetails();
        this.fetchCountryOptions();
    }

    fetchCountryOptions = () => {
        this.setState({
            loading: true,
        });
        authAxios
            .get(countriesURL)
            .then(res => {
                this.setState({
                    country_options: this.handleFormatCountries(res.data),
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatCountries = countries => {
        const keys = Object.keys(countries);
        return keys.map(k => {
            return {
                key: k,
                text: countries[k],
                value: k
            };
        });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleCompanyDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(companyDetailsURL(activeCompanyId, activeCompanyId))
            .then(res => {
                this.setState({
                    company_id: res.data.id,
                    vat: res.data.vat,
                    name: res.data.name,
                    description: res.data.description,
                    address: res.data.address,
                    postal_code: res.data.postal_code,
                    country: res.data.country,
                    phone: res.data.phone,
                    email: res.data.email,
                    homepage: res.data.homepage,
                    bank_account: res.data.bank_account,
                    bank_name: res.data.bank_name,
                    bank_BIC: res.data.bank_BIC,
                    last_modified: res.data.last_modified,
                    author: res.data.author,
                    city: res.data.city,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            author,
            vat,
            name,
            description,
            address,
            postal_code,
            country,
            phone,
            email,
            homepage,
            bank_account,
            bank_name,
            bank_BIC,
            city
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (name === "") {
            this.setState({
                error: t("Please fill in name"),
                success_mgs: null,
                loading: false
            });
        } else if (address === "") {
            this.setState({
                error: t("Please fill in address"),
                success_mgs: null,
                loading: false
            });
        } else if (postal_code === "") {
            this.setState({
                error: t("Please fill in postal code"),
                success_mgs: null,
                loading: false
            });
        } else if (city === "") {
            this.setState({
                error: t("Please fill in city"),
                success_mgs: null,
                loading: false
            });
        } else if (vat.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Vat"),
                success_mgs: null,
                loading: false
            });
        } else if (name.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 for Name"),
                success_mgs: null,
                loading: false
            });
        } else if (address.length > 150) {
            this.setState({
                error: t("Max amount of characters is 150 for Address"),
                success_mgs: null,
                loading: false
            });
        } else if (postal_code.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Postal code"),
                success_mgs: null,
                loading: false
            });
        } else if (city.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for City"),
                success_mgs: null,
                loading: false
            });
        } else if (phone.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for Phone"),
                success_mgs: null,
                loading: false
            });
        } else if (homepage.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Homepage"),
                success_mgs: null,
                loading: false
            });
        } else if (bank_account.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Bank account"),
                success_mgs: null,
                loading: false
            });
        } else if (bank_name.length > 30) {
            this.setState({
                error: t("Max amount of characters is 30 for Bank name"),
                success_mgs: null,
                loading: false
            });
        } else if (bank_BIC.length > 15) {
            this.setState({
                error: t("Max amount of characters is 15 for Bank BIC"),
                success_mgs: null,
                loading: false
            });
        } else {
            authAxios
                .put(updateCompanyDetailsURL(activeCompanyId, activeCompanyId), {
                    vat,
                    author,
                    name,
                    description,
                    address,
                    postal_code,
                    country,
                    phone,
                    email,
                    homepage,
                    bank_account,
                    bank_name,
                    bank_BIC,
                    city
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        success_mgs: t("Company updated successfully"),
                        error: null
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }
    };

    render() {
        const {
            company_id,
            vat,
            name,
            description,
            address,
            postal_code,
            country,
            phone,
            email,
            homepage,
            bank_account,
            bank_name,
            bank_BIC,
            last_modified,
            city,
            country_options,
            error,
            success_mgs,
            loading } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        return (
            <Shell>
                <Header as="h4">{t("Company details")}</Header>
                <Form error={error !== null}>
                    <Form.Field>
                        <label>{t("Customer number")}</label>
                        <b>{company_id}</b>
                    </Form.Field>
                    {(vat !== "" && vat !== "x") && (
                        <Form.Field >
                            <label>{t("Vat")}</label>
                            <b>{vat}</b>
                            {/* <Input
                                
                                required
                                value={vat}
                                name="vat"
                                onChange={this.handleChange} /> */}
                        </Form.Field>
                    )}
                    <Form.Field required>
                        <label>{t("Name")}</label>
                        <Input
                            required
                            value={name}
                            name="name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Description")}</label>
                        <Input
                            value={description}
                            name="description"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Address")}</label>
                        <Input
                            required
                            value={address}
                            name="address"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Postal code")}</label>
                        <Input
                            required
                            value={postal_code}
                            name="postal_code"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("City")}</label>
                        <Input
                            required
                            value={city}
                            name="city"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Country")}</label>
                        <Select
                            search
                            fluid
                            name='country'
                            options={country_options}
                            placeholder={country}
                            onChange={this.handleSelectChange}
                            value={country}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Phone")}</label>
                        <Input
                            value={phone}
                            name="phone"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Email")}</label>
                        <Input
                            value={email}
                            name="email"
                            onChange={this.handleChange} />
                    </Form.Field>
                    {/*
                    <Form.Field>
                        <label>{t("Homepage")}</label>
                        <Input
                            value={homepage}
                            name="homepage"
                            onChange={this.handleChange} />
                    </Form.Field>
                     <Form.Field>
                        <label>{t("Bank account")}</label>
                        <Input
                            value={bank_account}
                            name="bank_account"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Bank name")}</label>
                        <Input
                            value={bank_name}
                            name="bank_name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Bank BIC")}</label>
                        <Input
                            value={bank_BIC}
                            name="bank_BIC"
                            onChange={this.handleChange} />
                    </Form.Field> */}
                    <Form.Field>
                        <label>{t("Last modified")}</label>
                        <Input
                            value={new Date(last_modified).toLocaleString('fi-FI')}
                            name="last_modified"
                            onChange={this.handleChange} disabled />
                    </Form.Field>

                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    {(this.props.activeRoles && (this.props.activeRoles.includes("A") || this.props.activeRoles.includes("S"))) && (
                        <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleSubmit(e)}>
                            {t("Save")}
                        </Button>
                    )}
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRoles: state.company.activeRoles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companydetails')(CompanyDetails))
);