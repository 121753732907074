// Copyright Caproc Oy
// System name: IoT
// System module: Sensor
// Author: TS
//
// 6.3.2022 TS Initial version


import React from "react";
import { Form, Message, Header, Button, Select, Dimmer, Loader, Segment, Image } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { sensorMoveToOtherCompanyURL, sensorDownlinkDetailsURL } from "../../constants";


class SensorMoveToOtherCompany extends React.Component {
    state = {
        sensor: null,
        destination_company_id: null,
        error: null,
        success_mgs: null,
        loading: false,
        component_loading: false,
    };

    componentDidMount() {
        this.props.setActivity();
        this.setState({
            component_loading: true
        });
        this.handleDetails();
        this.setState({
            component_loading: false
        });

    }

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { sensor_id } = this.props.match.params;

        authAxios
            .get(sensorDownlinkDetailsURL(activeCompanyId, sensor_id))
            .then(res => {
                this.setState({
                    sensor: res.data,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            sensor, destination_company_id
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (sensor.id === null) return;

        let sensor_ids = [];
        sensor_ids.push(sensor.id);

        if (sensor_ids.length > 0) {
            try {
                const response = await authAxios.post(sensorMoveToOtherCompanyURL(activeCompanyId, destination_company_id), 
                {
                    sensor_ids: sensor_ids
                });
                this.setState({
                    loading: false,
                    success_mgs: t("Sensor moved"),
                    error: null
                });
            } catch (error) {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: error
                });
                return;
            }

        } else {
            this.setState({
                loading: false
            });
        }
    };

    handleSetOptions = (_userCompanies) => {
        let options = [];
        let i = 0;
        for (i in _userCompanies) {
            options.push({
                key: _userCompanies[i].id,
                text: _userCompanies[i].name,
                value: _userCompanies[i].id
            });
        }
        return options;
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            success_mgs: null,
            error: null,
        });
    };

    handleGetSensorCategories = sensor => {
        let return_string = sensor.local_name;
        if (sensor.local_machine !== null && sensor.local_machine !== "")
        {
            return_string = return_string + "/" + sensor.local_machine;
        }
        if (sensor.local_line !== null && sensor.local_line !== "")
        {
            return_string = return_string + "/" + sensor.local_line;
        }
        if (sensor.local_unit !== null && sensor.local_unit !== "")
        {
            return_string = return_string + "/" + sensor.local_unit;
        }
        return return_string;
    };

    render() {
        const {
            sensor,
            error,
            success_mgs,
            loading,
            component_loading,
            destination_company_id
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell active_sensor_id={sensor && sensor.id} active_sensor_name={sensor && sensor.local_name} active_sensor_model={sensor && (sensor.model_name)} active_sensor_device_name={sensor && (sensor.device_name)} active_sensor_type={sensor && (sensor.sensor_model.sensor_type)}>
                {sensor && (
                    <>
                        {component_loading ? (
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>
                                <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                            </Segment>

                        ) : (
                            <React.Fragment>
                                {loading && (
                                    <Segment>
                                        <Dimmer active inverted>
                                            <Loader inverted>Loading</Loader>
                                        </Dimmer>
                                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                    </Segment>
                                )}

                                <Header as="h3">{t("Move sensor to other company")}</Header>
                                <br/>
                                <Form onSubmit={this.handleSubmit} error={error !== null}>
                                    
                                    <Form.Field width="6">
                                    
                                    <b >{t("Sensor")}: </b>{this.handleGetSensorCategories(sensor)}<br/>
                                    <b >{t("Sensor model")}: </b> {sensor.device_name} <br/>
                                    <b >{t("Device EUI")}: </b> {sensor.device_eui} <br/>

                                    <hr/>
                                    </Form.Field>
                                    
                                    <Form.Field width={4}>
                                        <Select
                                            search
                                            name="destination_company_id"
                                            options={this.handleSetOptions(this.props.userCompanies)}
                                            onChange={this.handleSelectChange}
                                            placeholder={t("Choose company")}
                                            value={destination_company_id}
                                        />
                                    </Form.Field>           
                                    
                                    {error && <Message error header={t("Error")} content={error.response.data} />}
                                    {success_mgs && ( 
                                        <Form.Field width="8">
                                            <Message positive header={success_mgs} />
                                        </Form.Field>
                                    )}
                                    
                                    <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleSubmit(e)}>
                                        {t("Send move request")}
                                    </Button>
                                </Form>
                            </React.Fragment>
                        )}
                    </>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        userCompanies: state.company.userCompanies,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('sensormovetoothercompany')(SensorMoveToOtherCompany))
);